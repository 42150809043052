import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAccident } from "../../../../actions/accidentDashboard";
import {
  cleanAccidentsWithFilters,
  getAccidentsWithFilters,
  reOpenAccident,
} from "../../../../actions/accidents";
import snackbarUtils from "../../../../utils/snackbarUtils";
import ConfirmDialog from "../../../commons/dialog/confirmDialog";
import Search from "../../../commons/search/search";
import AccidentTable from "../../../commons/table/accidentTable";
import { useTableStyles } from "../../../commons/table/config/tableConfig";
import AccidentDashboardContainer from "../accidentDashboard/accidentDashboardContainer";
import EditAccidentContainer from "../edit/editAccidentContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "24px",
  },
  title: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "large",
    fontWeight: 500,
    color: "#484848",
    marginBottom: "8px",
  },
}));

const SearchContainer = () => {
  const [displayAccidents, setDisplayAccidents] = useState("block");

  const classes = useStyles();
  const dispatch = useDispatch();
  const tableClasses = useTableStyles(displayAccidents)();

  const accidents = useSelector((state) => state.accidents);
  const art = useSelector((state) => state.art.artsAdhered);

  const [accidentToEdit, setAccidentToEdit] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [accidentToReopen, setAccidentToReOpen] = useState(null);
  const [lastSearchFilters, setLastSearchFilters] = useState("");

  useEffect(() => {
    dispatch(cleanAccidentsWithFilters());
  }, []);

  const handleSearch = (params) => {
    dispatch(getAccidentsWithFilters(params));
    setLastSearchFilters(params);
  };

  const handleOpenConfirmDialog = (accident) => {
    setOpenConfirmDialog(true);
    setAccidentToReOpen(accident);
  };

  const handleAccidentDetail = (accident) => {
    dispatch(setSelectedAccident(accident));
    setDisplayAccidents("none");
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const confirmReOpenAccident = () => {
    dispatch(reOpenAccident(accidentToReopen))
      .then(() => {
        handleCloseConfirmDialog();
        handleSearch(lastSearchFilters);
        snackbarUtils.success("Siniestro reabierto correctamente");
      })
      .catch((err) => err && handleCloseConfirmDialog());
  };

  return (
    <div className={classes.container}>
      {displayAccidents === "none" && (
        <AccidentDashboardContainer setDisplayAccidents={setDisplayAccidents} />
      )}
      <div className={tableClasses.container}>
        <div className={classes.title}>Buscar Siniestro</div>
        <Search handleSearch={handleSearch} art={art} />
      </div>
      <AccidentTable
        data={accidents.listFilter}
        loading={accidents.isFetchingFilter}
        error={accidents.didInvalidateFilter}
        noRefresh
        handleAccidentDetail={handleAccidentDetail}
        displayAccidents={displayAccidents}
        handleEdit={(accident) => setAccidentToEdit(accident)}
        handleReOpenAccident={(accident) => handleOpenConfirmDialog(accident)}
        openInSearch={true}
      />
      <EditAccidentContainer
        accident={accidentToEdit}
        handleClose={() => setAccidentToEdit(null)}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        handleClose={handleCloseConfirmDialog}
        handleConfirm={confirmReOpenAccident}
        title={"¿Está seguro que desea reabrir este Siniestro?"}
        text="Al hacerlo, el mismo será accesible desde Siniestros > En Curso."
        warningText="Advertencia: Si el Siniestro no está abierto para la ART, esta acción no se ejecutará."
        loading={accidents.reOpen.isFetching}
      />
    </div>
  );
};

export default SearchContainer;
