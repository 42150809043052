import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormProfessionals, postForm } from "../../../../actions/forms";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import { useStyles } from "../formStyle";
import Idr from "./idr";
import IdrConfirmDialog from "./idrConfirmDialog";

const initialIdrModel = {
  fechaHora: null,
  nroFormularioFPA: null,
  detalle: "",
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  especialidadProfesional: "",
  especialidadInforme: "",
  informacionSensible: false,
};

const IdrContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenCancelDialog,
  selectedDocument,
  idSiniestro,
  idArt,
  disabled,
  postFormCallback,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const professionals = useSelector((state) => state.forms.professionals);
  const idr = useSelector((state) => state.formsState.idr);
  const [idrModel, setIdrModel] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [files, setFiles] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [formPostValues, setFormPostValues] = useState({});

  const disabledForm = disabled !== null ? disabled : (form.data.roles ? !form.data.roles?.editar : true);
  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    professionals.isFetching ||
    !idrModel || !formValues;

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!isView) {
      if (!model.isFetching) {
        setFormValues(() => ({
          ...initialIdrModel,
          nroFormularioFPA: idr.nroFormularioFPA
            ? idr.nroFormularioFPA
            : initialIdrModel.nroFormularioFPA,
          fechaHora: new Date(),
          profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
            .options
            ? model.data.fields.profesionalApellidoNombre.options
            : initialIdrModel.profesionalApellidoNombre,
          especialidadInforme: model.data.fields?.especialidadInforme.options
            ? model.data.fields?.especialidadInforme.options
            : initialIdrModel.especialidadInforme,
          informacionSensible: model.data.fields?.informacionSensible.options
            ? model.data.fields?.informacionSensible.options
            : initialIdrModel.informacionSensible,
        }));
        setIdrModel(model.data);
      }
    } else if (!form.isFetching) {
      setIdrModel(form.data.model);
      setFormValues(form.data.form);
    }
  }, [model.isFetching, form.isFetching, isView]);

  useEffect(() => {
    if (!isLoading) {
      setLoaded(true);
    }
  }, [isLoading]);

  const handleSubmitForm = (values) => {
    let fpa;
    if (isView) {
      fpa = form.data.model.fields.nroFormularioFPA.options.find(
        (f) => f.id === values.nroFormularioFPA,
      );
    } else {
      fpa = model.data.fields.nroFormularioFPA.options.find(
        (f) => f.id === values.nroFormularioFPA,
      );
    }
    if (fpa && fpa.existeFormularioAsociado) {
      handleOpenConfirmDialog(values);
    } else {
      handleSubmit(values);
    }
  };

  const handleOpenConfirmDialog = (values) => {
    setFormPostValues(values);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setFormPostValues({});
  };

  const handleSubmit = (form) => {
    const filesAux = [];
    files.forEach((e) =>
      filesAux.push({ ...e, nombre: e.filename || e.nombre, base64: e.value }),
    );
    const model = {
      ...form,
      numeroSiniestroPrestador: idSiniestro,
      adjuntos: filesAux,
      fechaHora: convertDateTimeToNet(form.fechaHora),
    };
    handleCloseConfirmDialog();

    dispatch(postForm("idr", model, idArt))
      .then(() => {
        dispatch(getFormProfessionals(idArt));
      })
      .then(() => {
        if (postFormCallback) {
          postFormCallback(form);
        }
        handleClose(true, false);
      }).catch((e) => { handleClose(true, true) })
  };
  const closeIdr = () => {
    if (isView) {
      handleClose();
    } else {
      handleOpenCancelDialog();
    }
  }

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.formLoadingContainer}>
          <CircularLoading />
        </div>
      ) : (
        <Idr
          handleClose={closeIdr}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmitForm}
          model={idrModel}
          formValues={formValues}
          roles={form.data.roles}
          selectedDocument={selectedDocument}
          disabled={disabledForm}
          idArt={idArt}
        />
      )
      }
      <IdrConfirmDialog
        handleClose={handleCloseConfirmDialog}
        open={openConfirmDialog}
        title="CONFIRMAR FORMULARIO"
        text={`El Pedido de Autorización seleccionado ya se encuentra asociado a otro Formulario.\n\n¿Está seguro que desea asociar adicionalmente este Formulario?`}
        handleSubmit={handleSubmit}
        form={formPostValues}
      />
    </div>
  );
};

export default IdrContainer;
