import { Button, Grid, MenuItem, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import FormikDatePicker from "../../../commons/formik/formikDatePicker";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextField from "../../../commons/formik/formikTextField";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    padding: 20,
    marginBottom: 20,
  },
  input: {
    marginTop: 10,
    marginRight: 20,
    width: 250,
  },
  checkbox: {
    marginTop: 20,
  },
  buttonContainer: {
    width: 250,
    marginTop: "15%",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const validationSchema = yup.object().shape({
  art: yup.string().required("La ART es obligatoria"),
  fechaDesde: yup
    .date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaDesde",
      message: "La fecha desde debe ser menor o igual a la fecha hasta",
      test: function (value) {
        if (this.parent.fechaHasta && value <= this.parent.fechaHasta) {
          return true;
        }
        return false;
      },
    }),

  fechaHasta: yup
    .date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaHasta",
      message: "La fecha hasta debe ser mayor o igual a la fecha desde",
      test: function (value) {
        if (this.parent.fechaDesde && value >= this.parent.fechaDesde) {
          return true;
        }
        return false;
      },
    }),
});
const Search = ({ handleSubmit, loading, initialValues }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const arts = useSelector((state) => state.art.artsAdhered);

  return (
    <div>
      <Paper className={classes.paper}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ errors }) => (
            <Form>
              <Grid container>
                <Grid item xs={11}>
                  <FormikSelect
                    name="art"
                    label="Art"
                    variant="standard"
                    value={initialValues.art}
                    className={classes.input}>
                    {arts.map((art) => {
                      return (
                        <MenuItem value={art.id} key={art.id}>
                          {art.nombre}
                        </MenuItem>
                      );
                    })}
                  </FormikSelect>
                  <FormikTextField
                    name="siniestro"
                    variant="standard"
                    label="Siniestro"
                    className={classes.input}
                  />
                  <FormikTextField
                    name="dni"
                    variant="standard"
                    label="DNI"
                    className={classes.input}
                  />
                  <FormikDatePicker
                    name="fechaDesde"
                    variant="outlined"
                    disabledButtons
                    label="Fecha Desde"
                    className={classes.input}
                  />
                  <FormikDatePicker
                    disabledButtons
                    name="fechaHasta"
                    variant="outlined"
                    label="Fecha Hasta"
                    className={classes.input}
                  />
                </Grid>
                <Grid item xs={1}>
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      type="submit"
                      color="primary"
                      disabled={loading}>
                      Buscar
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default Search;
