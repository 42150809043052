import {
    CLOSE_FORM,
    LOADED_FORM,
    OPEN_EDIT_FORM,
    OPEN_NEW_FORM,
    SET_FORMS_COMMON_PROPERTIES,
    SET_FORMS_DOCUMENT_LIST
} from "../actions/formsState";
import { formBehaviourEnum } from "../components/commons/enum/formEnums";

const INITIAL_STATE = {
    open: null,
    behaviour: null,
    loadingForm: true,
    documentList: [], // Son objectos que contienen los mismos valores que selectedDocument
    commonProperties: {
        idSiniestro: null,
        idArt: null,
        artName: null,
    },
    selectedDocument: {
        trabajador: null,
        documento: null,
        nroFormulario: null,
        idRespError: null,
    },
    formTitle: null,
    fpt: {
        appointmentDates: [],
        appointmentsIds: [],
        specialityName: "",
        professionalName: "",
    },
    idr: {
        nroFormularioFPA: null,
    },
    iami: {

    },
    iet: {

    },
    am: {

    },
    fpa: {

    }

}

const formsState = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case OPEN_EDIT_FORM: {
            const { formType, formBehaviour, props, selectedDocument } = action.payload;
            return {
                ...state,
                loadingForm: true,
                open: formType,
                behaviour: formBehaviour,
                selectedDocument: selectedDocument,
                [formType]: {
                    ...INITIAL_STATE[formType],
                    ...props,
                }
            }
        }
        case OPEN_NEW_FORM: {
            const { formType, props, formTitle } = action.payload;
            return {
                ...state,
                loadingForm: true,
                selectedDocument: null,
                open: formType,
                behaviour: formBehaviourEnum.CREATE_ACCIDENT_DOCUMENT,
                formTitle: formTitle,
                [formType]: {
                    ...INITIAL_STATE[formType],
                    ...props,
                }
            }
        }
        case SET_FORMS_DOCUMENT_LIST: {
            return {
                ...state,
                documentList: action.payload.documentList,
            }
        }
        case SET_FORMS_COMMON_PROPERTIES: {
            return {
                ...state,
                commonProperties: {
                    ...INITIAL_STATE.commonProperties,
                    ...action.payload,
                }
            }
        }

        case LOADED_FORM: {
            return {
                ...state,
                loadingForm: false,
            }
        }
        case CLOSE_FORM: {
            return {
                ...INITIAL_STATE,
                documentList: state.documentList,
                commonProperties: state.commonProperties,
            };
        }


        default:
            return state;
    }
};

export default formsState;