import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFiles } from "../../../actions/files";
import { downloadBase64AsCsv } from "../../../utils/filesUtils";
import FilesTable from "../../commons/table/filesTable";
import EditFileDialog from "./EditFileDialog";

const FilesContainer = () => {
  const dispatch = useDispatch();

  const availableFiles = useSelector((state) => state.files.availableFiles);

  const [fileToEdit, setFileToEdit] = useState(null);
  const [isEditingFile, setIsEditingFile] = useState(false);

  useEffect(() => {
    dispatch(getFiles());
  }, []);

  const handleEditFile = (file) => {
    setIsEditingFile(true);
    setFileToEdit(file);
  };

  const handleDownloadFile = (file) => {
    // TODO: modificar tabla de ArchivosDisponibles para que
    // haya un campo de MimeType y Extension.
    downloadBase64AsCsv(file.base64, `${file.descripcion}.csv`);
  };

  const handleClose = () => {
    setFileToEdit(null);
    setIsEditingFile(false);
  };

  return (
    <div>
      <FilesTable
        data={availableFiles.data}
        loading={availableFiles.loading}
        fetchData={() => dispatch(getFiles())}
        handleEdit={handleEditFile}
        handleDownload={handleDownloadFile}
      />

      <EditFileDialog
        open={isEditingFile}
        file={fileToEdit}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FilesContainer;
