import {
  GET_PENDING_AUTHORIZATIONS,
  GET_PENDING_DETAILS_AUTHORIZATIONS,
  UPDATE_PENDING_AUTHORIZATION,
} from "../actions/authorizations";
import { didInvalidate } from "../utils/errorHandler";

const INITIAL_STATE = {
  isFetching: false,
  isFetchingDetail: false,
  didInvalidate: false,
  listDetails: {},
  list: [],
};

const pendingAuthorizations = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_PENDING_AUTHORIZATIONS}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        token: false,
      };
    case `${GET_PENDING_AUTHORIZATIONS}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case `${GET_PENDING_AUTHORIZATIONS}_FULFILLED`:
      action.payload.data.authorizations.forEach((row) => {
        row["conTurnos"] = row.cantidadTurnos > 0 ? true : false;
      });

      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        list: action.payload.data.authorizations,
      };

    case `${GET_PENDING_DETAILS_AUTHORIZATIONS}_REJECTED`:
      return {
        ...state,
        isFetchingDetail: false,
        didInvalidate: true,
        token: false,
      };
    case `${GET_PENDING_DETAILS_AUTHORIZATIONS}_PENDING`:
      return {
        ...state,
        isFetchingDetail: true,
        didInvalidate: false,
      };
    case `${GET_PENDING_DETAILS_AUTHORIZATIONS}_FULFILLED`:
      const listDetails = state.listDetails;

      listDetails[action.payload.siniestroId] = {
        ...listDetails[action.payload.siniestroId],
        [action.payload.formNumber]: action.payload.data,
      };

      return {
        ...state,
        isFetchingDetail: false,
        didInvalidate: didInvalidate(action),
        listDetails,
      };

    case `${UPDATE_PENDING_AUTHORIZATION}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      };

    case `${UPDATE_PENDING_AUTHORIZATION}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };

    case `${UPDATE_PENDING_AUTHORIZATION}_FULFILLED`:
      const updatedAuthorization = action.payload.data;
      let authorizations = state.list;

      if (action.payload.data.estado !== "Pendiente") {
        authorizations = state.list.filter(
          (authorization) =>
            !(
              authorization.siniestroId === updatedAuthorization.siniestroId &&
              authorization.nroFormulario === updatedAuthorization.nroFormulario
            )
        );
      } else {
        authorizations = state.list.map((authorization) =>
          authorization.siniestroId === updatedAuthorization.siniestroId
            ? { ...authorization, ...updatedAuthorization }
            : authorization
        );
      }

      return {
        ...state,
        isFetching: false,
        didInvalidate: didInvalidate(action),
        list: authorizations,
      };

    default:
      return state;
  }
};

export default pendingAuthorizations;
