import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: 8,
  },
  name: {
    fontWeight: "bold",
  },
  alta: {
    color: "#cf0000",
    fontWeight: "bold",
  },
}));

const AccidentHeader = () => {
  const classes = useStyles();

  const accident = useSelector((state) => state.accidentDashboard.accident);

  return (
    <div className={classes.container}>
      <Typography className={classes.name}>
        {accident.apellidoTrabajador}, {accident.nombreTrabajador}{" "}
        {accident.documento}
      </Typography>
      <Typography>{accident.art}</Typography>
      <Typography className={accident.alta ? classes.alta : null}>
        {accident.estado}
      </Typography>
      <Typography>Siniestro {accident.siniestro}</Typography>
    </div>
  );
};

export default AccidentHeader;
