import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import edge_browser from "../../../assets/images/edge.png";
import chrome_browser from "../../../assets/images/chrome.png";
import opera_browser from "../../../assets/images/opera.png";
import firefox_browser from "../../../assets/images/firefox.png";

const useStyles = makeStyles((theme) => ({
  center: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto, sans-serif",
    fontSize: "x-large",
    alignSelf: "center",
    alignItems: "center",
    textAlign: "center",
  },
  browser: {
    color: "#303030",
    fontSize: "medium",
    marginRight: 20,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  link: {
    color: "blue",
  },
}));

export default function UnsupportedBrowser() {
  const classes = useStyles();

  return (
    <div className={classes.center}>
      <div className={classes.root}>
        <p>
          <b>Internet Explorer</b> no es compatible con <b>ml.point</b>
          <br />
          <br />
          Le sugerimos utilizar cualquiera de los siguientes navegadores:
          <br />
          <br />
        </p>
        <div className={classes.container}>
          <div className={classes.browser}>
            <img src={edge_browser} alt="Microsoft Edge" title="Microsoft Edge" width="80" height="80" />
            <div>
              <a className={classes.link} href="https://www.microsoft.com/es-es/edge/" target="_blank" rel="noreferrer">
                Microsoft Edge
              </a>
            </div>
          </div>
          <div className={classes.browser}>
            <img src={chrome_browser} alt="Google Chrome" title="Google Chrome" width="80" height="80" />
            <div>
              <a
                className={classes.link}
                href="https://www.google.com/intl/es/chrome/"
                target="_blank"
                rel="noreferrer"
              >
                Google Chrome
              </a>
            </div>
          </div>
          <div className={classes.browser}>
            <img src={opera_browser} alt="Opera" title="Opera" width="80" height="80" />
            <div>
              <a className={classes.link} href="https://www.opera.com/es/" target="_blank" rel="noreferrer">
                Opera
              </a>
            </div>
          </div>
          <div className={classes.browser}>
            <img src={firefox_browser} alt="Mozilla Firefox" title="Mozilla Firefox" width="80" height="80" />
            <div>
              <a className={classes.link} href="https://www.mozilla.org/es-ES/" target="_blank" rel="noreferrer">
                Mozilla Firefox
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
