import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import CircularLoading from "../loadingData/circularLoading";

const useStyles = (showActionButtons) =>
  makeStyles((theme) => ({
    title: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      marginLeft: 20,
      marginRight: 40,
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      padding: 10,
      backgroundColor: theme.palette.secondary.dark,
    },
    text: {
      color: "white",
      fontWeight: "bold",
      textTransform: "upperCase",
    },
    closeIcon: {
      color: "white",
      cursor: "pointer",
      width: 25,
      height: 25,
    },
    dialogContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 20,
      overflow: "auto",
      height: showActionButtons ? "calc(100vh - 166px)" : "100%",
    },
    actionsContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      backgroundColor: "white",
      paddingRight: 10,
      boxShadow: "0px 0 5px lightGrey",
    },
    circularLoadingContainer: {
      marginTop: 80,
      marginBottom: 80,
      width: "100%",
    },
    printButtonContainer: {
      display: "none",
    },
    print: {
      margin: "20px",
    },
  }));

const PrintAndDownloadDialog = ({
  maxWidth = "sm",
  openModal,
  handleCloseModal,
  childComponent,
  printComponent = null,
  loading,
  disableCancelButton = false,
  disablePrintButton = false,
  disableDownloadButton = false,
  handleDownload,
  showCancelButton = true,
  showDownloadButton = true,
  showPrintButton = true,
  title,
  reactToPrintProps = {},
}) => {
  const componentRef = useRef();
  const classes = useStyles(showActionButtons)();

  const showActionButtons =
    showCancelButton || showDownloadButton || showPrintButton;

  const handlePrint = useReactToPrint({
    ...reactToPrintProps,
    content: () => componentRef.current,
  });

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth={maxWidth}
        disableBackdropClick={true}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            <Typography className={classes.text}>{title}</Typography>
          </div>
          <Tooltip title="Cerrar">
            <Close onClick={handleCloseModal} className={classes.closeIcon} />
          </Tooltip>
        </div>
        {loading ? (
          <div className={classes.circularLoadingContainer}>
            <CircularLoading />
          </div>
        ) : (
          <div>
            <DialogContent className={classes.dialogContainer}>
              {childComponent}
            </DialogContent>
            {showActionButtons && (
              <div className={classes.actionsContainer}>
                <DialogActions>
                  {showCancelButton && (
                    <Button
                      onClick={handleCloseModal}
                      color="primary"
                      disabled={disableCancelButton}>
                      Cancelar
                    </Button>
                  )}
                  {showDownloadButton && (
                    <Button
                      onClick={handleDownload}
                      color="primary"
                      disabled={disableDownloadButton}
                      variant="contained">
                      Descargar
                    </Button>
                  )}
                  {showPrintButton && (
                    <Button
                      onClick={handlePrint}
                      color="primary"
                      disabled={disablePrintButton}
                      variant="contained">
                      Imprimir
                    </Button>
                  )}
                </DialogActions>
              </div>
            )}
          </div>
        )}
      </Dialog>
      {showPrintButton && (
        <div className={classes.printButtonContainer}>
          <div className={classes.print} ref={componentRef}>
            {!!printComponent ? printComponent : childComponent}
          </div>
        </div>
      )}
    </>
  );
};

export default PrintAndDownloadDialog;
