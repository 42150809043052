import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikTextField from "../../../commons/formik/formikTextField";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import { makeStyles } from "@material-ui/core";
import { getFormDiagnosis } from "../../../../actions/forms";
import { useDispatch, useSelector } from "react-redux";
import { validateRequiredFields } from "../../forms/formValidation";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
  },
  input: {
    flex: 1,
    marginBottom: 15,
  },
}));

const validation = Yup.object({
  nroSiniestroArt: Yup.string().max(
    15,
    "El campo no puede superar los 15 caracteres",
  ),
});

const EditAccidentForm = ({
  accident,
  model,
  artId,
  handleClose,
  handleSubmit,
  fromFacade,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const diagnosis = useSelector((state) => state.forms.diagnosis);

  const diagnosisIsFound = diagnosis.data.some(
    (d) => d.id === accident.diagnosticoOMS,
  );

  if (!diagnosisIsFound) {
    accident.diagnosticoOMS = "";
  }

  return (
    <Formik
      initialValues={accident}
      validationSchema={validation}
      validate={(values) => validateRequiredFields(values, model)}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      {({ setFieldValue }) => (
        <Form className={classes.container}>
          <FormikTextField
            name="nroSiniestroArt"
            label={`Nro Siniestro ART ${
              model.nroSiniestroArt.required ? "*" : ""
            }`}
            type="text"
            className={classes.input}
            disabled={fromFacade}
          />
          <FormikAutocomplete
            name="referenteApellidoyNombre"
            label={`Referente ART ${
              model.referenteApellidoyNombre.required ? "*" : ""
            }`}
            options={model.referenteApellidoyNombre.options}
            callBack={(value) => {
              if (value) {
                setFieldValue("idReferenteExterno", value.id);
                setFieldValue("referenteEmail", value.email);
              } else {
                setFieldValue("idReferenteExterno", null);
                setFieldValue("referenteEmail", null);
              }
            }}
            returnProp="nombre"
            className={classes.input}
            disabled={
              fromFacade && accident.referenteApellidoyNombre ? true : false
            }
          />
          <FormikAutocomplete
            name="diagnosticoOMS"
            label={`Diagnóstico OMS ${
              model.diagnosticoOMS.required ? "*" : ""
            }`}
            options={diagnosis.data}
            getOptions={() => dispatch(getFormDiagnosis(artId))}
            className={classes.input}
            renderOption={(option) => `${option.id} - ${option.nombre}`}
            filterOptions={(option) => option.id + option.nombre}
            disabled={fromFacade && diagnosisIsFound}
          />
          <DialogActions>
            <FormikActions handleCancel={handleClose} />
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default EditAccidentForm;
