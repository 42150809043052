import { makeStyles, Tooltip } from "@material-ui/core";
import { CloudDone, CloudOffSharp } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  icon: { cursor: "pointer" },
}));

const NewsIconComponent = ({ document, openErrorDetail }) => {
  const classes = useStyles();
  return (
    <div>
      {document.error ? (
        <Tooltip arrow title="Ver errores">
          <CloudOffSharp
            color="error"
            onClick={() => openErrorDetail(document)}
            className={classes.icon}
          />
        </Tooltip>
      ) : (
        <CloudDone color="secondary" />
      )}
    </div>
  );
};

export default NewsIconComponent;
