import { FormControlLabel, Radio, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getFormSpecialtiesDiagnosis } from "../../../../actions/forms";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";
import FormikRadio from "../../../commons/formik/formikRadio";
import FormikTextField from "../../../commons/formik/formikTextField";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    flex: 1,
    marginBottom: 15,
  },
}));

const validation = Yup.object({
  idEspecialidadDiagnosticoOms: Yup.string()
    .required("Campo obligatorio")
    .nullable(),
  atencion: Yup.string().required("Campo obligatorio"),
  turno: Yup.date()
    .nullable()
    .min(
      new Date(Date.now()),
      "La fecha del turno no puede ser menor a la fecha actual",
    )
    .typeError("Fecha inválida")
    .test({
      name: "FechaTurnoObligatorio",
      message: "Campo obligatorio",
      test: function (value) {
        return this.parent.atencion === "En este mismo centro" && !value
          ? false
          : true;
      },
    }),
  observaciones: Yup.string()
    .max(500, "El campo no puede superar los 500 caracteres")
    .nullable(),
});

const EspecialidadesAdd = ({
  especialidad,
  open,
  handleClose,
  handleSubmit,
  idArt,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const specialties = useSelector((state) => state.forms.specialtiesDiagnosis);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      <DialogTitle>Agregar especialidad continúa tratamiento</DialogTitle>
      <Formik
        initialValues={especialidad}
        validationSchema={validation}
        onSubmit={(values) => {
          handleSubmit(values);
        }}>
        {({ values, setFieldValue }) => (
          <Form>
            <DialogContent className={classes.container}>
              <FormikAutocomplete
                name="idEspecialidadDiagnosticoOms"
                label="Especialidad *"
                options={specialties.data}
                getOptions={() =>
                  dispatch(
                    getFormSpecialtiesDiagnosis(idArt),
                  )
                }
                className={classes.input}
                labelOption={(option) =>
                  `${option.especialidad} - ${option.diagnostico}`
                }
                renderOption={(option) =>
                  `${option.especialidad} - ${option.diagnostico}`
                }
                filterOptions={(option) =>
                  option.especialidad + option.diagnostico
                }
              />
              <div className={classes.rowContainer}>
                <FormikRadio
                  name="atencion"
                  value={especialidad.atencion}
                  label="Atención"
                  callBack={(value) => {
                    if (value !== "En este mismo centro") {
                      setFieldValue("turno", null);
                    }
                  }}>
                  {["En este mismo centro", "En otro prestador"].map((o) => (
                    <FormControlLabel
                      value={o}
                      control={<Radio />}
                      label={o}
                      labelPlacement="end"
                      key={o}
                    />
                  ))}
                </FormikRadio>
                {values.atencion === "En este mismo centro" && (
                  <FormikDateTimePicker
                    name="turno"
                    label="Fecha Hora Turno *"
                    className={classes.input}
                    disablePast
                    autoComplete="off"
                  />
                )}
              </div>
              <FormikTextField
                name="observaciones"
                label="Observaciones *"
                type="text"
                multiline
                className={classes.input}
              />
            </DialogContent>
            <DialogActions>
              <FormikActions handleCancel={handleClose} />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EspecialidadesAdd;
