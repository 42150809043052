import { GET_CONFIDENTIAL_FIELDS } from "../actions/fields";

const INITIAL_STATE = {
  confidential: {
    loading: false,
    error: false,
    data: [],
  },
};

const fields = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_CONFIDENTIAL_FIELDS}_REJECTED`:
      return {
        ...state,
        confidential: {
          loading: false,
          error: true,
        },
      };
    case `${GET_CONFIDENTIAL_FIELDS}_PENDING`:
      return {
        ...state,
        confidential: {
          loading: true,
          error: false,
        },
      };
    case `${GET_CONFIDENTIAL_FIELDS}_FULFILLED`: {
      return {
        ...state,
        confidential: {
          loading: false,
          error: false,
          data: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default fields;
