import { Card, CircularProgress, makeStyles } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetailsAuthorizations } from "../../../../actions/authorizations";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    marginLeft: "32px",
    marginRight: "16px",
  },
  content: {
    display: "flex",
  },
  details: {
    flex: 1,
    display: "flex",
    justifyContent: "start",
    padding: "14px",
  },
  loading: {
    flex: 1,
    display: "flex",
    height: "210px",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    margin: "0 0.8cm 0 0.8cm",
  },
  textTitle: {
    fontWeight: "bold",
  },
  textValue: {
    marginLeft: "18px",
    fontWeight: 300,
  },
  answeredAuthorizationContainer: {
    display: "flex",
  },
  authorizationDetailsTable: {
    border: "none",
    boxShadow: "none",
  },
});

const detailsTableAuthorizations = ({ rowData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const detailsAnsweredAuthorizations = useSelector(
    (state) => state.answeredAuthorizations,
  );
  const detailsPendingAuthorizations = useSelector(
    (state) => state.pendingAuthorizations,
  );

  let detailsAuthorizations;

  if (rowData.respondida === true) {
    detailsAuthorizations = detailsAnsweredAuthorizations;
  } else if (rowData.respondida === false) {
    detailsAuthorizations = detailsPendingAuthorizations;
  }

  useEffect(() => {
    if (
      !detailsAuthorizations.listDetails[rowData.siniestroId] ||
      !detailsAuthorizations.listDetails[rowData.siniestroId][
        rowData.nroFormulario
      ]
    ) {
      dispatch(
        getDetailsAuthorizations(
          rowData.siniestroId,
          rowData.artMLCloud,
          rowData.nroFormulario,
          rowData.respondida,
        ),
      );
    }
  }, []);
  return (
    <div>
      {(!detailsAuthorizations.listDetails[rowData.siniestroId] ||
        !detailsAuthorizations.listDetails[rowData.siniestroId][
          rowData.nroFormulario
        ]) &&
      detailsAuthorizations.isFetchingDetail ? (
        <div className={classes.container}>
          <Card>
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          </Card>
        </div>
      ) : (
        <div className={classes.container}>
          <Card>
            <div className={classes.content}>
              <div className={classes.details}>
                <div className={classes.textTitle}>Solicitado por: </div>
                <div className={classes.textValue}>
                  {rowData.solicitadoPor || "---"}
                </div>
              </div>

              <div className={classes.details}>
                <div className={classes.textTitle}>Administrador: </div>
                <div className={classes.textValue}>
                  {rowData.administrador || "---"}
                </div>
              </div>
            </div>

            <div className={classes.details}>
              <div className={classes.textTitle}>Detalle: </div>
              <div className={classes.textValue}>
                {rowData.detalle || "---"}
              </div>
            </div>

            {detailsAuthorizations.listDetails[rowData.siniestroId] &&
              detailsAuthorizations.listDetails[rowData.siniestroId][
                rowData.nroFormulario
              ] && (
                <div className={classes.table}>
                  <MaterialTable
                    className={classes.authorizationDetailsTable}
                    columns={
                      rowData.respondida === true
                        ? [
                            { title: "Código", field: "codigo" },
                            { title: "Nombre", field: "nombre" },
                            { title: "Cantidad", field: "cantidad" },
                            { title: "Autorizado", field: "autorizado" },
                            { title: "Estado", field: "estado" },
                          ]
                        : [
                            { title: "Código", field: "codigo" },
                            { title: "Nombre", field: "nombre" },
                            { title: "Cantidad", field: "cantidad" },
                          ]
                    }
                    data={
                      rowData.respondida === true
                        ? (
                            detailsAuthorizations.listDetails[
                              rowData.siniestroId
                            ][rowData.nroFormulario] || []
                          ).map((data) => ({
                            codigo: data.codigo || "---",
                            nombre: data.nombre || "---",
                            cantidad: data.cantidad || "---",
                            autorizado: data.autorizado === true ? "Sí" : "No",
                            estado: data.estado || "---",
                          }))
                        : (
                            detailsAuthorizations.listDetails[
                              rowData.siniestroId
                            ][rowData.nroFormulario] || []
                          ).map((data) => ({
                            codigo: data.codigo || "---",
                            nombre: data.nombre || "---",
                            cantidad: data.cantidad || "---",
                          }))
                    }
                    localization={{
                      body: {
                        emptyDataSourceMessage: "No hay detalles para mostrar",
                      },
                    }}
                    options={{
                      search: false,
                      paging: false,
                      showTitle: false,
                      draggable: false,
                      toolbar: false,
                      sorting: false,
                      rowStyle: {
                        color: "#FF",
                        fontFamily: "Roboto, sans-serif",
                      },

                      headerStyle: {
                        fontFamily: "Roboto, sans-serif",
                      },
                    }}
                  />
                </div>
              )}

            {rowData.respondida === true ? (
              <div className={classes.answeredAuthorizationContainer}>
                <div className={classes.details}>
                  <div className={classes.textTitle}>
                    Observaciones de Art:{" "}
                  </div>
                  {rowData.observacionesAutorizacion || "---"}
                </div>

                <div className={classes.details}>
                  <div className={classes.textTitle}>Estado: </div>
                  <div className={classes.textValue}>
                    {rowData.estado || "---"}
                  </div>
                </div>
              </div>
            ) : null}
          </Card>
        </div>
      )}
    </div>
  );
};

export default detailsTableAuthorizations;
