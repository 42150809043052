import { Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import DarkerDisabledTextField from "../../../commons/formComponents/darkerDisabledTextField";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";
import FormikTextFieldSpeech from "../../../commons/formik/formikTextFieldSpeech";
import DropZone from "../dropZone/dropZone";
import FormSectionDivider from "../formSectionDivider";
import { useStyles } from "../formStyle";
import { idrValidation, validateRequiredFields } from "../formValidation";
import ProfessionalControl from "../professionalControl/professionalControl";

const Idr = ({
  handleClose,
  files,
  setFiles,
  handleSubmit,
  model,
  formValues,
  selectedDocument,
  disabled,
  idArt,
}) => {
  const classes = useStyles();

  const [attachmentError, setAttachmentError] = useState(false);
  const [professionalError, setProfessionalError] = useState(false);

  const fields = model.fields;


  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => idrValidation()}
      validate={(values) => validateRequiredFields(values, fields)}
      onSubmit={(values) => {
        if (model.configuration.requiresAttachment && files.length === 0) {
          setAttachmentError(true);
        } else if (!professionalError) {
          handleSubmit(values);
        }
      }}>
      {({ values, setFieldValue }) => (
        <Form className={classes.container}>
          <div className={classes.fieldContainer}>
            <FormSectionDivider title="Informe / Documentación Respaldatoria" />
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikDateTimePicker
                  name="fechaHora"
                  label={`Fecha Hora ${fields.fechaHora.required ? "*" : ""}`}
                  inputVariant="outlined"
                  className={classes.input}
                  disabled={true}
                  autoComplete="off"
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikAutocomplete
                  name="nroFormularioFPA"
                  label={`Pedido de Autorización para asociar ${fields.nroFormularioFPA.required ? "*" : ""
                    }`}
                  variant="outlined"
                  options={fields.nroFormularioFPA.options}
                  className={classes.input}
                  renderOption={(option) =>
                    `${moment(option.fecha).format("DD/MM/YYYY HH:mm")} ${option.estado ? "-" : ""
                    } ${option.estado || ""} ${option.detalle ? "-" : ""}  ${option.detalle || ""
                    }`
                  }
                  labelOption={(option) =>
                    `${moment(option.fecha).format("DD/MM/YYYY HH:mm")} ${option.estado ? "-" : ""
                    } ${option.estado || ""}`
                  }
                  filterOptions={(option) =>
                    moment(option.fecha).format("DD/MM/YYYY HH:mm") +
                    option.estado +
                    option.detalle
                  }
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <DarkerDisabledTextField
                label=""
                type="text"
                variant="outlined"
                value="Recuerde la importancia para la ART de asociar el Pedido de Autorización correspondiente al Informe enviado en esta evolución, si existiera uno."
                multiline
                className={classes.input}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="detalle"
                name="detalle"
                label={`Detalle ${fields.detalle.required ? "*" : ""}`}
                placeholder="Copie aquí el Informe de Estudio o detalle del adjunto"
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <FormSectionDivider title="Profesional" />
            <ProfessionalControl
              disabled={disabled}
              fields={fields}
              values={values}
              setFieldValue={setFieldValue}
              profesionalPreCarga={formValues.profesionalApellidoNombre}
              error={professionalError}
              setError={setProfessionalError}
              especialidadesProfesional={fields.especialidadProfesional}
              habilitarEspecialidadInforme={true}
              idArt={idArt}
            />
            {model.configuration.showAttachments && (
              <div>
                <FormSectionDivider title="Adjuntos" />
                <div className={classes.rowContainer}>
                  <DropZone
                    files={files}
                    setFiles={setFiles}
                    configuration={model.configuration}
                    error={attachmentError}
                    setError={setAttachmentError}
                    disabled={disabled}
                    selectedDocument={selectedDocument}
                    accept={model.configuration.availableExtensions}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classes.actionsContainer}>
            <FormikActions handleCancel={handleClose} disabled={disabled} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Idr;
