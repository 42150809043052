import { Button, makeStyles, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    textAlign: "center",
    padding: 10,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: "white",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  actionsContainer: {
    margin: 10,
  },
  title: {
    color: "white",
    fontWeight: "bold",
    textTransform: "upperCase",
  },
  text: {
    whiteSpace: "pre-line",
  },
}));

const IdrConfirmDialog = ({
  handleClose,
  open,
  title,
  text,
  handleSubmit,
  form,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
      </DialogTitle>
      <div>
        <DialogContent className={classes.contentContainer}>
          <Typography className={classes.text}>{text}</Typography>
        </DialogContent>
        <DialogActions className={classes.actionsContainer}>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="button"
            onClick={() => handleSubmit(form)}>
            Aceptar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default IdrConfirmDialog;
