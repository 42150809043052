import {
  GET_ANSWERED_AUTHORIZATIONS,
  GET_ANSWERED_DETAILS_AUTHORIZATIONS,
  UPDATE_ANSWERED_AUTHORIZATION,
  GET_ANSWERED_AUTHORIZATIONS_NOTIFICATION,
} from "../actions/authorizations";
import { didInvalidate } from "../utils/errorHandler";

const INITIAL_STATE = {
  isFetching: false,
  isFetchingDetail: false,
  didInvalidate: false,
  listDetails: {},
  list: [],
  notification: {
    isFetching: false,
    didInvalidate: false,
    count: 0,
  },
};

const answeredAuthorizations = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_ANSWERED_AUTHORIZATIONS}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        token: false,
      };
    case `${GET_ANSWERED_AUTHORIZATIONS}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case `${GET_ANSWERED_AUTHORIZATIONS}_FULFILLED`:
      action.payload.data.authorizations.forEach((row) => {
        row["conTurnos"] = row.cantidadTurnos > 0 ? true : false;
      });

      return {
        ...state,
        isFetching: false,
        didInvalidate: didInvalidate(action),
        list: action.payload.data.authorizations,
      };

    case `${GET_ANSWERED_DETAILS_AUTHORIZATIONS}_REJECTED`:
      return {
        ...state,
        isFetchingDetail: false,
        didInvalidate: true,
        token: false,
      };
    case `${GET_ANSWERED_DETAILS_AUTHORIZATIONS}_PENDING`:
      return {
        ...state,
        isFetchingDetail: true,
        didInvalidate: false,
      };
    case `${GET_ANSWERED_DETAILS_AUTHORIZATIONS}_FULFILLED`:
      const listDetails = state.listDetails;

      listDetails[action.payload.siniestroId] = {
        ...listDetails[action.payload.siniestroId],
        [action.payload.formNumber]: action.payload.data,
      };

      return {
        ...state,
        isFetchingDetail: false,
        didInvalidate: didInvalidate(action),
        listDetails,
      };
    case `${UPDATE_ANSWERED_AUTHORIZATION}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      };
    case `${UPDATE_ANSWERED_AUTHORIZATION}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: didInvalidate(action),
      };
    case `${UPDATE_ANSWERED_AUTHORIZATION}_FULFILLED`:
      let updatedList = [...state.list];
      if (action.payload && action.payload.data) {
        if (action.payload.data.estado !== "Pendiente") {
          updatedList = updatedList.map((each) => {
            if (each.siniestroId === action.payload.data.siniestroId) {
              return { ...each, ...action.payload.data };
            } else {
              return each;
            }
          });
        } else {
          let index = -1;
          updatedList.forEach((art, i) => {
            if (art.siniestroId === action.payload.data.siniestroId) {
              index = i;
            }
          });
          if (index >= 0) {
            updatedList.splice(index, 1);
          }
        }
      }

      return {
        ...state,
        isFetching: false,
        list: updatedList,
      };
    case `${GET_ANSWERED_AUTHORIZATIONS_NOTIFICATION}_REJECTED`:
      return {
        ...state,
        notification: {
          ...state.notification,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ANSWERED_AUTHORIZATIONS_NOTIFICATION}_PENDING`:
      return {
        ...state,
        notification: {
          ...state.notification,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ANSWERED_AUTHORIZATIONS_NOTIFICATION}_FULFILLED`:
      return {
        ...state,
        notification: {
          ...state.notification,
          isFetching: false,
          didInvalidate: false,
          count: action.payload.data.authorizations.length,
        },
      };
    default:
      return state;
  }
};

export default answeredAuthorizations;
