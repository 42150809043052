import React, { useEffect, useState } from "react";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikCheckbox from "../../../commons/formik/formikCheckbox";
import { useStyles } from "../formStyle";

const SpecialtyForm = ({
  disabled,
  fields,
  setFieldValue,
  // Lista de especialidades para el profesion seleccionado
  especialidadesInforme,
  values,
  isView,
  // Lista de especialidades completa
  especialidadesProfesional,
}) => {
  const classes = useStyles();

  const [initialValuesAreSet, setInitialValuesAreSet] = useState(false);


  const buscarEspecialidad = (valor) => {
    return especialidadesProfesional.options.find(
      (e) => e.id.toUpperCase() == valor.toUpperCase(),
    )
  }

  useEffect(() => {
    if (
      especialidadesInforme.length !== 0 &&
      especialidadesProfesional.length !== 0
    ) {
      let especialidad;

      // Caso inicial el valor puede venir de distintos lugares pero
      // despues de eso el componente usa la misma logica para cambiar los valores en todos los caso

      if (initialValuesAreSet) {
        // Caso valores no iniciales siempre tomo el valor de especialidad
        // Usamos especialidades informe ya que tiene las opciones correctas
        especialidad = buscarEspecialidad(values.especialidadProfesional)
        setFieldValue("especialidadInforme", values.especialidadProfesional);
        especialidad &&
          setFieldValue("informacionSensible", especialidad.informacionSensible);

      } else {



        setInitialValuesAreSet(true);
        // Si esta en valores (Por ejemplo cuando es view) 
        if (values.especialidadInforme) {
          especialidad = buscarEspecialidad(values.especialidadInforme)
        } else if (fields.especialidadInforme.options) {
          // Es por si el valor no viene en valuesIniciales pero viene en options
          setFieldValue("especialidadInforme", fields.especialidadInforme.options);
          especialidad = buscarEspecialidad(fields.especialidadInforme.options);
        }
        else {
          // Usamos la especialidad del profesional como valor default en este caso
          setFieldValue("especialidadInforme", values.especialidadProfesional);
          especialidad = buscarEspecialidad(values.especialidadProfesional)
        }

        // Si informacion sensible me llego del back uso ese valor casos contrario el que me dice la especialidad por default
        if (!values.informacionSensible && especialidad) {
          setFieldValue("informacionSensible", especialidad.informacionSensible)
        }
      }
    }
  }, [especialidadesInforme]);

  return (
    <div className={classes.rowContainer}>
      <div className={classes.leftColumnContainer}>
        <FormikAutocomplete
          name="especialidadInforme"
          label={`Especialidad del informe ${fields.especialidadInforme.required ? "*" : ""
            }`}
          callBack={(value) => {
            if (value) {
              setFieldValue(
                "informacionSensible",
                value.informacionSensible || false,
              );
              return;
            }
            setFieldValue("informacionSensible", false);
          }}
          variant="outlined"
          options={especialidadesInforme}
          className={classes.input}
          disabled={disabled}
        />
      </div>
      <div className={classes.rightColumnContainerCheckBoxStart}>
        <FormikCheckbox
          name="informacionSensible"
          label="Información Sensible"
          labelPlacement="end"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SpecialtyForm;
