import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccidentsInTreatment,
  getPendingAuthorizations,
} from "../../../actions/dashboard";
import DashboardContainer from "./dashboardContainer";

const useStyles = makeStyles({
  circularLoadingContainer: {
    justifyContent: "center",
    marginTop: "230px",
    textAlign: "center",
  },
  dashboardContainer: {
    backgroundColor: "#f5f6fa",
  },
});

const Container = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accidentsInTreatment = useSelector(
    (state) => state.dashboard.accidentsInTreatment,
  );
  const pendingAuthorizations = useSelector(
    (state) => state.dashboard.pendingAuthorizations,
  );
  const namesArtsArray = useSelector((state) => state.art.namesArtsArray);

  useEffect(() => {
    if (!accidentsInTreatment.isFetching && !accidentsInTreatment.data.length) {
      dispatch(getAccidentsInTreatment());
    }
    if (
      !pendingAuthorizations.isFetching &&
      !pendingAuthorizations.data.length
    ) {
      dispatch(getPendingAuthorizations());
    }
  }, []);

  return (
    <div className={classes.dashboardContainer}>
      {namesArtsArray.length ? (
        <DashboardContainer />
      ) : (
        <div className={classes.circularLoadingContainer}>
          <CircularProgress size={40} />
        </div>
      )}
    </div>
  );
};

export default Container;
