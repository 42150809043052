import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        padding: 10,
        backgroundColor: theme.palette.primary.main,
    },
    titleContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        marginLeft: 20,
        marginRight: 40,
    },
    text: {
        color: "white",
    },
    name: {
        fontWeight: "bold",
    },
    closeIcon: {
        color: "white",
        cursor: "pointer",
        width: 25,
        height: 25,
    },
}));

const EditFormHeader = ({ handleClose, selectedDocument, idSiniestro, artName }) => {
    const classes = useStyles();


    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography className={`${classes.text} ${classes.name}`}>
                    {
                        (!!selectedDocument.trabajador ? selectedDocument.trabajador : "")
                        + ` ${selectedDocument.documento}`
                    }
                </Typography>
                <Typography className={classes.text}>
                    {artName}
                </Typography>
                <Typography className={classes.text}>
                    Siniestro {idSiniestro}
                </Typography>
            </div>
            <Tooltip title="Cerrar">
                <Close onClick={handleClose} className={classes.closeIcon} />
            </Tooltip>
        </div>
    );
    /*
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography className={`${classes.text} ${classes.name}`}>
          {accident.trabajador || selectedDocument.trabajador}{" "}
          {accident.documento || selectedDocument.documento}
        </Typography>
        <Typography className={classes.text}>
          {accident.art || selectedDocument.art}
        </Typography>
        <Typography className={classes.text}>
          Siniestro {accident.siniestro || selectedDocument.siniestro}
        </Typography>
      </div>
      <Tooltip title="Cerrar">
        <Close onClick={handleClose} className={classes.closeIcon} />
      </Tooltip>
    </div>
    */
};

export default EditFormHeader;
