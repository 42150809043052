export const OPEN_EDIT_FORM = 'OPEN_EDIT_FORM';
export const CLOSE_FORM = 'CLOSE_FORM';
export const OPEN_NEW_FORM = 'OPEN_NEW_FORM';
export const SET_FORMS_DOCUMENT_LIST = 'SET_FORMS_DOCUMENT_LIST';
export const SET_FORMS_COMMON_PROPERTIES = 'SET_FORMS_COMMON_PROPERTIES';
export const LOADED_FORM = 'LOADED_FORM'

// Selected document contiene la informacion relevante del form como Id, IdResError que son necesarios para ese origen
export const openEditForm = (formType, formBehaviour, selectedDocument, props = {}) => (dispatch) => {
    return dispatch({
        type: OPEN_EDIT_FORM,
        payload: { formType: formType.toLowerCase(), formBehaviour, selectedDocument, props }
    })
}
export const openNewForm = (formType, formTitle, props = {}) => (dispatch) => {
    return dispatch({
        type: OPEN_NEW_FORM,
        payload: { formType: formType.toLowerCase(), formTitle, props }
    })
}

export const closeForm = (props) => (dispatch) => {
    return dispatch({
        type: CLOSE_FORM,
        payload: props
    })
}

export const setFormsDocumentList = (documentList) => (dispatch) => {
    return dispatch({
        type: SET_FORMS_DOCUMENT_LIST,
        payload: { documentList }
    })
}

export const setFormsGeneralProperties = (props) => (dispatch) => {
    return dispatch({
        type: SET_FORMS_COMMON_PROPERTIES,
        payload: props,
    })
}

export const setLoadedForm = () => (dispatch) => {
    return dispatch({
        type: LOADED_FORM,
    })
} 