import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikActions from "../formik/formikActions";
import FormikAutocomplete from "../formik/formikAutocomplete";
import FormikSelect from "../formik/formikSelect";

const useStyles = makeStyles({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  circularProgress: {
    marginLeft: 200,
    marginTop: 100,
    marginBottom: 100,
  },
});

const validation = Yup.object({
  id: Yup.string().required("Campo obligatorio"),
});

const EditProviderInUserDialog = ({
  provider,
  providersInUser,
  providers,
  open,
  handleClose,
  addingNewProvider,
  handleSubmit,
  loading,
}) => {
  const classes = useStyles();

  let availableProviders = [];
  if (addingNewProvider && !loading) {
    availableProviders = providers.filter((p) => {
      let flag = true;
      providersInUser.forEach((pInUser) => {
        if (pInUser.id === p.id) {
          flag = false;
          return;
        }
      });
      return flag;
    });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      disableBackdropClick={true}>
      {loading ? (
        <CircularProgress className={classes.circularProgress} size={40} />
      ) : (
        <div>
          <DialogTitle>
            {addingNewProvider
              ? "Asociar prestador"
              : "Editar prestador asociado"}
          </DialogTitle>
          <Formik
            initialValues={provider}
            validationSchema={validation}
            onSubmit={(values) => {
              handleSubmit(values, addingNewProvider);
            }}>
            {({ setFieldValue }) => (
              <Form>
                <DialogContent className={classes.contentContainer}>
                  {addingNewProvider ? (
                    <FormikAutocomplete
                      name="id"
                      label="Prestador"
                      options={providers}
                      className={classes.input}
                      renderOption={(option) =>
                        `${option.id} - ${option.nombre}`
                      }
                      filterOptions={(option) => option.id + option.nombre}
                    />
                  ) : (
                    <FormikSelect
                      name="id"
                      value={provider.id}
                      label="Prestador"
                      disabled>
                      <MenuItem value={provider.id}>
                        {provider.nombre ? provider.nombre : ""}
                      </MenuItem>
                    </FormikSelect>
                  )}
                </DialogContent>
                <DialogActions>
                  <FormikActions handleCancel={handleClose} />
                </DialogActions>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Dialog>
  );
};

export default EditProviderInUserDialog;
