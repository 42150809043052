import {
  GET_ARTS,
  POST_ART,
  PUT_ART,
  DELETE_ART,
  GET_ART_PRESTADORES,
} from "../actions/arts";
import { didInvalidate } from "../utils/errorHandler";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  isLoadingEditArt: false,
  didInvalidateEditArt: false,
  list: [],
  artPrestadores: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
};

const arts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_ARTS}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        didInvalidateEditArt: false,
      };
    case `${GET_ARTS}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        didInvalidateEditArt: false,
      };
    case `${GET_ARTS}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: didInvalidate(action),
        list: action.payload.data.arts,
        didInvalidateEditArt: false,
      };

    case `${POST_ART}_REJECTED`:
      return {
        ...state,
        isLoadingEditArt: false,
        didInvalidateEditArt: true,
      };
    case `${POST_ART}_PENDING`:
      return {
        ...state,
        isLoadingEditArt: true,
        didInvalidateEditArt: false,
      };
    case `${POST_ART}_FULFILLED`:
      let newList = state.list;
      if (action.payload.data.workRiskInsurance) {
        newList = [...newList, action.payload.data.workRiskInsurance];
      }
      return {
        ...state,
        isLoadingEditArt: false,
        didInvalidateEditArt: didInvalidate(action),
        list: newList,
      };

    case `${PUT_ART}_REJECTED`:
      return {
        ...state,
        isLoadingEditArt: false,
        didInvalidateEditArt: true,
      };
    case `${PUT_ART}_PENDING`:
      return {
        ...state,
        isLoadingEditArt: true,
        didInvalidateEditArt: false,
      };
    case `${PUT_ART}_FULFILLED`:
      let updatedList = state.list;
      if (action.payload.art) {
        updatedList = updatedList.map((art) => {
          if (art.id === action.payload.art.id) {
            return action.payload.art;
          }
          return art;
        });
      }
      return {
        ...state,
        isLoadingEditArt: false,
        didInvalidateEditArt: didInvalidate(action),
        list: updatedList,
      };
    case `${DELETE_ART}_REJECTED`:
      return {
        ...state,
        isLoadingDeleteArt: false,
        didInvalidateDeleteArt: true,
      };
    case `${DELETE_ART}_PENDING`:
      return {
        ...state,
        isLoadingDeleteArt: true,
        didInvalidateDeleteArt: false,
      };
    case `${DELETE_ART}_FULFILLED`:
      let deletedElementList = [...state.list];
      const _didInvalidate = didInvalidate(action);

      if (!_didInvalidate) {
        let index = -1;
        deletedElementList.forEach((art, i) => {
          if (art.id === action.payload.data.id) {
            index = i;
          }
        });
        if (index >= 0) {
          deletedElementList.splice(index, 1);
        }
      }
      return {
        ...state,
        isLoadingDeleteArt: false,
        didInvalidateDeleteArt: _didInvalidate,
        list: deletedElementList,
      };
    case `${GET_ART_PRESTADORES}_REJECTED`:
      return {
        ...state,
        artPrestadores: {
          ...state.artPrestadores,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ART_PRESTADORES}_PENDING`:
      return {
        ...state,
        artPrestadores: {
          ...state.artPrestadores,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ART_PRESTADORES}_FULFILLED`: {
      return {
        ...state,
        artPrestadores: {
          ...state.artPrestadores,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default arts;
