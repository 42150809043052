import DateFnsUtils from "@date-io/date-fns";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from "date-fns/esm/locale";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAnsweredAuthorizationsNotification,
  updateAnsweredAuthorizations,
  updatePendingAuthorizations,
  updateSearchAuthorizations,
} from "../../../actions/authorizations";
import TextFieldSpeech from "../speechToText/textFieldSpeech";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    width: "fit-content",
    justifyContent: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  datePicker: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  dialogContent: {
    overflow: "hidden",
  },
}));

export default function ModalFPAState({
  open,
  handleClose,
  autorizacion,
  estadoSeleccionado,
  setEstadoSeleccionado,
  fechaAutorizacionSeleccionada,
  setFechaAutorizacionSeleccionada,
  codigoAutorizacion,
  setCodigoAutorizacion,
  observacionesAutorizacion,
  setobservacionesAutorizacion,
  isAnswered,
  disabled,
  isSearch,
  handleOpenConfirmDialog,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const title = "Nuevo estado";
  const inputLabel = "Estado";
  let options = [
    "Pendiente",
    "Auditoria",
    "Autorizado",
    "Derivado",
    "Parcial",
    "Rechazado",
    "SinRespArt",
  ];
  const maxLengthCodigoAutorizacion = 15;
  const maxLengthObservacionesArt = 100;

  if (autorizacion) {
    options = options.filter((each) => each !== autorizacion.estado);
  }

  const [formErrors, setFormErrors] = useState({
    codigoAutorizacion: false,
    observacionesAutorizacion: false,
    estado: false,
  });

  const handleChange = (event) => {
    setEstadoSeleccionado(event.target.value || "");
    setFormErrors({ ...formErrors, estado: false });
  };

  const handleConfirm = () => {
    if (
      !formErrors.codigoAutorizacion &&
      !formErrors.observacionesAutorizacion &&
      validarEstado() &&
      validarFechaAutorizacion()
    ) {
      const artId = autorizacion.artMLCloud;
      const modeloAutorizacion = {
        siniestroId: autorizacion.siniestroId,
        nroFormulario: autorizacion.nroFormulario,
        estado: estadoSeleccionado,
        fechaAutorizacion: fechaAutorizacionSeleccionada,
        codigoAutorizacion: codigoAutorizacion,
        observacionAutorizacion: observacionesAutorizacion,
      };
      if (isAnswered && !isSearch) {
        dispatch(updateAnsweredAuthorizations(artId, modeloAutorizacion));
      } else if (isSearch) {
        dispatch(updateSearchAuthorizations(artId, modeloAutorizacion));
      } else {
        dispatch(updatePendingAuthorizations(artId, modeloAutorizacion)).then(
          () => dispatch(getAnsweredAuthorizationsNotification()),
        );
      }
      handleClose();
    }
  };

  const validarEstado = () => {
    if (!estadoSeleccionado) {
      setFormErrors({ ...formErrors, estado: true });
    }
    return !!estadoSeleccionado;
  };

  const validarFechaAutorizacion = (
    fechaAutorizacion = fechaAutorizacionSeleccionada,
  ) => {
    if (
      moment(new Date(fechaAutorizacion)).diff(
        moment(new Date(autorizacion.fecha)).format("YYYY-MM-DDT00:00:00"),
      ) < 0
    ) {
      setFormErrors({ ...formErrors, fechaAutorizacionSeleccionada: true });
      return false;
    }

    setFormErrors({ ...formErrors, fechaAutorizacionSeleccionada: false });
    return true;
  };

  const editCodigoAutorizacion = (event) => {
    const inputText = event.target.value;
    setCodigoAutorizacion(inputText);
    if (
      inputText.length > maxLengthCodigoAutorizacion &&
      !formErrors.codigoAutorizacion
    ) {
      setFormErrors({ ...formErrors, codigoAutorizacion: true });
    } else if (
      inputText.length <= maxLengthCodigoAutorizacion &&
      formErrors.codigoAutorizacion
    ) {
      setFormErrors({ ...formErrors, codigoAutorizacion: false });
    }
  };

  const editobservacionesAutorizacion = (value, isAudio, idSelected) => {
    if (idSelected === "observacionesAutorizacion") {
      if (isAudio) {
        const aux = observacionesAutorizacion || "";
        setobservacionesAutorizacion(aux + value);
        validateObservacionesAutorizacion(aux + value);
      } else {
        setobservacionesAutorizacion(value);
        validateObservacionesAutorizacion(value);
      }
    }
  };

  const validateObservacionesAutorizacion = (inputText) => {
    if (
      inputText.length > maxLengthObservacionesArt &&
      !formErrors.observacionesAutorizacion
    ) {
      setFormErrors({ ...formErrors, observacionesAutorizacion: true });
    } else if (
      inputText.length <= maxLengthObservacionesArt &&
      formErrors.observacionesAutorizacion
    ) {
      setFormErrors({ ...formErrors, observacionesAutorizacion: false });
    }
  };

  return autorizacion ? (
    <div>
      <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form className={classes.form}>
            <Grid container direction={"row"} spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classes.formControl}
                  error={formErrors.estado}>
                  <InputLabel required htmlFor={inputLabel}>
                    {inputLabel}
                  </InputLabel>
                  <Select
                    value={estadoSeleccionado}
                    disabled={disabled}
                    onChange={handleChange}
                    input={<Input id={inputLabel} />}>
                    {options.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Requerido</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <KeyboardDatePicker
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="dateFrom"
                    label="Fecha de autorización"
                    value={moment(fechaAutorizacionSeleccionada).format(
                      "YYYY-MM-DDT00:00:00",
                    )}
                    autoOk
                    disableFuture
                    invalidDateMessage="Fecha inválida"
                    invalidLabel="Fecha inválida"
                    minDate={moment(autorizacion.fecha).format(
                      "YYYY-MM-DDT00:00:00",
                    )}
                    minDateMessage="Fecha inválida"
                    maxDateMessage="Fecha inválida"
                    onChange={(e) => {
                      const from = moment(e).format("YYYY-MM-DDT00:00:00");
                      setFechaAutorizacionSeleccionada(from);
                      validarFechaAutorizacion(from);
                    }}
                    className={classes.datePicker}
                    required
                    disabled={disabled}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="codigoAutorizacion"
                  label="Código de autorización de la ART"
                  fullWidth
                  value={codigoAutorizacion || ""}
                  onChange={editCodigoAutorizacion}
                  error={formErrors.codigoAutorizacion}
                  helperText={`Máximo ${maxLengthCodigoAutorizacion} caracteres.`}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldSpeech
                  id="observacionesAutorizacion"
                  label="Observaciones de la ART"
                  fullWidth
                  multiline
                  value={observacionesAutorizacion || ""}
                  onChange={editobservacionesAutorizacion}
                  error={formErrors.observacionesAutorizacion}
                  helperText={`Máximo ${maxLengthObservacionesArt} caracteres.`}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOpenConfirmDialog}
            color="primary"
            disabled={disabled}>
            Cancelar
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            disabled={
              formErrors.observacionesAutorizacion ||
              formErrors.codigoAutorizacion ||
              formErrors.estado ||
              estadoSeleccionado === "" ||
              formErrors.fechaAutorizacionSeleccionada ||
              disabled
            }>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
}
