import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAccident } from "../../../../actions/accidentDashboard";
import {
  cleanAuthorizationsWithFilters,
  getAuthorizationsWithFilters,
} from "../../../../actions/authorizations";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import AnsweredAuthorizationsTable from "../../../commons/table/answeredAuthorizationsTable";
import AccidentDashboardContainer from "../../accident/accidentDashboard/accidentDashboardContainer";
import FormContainer from "../../forms/formContainer";
import SearchAuthorizationsForm from "./searchAuthorizationsForm";

const useStyles = makeStyles({
  container: {
    marginBottom: "24px",
  },
  title: {
    color: "#484848",
    marginBottom: "8px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "large",
    fontWeight: 500,
  },
});

const initialSearchValues = {
  siniestro: "",
  dni: "",
  fechaDesde: convertDateTimeToNet(new Date()),
  fechaHasta: convertDateTimeToNet(new Date()),
  otrosPrestadores: false,
};

const AuthorizationsSearchContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const authorizations = useSelector((state) => state.authorizations);

  const [displayAccidents, setDisplayAccidents] = useState("block");
  const [searchFilters, setSearchFilters] = useState(initialSearchValues);

  useEffect(() => {
    dispatch(cleanAuthorizationsWithFilters());
  }, []);

  const handleSearch = (values) => {
    const searchModel = {
      ...values,
      fechaDesde: values.fechaDesde
        ? convertDateTimeToNet(values.fechaDesde)
        : "",
      fechaHasta: values.fechaHasta
        ? convertDateTimeToNet(values.fechaHasta)
        : "",
    };

    setSearchFilters(searchModel);
    dispatch(getAuthorizationsWithFilters(searchModel));
  };

  const handleAccidentDetail = (accident) => {
    dispatch(
      setSelectedAccident({
        ...accident,
        mlCloudCoreArtId: accident.artMLCloud,
        idSiniestro: accident.siniestroId,
      }),
    );
    setDisplayAccidents("none");
  };

  return (
    <div className={classes.container}>
      {displayAccidents === "none" && (
        <AccidentDashboardContainer setDisplayAccidents={setDisplayAccidents} />
      )}
      {displayAccidents !== "none" && (
        <div>
          <div className={classes.title}>Buscar Autorización</div>
          <SearchAuthorizationsForm
            handleSubmit={handleSearch}
            loading={authorizations.isFetchingFilter}
          />
        </div>
      )}
      <AnsweredAuthorizationsTable
        data={authorizations.listFilter.authorizations}
        loading={authorizations.isFetchingFilter}
        error={authorizations.didInvalidate}
        // cuando se agrega un turno/sesiones a una autorización, queremos
        // que se actualice el ícono de cantidad de turnos
        fetchData={() => dispatch(getAuthorizationsWithFilters(searchFilters))}
        title="Autorizaciones"
        isSearch={true}
        handleAccidentDetail={handleAccidentDetail}
        displayAccidents={displayAccidents}
      />
      <FormContainer />
    </div>
  );
};

export default AuthorizationsSearchContainer;
