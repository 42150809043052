import { makeStyles, Paper } from "@material-ui/core";
import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import snackbarUtils from "../../../../../utils/snackbarUtils";
import CircularLoading from "../../../../commons/loadingData/circularLoading";
import CalendarLabel from "./calendarLabel";
import "./reactCalendar.css";

const useStyles = makeStyles((theme) => ({
  calendar: {
    boxShadow: "3px 3px 7px rgba(219,219,219,1)",
    width: "10000px !important",
    borderRadius: "0px 0px 5px 5px",
  },
  tileCalendar: {
    height: 31,
    padding: 0,
  },
  tileContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  tileContent: {
    padding: 5,
    paddingLeft: 12,
    paddingRight: 12,
    marginLeft: 2,
    marginRight: 2,
    borderRadius: 10,
  },
  loading: {
    display: "flex",
    height: 250,
  },
  absentState: {
    backgroundColor: "black",
  },
  notInformedState: {
    backgroundColor: "red",
  },
  presentState: {
    backgroundColor: "blue",
  },
  plannedState: {
    backgroundColor: "green",
  },
  anulledState: {
    backgroundColor: "grey",
  },
}));

const CalendarComponent = ({ data, loading, handleClickDay, disabled }) => {
  const classes = useStyles();

  const getAppointmentsOnDate = (date) => {
    if (!loading) {
      const appointments = [];
      data.forEach((appointment) => {
        if (
          new Date(appointment.fechaHora).toDateString() === date.toDateString()
        ) {
          appointments.push(appointment);
        }
      });
      return appointments;
    }
  };

  const tileContent = (appointments) => {
    return (
      <div className={classes.tileContentContainer}>
        {appointments.map((appointment) => {
          switch (appointment.estado.toUpperCase()) {
            case "AUSENTES":
              return (
                <div
                  key={appointment.idTurnoSiniestro}
                  className={`${classes.tileContent} ${classes.absentState}`}
                />
              );
            case "NO INFORMADOS":
              return (
                <div
                  key={appointment.idTurnoSiniestro}
                  className={`${classes.tileContent} ${classes.notInformedState}`}
                />
              );
            case "PRESENTES":
              return (
                <div
                  key={appointment.idTurnoSiniestro}
                  className={`${classes.tileContent} ${classes.presentState}`}
                />
              );
            case "PROGRAMADOS":
              return (
                <div
                  key={appointment.idTurnoSiniestro}
                  className={`${classes.tileContent} ${classes.plannedState}`}
                />
              );
            case "ANULADOS":
              return (
                <div
                  key={appointment.idTurnoSiniestro}
                  className={`${classes.tileContent} ${classes.anulledState}`}
                />
              );
            default:
              return null;
          }
        })}
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <Paper elevation={2} className={classes.loading}>
          <CircularLoading />
        </Paper>
      ) : (
        <div>
          <Calendar
            className={classes.calendar}
            tileClassName={classes.tileCalendar}
            view="month"
            tileContent={({ activeStartDate, date, view }) =>
              tileContent(getAppointmentsOnDate(date))
            }
            onClickDay={(date, event) => {
              let appointment = getAppointmentsOnDate(date);
              if (disabled && !appointment.length) {
                snackbarUtils.error(
                  "Funcionalidad agregar turno deshabilitada",
                );
              } else {
                handleClickDay(getAppointmentsOnDate(date), date);
              }
            }}
          />
          <CalendarLabel />
        </div>
      )}
    </div>
  );
};

export default CalendarComponent;
