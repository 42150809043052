import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../../actions/accidentDashboard";
import { postForm } from "../../../../actions/forms";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import snackbarUtils from "../../../../utils/snackbarUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import { useStyles } from "../formStyle";
import Fpa from "./fpa";
import FpaAutorizationDialog from "./fpaAutorizationDialog";
import FpaConfirmDialog from "./fpaConfirmDialog";

const initialFpaModel = {
  nombreTipoFormulario: null,
  fechaHoraPedido: null,
  enInstitucionPropia: true,
  diagnostico: "",
  detalleAutorizacion: "",
  planTerapeutico: "",
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  especialidadProfesional: "",
  especialidadInforme: "",
  informacionSensible: false,
};

const FpaContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenCancelDialog,
  selectedDocument,
  idSiniestro,
  idArt,
  disabled,
  postFormCallback,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const professionals = useSelector((state) => state.forms.professionals);
  const prestacionesState = useSelector((state) => state.forms.prestaciones);
  const [fpaModel, setFpaModel] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [files, setFiles] = useState([]);
  const [prestaciones, setPrestaciones] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAutorizationDialog, setOpenAutorizationDialog] = useState(false);
  const [codigoAutorizacion, setCodigoAutorizacion] = useState(null);
  const [formPostValues, setFormPostValues] = useState({});
  const [loading, setLoading] = useState(false);
  const disabledForm =
    disabled !== null
      ? disabled
      : form.data.roles
      ? !form.data.roles?.editar
      : true;
  const isLoading =
    loading ||
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    professionals.isFetching ||
    !fpaModel ||
    !formValues ||
    (isView && !prestacionesState.data);

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
      setPrestaciones(form.data.form.prestaciones);
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!isView) {
      if (!model.isFetching) {
        setFpaModel(model.data);
        setFormValues({
          ...initialFpaModel,
          diagnostico: model.data.fields.diagnostico.options
            ? model.data.fields.diagnostico.options
            : initialFpaModel.diagnostico,
          profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
            .options
            ? model.data.fields.profesionalApellidoNombre.options
            : initialFpaModel.profesionalApellidoNombre,
          nombreTipoFormulario:
            model.data.fields.tiposFormulario.options.length === 1
              ? model.data.fields.tiposFormulario.options[0].nombre
              : initialFpaModel.nombreTipoFormulario,
          especialidadInforme: model.data.fields?.especialidadInforme.options
            ? model.data.fields?.especialidadInforme.options
            : initialFpaModel.especialidadInforme,
          informacionSensible: model.data.fields?.informacionSensible.options
            ? model.data.fields?.informacionSensible.options
            : initialFpaModel.informacionSensible,
        });
      }
    } else if (!form.isFetching) {
      setFpaModel(form.data.model);
      setFormValues(form.data.form);
    }
  }, [model.isFetching, form.isFetching, isView]);

  useEffect(() => {
    if (!isLoading) {
      setLoaded(true);
    }
  }, [isLoading]);

  const handleCloseAutorizationDialog = () => {
    setOpenAutorizationDialog(false);
    setOpenConfirmDialog(true);
  };

  const handleOpenOtherFpa = () => {
    // Si el usuario hace click en "Guardar y Continuar", pero luego en el
    // segundo FPA cancela la operación, deberia ver el primer formulario
    // cargado en la lista de documentos.
    dispatch(getDocuments(idSiniestro, idArt));
    setPrestaciones([]);
    setFormValues({
      ...formPostValues,
      detalleAutorizacion: "",
    });
    setLoading(false);
    handleCloseConfirmDialog();
    snackbarUtils.success("Formulario ingresado correctamente");
  };

  const handleConfirmAndClose = () => {
    handleClose(false, false);
    handleCloseConfirmDialog();
    snackbarUtils.success("Formulario ingresado correctamente");
  };

  const handleSubmit = (form) => {
    const filesAux = [];
    files.forEach((e) =>
      filesAux.push({ ...e, nombre: e.filename || e.nombre, base64: e.value }),
    );
    setFormPostValues(form);
    const model = {
      ...form,
      numeroSiniestroPrestador: idSiniestro,
      adjuntos: filesAux,
      prestaciones: prestaciones,
      fechaHoraPedido: convertDateTimeToNet(form.fechaHoraPedido),
    };
    setLoading(true);
    dispatch(postForm("fpa", model, idArt))
      .then((res) => {
        if (!isView) {
          if (res.value.data.codigoAutorizacion !== "0") {
            setOpenAutorizationDialog(true);
            setCodigoAutorizacion(res.value.data.codigoAutorizacion);
          } else {
            setOpenConfirmDialog(true);
          }
        } else {
          handleClose(true, false);
        }
      })
      .then(() => {
        if (postFormCallback) {
          postFormCallback(form);
        }
      })
      .catch((e) => {
        handleClose(true, true);
      });
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const closeFpa = () => {
    if (isView) {
      handleClose();
    } else {
      handleOpenCancelDialog();
    }
  };

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.formLoadingContainer}>
          <CircularLoading />
        </div>
      ) : (
        <Fpa
          handleClose={closeFpa}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={fpaModel}
          formValues={formValues}
          roles={form.data.roles}
          selectedDocument={selectedDocument}
          prestaciones={prestaciones}
          setPrestaciones={setPrestaciones}
          isView={isView}
          disabled={disabledForm}
          idArt={idArt}
        />
      )}
      <FpaConfirmDialog
        open={openConfirmDialog}
        title="CONFIRMAR FORMULARIO"
        text="¿Desea cargar un nuevo pedido de autorización?"
        handleConfirmarYFinalizar={handleConfirmAndClose}
        handleConfirmarYContinuar={handleOpenOtherFpa}
      />
      <FpaAutorizationDialog
        open={openAutorizationDialog}
        title="PEDIDO DE AUTORIZACION"
        handleSubmit={handleCloseAutorizationDialog}
        codigoAutorizacion={codigoAutorizacion}
      />
    </div>
  );
};

export default FpaContainer;
