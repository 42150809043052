import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import deLocale from "date-fns/locale/es";
import { useField, useFormikContext } from "formik";
import React from "react";
const useStyles = makeStyles({
  dialogActions: {},
});
const FormikDatePicker = (props) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();
  const handleChange = (value) => {
    setFieldValue(props.name, value);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <KeyboardDatePicker
        ampm={false}
        format="dd/MM/yyyy"
        autoOk={true}
        variant="inline"
        {...props}
        {...field}
        error={meta.touched && meta.error ? true : false}
        helperText={meta.touched && meta.error ? meta.error : null}
        onChange={handleChange}
        cancelLabel={props.disabledButtons ? "" : undefined}
        okLabel={props.disabledButtons ? "" : undefined}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FormikDatePicker;
