import {
  GET_ART,
  GET_ARTS_NOT_ADHERED,
  REQUEST_ADHESION,
} from "../actions/art";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  list: [],
  namesArts: {},
  namesArtsArray: [],
  artsAdhered: [],
  listAssignedArts: {
    isFetching: false,
    didInvalidate: false,
    list: [],
  },
  listArtsNotAdhered: {
    isFetching: false,
    didInvalidate: false,
    list: [],
  },
  requestAdhesion: {
    isFetching: false,
    didInvalidate: false,
    list: [],
  },
};

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_ART}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      };
    case `${GET_ART}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case `${GET_ART}_FULFILLED`: {
      const dataHelp = [];
      const names = {};
      const namesArtsArray = [];
      const shortNameArt = {};

      action.payload.data.forEach((item) => {
        if (item.adherida === 0) {
          dataHelp.push(item);
          names[item.nombre] = item.nombre;
          namesArtsArray.push(item.nombre);
          shortNameArt[item.nombreCorto] = item.nombreCorto;
        }
      });

      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        list: action.payload.data,
        artsAdhered: dataHelp,
        namesArts: names,
        namesArtsArray,
        shortNameArt,
        listAssignedArts: {
          list: action.payload.data,
          isFetching: false,
          didInvalidate: false,
        },
      };
    }

    case `${GET_ARTS_NOT_ADHERED}_REJECTED`:
      return {
        ...state,
        listArtsNotAdhered: {
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ARTS_NOT_ADHERED}_PENDING`:
      return {
        ...state,
        listArtsNotAdhered: {
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ARTS_NOT_ADHERED}_FULFILLED`:
      return {
        ...state,
        listArtsNotAdhered: {
          isFetching: false,
          didInvalidate: false,
          list: action.payload.data,
        },
      };

    case `${REQUEST_ADHESION}_REJECTED`:
      return {
        ...state,
        requestAdhesion: {
          ...state.requestAdhesion,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${REQUEST_ADHESION}_PENDING`:
      return {
        ...state,
        requestAdhesion: {
          ...state.requestAdhesion,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${REQUEST_ADHESION}_FULFILLED`:
      let art = action.payload.art;
      let availableArts = state.listArtsNotAdhered.list;
      let notAdheredArt = state.listAssignedArts.list;

      availableArts.splice(availableArts.indexOf(art), 1);
      art.adherida = 2;
      notAdheredArt.push(art);

      return {
        ...state,
        requestAdhesion: {
          ...state.requestAdhesion,
          isFetching: false,
          didInvalidate: false,
        },
        listAssignedArts: {
          ...state.listAssignedArts,
          list: notAdheredArt,
        },
        listArtsNotAdhered: {
          ...state.listArtsNotAdhered,
          list: availableArts,
        },
      };

    default:
      return state;
  }
};

export default providers;
