import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentsCalendar,
  getAvailableDocumentsToAdd,
  getDocuments,
} from "../../../actions/accidentDashboard";
import { getAccidents } from "../../../actions/accidents";
import { clearIdTurnos } from "../../../actions/appointments";
import {
  correctConnectivityErrorAction,
  getAllConnectivityErrors,
  getConnectivityErrors,
} from "../../../actions/connectivityResponse";
import {
  clearIdTurnosForms,
  getForm,
  getFormDiagnosis,
  getFormModel,
  getFormPrestaciones,
  getFormProfessionals,
  getFormProfessionalSpecialties,
  getFormSpecialtiesDiagnosis,
} from "../../../actions/forms";
import {
  closeForm,
  openEditForm,
  setLoadedForm,
} from "../../../actions/formsState";
import snackbarUtils from "../../../utils/snackbarUtils";
import { formBehaviourEnum } from "../../commons/enum/formEnums";
import Form from "./form";

/** Solo puede haber uno de estos componentes a la vez por pantalla. Para abrir distintos se usan las acciones. openNewForm y openEditForm
 *
 * Para mas información de la implementacion del componente y como utilizarlo:
 * https://aisrl.sharepoint.com/sites/desarrollo/_layouts/OneNote.aspx?id=%2Fsites%2Fdesarrollo%2FSiteAssets%2FBloc%20de%20notas%20de%20Desarrollo&wd=target%28ML%20Point.one%7C9860A33D-0D30-4392-BB53-700F7E4BBAAE%2F1.%20Forms%7C79042C1E-1675-4422-8431-442C7F94C94B%2F%29
 */
const FormContainer = () => {
  const dispatch = useDispatch();
  const formsState = useSelector((state) => state.formsState);
  const professionals = useSelector((state) => state.forms.professionals);
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const prestacionesState = useSelector((state) => state.forms.prestaciones);
  const specialties = useSelector((state) => state.forms.specialtiesDiagnosis);
  const availableDocumentsToAdd = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );
  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const disabled = useMemo(() => {
    switch (formsState.behaviour) {
      case formBehaviourEnum.CREATE_ACCIDENT_DOCUMENT:
      case formBehaviourEnum.ERROR_DIALOG:
      case formBehaviourEnum.ERROR_CONECTIVIDAD:
        return false;
      default:
        return null;
    }
  }, [formsState.behaviour]);

  const isView = useMemo(() => {
    switch (formsState.behaviour) {
      case formBehaviourEnum.CREATE_ACCIDENT_DOCUMENT:
        return false;
      default:
        return true;
    }
  }, [formsState.behaviour]);

  // Funcion que se ejecuta despues de que el form se guarde correctamente
  const postFormCallback = useCallback(
    (formValues) => {
      switch (formsState.behaviour) {
        case formBehaviourEnum.ERROR_DIALOG:
          dispatch(
            correctConnectivityErrorAction(
              formsState.selectedDocument.idRespError,
              formsState.commonProperties.idArt,
            ),
          ).then(() => {
            dispatch(
              getDocuments(
                formsState.commonProperties.idSiniestro,
                formsState.commonProperties.idArt,
              ),
            );
            dispatch(
              getConnectivityErrors(
                formsState.selectedDocument.nroTransaccion,
                formsState.commonProperties.idArt,
              ),
            );
          });
          dispatch(
            getAppointmentsCalendar(
              formsState.commonProperties.idSiniestro,
              formsState.commonProperties.idArt,
            ),
          );
          break;
        case formBehaviourEnum.ERROR_CONECTIVIDAD:
          dispatch(
            correctConnectivityErrorAction(
              formsState.selectedDocument.idRespError,
              formsState.commonProperties.idArt,
            ),
          ).then((res) => {
            dispatch(getAllConnectivityErrors(time));
          });
          break;
        case formBehaviourEnum.CREATE_ACCIDENT_DOCUMENT:
          dispatch(
            getAvailableDocumentsToAdd(
              formsState.commonProperties.idSiniestro,
              formsState.commonProperties.idArt,
            ),
          );
          dispatch(
            getDocuments(
              formsState.commonProperties.idSiniestro,
              formsState.commonProperties.idArt,
            ),
          );
          dispatch(
            getAppointmentsCalendar(
              formsState.commonProperties.idSiniestro,
              formsState.commonProperties.idArt,
            ),
          );
          dispatch(getAccidents());
          break;
        case formBehaviourEnum.MODIFY_ACCIDENT_DOCUMENT:
          dispatch(
            getDocuments(
              formsState.commonProperties.idSiniestro,
              formsState.commonProperties.idArt,
            ),
          );
          dispatch(
            getAppointmentsCalendar(
              formsState.commonProperties.idSiniestro,
              formsState.commonProperties.idArt,
            ),
          );
          break;
      }
    },
    [
      formsState.behaviour,
      formsState.commonProperties.idArt,
      formsState.commonProperties.idSiniestro,
      time,
      formsState.selectedDocument,
    ],
  );

  useEffect(() => {
    () => {
      // Para asegurar que el form este cerrado al desmontar el componente
      dispatch(closeForm());
    };
  }, []);

  useEffect(() => {
    // Add selectedDocument o selectedDocument como accion de redux.
    if (formsState.open) {
      setLoaded(false);
      if (formsState.selectedDocument) {
        dispatch(
          getForm(
            formsState.open,
            formsState.commonProperties.idArt,
            formsState.commonProperties.idSiniestro,
            formsState.selectedDocument.nroFormulario,
          ),
        ).then(() => dispatch(setLoadedForm()));
      } else {
        // Si no hay documento seleccionado estoy creando uno nuevo
        dispatch(
          getFormModel(
            formsState.open,
            formsState.commonProperties.idArt,
            formsState.commonProperties.idSiniestro,
          ),
        ).then(() => dispatch(setLoadedForm()));
      }
    }
  }, [formsState.selectedDocument?.nroFormulario, formsState.open]);

  const handleClose = (formSubmited, error) => {
    dispatch(clearIdTurnosForms());
    dispatch(clearIdTurnos());
    if (formSubmited === true) {
      if (!error) {
        !isView
          ? snackbarUtils.success("Formulario enviado correctamente")
          : snackbarUtils.success("Formulario modificado correctamente");
      }
    }
    // Close del dialog de cancelar asi no queda abierto para el proximo form que se abra
    setOpenCancelDialog(false);
    // Close del form
    dispatch(closeForm());
  };

  // Precarga de datos que requieren los formularios:
  useEffect(() => {
    if (formsState.open) {
      // Necesario para poder cargar fpt
      if (availableDocumentsToAdd.data.length === 0) {
        dispatch(
          getAvailableDocumentsToAdd(
            formsState.commonProperties.idSiniestro,
            formsState.commonProperties.idArt,
          ),
        );
      }
      // Carga de turnos
      if (["iet", "iami"].includes(formsState.open)) {
        dispatch(
          getAppointmentsCalendar(
            formsState.commonProperties.idSiniestro,
            formsState.commonProperties.idArt,
          ),
        );
      }

      if (["iet", "iami"].includes(formsState.open)) {
        dispatch(
          getFormProfessionalSpecialties(formsState.commonProperties.idArt),
        );
      }

      if (
        professionals.data.length === 0 &&
        ["iet", "iami", "am", "fpa", "idr"].includes(formsState.open)
      ) {
        dispatch(getFormProfessionals(formsState.commonProperties.idArt));
      }

      if (
        diagnosis.data.length === 0 &&
        ["iet", "iami", "am"].includes(formsState.open)
      ) {
        dispatch(getFormDiagnosis(formsState.commonProperties.idArt));
      }
      // Estos dispatch usan el comportamiento en selected document por que no se ejecutan si es nuevo documento
      if (formsState.selectedDocument) {
        if (formsState.open === "fpa" && !prestacionesState.data.prestaciones) {
          dispatch(getFormPrestaciones(formsState.commonProperties.idArt));
        }

        if (formsState.open === "am" && specialties.data.length === 0) {
          dispatch(
            getFormSpecialtiesDiagnosis(formsState.commonProperties.idArt),
          );
        }
      }
    }
  }, [formsState.open, formsState.selectedDocument]);

  const changeSelectedDocument = (document) => {
    if (formsState.selectedDocument) {
      dispatch(
        openEditForm(
          document.comportamiento,
          formBehaviourEnum.MODIFY_ACCIDENT_DOCUMENT,
          {
            nroFormulario: document.nroFormulario,
            documento: formsState.selectedDocument.documento,
          },
          {},
        ),
      );
    }
  };

  const handleDialogClose = useCallback(() => {
    switch (formsState.behaviour) {
      case formBehaviourEnum.CREATE_ACCIDENT_DOCUMENT:
        setOpenCancelDialog(true);
        break;
      default:
        handleClose();
    }
  }, [formsState.behaviour]);

  return (
    <Form
      openForm={formsState.open}
      handleDialogClose={handleDialogClose}
      selectedDocument={formsState.selectedDocument}
      handleClose={handleClose}
      formTitle={formsState.formTitle}
      behaviour={formsState.behaviour}
      documentList={formsState.documentList}
      changeSelectedDocument={changeSelectedDocument}
      loaded={loaded}
      setLoaded={setLoaded}
      loadingForm={formsState.loadingForm}
      isView={isView}
      disabled={disabled}
      idSiniestro={formsState.commonProperties.idSiniestro}
      idArt={formsState.commonProperties.idArt}
      postFormCallback={postFormCallback}
      openCancelDialog={openCancelDialog}
      handleCancelFormCancelDialog={() => setOpenCancelDialog(false)}
      handleConfirmFormCancelDialog={handleClose}
      artName={formsState.commonProperties.artName}
    />
  );
};

export default FormContainer;
