import axios from "axios";

export const GET_ANSWERED_AUTHORIZATIONS_NOTIFICATION =
  "GET_ANSWERED_AUTHORIZATIONS_NOTIFICATION";
export const GET_ANSWERED_AUTHORIZATIONS = "GET_ANSWERED_AUTHORIZATIONS";
export const GET_PENDING_AUTHORIZATIONS = "GET_PENDING_AUTHORIZATIONS";
export const GET_AUTHORIZATIONS_FILTER = "GET_AUTHORIZATIONS_FILTER";
export const GET_PENDING_DETAILS_AUTHORIZATIONS =
  "GET_PENDING_DETAILS_AUTHORIZATIONS";
export const GET_ANSWERED_DETAILS_AUTHORIZATIONS =
  "GET_ANSWERED_DETAILS_AUTHORIZATIONS";
export const CLEAN_AUTHORIZATIONS_FILTER = "CLEAN_AUTHORIZATIONS_FILTER";
export const UPDATE_PENDING_AUTHORIZATION = "UPDATE_PENDING_AUTHORIZATION";
export const UPDATE_ANSWERED_AUTHORIZATION = "UPDATE_ANSWERED_AUTHORIZATION";
export const UPDATE_AUTHORIZATIONS_FILTER = "UPDATE_AUTHORIZATIONS_FILTER";

export const getAnsweredAuthorizations = (timeSpan) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_ANSWERED_AUTHORIZATIONS,
      payload: axios
        .get(`authorization/${selectedProvider}/Answered?timeSpan=${timeSpan}`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getAnsweredAuthorizationsNotification =
  () => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    if (selectedProvider) {
      dispatch({
        type: GET_ANSWERED_AUTHORIZATIONS_NOTIFICATION,
        payload: axios
          .get(`authorization/${selectedProvider}/Answered?timeSpan=2`)
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    }
  };

export const getPendingAuthorizations = (timeSpan) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_PENDING_AUTHORIZATIONS,
      payload: axios
        .get(`authorization/${selectedProvider}/Pending?timeSpan=${timeSpan}`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getDetailsAuthorizations =
  (siniestroId, wiri, formNumber, isAnswered) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    const type = isAnswered
      ? GET_ANSWERED_DETAILS_AUTHORIZATIONS
      : GET_PENDING_DETAILS_AUTHORIZATIONS;

    if (selectedProvider) {
      dispatch({
        type,
        payload: axios
          .get(
            `authorization/provisions?Accident=${siniestroId}&Provider=${selectedProvider}&WRI=${wiri}&FormNumber=${formNumber}`,
          )
          .then((response) => ({
            data: response.data,
            siniestroId,
            type,
            formNumber,
          }))
          .catch((error) => Promise.reject(error)),
      });
    }
  };

export const getAuthorizationsWithFilters =
  (filters) => (dispatch, getState) => {
    const { siniestro, dni, fechaDesde, fechaHasta, otrosPrestadores } =
      filters;
    const { selectedProvider } = getState().providers;

    if (selectedProvider) {
      dispatch({
        type: GET_AUTHORIZATIONS_FILTER,
        payload: axios
          .get(
            `authorization/${selectedProvider}?siniestro=${siniestro}&dni=${dni}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&otrosPrestadores=${otrosPrestadores}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    }
  };

export const cleanAuthorizationsWithFilters = () => ({
  type: CLEAN_AUTHORIZATIONS_FILTER,
});

export const updatePendingAuthorizations =
  (artId, updatedPendingAuthorization) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    return dispatch({
      type: UPDATE_PENDING_AUTHORIZATION,
      payload: axios
        .put(`authorization/${artId}/${selectedProvider}/update`, {
          siniestroId: updatedPendingAuthorization.siniestroId,
          nroFormulario: updatedPendingAuthorization.nroFormulario,
          estado: updatedPendingAuthorization.estado,
          fechaAutorizacion: updatedPendingAuthorization.fechaAutorizacion,
          codigoAutorizacion:
            updatedPendingAuthorization.codigoAutorizacion || null,
          observacionesAutorizacion:
            updatedPendingAuthorization.observacionesAutorizacion || null,
        })
        .then((response) => {
          return {
            data: {
              ...response.data,
              ...updatedPendingAuthorization,
              siniestroId: response.data,
            },
          };
        })
        .catch((error) => Promise.reject(error)),
    });
  };

export const updateAnsweredAuthorizations =
  (artId, updatedAnsweredAuthorization) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    dispatch({
      type: UPDATE_ANSWERED_AUTHORIZATION,
      payload: axios
        .put(`authorization/${artId}/${selectedProvider}/update`, {
          siniestroId: updatedAnsweredAuthorization.siniestroId,
          nroFormulario: updatedAnsweredAuthorization.nroFormulario,
          estado: updatedAnsweredAuthorization.estado,
          fechaAutorizacion: updatedAnsweredAuthorization.fechaAutorizacion,
          codigoAutorizacion:
            updatedAnsweredAuthorization.codigoAutorizacion || null,
          observacionesAutorizacion:
            updatedAnsweredAuthorization.observacionesAutorizacion || null,
        })
        .then((response) => {
          return {
            data: {
              ...response.data,
              ...updatedAnsweredAuthorization,
              siniestroId: response.data,
            },
          };
        })
        .catch((error) => Promise.reject(error)),
    });
  };

/**
 * @param {int} artId - El id de la ART (artMLCloud)
 * @param {Object} autorizacion - La autorizacion con las propiedades:
 * `{
 *  siniestroId: int,
 *  nroFormulario: int,
 *  estado: string,
 *  fechaAutorizacion: date,
 *  codigoAutorizacion: int,
 *  observacionAutorizacion: string
 * }`
 */
export const updateSearchAuthorizations =
  (artId, autorizacion) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    dispatch({
      type: UPDATE_AUTHORIZATIONS_FILTER,
      payload: axios
        .put(`authorization/${artId}/${selectedProvider}/update`, autorizacion)
        .then((response) => {
          return {
            data: {
              ...response.data,
              ...autorizacion,
              siniestroId: response.data,
            },
          };
        })
        .catch((error) => Promise.reject(error)),
    });
  };
