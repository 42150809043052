import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import React from "react";

const MyResponsiveBar = ({ data, keys, groupMode, type, name }) => {
  const quarterActual = moment().quarter();
  const newArrayOrder = [];

  data.forEach((item) => {
    if (quarterActual === 1) {
      if (item.quarter === 2) newArrayOrder[0] = item;
      if (item.quarter === 3) newArrayOrder[1] = item;
      if (item.quarter === 4) newArrayOrder[2] = item;
      if (item.quarter === 1) newArrayOrder[3] = item;
    } else if (quarterActual === 2) {
      if (item.quarter === 3) newArrayOrder[0] = item;
      if (item.quarter === 4) newArrayOrder[1] = item;
      if (item.quarter === 1) newArrayOrder[2] = item;
      if (item.quarter === 2) newArrayOrder[3] = item;
    } else if (quarterActual === 3) {
      if (item.quarter === 4) newArrayOrder[0] = item;
      if (item.quarter === 1) newArrayOrder[1] = item;
      if (item.quarter === 2) newArrayOrder[2] = item;
      if (item.quarter === 3) newArrayOrder[3] = item;
    } else if (quarterActual === 4) {
      if (item.quarter === 1) newArrayOrder[0] = item;
      if (item.quarter === 2) newArrayOrder[1] = item;
      if (item.quarter === 3) newArrayOrder[2] = item;
      if (item.quarter === 4) newArrayOrder[3] = item;
    }
  });

  return (
    <React.Fragment>
      <ResponsiveBar
        data={newArrayOrder}
        keys={keys}
        indexBy={type}
        padding={0.3}
        margin={{
          top: 20,
          right: 90,
          bottom: 60,
          left: 60,
        }}
        groupMode={groupMode}
        colors={{ scheme: "set3" }}
        borderRadius={0}
        borderColor={{ from: "color", modifiers: [["darker", "1.2"]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: name === "month" ? -90 : 0,
          legendPosition: "middle",
          legendOffset: 36,
        }}
        axisLeft={{
          tickSize: 3,
          tickPadding: 11,
          tickRotation: 0,
          legend: name === "month" ? "" : "",
          legendPosition: "middle",
          legendOffset: -47,
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 110,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 10,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        enableLabel={false}
        labelSkipWidth={5}
        labelSkipHeight={8}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate
        motionStiffness={90}
        motionDamping={8}
      />
    </React.Fragment>
  );
};

export default MyResponsiveBar;
