import { useFormikContext } from 'formik';
import { forwardRef, useCallback } from 'react';
import Captcha from '../captcha/captcha';

// Consideraciones a tener en cuenta: Siempre reiniciar el componente despues de llamar a la api si falla. 
// Ya que se tiene que adquirir un nuevo token, por que el anterior deja de ser valido automaticamente. Hay ejemplo de esto en login

const FormikCaptcha = forwardRef(({ name = "token" }, ref) => {

    const { setFieldValue } = useFormikContext();

    // Funciones creadas con useCallback para evitar renderizaciones extras del componente cloudflare
    const onVerify = useCallback((value) => {
        setFieldValue(name, value);
    }, [])
    const onError = useCallback((error) => {
        setFieldValue(name, null);
        console.error("on error", error)
    }, [])

    const onReset = useCallback(() => {
        setFieldValue(name, null);
    })

    if (!CONFIG.useCaptcha) {
        return <></>
    }

    return <Captcha
        ref={ref}
        onVerify={onVerify}
        onError={onError}
        onReset={onReset}
        theme="light"
    />
});

export default FormikCaptcha;