import {
  CLEAN_CONNECTIVITY_RESPONSES,
  GET_ALL_CONNECTIVITY_ERRORS,
  GET_ALL_CONNECTIVITY_ERRORS_NOT_PROCESSED,
  GET_CONNECTIVITY_ERRORS,
  GET_CONNECTIVITY_RESPONSES,
  SET_TIME,
} from "../actions/connectivityResponse";

const INITIAL_STATE = {
  connectivityErrors: {
    isFetching: false,
    didInvalidate: false,
    data: [],
    time: 2,
  },
  allConnectivityErrors: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  allConnectivityErrorsNotProcessed: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  searchConnectivityResponses: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
};

const connectivityResponse = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_CONNECTIVITY_ERRORS}_FULFILLED`:
      return {
        ...state,
        connectivityErrors: {
          ...state.connectivityErrors,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${GET_CONNECTIVITY_ERRORS}_REJECTED`:
      return {
        ...state,
        connectivityErrors: {
          ...state.connectivityErrors,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_CONNECTIVITY_ERRORS}_PENDING`:
      return {
        ...state,
        connectivityErrors: {
          ...state.connectivityErrors,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ALL_CONNECTIVITY_ERRORS}_FULFILLED`:
      return {
        ...state,
        allConnectivityErrors: {
          ...state.allConnectivityErrors,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${GET_ALL_CONNECTIVITY_ERRORS}_REJECTED`:
      return {
        ...state,
        allConnectivityErrors: {
          ...state.allConnectivityErrors,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ALL_CONNECTIVITY_ERRORS}_PENDING`:
      return {
        ...state,
        allConnectivityErrors: {
          ...state.allConnectivityErrors,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ALL_CONNECTIVITY_ERRORS_NOT_PROCESSED}_FULFILLED`:
      return {
        ...state,
        allConnectivityErrorsNotProcessed: {
          ...state.allConnectivityErrorsNotProcessed,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${GET_ALL_CONNECTIVITY_ERRORS_NOT_PROCESSED}_REJECTED`:
      return {
        ...state,
        allConnectivityErrorsNotProcessed: {
          ...state.allConnectivityErrorsNotProcessed,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ALL_CONNECTIVITY_ERRORS_NOT_PROCESSED}_PENDING`:
      return {
        ...state,
        allConnectivityErrorsNotProcessed: {
          ...state.allConnectivityErrorsNotProcessed,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_CONNECTIVITY_RESPONSES}_FULFILLED`:
      return {
        ...state,
        searchConnectivityResponses: {
          ...state.searchConnectivityResponses,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${GET_CONNECTIVITY_RESPONSES}_REJECTED`:
      return {
        ...state,
        searchConnectivityResponses: {
          ...state.searchConnectivityResponses,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_CONNECTIVITY_RESPONSES}_PENDING`:
      return {
        ...state,
        searchConnectivityResponses: {
          ...state.searchConnectivityResponses,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${CLEAN_CONNECTIVITY_RESPONSES}`:
      return {
        ...state,
        searchConnectivityResponses: INITIAL_STATE.searchConnectivityResponses,
      };
    case `${SET_TIME}`:
      return {
        ...state,
        connectivityErrors: {
          ...state.connectivityErrors,
          time: action.payload,
        },
      };
    default:
      return state;
  }
};

export default connectivityResponse;
