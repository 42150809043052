import axios from "axios";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const VERIFY_PASSWORD = "VERIFY_PASSWORD";
export const SET_PASSWORD = "SET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const resetPassword = (email, token) => ({
  type: RESET_PASSWORD,
  payload: axios
    .post("account/resetPassword", { Email: email, Token: token })
    .then((response) => response)
    .catch((error) => Promise.reject(error)),
});

export const verifyPassword = (data) => ({
  type: VERIFY_PASSWORD,
  payload: axios
    .post("account/verify", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error)),
});

export const setPassword = (data) => ({
  type: SET_PASSWORD,
  payload: axios
    .post("account/setPassword", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error)),
});

export const changePassword = (data) => ({
  type: SET_PASSWORD,
  payload: axios
    .post("account/changePassword", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error)),
});
