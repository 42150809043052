import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAppointmentAssist,
  openAppointmentsGrid
} from "../../../../actions/accidentDashboard";
import { getFormProfessionals, postForm } from "../../../../actions/forms";
import {
  convertDateTimeToNet,
  validateDateRange,
} from "../../../../utils/datetimeUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import { useStyles } from "../formStyle";
import Am from "./am";

const initialAmModel = {
  nombreTipoFormulario: null,
  fechaAlta: null,
  fechaReinicioLaboral: null,
  diagnosticoOMS: null,
  diagnostico: "",
  observaciones: "",
  sugerenciaRecalificacion: false,
  actividadHabitual: false,
  tipoAlta: "",
  incapacidad: "",
  prestacionesMantenimiento: false,
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  diasReinicioLaboral: 0,
  especialidadProfesional: "",
  marcaFirmado: "Si",
  firmaConforme: null,
  especialidadInforme: "",
  informacionSensible: false,
};
const AmContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenCancelDialog,
  selectedDocument,
  idSiniestro,
  idArt,
  disabled,
  postFormCallback,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const appointmentsPostForm = useSelector(
    (state) => state.accidentDashboard.appointmentsPostForm,
  );
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const professionals = useSelector((state) => state.forms.professionals);
  const specialtiesState = useSelector(
    (state) => state.forms.specialtiesDiagnosis,
  );
  const calendar = useSelector(
    (state) => state.accidentDashboard.appointmentsCalendar,
  );

  const [amModel, setAmModel] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [files, setFiles] = useState([]);
  const [especialidades, setEspecialidades] = useState(null);

  const disabledForm = disabled !== null ? disabled : (form.data.roles ? !form.data.roles?.editar : true);
  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    diagnosis.isFetching ||
    especialidades === null ||
    professionals.isFetching ||
    (isView && specialtiesState.isFetching) ||
    (!isView && !amModel);

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
      setEspecialidades(form.data.form.especialidadesAm);
    } else if (!form.isFetching) {
      setEspecialidades([]);
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!isView) {
      if (!model.isFetching) {
        setAmModel(model.data);
        setFormValues(() => ({
          ...initialAmModel,
          fechaAlta: new Date(),
          diagnosticoOMS: model.data.fields.diagnosticoOMS
            ? model.data.fields.diagnosticoOMS.options
            : initialAmModel.diagnosticoOMS,
          diagnostico: model.data.fields.diagnostico.options
            ? model.data.fields.diagnostico.options
            : initialAmModel.diagnostico,
          fechaReinicioLaboral: model.data.configuration
            .fechaReinicioLaboralDefault
            ? model.data.fields.diasReinicioLaboral.options
              ? moment(new Date()).add(
                model.data.fields.diasReinicioLaboral.options,
                "days",
              )
              : initialAmModel.fechaReinicioLaboral
            : initialAmModel.fechaReinicioLaboral,
          profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
            .options
            ? model.data.fields.profesionalApellidoNombre.options
            : initialAmModel.profesionalApellidoNombre,
          nombreTipoFormulario:
            model.data.fields.tiposFormulario.options.length === 1
              ? model.data.fields.tiposFormulario.options[0].nombre
              : initialAmModel.nombreTipoFormulario,
          especialidadInforme: model.data.fields?.especialidadInforme.options
            ? model.data.fields?.especialidadInforme.options
            : initialAmModel.especialidadInforme,
          informacionSensible: model.data.fields?.informacionSensible.options
            ? model.data.fields?.informacionSensible.options
            : initialAmModel.informacionSensible,
        }));
      }
    } else if (!form.isFetching) {
      setAmModel(form.data.model);
      setFormValues(form.data.form);
    }
  }, [model.isFetching, form.isFetching, isView]);

  useEffect(() => {
    if (!isLoading) {
      setLoaded(true);
    }
  }, [isLoading]);

  const handleSubmit = (form) => {
    if (!isView) {
      var appointmentsInRange = calendar.data.appointments?.filter(
        (d) =>
          validateDateRange(d.fechaHora, form, null, model) &&
          d.estado === "Programados",
      );
    }
    if (
      appointmentsInRange &&
      !appointmentsPostForm.wasSent &&
      appointmentsInRange.length != 0
    ) {
      dispatch(openAppointmentsGrid(form));
    } else {
      const filesAux = [];
      files.forEach((e) =>
        filesAux.push({
          ...e,
          nombre: e.filename || e.nombre,
          base64: e.value,
        }),
      );
      const model = {
        ...form,
        numeroSiniestroPrestador: idSiniestro,
        adjuntos: filesAux,
        fechaAlta: convertDateTimeToNet(form.fechaAlta),
        fechaReinicioLaboral: convertDateTimeToNet(form.fechaReinicioLaboral),
        especialidadesAm: especialidades,
      };
      dispatch(postForm("am", model, idArt))
        .then(() => {
          dispatch(getFormProfessionals(idArt));
        })
        .then(() => {
          if (postFormCallback) {
            postFormCallback(form);
          }
          handleClose(true, false);
        }).catch((e) => { handleClose(true, true) })
      dispatch(clearAppointmentAssist());
    }
  };

  useEffect(() => {
    if (appointmentsPostForm.wasSent) {
      handleSubmit(appointmentsPostForm.data);
    }
  }, [appointmentsPostForm.wasSent]);

  const closeAm = () => {
    if (isView) {
      handleClose();
    } else {
      handleOpenCancelDialog();
    }
  }

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.formLoadingContainer}>
          <CircularLoading />
        </div>
      ) : (
        <Am
          handleClose={closeAm}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={amModel}
          formValues={formValues}
          selectedDocument={selectedDocument}
          especialidades={especialidades}
          setEspecialidades={setEspecialidades}
          isView={isView}
          disabled={disabledForm}
          idArt={idArt}
        />
      )}
    </div>
  );
};

export default AmContainer;
