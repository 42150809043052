import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { setSelectedAccident } from "../../../../actions/accidentDashboard";
import {
  cleanAppointmentsWithFilters,
  getAppointmentsWithFilters,
} from "../../../../actions/appointments";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import AppointmentsSearch from "../../../commons/appointmentsSearch/search";
import AppointmentsTable from "../../../commons/table/appointmentsTable";
import AccidentDashboardContainer from "../../accident/accidentDashboard/accidentDashboardContainer";

const useStyles = makeStyles({
  container: {
    marginBottom: "24px",
  },
  title: {
    color: "#484848",
    marginBottom: "8px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "large",
    fontWeight: 500,
  },
});

const initialValues = {
  art: "",
  siniestro: "",
  dni: "",
  estado: "",
  fechaDesde: null,
  fechaHasta: null,
};

const estadosOptions = [
  { id: "AUSENTES", nombre: "AUSENTES" },
  { id: "NO INFORMADOS", nombre: "NO INFORMADOS" },
  { id: "PRESENTES", nombre: "PRESENTES" },
  { id: "PROGRAMADOS", nombre: "PROGRAMADOS" },
  { id: "ANULADOS", nombre: "ANULADOS" },
];

const validationSchema = yup.object().shape({
  art: yup.string().required("Debe seleccionar una opción"),
  fechaDesde: yup
    .date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaDesde",
      message: "La fecha desde debe ser menor o igual a la fecha hasta",
      test: function (value) {
        if (value <= this.parent.fechaHasta) {
          return true;
        }
        return false;
      },
    }),

  fechaHasta: yup
    .date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaHasta",
      message: "La fecha hasta debe ser mayor o igual a la fecha desde",
      test: function (value) {
        if (value >= this.parent.fechaDesde) {
          return true;
        }
        return false;
      },
    }),
});

const AppointmentsSearchContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const appointments = useSelector(
    (state) => state.appointments.appointmentsFilter,
  );
  const art = useSelector((state) => state.art.artsAdhered);
  const namesArts = useSelector((state) => state.art.shortNameArt);

  const [displayAccidents, setDisplayAccidents] = useState("block");

  useEffect(() => {
    dispatch(cleanAppointmentsWithFilters());
  }, []);

  const handleSearch = (values) => {
    const { estado, fechaDesde, fechaHasta } = values;
    const valuesToAction = {
      ...values,
      estado: estado ? estado : "",
      fechaDesde: fechaDesde ? convertDateTimeToNet(fechaDesde) : "",
      fechaHasta: fechaHasta ? convertDateTimeToNet(fechaHasta) : "",
    };
    dispatch(getAppointmentsWithFilters(valuesToAction));
  };

  const handleAccidentDetail = (accident) => {
    dispatch(
      setSelectedAccident({
        ...accident,
        siniestro: accident.nroSiniestro,
        documento: accident.nroDocumento,
      }),
    );
    setDisplayAccidents("none");
  };

  return (
    <div className={classes.container}>
      {displayAccidents === "none" && (
        <AccidentDashboardContainer setDisplayAccidents={setDisplayAccidents} />
      )}
      {displayAccidents !== "none" && (
        <div>
          <div className={classes.title}>Buscar Turnos</div>
          <AppointmentsSearch
            handleSubmit={handleSearch}
            art={art}
            initialValues={initialValues}
            estadosOptions={estadosOptions}
            validationSchema={validationSchema}
          />
        </div>
      )}
      <AppointmentsTable
        namesArts={namesArts}
        data={appointments.list}
        loading={appointments.isFetching}
        error={appointments.didInvalidate}
        noRefresh
        title="Turnos"
        handleAccidentDetail={handleAccidentDetail}
        displayAccidents={displayAccidents}
      />
    </div>
  );
};

export default AppointmentsSearchContainer;
