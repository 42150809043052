import {
  CLEAN_AUTHORIZATIONS_FILTER,
  GET_AUTHORIZATIONS_FILTER,
  UPDATE_AUTHORIZATIONS_FILTER,
} from "../actions/authorizations";
import { didInvalidate } from "../utils/errorHandler";

const INITIAL_STATE = {
  isFetchingFilter: false,
  didInvalidate: false,
  listFilter: [],
};

const authorizations = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_AUTHORIZATIONS_FILTER}_REJECTED`:
      return {
        ...state,
        isFetchingFilter: false,
        didInvalidate: true,
      };
    case `${GET_AUTHORIZATIONS_FILTER}_PENDING`:
      return {
        ...state,
        isFetchingFilter: true,
        didInvalidate: false,
      };
    case `${GET_AUTHORIZATIONS_FILTER}_FULFILLED`:
      action.payload.data.authorizations.forEach((row) => {
        row["conTurnos"] = row.cantidadTurnos > 0 ? true : false;
      });
      return {
        ...state,
        isFetchingFilter: false,
        didInvalidate: didInvalidate(action),
        listFilter: action.payload.data,
      };

    case `${UPDATE_AUTHORIZATIONS_FILTER}_REJECTED`:
      return {
        ...state,
        isFetchingFilter: false,
        didInvalidate: true,
      };
    case `${UPDATE_AUTHORIZATIONS_FILTER}_PENDING`:
      return {
        ...state,
        isFetchingFilter: true,
        didInvalidate: false,
      };
    case `${UPDATE_AUTHORIZATIONS_FILTER}_FULFILLED`:
      const _didInvalidate = didInvalidate(action);
      let nuevaLista = Array.from(state.listFilter.authorizations);

      if (!_didInvalidate) {
        const indexAutorizacionModificada = nuevaLista.findIndex(
          (auth) => auth.nroFormulario === action.payload.data.nroFormulario,
        );

        if (indexAutorizacionModificada >= 0) {
          const autorizacionVieja = nuevaLista.find(
            (auth) => auth.nroFormulario === action.payload.data.nroFormulario,
          );
          nuevaLista.splice(indexAutorizacionModificada, 1, {
            ...autorizacionVieja,
            ...action.payload.data,
          });
        }

        return {
          ...state,
          isFetchingFilter: false,
          didInvalidate: _didInvalidate,
          listFilter: { ...state.listFilter, authorizations: nuevaLista },
        };
      }
    case `${CLEAN_AUTHORIZATIONS_FILTER}`:
      return {
        ...state,
        isFetchingFilter: false,
        didInvalidate: false,
        listFilter: [],
      };

    default:
      return state;
  }
};

export default authorizations;
