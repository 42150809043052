import { CHANGE_DRAWER_STATUS } from '../actions/userPreferences';
import { CHANGE_SELECT_PROVIDER } from '../actions/providers';

const INITIAL_STATE = {
  drawer: true,
  selectedProvider: false
};

const userPreferences = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case CHANGE_DRAWER_STATUS:
    return {
      ...state,
      drawer: action.payload.status,
    };
  case CHANGE_SELECT_PROVIDER:
    return {
      ...state,
      selectedProvider: action.payload.provider,
    };

  default:
    return state;
  }
};

export default userPreferences;
