import axios from "axios";

export const GET_FILES = "GET_FILES";
export const UPDATE_FILE = "UPDATE_FILE";

export const getFiles = () => (dispatch) => {
  return dispatch({
    type: GET_FILES,
    payload: axios
      .get("file")
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const updateFile = (file) => (dispatch) => {
  return dispatch({
    type: UPDATE_FILE,
    payload: axios
      .put("file", file)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
