import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAccident } from "../../../../actions/accidentDashboard";
import {
  cleanAccidentsWithFilters,
  getAccidentsArt,
} from "../../../../actions/accidents";
import { changeSelectedProviderWriUser } from "../../../../actions/providers";
import { useTableStyles } from "../../../commons/table/config/tableConfig";
import AccidentDashboardContainer from "../../accident/accidentDashboard/accidentDashboardContainer";
import SearchSiniestrosArt from "./siniestroSearch";
import AccidentArtTable from "./siniestroTable";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "24px",
  },
  title: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "large",
    fontWeight: 500,
    color: "#484848",
    marginBottom: "8px",
  },
}));

const ArtSearchContainer = () => {
  const [displayAccidents, setDisplayAccidents] = useState("block");

  const classes = useStyles();
  const tableClasses = useTableStyles(displayAccidents)();
  const dispatch = useDispatch();

  const accidents = useSelector((state) => state.accidents.art);

  useEffect(() => {
    dispatch(cleanAccidentsWithFilters());
  }, []);

  const handleSearch = (params) => {
    dispatch(getAccidentsArt(params));
  };

  const handleAccidentDetail = (accident) => {
    dispatch(changeSelectedProviderWriUser(accident.prestadorId));
    dispatch(setSelectedAccident(accident));
    setDisplayAccidents("none");
  };

  return (
    <div className={classes.container}>
      {displayAccidents === "none" && (
        <AccidentDashboardContainer setDisplayAccidents={setDisplayAccidents} />
      )}
      <div className={tableClasses.container}>
        <div className={classes.title}>Buscar Siniestro</div>
        <SearchSiniestrosArt
          handleSearch={handleSearch}
          isSearching={accidents.isFetching}
        />
      </div>
      <AccidentArtTable
        data={accidents.data}
        loading={accidents.isFetching}
        error={accidents.didInvalidate}
        noRefresh
        handleAccidentDetail={handleAccidentDetail}
        displayAccidents={displayAccidents}
      />
    </div>
  );
};

export default ArtSearchContainer;
