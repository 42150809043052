import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearIdTurnos } from "../../../../actions/appointments";
import {
  clearFormLocalidades,
  getLocalidades,
  postForm,
} from "../../../../actions/forms";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import { useStyles } from "../formStyle";
import Fpt from "./fpt";

const initialFptModel = {
  fechaHoraSolicitud: null,
  fechaHoraTurno: null,
  tipoTransporteNombre: "",
  tipoUrgenciaNombre: 1,
  solicitadoPor: null,
  direccionHabitualDesde: null,
  calleDesde: "",
  numeroDesde: "",
  pisoDesde: "",
  departamentoDesde: "",
  entreCalleDesde: "",
  entreCalleDesde1: "",
  localidadDesde: "Capital Federal",
  provinciaDesde: "Capital Federal",
  codigoPostalDesde: "",
  telefonosDesde: "",
  direccionHabitualHasta: null,
  calleHasta: "",
  numeroHasta: "",
  pisoHasta: "",
  departamentoHasta: "",
  entreCalleHasta: "",
  entreCalleHasta1: "",
  localidadHasta: "Capital Federal",
  provinciaHasta: "Capital Federal",
  codigoPostalHasta: "",
  telefonosHasta: "",
  observaciones: "",
  esperaRetorno: null,
  tipoDireccionDesde: null,
  tipoDireccionHasta: null,
};

const FptContainer = ({
  handleClose,
  handleOpenCancelDialog,
  isView,
  setLoaded,
  idArt,
  selectedDocument,
  idSiniestro,
  disabled,
  postFormCallback,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const saveForm = useSelector((state) => state.forms.saveForm);
  // Modelo traido de la api se usa combinado con FTP en caso !Isview
  const model = useSelector((state) => state.forms.model);
  // Datos de form a editar se usa si es IsView
  const form = useSelector((state) => state.forms.form);
  // Datos seteados en redux en general al salir de crear un turno con traslado = true. Ejemplo: IAMI, IET.
  const fpt = useSelector((state) => state.formsState.fpt);

  const localidadDesde = useSelector((state) => state.forms.localidades.desde);
  const localidadHasta = useSelector((state) => state.forms.localidades.hasta);

  const [files, setFiles] = useState([]);
  const [fptModel, setFptModel] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [loadingProvincias, setloadingProvincias] = useState(true);

  const disableDateUpdate = fpt.appointmentDates?.length > 0;
  const disabledForm =
    disabled !== null
      ? disabled
      : form.data.roles
      ? !form.data.roles?.editar
      : true;
  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    !fptModel ||
    !formValues ||
    (loadingProvincias && isView);

  useEffect(() => {
    if (isView) {
      // Buscar localidades en base a provincias
      if (
        form?.data?.form?.provinciaDesde &&
        form?.data?.form?.provinciaDesde != "Capital Federal"
      ) {
        dispatch(getLocalidades(idArt, form?.data?.form?.provinciaDesde, true));
      }
      if (
        form?.data?.form?.provinciaHasta &&
        form?.data?.form?.provinciaHasta != "Capital Federal"
      ) {
        dispatch(
          getLocalidades(idArt, form?.data?.form?.provinciaHasta, false),
        );
      }
    }
  }, [form.isFetching, isView, idArt]);

  // Clear de localidades
  useEffect(() => {
    return () => {
      dispatch(clearFormLocalidades());
    };
  }, []);

  // Verifica que se terminaron de cargar las provincias necesarias.
  useEffect(() => {
    const obtainedForm = form.data.form;
    const model = form.data.model;
    if (isView) {
      if (obtainedForm && model) {
        // Si cargo el form
        if (
          Object.keys(obtainedForm).length > 0 &&
          Object.keys(model).length > 0
        ) {
          let cargandoLocalidadesDesde = false;
          let cargandoLocalidadesHasta = false;
          if (
            obtainedForm.provinciaDesde &&
            obtainedForm.provinciaDesde != "Capital Federal"
          ) {
            cargandoLocalidadesDesde = localidadDesde.data.length <= 0;
          }
          if (
            obtainedForm.provinciaHasta &&
            obtainedForm.provinciaHasta != "Capital Federal"
          ) {
            cargandoLocalidadesHasta = localidadHasta.data.length <= 0;
          }

          // Caso provincia no valida para que no quede cargando infinitamente
          if (
            !model.fields?.provinciaDesde?.options.find(
              (p) => p.id == obtainedForm.provinciaDesde,
            )
          ) {
            cargandoLocalidadesDesde = false;
          }
          if (
            !model.fields?.provinciaHasta?.options.find(
              (p) => p.id == obtainedForm.provinciaHasta,
            )
          ) {
            cargandoLocalidadesHasta = false;
          }

          setloadingProvincias(
            cargandoLocalidadesDesde || cargandoLocalidadesHasta,
          );
        }
      } else {
        setloadingProvincias(false);
      }
    }
  }, [localidadDesde, localidadHasta, form.isFetching]);

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!isLoading) {
      setLoaded(true);
    }
  }, [isLoading]);

  // Manejo de la fecha si viene de crear turno / turnos con traslado
  useEffect(() => {
    if (!isView) {
      if (!model.isFetching) {
        setFptModel(model.data);
        setFormValues(() => {
          const newFptModel = {
            ...initialFptModel,
            fechaHoraSolicitud: new Date(),
          };
          // Si se cargo uno o mas turnos de traslado desde otro lado
          if (fpt.appointmentDates?.length > 0) {
            // Ordenamos la lista de turnos por fecha
            fpt.appointmentDates.sort();

            newFptModel.solicitadoPor = fpt.professionalName;
            newFptModel.especialidad = fpt.specialityName;
            // Asigno el menor turno de la lista
            newFptModel.fechaHoraTurno = fpt.appointmentDates[0];

            const primerFecha = moment(fpt.appointmentDates[0]).format(
              "DD/MM/YYYY HH:mm",
            );
            const ultimaFecha = moment(fpt.appointmentDates.at(-1)).format(
              "DD/MM/YYYY HH:mm",
            );
            const masDeUnaFecha = primerFecha != ultimaFecha;
            newFptModel.observaciones =
              `Fechas turnos: ${primerFecha} ${
                masDeUnaFecha ? "- " + ultimaFecha : ""
              }` +
              ` | Profesional: ${fpt.professionalName}` +
              ` | Especialidad: ${fpt.specialityName}`;
          }
          return newFptModel;
        });
      }
    } else if (!form.isFetching) {
      setFptModel(form.data.model);
      const valoresFormReemplazados = { ...form.data.form };

      Object.keys(form.data.form).forEach((k) => {
        valoresFormReemplazados[k] =
          form.data.form[k] === null ? initialFptModel[k] : form.data.form[k];
      });

      setFormValues(valoresFormReemplazados);
    }
  }, [model.isFetching, fpt, form.isFetching]);

  const handleSubmit = (form) => {
    const filesAux = [];
    files.forEach((e) =>
      filesAux.push({ ...e, nombre: e.filename || e.nombre, base64: e.value }),
    );
    var model = {
      ...form,
      numeroSiniestroPrestador: idSiniestro,
      adjuntos: filesAux,
      fechaHoraSolicitud: convertDateTimeToNet(form.fechaHoraSolicitud),
      fechaHoraTurno: convertDateTimeToNet(form.fechaHoraTurno),
    };
    if (fpt.appointmentDates?.length > 0) {
      model = {
        ...model,
        idTurnos: fpt.appointmentsIds,
      };
      //borro la data del redux que me trae el post segun sea session o unico turno
      dispatch(clearIdTurnos());
    }

    dispatch(postForm("fpt", model, idArt))
      .then(() => {
        if (postFormCallback) {
          postFormCallback(form);
        }
        handleClose(true, false);
      })
      .catch((e) => {
        handleClose(true, true);
      });
  };

  const closeFpt = () => {
    if (isView) {
      handleClose();
    } else {
      handleOpenCancelDialog();
    }
  };

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.formLoadingContainer}>
          <CircularLoading />
        </div>
      ) : (
        <Fpt
          handleClose={closeFpt}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={fptModel}
          formValues={formValues}
          roles={form.data.roles}
          selectedDocument={selectedDocument}
          idArt={idArt}
          isView={isView}
          disabled={disabledForm}
          disableDateUpdate={disableDateUpdate}
        />
      )}
    </div>
  );
};

export default FptContainer;
