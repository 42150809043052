import React from "react";
import { ResponsivePie } from "@nivo/pie";

const MyResponsivePie = ({ data }) => (
  <React.Fragment>
    <ResponsivePie
      data={data}
      margin={{
        top: 20,
        right: 0,
        bottom: 20,
        left: 10,
      }}
      innerRadius={0.5}
      startAngle={353}
      endAngle={-64}
      padAngle={4}
      sortByValue
      cornerRadius={3}
      colors={{ scheme: "pastel2" }}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      radialLabelsSkipAngle={30}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={3}
      radialLabelsLinkDiagonalLength={8}
      radialLabelsLinkHorizontalLength={8}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor="#b4b5b4"
      slicesLabelsSkipAngle={30}
      slicesLabelsTextColor="black"
      motionStiffness={90}
      motionDamping={15}
      fit={false}
    />
  </React.Fragment>
);

export default MyResponsivePie;
