import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikActions from "../../../../../commons/formik/formikActions";
import { FormControlLabel, makeStyles, Radio } from "@material-ui/core";
import FormikRadio from "../../../../../commons/formik/formikRadio";
import CircularLoading from "../../../../../commons/loadingData/circularLoading";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    marginBottom: 10,
  },
  loading: {
    marginTop: 40,
    marginBottom: 80,
  },
}));

const validation = Yup.object({
  marcaFirmado: Yup.string().required("Campo obligatorio"),
  firmaConforme: Yup.string().required("Campo obligatorio"),
});

const SignForm = ({
  open,
  handleClose,
  handleSubmit,
  document,
  handleConfirmClose,
}) => {
  const classes = useStyles();

  const postSignForm = useSelector((state) => state.forms.postSignForm);

  const signFormModel = {
    marcaFirmado: document.marcaFirmado ? "Si" : "No",
    firmaConforme: document.firmaConforme || "",
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      <DialogTitle>Firma del trabajador</DialogTitle>
      {postSignForm.isFetching ? (
        <DialogContent className={classes.loading}>
          <CircularLoading />
        </DialogContent>
      ) : (
        <Formik
          initialValues={signFormModel}
          validationSchema={validation}
          onSubmit={(values) => {
            handleSubmit(values);
          }}>
          {(props) => (
            <Form>
              <DialogContent>
                <div className={classes.rowContainer}>
                  <FormikRadio
                    name="marcaFirmado"
                    value={signFormModel.marcaFirmado}
                    label="¿El trabajador firmó el documento?"
                    row>
                    <FormControlLabel
                      value="Si"
                      control={<Radio />}
                      label="Si"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="end"
                    />
                  </FormikRadio>
                </div>
                {props.values.marcaFirmado == "Si" && (
                  <div className={classes.rowContainer}>
                    <FormikRadio
                      name="firmaConforme"
                      value={signFormModel.firmaConforme}
                      label="¿El trabajador firmó en conformidad?"
                      row>
                      <FormControlLabel
                        value="C"
                        control={<Radio />}
                        label="Si"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="D"
                        control={<Radio />}
                        label="No"
                        labelPlacement="end"
                      />
                    </FormikRadio>
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <FormikActions handleCancel={handleConfirmClose} />
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default SignForm;
