import axios from "axios";

export const GET_PENDING_APPOINTMENTS = "GET_PENDING_APPOINTMENTS";
export const GET_APPOINTMENTS_FILTER = "GET_APPOINTMENTS_FILTER";
export const CLEAN_APPOINTMENTS_FILTER = "CLEAN_APPOINTMENTS_FILTER";
export const CANCELLED_APPOINTMENT = "CANCELLED_APPOINTMENT";
export const ATTENDED_APPOINTMENT = "ATTENDED_APPOINTMENT";
export const UN_ATTENDED_APPOINTMENT = "UN_ATTENDED_APPOINTMENT";
export const REMOVE_APPOINTMENT_FROM_LIST = "REMOVE_APPOINTMENT_FROM_LIST";
export const POST_APPOINTMENT = "POST_APPOINTMENT";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const APPOINTMENT_SESSION = "APPOINTMENT_SESSION";
export const POST_SESSION = "POST_SESSION";
export const CLEAR_IDTURNOS = "CLEAR_IDTURNOS";

export const clearIdTurnos = () => ({
  type: CLEAR_IDTURNOS,
});

export const getPendingAppointments = (timeSpan) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_PENDING_APPOINTMENTS,
      payload: axios
        .get(`appointment/${selectedProvider}/pending?timeSpan=${timeSpan}`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const removeAndRefreshPendingAppointments =
  (appointmentIdToRemove) => (dispatch) => {
    if (appointmentIdToRemove) {
      dispatch({
        type: REMOVE_APPOINTMENT_FROM_LIST,
        payload: {
          data: {
            appointmentIdToRemove,
          },
        },
      });
    }
  };

export const getAppointmentsWithFilters = (values) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;
  const { art, siniestro, dni, estado, fechaDesde, fechaHasta } = values;
  if (selectedProvider) {
    dispatch({
      type: GET_APPOINTMENTS_FILTER,
      payload: axios
        .get(
          `appointment/${selectedProvider}?art=${art}&siniestro=${siniestro}&dni=${dni}&estado=${estado}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const cleanAppointmentsWithFilters = () => ({
  type: CLEAN_APPOINTMENTS_FILTER,
});

export const cancelledAppointment =
  (appointmentId, wriId) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    if (selectedProvider) {
      return dispatch({
        type: CANCELLED_APPOINTMENT,
        payload: axios
          .put(
            `appointment/${selectedProvider}/cancelled?Appointment=${appointmentId}&Wri=${wriId}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    }
  };

export const attendedAppointment =
  (appointmentId, wriId) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    if (selectedProvider) {
      return dispatch({
        type: ATTENDED_APPOINTMENT,
        payload: axios
          .put(
            `appointment/${selectedProvider}/attended?Appointment=${appointmentId}&Wri=${wriId}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    }
  };

export const updateAppointment =
  (appointmentId, wriId, dataAppointment) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    if (selectedProvider) {
      return dispatch({
        type: UPDATE_APPOINTMENT,
        payload: axios
          .put(
            `appointment/${selectedProvider}/?Appointment=${appointmentId}&WriId=${wriId}`,
            dataAppointment,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    }
  };

export const unAttendedAppointment =
  (appointmentId, wriId) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    if (selectedProvider) {
      return dispatch({
        type: UN_ATTENDED_APPOINTMENT,
        payload: axios
          .put(
            `appointment/${selectedProvider}/unattended?Appointment=${appointmentId}&Wri=${wriId}`,
          )
          .then((response) => ({ data: response.data }))
          .catch((error) => Promise.reject(error)),
      });
    }
  };

export const postAppointment =
  (appointment, idArt, siniestro, nroFormulario) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;
    const { accident } = getState().accidentDashboard;
    const accidentProp = accident.mlCloudCoreArtId
      ? accident
      : {
          mlCloudCoreArtId: idArt,
          idSiniestro: siniestro,
        };
    return dispatch({
      type: POST_APPOINTMENT,
      payload: axios
        .post(
          `appointment?idArt=${accidentProp.mlCloudCoreArtId}&siniestro=${
            accidentProp.idSiniestro
          }${
            nroFormulario ? `&nroFormulario=${nroFormulario}` : ""
          }&providerId=${selectedProvider}`,
          appointment,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const getAppointmentSession =
  (appointmentId) => (dispatch, getState) => {
    const { accident } = getState().accidentDashboard;

    dispatch({
      type: APPOINTMENT_SESSION,
      payload: axios
        .get(
          `appointment/session?idArt=${accident.mlCloudCoreArtId}&appointmentId=${appointmentId}`,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const postSession =
  (appointments, idArt, siniestro, nroFormulario) => (dispatch, getState) => {
    const { selectedProvider } = getState().providers;

    return dispatch({
      type: POST_SESSION,
      payload: axios
        .post(
          `appointment/session?idArt=${idArt}&siniestro=${siniestro}${
            nroFormulario ? `&nroFormulario=${nroFormulario}` : ""
          }&ProviderId=${selectedProvider}`,
          appointments,
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };
