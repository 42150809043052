import MaterialTable from "material-table";
import React from "react";
import { useSelector } from "react-redux";
import {
  localization,
  useTableStyles,
} from "../../../commons/table/config/tableConfig";
import TitleContainer from "../../../commons/table/utilsTable/titleWithFetchData";

const PrestadorTable = ({ data, loading, fetchData, handleDelete }) => {
  const classes = useTableStyles()();
  const userType = useSelector((state) => state.auth.roles.tipo);

  const columns = [
    {
      title: "Razón social",
      field: "nombre",
      render: (rowData) => rowData.nombre,
    },
  ];

  return (
    <div className={classes.tablePadding}>
      <MaterialTable
        columns={columns}
        data={data}
        isLoading={loading}
        locale="es"
        title={<TitleContainer title="Prestadores" fetchData={fetchData} />}
        options={{
          actionsColumnIndex: -1,
          rowStyle: classes.rowStyle,
          headerStyle: classes.headerStyle,
          pageSize: 10,
          search: false,
          filtering: true,
          exportButton: false,
        }}
        actions={[
          {
            icon: "delete",
            tooltip: "Deshabilitar Prestador",
            onClick: (event, rowData) => handleDelete(rowData),
            disabled: userType === "Usuario Art",
          },
        ]}
        localization={{
          ...localization,
          body: {
            emptyDataSourceMessage: "No hay Prestadores que mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
      />
    </div>
  );
};

export default PrestadorTable;
