import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import version from "../../../../package.json";
import Logo from "../../../assets/images/logo_medium.png";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "default",
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "500px",
    marginTop: 10,
    cursor: "default",
  },
  title: {
    margin: -2,
  },
}));

export default function AboutDialog({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialog}
      disableBackdropClick={true}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h2 className={classes.title}>Acerca de...</h2>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <div className={classes.dialogContent}>
            <img src={Logo} alt="" />
            <p>
              <b>Versión {version}</b>
            </p>
            <div>
              Copyright © 1998 - {new Date().getFullYear()}. Access Informática
              S.R.L.
            </div>
            <div>Todos los derechos reservados.</div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
