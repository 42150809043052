import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import moment from "moment";
import React from "react";

const useStyles = makeStyles({
  card: {
    display: "flex",
    margin: 10,
    padding: 15,
  },
  cardLeftContainer: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
  },
  cardRightContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardIcon: {
    width: 30,
    height: 30,
    cursor: "pointer",
  },
});

const OpenAccidentCards = ({ accidents, handleView }) => {
  const classes = useStyles();

  return accidents.map((accident) => (
    <Paper elevation={2} className={classes.card} key={accident.siniestro}>
      <div className={classes.cardLeftContainer}>
        <Typography>
          <b>Nº Siniestro:</b> {accident.siniestro}
        </Typography>
        <Typography>
          <b>Fecha hora accidente:</b>{" "}
          {moment(accident.fechaHoraAccidente).format("DD/MM/YYYY HH:mm")}
        </Typography>
        <Typography>
          <b>Diagnóstico:</b> {accident.diagnosticoOMS} - {accident.diagnostico}
        </Typography>
        <Typography>
          <b>Empleador:</b> {accident.empleador} ({accident.empleadorCuit})
        </Typography>
        <Typography>
          <b>Referente:</b> {accident.referente}
        </Typography>
        <Typography>
          <b>Referente Email:</b> {accident.referenteEmail}
        </Typography>
      </div>
      <div className={classes.cardRightContainer}>
        <Tooltip title="Ver siniestro">
          <Visibility
            className={classes.cardIcon}
            onClick={() => handleView(accident)}
          />
        </Tooltip>
      </div>
    </Paper>
  ));
};

export default OpenAccidentCards;
