import axios from "axios";

import { getArt, getArtsNotAdhered } from "./art";

export const GET_USER_PROVIDERS = "GET_USER_PROVIDERS";
export const CHANGE_SELECT_PROVIDER = "CHANGE_SELECT_PROVIDER";
export const GET_LIST_PROVIDERS = "GET_LIST_PROVIDERS";
export const CREATE_PROVIDER = "CREATE_PROVIDER";
export const UPDATE_PROVIDER = "UPDATE_PROVIDER";
export const DELETE_PROVIDER = "DELETE_PROVIDER";
export const REQUEST_ADHESION_BY_PROVIDER = "REQUEST_ADHESION_BY_PROVIDER";
export const CANCEL_ADHERENCE = "CANCEL_ADHERENCE";
export const CONFIRM_ADHERENCE = "CONFIRM_ADHERENCE";
export const DISABLE_ART_PRESTADOR = "DISABLE_ART_PRESTADOR";

export const getUserProviders = () => (dispatch, getState) => {
  const { selectedProvider } = getState().userPreferences;

  const users = dispatch({
    type: GET_USER_PROVIDERS,
    payload: axios
      .get("provider/userproviders")
      .then((response) => ({ data: response.data, selectedProvider }))
      .catch((error) => Promise.reject(error)),
  });

  users.then(() => {
    dispatch(getArt());
    dispatch(getArtsNotAdhered());
  });
};

export const changeSelectProvider = (provider) => (dispatch) => {
  dispatch({
    type: CHANGE_SELECT_PROVIDER,
    payload: { provider },
  });

  return dispatch(getArt());
};

// este metodo es para cambiar el prestador sin hacer una request de getArts(),
// ya que esa action (getArts) solo funciona para los usuarios que son prestadores.
export const changeSelectedProviderWriUser = (providerId) => (dispatch) => {
  return dispatch({
    type: CHANGE_SELECT_PROVIDER,
    payload: { provider: providerId },
  });
};

export const getProviders = (token) => (dispatch) => {
  dispatch({
    type: GET_LIST_PROVIDERS,
    payload: axios
      .get("provider", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response)
      .catch((error) => Promise.reject(error)),
  });
};

export const createProvider = (provider) => (dispatch) => {
  dispatch({
    type: CREATE_PROVIDER,
    payload: axios
      .post("provider", provider)
      .then((response) => ({
        data: response.data,
        provider,
      }))
      .catch((error) => Promise.reject(error)),
  });
};

export const updateProvider = (provider) => (dispatch) => {
  dispatch({
    type: UPDATE_PROVIDER,
    payload: axios
      .put("provider", provider)
      .then(() => ({
        provider,
      }))
      .catch((error) => Promise.reject(error)),
  });
};

export const deleteProvider = (id) => (dispatch) => {
  dispatch({
    type: DELETE_PROVIDER,
    payload: axios
      .delete(`provider/${id}`)
      .then((response) => ({ data: response.data, id }))
      .catch((error) => Promise.reject(error)),
  });
};

export const requestAdhesionByProviderId =
  (providerId, adhesion) => (dispatch) => {
    return dispatch({
      type: REQUEST_ADHESION_BY_PROVIDER,
      payload: axios
        .put(`provider/${providerId}/wris/adhere`, adhesion)
        .then((response) => response)
        .catch((error) => Promise.reject(error)),
    });
  };

export const cancelAdherence = (providerId, adhesion) => (dispatch) => {
  dispatch({
    type: CANCEL_ADHERENCE,
    payload: axios
      .put(`provider/${providerId}/wris/cancelAdherence`, {
        artId: adhesion.id,
      })
      .then((response) => ({ data: response.data, adhesion, providerId }))
      .catch((error) => Promise.reject(error)),
  });
};

export const disableArtPrestador = (idPrestador, idArt) => (dispatch) => {
  return dispatch({
    type: DISABLE_ART_PRESTADOR,
    payload: axios
      .put(`provider/${idPrestador}/wris/cancelAdherence`, {
        artId: idArt,
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const confirmAdherence = (providerId, adhesion) => (dispatch) => {
  dispatch({
    type: CONFIRM_ADHERENCE,
    payload: axios
      .put(`provider/${providerId}/wris/confirmAdherence`, {
        artId: adhesion.id,
      })
      .then((response) => ({ data: response.data, adhesion, providerId }))
      .catch((error) => Promise.reject(error)),
  });
};
