import { Chip, makeStyles } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ReplayIcon from "@material-ui/icons/Replay";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfidentialFields } from "../../../actions/fields";
import { getFormatoFechaExportacion } from "../../../utils/datetimeUtils";
import { downloadCsv } from "../../../utils/filesUtils";
import snackbarUtils from "../../../utils/snackbarUtils";
import { redactFromRegistroConfidentialFields } from "../../../utils/util";
import MessagesTooltip from "../tooltip/messagesTooltip";
import { localization, useTableStyles } from "./config/tableConfig";
import DetailsTable from "./utilsTable/detailsTableAccidents";
import TitleContainer from "./utilsTable/titleWithFetchData";
const useStyles = makeStyles((theme) => ({
  actionButton: {
    width: "10px",
  },
  desvioIcon: {
    fill: "#e6d400",
    width: 28,
    height: 28,
    marginTop: 5,
    cursor: "pointer",
  },
  detailsTableContainer: {
    backgroundColor: "#fafafa",
  },
  error: {
    borderColor: "#f37c578f",
    color: "white",
    backgroundColor: "#FF9A78",
    fontFamily: "Roboto, sans-serif",
  },
  success: {
    borderColor: "green",
    backgroundColor: "#00800094",
    color: "white",
    fontFamily: "Roboto, sans-serif",
  },
}));

const MaterialTableComponent = ({
  data,
  namesArts,
  loading,
  noRefresh,
  fetchData,
  handleAccidentDetail,
  displayAccidents,
  handleEdit,
  handleReOpenAccident,
  openInSearch,
  openModalCloseAccident,
}) => {
  const classes = useStyles();
  const tableClasses = useTableStyles(displayAccidents)();
  const dispatch = useDispatch();

  const esPrestador = useSelector((state) => state.auth.roles?.PRESTADOR);

  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

  const columns = [
    {
      title: "Art",
      field: "art",
      lookup: namesArts,
      render: (rowData) => rowData.art.toUpperCase(),
      filtering: !!namesArts,
      width: "10%",
    },
    {
      title: "Nuevo",
      field: "nuevo",
      lookup: { true: "Si", false: "No" },
      width: "5%",
    },
    {
      title: "Siniestro",
      field: "siniestro",
      width: "10%",
      render: (rowData) => {
        if (rowData.siniestro === "0") return " ";
        return rowData.siniestro;
      },
    },
    { title: "Documento", field: "documento", width: "10%" },
    {
      title: "Trabajador",
      field: "trabajador",
      width: "20%",
      customFilterAndSearch: (term, rowData) => {
        if (
          rowData.apellidoTrabajador
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          rowData.nombreTrabajador.toLowerCase().includes(term.toLowerCase())
        )
          return true;
        return false;
      },
      render: (rowData) => {
        return rowData.apellidoTrabajador + ", " + rowData.nombreTrabajador;
      },
    },
    {
      title: "Accidente",
      field: "fechaHoraAccidente",
      width: "10%",
      customFilterAndSearch: (term, rowData) => {
        if (
          moment(rowData.fechaHoraAccidente).format("DD/MM/YYYY").includes(term)
        )
          return true;
        return false;
      },
      render: (rowData) =>
        moment(rowData.fechaHoraAccidente).format("DD/MM/YYYY"),
    },
    {
      title: "Próximo Control",
      field: "proximoControl",
      width: "10%",
      cellStyle: {
        textAlign: "center",
      },
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.proximoControl).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) => {
        if (rowData.proximoControl === null) {
          return "";
        }
        const diff = moment(rowData.proximoControl).diff(Date.now(), "days");

        return (
          <Chip
            variant="outlined"
            label={moment(rowData.proximoControl).format("DD/MM/YYYY")}
            className={diff < 0 ? classes.error : classes.success}
          />
        );
      },
    },
    {
      title: "Desvío",
      field: "desvio",
      lookup: { true: "Si", false: "No" },
      filtering: true,
      width: "5%",
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => {
        if (rowData.desvio) {
          return (
            <MessagesTooltip values={rowData.desviosDescripcion}>
              <WarningTwoToneIcon className={classes.desvioIcon} />
            </MessagesTooltip>
          );
        }
        return "";
      },
    },
  ];

  const formatTableDataForExport = (table) => {
    const tableDataWithFormat = table.map((fila) => [
      fila.art ? `"${fila.art}"` : "",
      fila.siniestro ? `"${fila.siniestro}"` : "",
      fila.documento ? `"${fila.documento}"` : "",
      fila.nombreTrabajador + " " + fila.apellidoTrabajador,
      fila.empleador ? `"${fila.empleador}"` : "",
      getFormatoFechaExportacion(fila.fechaHoraAccidente, "P"),
      getFormatoFechaExportacion(fila.proximoControl, "P"),
      fila.administrador ? `"${fila.administrador}"` : "",
      fila.cuitEmpleador ? `"${fila.cuitEmpleador}"` : "",
      getFormatoFechaExportacion(fila.fechaAlta, "P"),
      getFormatoFechaExportacion(fila.fechaPrimeraCuracion, "P"),
      fila.estado ? `"${fila.estado}"` : "",
    ]);

    return tableDataWithFormat;
  };

  const handleExportToCsv = (data) => {
    setIsDownloadingCsv(true);

    dispatch(getConfidentialFields("siniestro"))
      .then((res) => {
        const redactedData = data.map((row) => {
          return redactFromRegistroConfidentialFields(
            row,
            res.value.data,
            "mlCloudCoreArtId",
          );
        });

        const encabezado = [
          "Art",
          "Siniestro",
          "Documento",
          "Trabajador",
          "Empleador",
          "Accidente",
          "Próximo Control",
          "Administrador",
          "CuitEmpleador",
          "Alta",
          "Primera Curación",
          "Estado",
        ];

        const formattedDataAsCsv = [
          encabezado,
          ...formatTableDataForExport(redactedData),
        ];

        let csvContent = "sep=,\n";
        csvContent += `${formattedDataAsCsv
          .map((e) => e.join(","))
          .join("\n")}`;

        downloadCsv(csvContent, "Accidentes");
        snackbarUtils.success("Los siniestros han sido descargados con éxito.");
        setIsDownloadingCsv(false);
      })
      .catch((err) => {
        snackbarUtils.error(err);
        setIsDownloadingCsv(false);
      });
  };
  return (
    <div className={`${tableClasses.container} ${tableClasses.tablePadding}`}>
      <MaterialTable
        columns={columns}
        data={data}
        isLoading={loading || isDownloadingCsv}
        locale="fr"
        title={
          <TitleContainer
            title="Siniestros"
            noRefresh={noRefresh}
            fetchData={fetchData}
          />
        }
        actions={[
          (rowData) => ({
            icon:
              rowData.metodoCarga === "M" ? "edit" : () => <EditOutlinedIcon />,
            tooltip: rowData.editable
              ? "Editar"
              : "No es posible editar el siniestro",
            onClick: (event, rowData) => handleEdit(rowData),
            disabled: !rowData.editable || !esPrestador,
          }),
          (rowData) => ({
            icon: () => <ReplayIcon />,
            tooltip:
              rowData.reabrirSiniestro == 1
                ? "Reabrir Siniestro"
                : "No es posible reabrir el siniestro",
            onClick: (event, rowData) => handleReOpenAccident(rowData),
            hidden: !openInSearch,
            disabled: !rowData.reabrirSiniestro || !esPrestador,
          }),
          {
            icon: "visibility",
            tooltip: "Ver detalles",
            onClick: (event, rowData) => {
              handleAccidentDetail(rowData);
            },
            className: classes.actionButton,
          },

          (rowData) => ({
            icon: "close",
            tooltip: "Cerrar siniestro",
            onClick: (event, rowData) => {
              openModalCloseAccident(rowData);
            },
            className: classes.actionButton,
            hidden: openInSearch,
            disabled:
              (rowData.cerrarSiniestro == 0 &&
                rowData.alta == false &&
                rowData.cerrado == 0) ||
              !esPrestador,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          rowStyle: localization.rowStyle,
          headerStyle: localization.headerStyle,
          pageSize: 10,
          search: false,
          filtering: true,
          exportButton: true,
          // eslint-disable-next-line no-shadow
          exportCsv: (columns, dataExport) => {
            handleExportToCsv(dataExport);
          },
        }}
        localization={{
          ...localization,
          body: {
            emptyDataSourceMessage: "No hay siniestros que mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
        detailPanel={[
          {
            tooltip: "Más Información",
            render: (rowData) => (
              <div className={classes.detailsTableContainer}>
                <DetailsTable rowData={rowData} />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default MaterialTableComponent;
