import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import { CancelOutlined } from "@material-ui/icons";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getFormatoFechaExportacion } from "../../../../utils/datetimeUtils";
import { downloadCsv } from "../../../../utils/filesUtils";
import snackbarUtils from "../../../../utils/snackbarUtils";
import {
  localization,
  useTableStyles,
} from "../../../commons/table/config/tableConfig";
import TitleContainer from "../../../commons/table/utilsTable/titleWithFetchData";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    marginLeft: 24,
  },
  formDataValue: {
    textAlign: "center",
  },
}));

const ConnectivityErrorsTable = ({
  data,
  namesArts,
  loading,
  title,
  noRefresh,
  fetchData,
  handleChangeTime,
  handleDesestimar,
  handleOpenEditForm,
}) => {
  const classes = useStyles();
  const tableClasses = useTableStyles()();
  const time = useSelector(
    (state) => state.connectivityResponse.connectivityErrors.time,
  );
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
  const columns = [
    {
      title: "Art",
      field: "art",
      lookup: namesArts,
      render: (rowData) => rowData.art.toUpperCase(),
      filtering: !!namesArts,
      width: "10%",
    },
    {
      title: "Fecha Formulario",
      field: "fechaFormulario",
      width: "5%",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaFormulario).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) => moment(rowData.fechaFormulario).format("DD/MM/YYYY"),
    },
    {
      title: "Fecha",
      field: "fechaError",
      width: "7%",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaError).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) => moment(rowData.fechaError).format("DD/MM/YYYY"),
    },
    { title: "Siniestro", field: "historiaClinica", width: "7%" },
    { title: "Documento", field: "documento", width: "5%" },
    {
      title: "Trabajador",
      field: "trabajador",
      width: "12%",
      customFilterAndSearch: (term, rowData) => {
        if (
          rowData.apellidoTrabajador
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          rowData.nombreTrabajador.toLowerCase().includes(term.toLowerCase())
        )
          return true;
        return false;
      },
    },
    { title: "Empleador", field: "empleador", width: "15%" },
    {
      title: "Formulario",
      field: "formulario",
      render: (rowData) => (
        <div className={classes.formDataValue}>{rowData.formulario}</div>
      ),
    },
    {
      title: "Detalle",
      field: "detalle",
      width: "50%",
    },
  ];

  const handleExportToCsv = (data) => {
    setIsDownloadingCsv(true);

    const encabezado = [
      "Art",
      "Fecha Formulario",
      "Fecha",
      "Siniestro",
      "Documento",
      "Trabajador",
      "Empleador",
      "Formulario",
      "Detalle",
    ];

    const formattedDataAsCsv = [encabezado, ...formatTableDataForExport(data)];

    let csvContent = "";
    csvContent += `${formattedDataAsCsv.map((e) => e.join(",")).join("\n")}`;

    downloadCsv(csvContent, title);
    snackbarUtils.success("Los errores han sido descargados con éxito.");
    setIsDownloadingCsv(false);
  };
  const formatTableDataForExport = (table) => {
    const tableDataWithFormat = table.map((fila) => [
      fila.art ? `"${fila.art}"` : "",
      getFormatoFechaExportacion(fila.fechaFormulario, "dd/MM/yyyy"),
      getFormatoFechaExportacion(fila.fechaError, "dd/MM/yyyy"),
      fila.siniestro ? `"${fila.siniestro}"` : "",
      fila.documento ? `"${fila.documento}"` : "",
      fila.trabajador ? `"${fila.trabajador}"` : "",
      fila.empleador ? `"${fila.empleador}"` : "",
      fila.formulario ? `"${fila.formulario}"` : "",
      fila.detalle ? `"${fila.detalle}"` : "",
    ]);

    return tableDataWithFormat;
  };
  return (
    <>
      <div className={tableClasses.tablePadding}>
        <MaterialTable
          columns={columns}
          data={data}
          isLoading={loading || isDownloadingCsv}
          title={
            <TitleContainer
              title={title}
              noRefresh={noRefresh}
              fetchData={fetchData}
            />
          }
          actions={[
            {
              icon: () => <CancelOutlined />,
              tooltip: "Desestimar",
              onClick: (event, rowData) => {
                handleDesestimar(rowData);
              },
            },
            {
              icon: "edit",
              tooltip: "Editar",
              onClick: (event, rowData) => handleOpenEditForm(rowData),
            },
          ]}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                {time && (
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel>Duración</InputLabel>
                      <Select onChange={handleChangeTime} value={time}>
                        <MenuItem value={2}>48 horas</MenuItem>
                        <MenuItem value={7}>7 días</MenuItem>
                        <MenuItem value={30}>30 días</MenuItem>
                        <MenuItem value={100}>100 días</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: localization.headerStyle,
            rowStyle: localization.rowStyle,
            pageSize: 10,
            search: false,
            filtering: true,
            exportButton: true,
            exportCsv: (columns, dataExport) => {
              handleExportToCsv(dataExport);
            },
          }}
          localization={{
            ...localization,
            body: {
              emptyDataSourceMessage: "No hay errores que mostrar",
              filterRow: {
                filterTooltip: "Filtrar",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default ConnectivityErrorsTable;
