import { Button, MenuItem, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import FormikSelect from "../formik/formikSelect";
import FormikTextField from "../formik/formikTextField";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    padding: 20,
    marginBottom: 20,
  },
  input: {
    display: "inline-flex",
    marginRight: 20,
    width: 250,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  artId: yup.string().required("Campo obligatorio"),
  workerId: yup
    .number("Ingrese sólo números")
    .integer("Ingrese sólo números")
    .typeError("Ingrese sólo números")
    .optional(),
});

const initialValues = {
  artId: "",
  workerId: "",
};

const SearchComponent = ({ invalid, handleSearch, art }) => {
  const classes = useStyles();

  const handleSubmitSearch = (values) => {
    let query = "";

    // como el artId es un campo obligatorio, no hace falta el if
    query += `&wri=${values.artId}`;
    if (values.accident) query = `${query}&accident=${values.accident}`;
    if (values.workerId) query = `${query}&WorkerId=${values.workerId}`;

    handleSearch(query);
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitSearch}>
          {({ handleSubmit }) => (
            <>
              <Form>
                <FormikSelect
                  name="artId"
                  label="Art"
                  variant="standard"
                  size="medium"
                  className={classes.input}>
                  {art.map((art) => (
                    <MenuItem value={art.id} key={art.id}>
                      {art.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>

                <FormikTextField
                  name="accident"
                  label="Siniestro"
                  variant="standard"
                  className={classes.input}
                />

                <FormikTextField
                  name="workerId"
                  label="Documento Trabajador"
                  variant="standard"
                  className={classes.input}
                />
              </Form>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  type="submit"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={invalid}>
                  Buscar
                </Button>
              </div>
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default SearchComponent;
