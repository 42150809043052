import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  clearAffectedZones,
  getAffectedZones,
} from "../../../../actions/forms";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikTextField from "../../../commons/formik/formikTextField";
import CircularLoading from "../../../commons/loadingData/circularLoading";

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: 15,
  },
  circularLoadingContainer: {
    marginTop: 50,
    marginBottom: 150,
  },
}));

const validation = Yup.object({
  id: Yup.string().required("Campo obligatorio"),
  cantidad: Yup.number().required("Campo obligatorio"),
  zonaAfectada: Yup.string().required("Campo obligatorio").nullable(),
});

const PrestacionesAdd = ({ prestacion, open, handleClose, handleSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const prestacionesState = useSelector((state) => state.forms.prestaciones);
  const affectedZonesState = useSelector((state) => state.forms.affectedZones);

  const [cantidadDisabled, setCantidadDisabled] = useState(true);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      <DialogTitle>Agregar prestación</DialogTitle>
      {prestacionesState.isFetching ? (
        <div className={classes.circularLoadingContainer}>
          <CircularLoading />
        </div>
      ) : (
        <Formik
          initialValues={prestacion}
          validationSchema={validation}
          onSubmit={(values) => {
            handleSubmit(values);
          }}>
          {({ values, setFieldValue }) => (
            <Form>
              <DialogContent>
                <FormikAutocomplete
                  name="id"
                  label="Prestación *"
                  options={prestacionesState.data}
                  className={classes.input}
                  callBack={(value) => {
                    if (value) {
                      if (value.cantidadFija) {
                        setFieldValue("cantidad", 1);
                        setCantidadDisabled(true);
                      } else {
                        setCantidadDisabled(false);
                      }
                      if (value.subnomenclador) {
                        setFieldValue("subnomenclador", value.subnomenclador);
                      }
                      if (values.id != value.id) {
                        setFieldValue("zonaAfectada", "");
                        dispatch(clearAffectedZones());
                      }
                    } else if (!value) {
                      setFieldValue("zonaAfectada", "");
                      dispatch(clearAffectedZones());
                    }
                  }}
                  renderOption={(option) => `${option.id} - ${option.nombre}`}
                  filterOptions={(option) => option.id + option.nombre}
                />
                <FormikTextField
                  name="cantidad"
                  label="Cantidad *"
                  type="number"
                  className={classes.input}
                  disabled={cantidadDisabled}
                />
                <FormikAutocomplete
                  name="zonaAfectada"
                  label="Zona del cuerpo *"
                  getOptions={() => {
                    dispatch(getAffectedZones(values.id));
                  }}
                  options={affectedZonesState.data}
                  className={classes.input}
                  disabled={!values.id}
                />
              </DialogContent>
              <DialogActions>
                <FormikActions handleCancel={handleClose} />
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default PrestacionesAdd;
