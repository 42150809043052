import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { useField } from "formik";
import React from "react";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginLeft: -10,
  },
}));

const FormikCheckbox = (props) => {
  const classes = useStyles();

  const [field] = useField(props);

  return (
    <FormControlLabel
      control={<Checkbox name={props.name} {...field} checked={field.value} />}
      {...props}
      className={
        props.className
          ? `${classes.checkbox} ${props.className}`
          : classes.checkbox
      }
    />
  );
};

export default FormikCheckbox;
