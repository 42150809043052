import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import edge_browser from "../../../assets/images/edge.png";
import chrome_browser from "../../../assets/images/chrome.png";
import opera_browser from "../../../assets/images/opera.png";
import firefox_browser from "../../../assets/images/firefox.png";

const useStyles = makeStyles((theme) => ({
  center: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto, sans-serif",
    fontSize: "x-large",
    alignSelf: "center",
    alignItems: "center",
    textAlign: "center",
  },
  browser: {
    color: "#303030",
    fontSize: "medium",
    marginRight: 20,
  },
  versionContainer: {
    fontSize: 12,
  },
  browserVersion: {
    color: "red",
  },
  browserMinVersion: {
    color: "green",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  link: {
    fontWeight: "500",
    color: "blue",
  },
}));

export default function UnsupportedVersionBrowser({
  browser,
  browserName,
  version,
  minVersion,
}) {
  const classes = useStyles();

  return (
    <div className={classes.center}>
      <div className={classes.root}>
        <p>
          El navegador que utiliza, <b>{browserName}</b>, no supera la versión
          mínima requerida para usar <b>ml.point</b>
          <br />
          <br />
          Le sugerimos actualizar su navegador a fin de poder utilizar la
          aplicación.
          <br />
          <br />
        </p>
        <div className={classes.container}>
          <div className={classes.browser}>
            <img
              src={
                {
                  "edge-chromium": edge_browser,
                  chrome: chrome_browser,
                  firefox: firefox_browser,
                  opera: opera_browser,
                }[browser]
              }
              alt={browserName}
              title={browserName}
              width="80"
              height="80"
            />

            <div>
              <a
                className={classes.link}
                href={
                  {
                    "edge-chromium": "https://www.microsoft.com/es-es/edge/",
                    edge: "https://www.microsoft.com/es-es/edge/",
                    chrome: "https://www.google.com/intl/es/chrome/",
                    firefox: "https://www.mozilla.org/es-ES/",
                    opera: "https://www.opera.com/es/",
                  }[browser]
                }
                target="_blank"
                rel="noreferrer"
              >
                {browser === "edge" ? browserName + "(Chromium)" : browserName}
              </a>
              <div>
                <br />
                <div className={classes.versionContainer}>
                  <div>
                    Su versión:
                    <span className={classes.browserVersion}>
                      {" "}
                      {browser !== "edge-chromium" ? version : browserName}
                    </span>
                  </div>
                  <div>
                    Versión mínima:
                    <span className={classes.browserMinVersion}>
                      {" "}
                      {browser !== "edge-chromium"
                        ? minVersion
                        : browserName}{" "}
                      (Chromium)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
