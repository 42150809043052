import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAccident } from "../../../../actions/accidentDashboard";
import {
  cleanConnectivityResponsesWithFilters,
  getConnectivityResponsesWithFilter,
} from "../../../../actions/connectivityResponse";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import AccidentDashboardContainer from "../../accident/accidentDashboard/accidentDashboardContainer";
import Search from "./Search";
import SearchErroresConectividadTable from "./SearchConnectivityErrorsTable";

const useStyles = makeStyles({
  container: {
    marginBottom: "24px",
    overflow: "hidden",
  },
  title: {
    color: "#484848",
    marginBottom: "8px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "large",
    fontWeight: 500,
  },
});
const initialSearchValues = {
  art: "",
  siniestro: "",
  dni: "",
  fechaDesde: null,
  fechaHasta: null,
  otrosPrestadores: false,
};
const SearchConnectivityErrorsContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const respuestasConectividad = useSelector(
    (state) => state.connectivityResponse,
  );
  const [displayAccidents, setDisplayAccidents] = useState("block");
  const [searchFilters, setSearchFilters] = useState(initialSearchValues);

  useEffect(() => {
    dispatch(cleanConnectivityResponsesWithFilters());
    setSearchFilters(initialSearchValues);
  }, []);

  const handleFetchData = () => {
    if (searchFilters.fechaDesde != null && searchFilters.fechaHasta != null) {
      dispatch(getConnectivityResponsesWithFilter(searchFilters));
    }
  };

  const handleSearch = (values) => {
    const searchModel = {
      ...values,
      fechaDesde: values.fechaDesde
        ? convertDateTimeToNet(values.fechaDesde)
        : "",
      fechaHasta: values.fechaHasta
        ? convertDateTimeToNet(values.fechaHasta)
        : "",
    };

    setSearchFilters(searchModel);
    dispatch(getConnectivityResponsesWithFilter(searchModel));
  };

  const handleAccidentDetail = (accident) => {
    dispatch(
      setSelectedAccident({
        ...accident,
        idSiniestro: accident.siniestro,
        apellidoTrabajador: accident.trabajador,
      }),
    );
    setDisplayAccidents("none");
  };
  return (
    <div className={classes.container}>
      {displayAccidents === "none" && (
        <AccidentDashboardContainer setDisplayAccidents={setDisplayAccidents} />
      )}
      {displayAccidents !== "none" && (
        <div>
          <div className={classes.title}>Buscar Respuestas Conectividad</div>
          <Search
            handleSubmit={handleSearch}
            loading={
              respuestasConectividad.searchConnectivityResponses.isFetching
            }
            initialValues={initialSearchValues}
          />
        </div>
      )}
      <SearchErroresConectividadTable
        data={
          respuestasConectividad.searchConnectivityResponses.data
            .searchRespuestaConectividadList
        }
        loading={respuestasConectividad.searchConnectivityResponses.isFetching}
        error={respuestasConectividad.searchConnectivityResponses.didInvalidate}
        fetchData={() => handleFetchData()}
        title="Respuestas Conectividad"
        handleOpenAccidentDetail={handleAccidentDetail}
        displayAccidents={displayAccidents}
      />
    </div>
  );
};

export default SearchConnectivityErrorsContainer;
