import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikActions from "../../commons/formik/formikActions";
import FormikTextField from "../../commons/formik/formikTextField";

const validation = Yup.object({
  nombre: Yup.string().required("Campo obligatorio"),
});

const EditProviderDialog = ({
  provider,
  open,
  handleClose,
  addingNewProvider,
  handleSubmit,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      <DialogTitle>
        {addingNewProvider ? "Agregar Prestador" : "Editar Prestador"}
      </DialogTitle>
      <Formik
        initialValues={provider}
        validationSchema={validation}
        onSubmit={(values) => {
          handleSubmit(values, addingNewProvider);
        }}>
        <Form>
          <DialogContent>
            <FormikTextField
              margin="dense"
              id="nombre"
              name="nombre"
              label="Razón social"
              type="text"
              fullWidth
            />
            <FormikTextField
              margin="dense"
              id="apiKeyValidadorSiniestros"
              name="apiKeyValidadorSiniestros"
              label="Api Key Validador Siniestros"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <FormikActions handleCancel={handleClose} />
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default EditProviderDialog;
