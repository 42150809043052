import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(0,0,0, 0.7)",
    borderRadius: 10,
    padding: 1,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#ff5c5c",
    borderRadius: 10,
    margin: 5,
    paddingLeft: 5,
    padding: 2,
  },
}));

const MessagesTooltip = ({ values, children }) => {
  const classes = useStyles();

  return (
    <HtmlTooltip
      arrow
      TransitionComponent={Zoom}
      placement="left"
      title={
        <React.Fragment>
          {values.map((value) => (
            <div className={classes.container} key={value}>
              <Typography variant="body2">{value}</Typography>
            </div>
          ))}
        </React.Fragment>
      }>
      {children}
    </HtmlTooltip>
  );
};

export default MessagesTooltip;
