import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import EspecialidadesAdd from "./especialidadesAdd";
import EspecialidadesCard from "./especialidadesCard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    marginBottom: 20,
  },
  errorText: {
    marginBottom: 10,
  },
}));

const especialidadModel = {
  idEspecialidadDiagnosticoOms: null,
  atencion: "",
  turno: null,
  observaciones: "",
};

const EspecialidadesContainer = ({
  especialidades,
  setEspecialidades,
  disabled,
  openAdd,
  setOpenAdd,
  isView,
  idArt,
}) => {
  const classes = useStyles();

  const handleAdd = (especialidad) => {
    let especialidadExisted = especialidades.find(
      (e) =>
        e.idEspecialidadDiagnosticoOms ===
        especialidad.idEspecialidadDiagnosticoOms,
    );

    if (!especialidadExisted) {
      especialidades.push({
        ...especialidad,
        turno: convertDateTimeToNet(especialidad.turno),
      });
    } else {
      especialidadExisted.turno = convertDateTimeToNet(especialidad.turno);
      especialidadExisted.observaciones = especialidad.observaciones;
    }
    handleCloseAdd();
  };

  const handleDelete = (especialidad) => {
    setEspecialidades(
      especialidades.filter(
        (e) =>
          e.idEspecialidadDiagnosticoOms !==
          especialidad.idEspecialidadDiagnosticoOms,
      ),
    );
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  return (
    <div className={classes.container}>
      <EspecialidadesCard
        especialidades={especialidades}
        disabled={disabled}
        handleDelete={handleDelete}
      />
      {especialidades.length === 0 && !isView && (
        <Typography variant="body2" color="error" className={classes.errorText}>
          Debe agregar una especialidad
        </Typography>
      )}
      <Button
        color="primary"
        variant="outlined"
        onClick={handleOpenAdd}
        disabled={disabled || isView}>
        Agregar especialidad
      </Button>
      {/* El agregado de especialidades deberia estar deshabilitado
      cuando estamos viendo el formulario */}
      <EspecialidadesAdd
        especialidad={especialidadModel}
        open={openAdd}
        handleClose={handleCloseAdd}
        handleSubmit={handleAdd}
        idArt={idArt}
      />
    </div>
  );
};

export default EspecialidadesContainer;
