import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import Logo from "../../../assets/images/logo_medium.png";
import { useDispatch } from "react-redux";
import { signoutUser } from "../../../actions/login";

const useStyles = makeStyles(() => ({
  root: {
    height: "95vh",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logo: {
    marginBottom: "20px",
  },
  alert: {
    marginTop: "20px",
  },
}));

export default function LoadingComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();

  setTimeout(() => {
    dispatch(signoutUser());
  }, 4000);

  return (
    <div className={classes.root}>
      <img className={classes.logo} src={Logo} alt="" />
      <Alert className={classes.alert} severity="warning" variant="filled">
        Redirigiendo...
      </Alert>
    </div>
  );
}
