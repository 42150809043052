import axios from "axios";
import Cookies from "js-cookie";
export const USER_LOGIN = "USER_LOGIN";
export const SIGNOUT_REQUEST = "SIGNOUT_REQUEST";
export const UNAUTHORIZED_REQUEST = "UNAUTHORIZED_REQUEST";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const loginUser = (credentials, userName) => ({
  type: USER_LOGIN,
  payload: axios
    .post("account/login", credentials)
    .then((response) => {
      const { token } = response.data;
      const { roles } = response.data;

      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      return {
        token,
        roles,
        userName,
      };
    })
    .catch((error) => Promise.reject(error)),
});

export const signoutUser = () => (dispatch) => {
  Object.keys(Cookies.get()).forEach((cookie) => Cookies.remove(cookie));
  delete axios.defaults.headers.common["Authorization"];
  dispatch({
    type: SIGNOUT_REQUEST,
    payload: {},
  });
};

export const impersonateUser = (user) => ({
  type: USER_LOGIN,
  payload: axios
    .post("account/impersonate", user)
    .then((response) => {
      const { token } = response.data;
      const { roles } = response.data;

      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      return {
        token,
        roles,
        userName: user.userName,
      };
    })
    .catch((error) => Promise.reject(error)),
});

export const refreshToken = (data) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
  return {
    type: REFRESH_TOKEN,
    payload: data.token,
  };
};

