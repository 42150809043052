import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanAccidentForm,
  getAccidentFacade,
  getAccidentForm,
  getAccidents,
} from "../../../../actions/accidents";
import { getFormDiagnosis } from "../../../../actions/forms";
import snackbarUtils from "../../../../utils/snackbarUtils";
import AccidentsFacadeAndOpen from "../newAccident/facadeAndOpenAccidents/accidentsFacadeAndOpen";
import EditAccidentFormContainer from "./editAccidentFormContainer";

const EditAccidentContainer = ({ accident, handleClose }) => {
  const dispatch = useDispatch();

  const facadeState = useSelector((state) => state.accidents.facade);

  const [artId, setArtId] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [facadeAccidents, setFacadeAccidents] = useState([]);
  const [openFacade, setOpenFacade] = useState(false);
  const [facadeAccident, setFacadeAccident] = useState(null);

  const handleSearch = (accident) => {
    setArtId(accident.mlCloudCoreArtId);
    switch (accident.metodoCarga) {
      case "M":
        dispatch(
          getAccidentForm(
            accident.mlCloudCoreArtId,
            accident.tipoDocumento,
            accident.documento,
            accident.idSiniestro,
            null,
          ),
        );
        dispatch(getFormDiagnosis(accident.mlCloudCoreArtId));
        setOpenForm(true);
        break;
      case "F":
        dispatch(
          getAccidentFacade(
            accident.mlCloudCoreArtId,
            accident.tipoDocumento,
            accident.documento,
          ),
        ).then((res) => {
          handleOpenFacade(res.value.data, accident.mlCloudCoreArtId);
        });
        setOpenFacade(true);
        break;
      default:
        snackbarUtils.error("No es posible modificar el siniestro.");
    }
  };

  const handleOpenFacade = (facadeAccidents, artId) => {
    if (facadeAccidents.length === 1) {
      handleEditFacade(facadeAccidents[0], artId);
    } else {
      setFacadeAccidents(facadeAccidents);
    }
  };

  const handleEditFacade = (facadeAccident, artIdParam) => {
    const artIdAux = artIdParam || artId;
    setFacadeAccident({ ...facadeAccident, siniestro: accident.idSiniestro });
    dispatch(
      getAccidentForm(
        artIdAux,
        null,
        null,
        null,
        facadeAccident.diagnosticoOMS,
      ),
    );
    dispatch(getFormDiagnosis(artIdAux));
    setOpenForm(true);
    handleCloseFacade();
  };

  const handleCloseFacade = () => {
    setOpenFacade(false);
    setFacadeAccidents([]);
    handleClose();
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFacadeAccident(null);
    dispatch(cleanAccidentForm());
    handleClose();
  };

  const handleSubmitForm = (ret) => {
    handleCloseFacade();
    handleCloseForm();
    dispatch(getAccidents());
    snackbarUtils.success("Siniestro modificado correctamente");
  };

  useEffect(() => {
    if (accident) {
      handleSearch(accident);
    }
  }, [accident]);

  return (
    <div>
      <EditAccidentFormContainer
        open={openForm}
        artId={artId}
        handleCloseForm={handleCloseForm}
        handleSubmitForm={handleSubmitForm}
        facadeAccident={facadeAccident}
      />
      <AccidentsFacadeAndOpen
        open={openFacade}
        handleClose={handleCloseFacade}
        openAccidents={[]}
        facadeAccidents={facadeAccidents}
        handleEdit={handleEditFacade}
        loading={facadeState.isFetching}
      />
    </div>
  );
};

export default EditAccidentContainer;
