import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postAccidentForm } from "../../../../actions/accidents";
import { convertDateTimeToNet } from "../../../../utils/datetimeUtils";
import { getCuilCuit } from "../../../../utils/util";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import AddFormHeader from "../../accident/accidentDashboard/addDocumentation/addFormHeader";
import AccidentForm from "./accidentForm";

const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100vh",
    padding: 0,
  },
  circularLoadingContainer: {
    width: "100%",
    marginTop: 100,
  },
});

const AccidentFormContainer = ({
  open,
  artId,
  handleCloseForm,
  handleSubmitForm,
  facadeAccident,
  tipoDocumento,
  numeroDocumento,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const accidentFormState = useSelector((state) => state.accidents.form);
  const postFormState = useSelector((state) => state.accidents.postForm);
  const employers = useSelector((state) => state.forms.employers);
  const diagnosis = useSelector((state) => state.forms.diagnosis);

  const [accidentForm, setAccidentForm] = useState(null);
  const [accidentModel, setAccidentModel] = useState(null);

  const isLoading =
    accidentFormState.isFetching ||
    employers.isFetching ||
    (facadeAccident && diagnosis.isFetching) ||
    !accidentForm ||
    !accidentModel ||
    postFormState.isFetching;

  useEffect(() => {
    if (!accidentFormState.isFetching && accidentFormState.data.model) {
      // Puede ser que vengan los campos pero estén en "null" o "" (string vacia).
      // En ese caso, no hay que pushear el objeto referente a la lista de opciones.
      const tieneDatosReferente =
        !!facadeAccident?.idReferenteExterno &&
        !!facadeAccident?.referenteApellidoyNombre &&
        !!facadeAccident?.referenteEmail;

      if (
        facadeAccident &&
        tieneDatosReferente &&
        !accidentFormState.data.model.referenteApellidoyNombre.options.some(
          (r) => r.id === facadeAccident.idReferenteExterno,
        )
      ) {
        accidentFormState.data.model.referenteApellidoyNombre.options.push({
          id: facadeAccident.idReferenteExterno,
          nombre: facadeAccident.referenteApellidoyNombre,
          email: facadeAccident.referenteEmail,
        });
      }
      setAccidentModel(accidentFormState.data.model);
    }
  }, [accidentFormState.isFetching]);

  const [employersOptions, setEmployersOptions] = useState();

  useEffect(() => {
    if (
      !accidentFormState.isFetching && !employers.isFetching &&
      (facadeAccident || accidentFormState.data.form)
    ) {
      const form = facadeAccident || accidentFormState.data.form;
      let empleadorInicial = employers.data.find((v) => v.cuit === form.cuitEmpleador);

      if (!empleadorInicial) {
        empleadorInicial = {
          cuit: form?.cuitEmpleador ?? "",
          razonSocial: form?.razonSocialEmpleador ?? "",
          calle: form?.calleEmpleador ?? "",
          numero: form?.numeroEmpleador ?? "",
          piso: form?.pisoEmpleador ?? "",
          departamento: form?.departamentoEmpleador ?? "",
          localidad: form?.localidadEmpleador ?? "",
          provincia: form?.provinciaEmpleador ?? "",
          codigoPostal: form?.codigoPostalEmpleador ?? "",
          telefonos: form?.telefonosEmpleador ?? "",
        };
        setEmployersOptions([...employers.data, empleadorInicial])
      } else {
        setEmployersOptions(employers.data);
      }

      setAccidentForm({
        ...form,
        tipoSucesoArt:
          accidentModel?.tipoSucesoArt?.options?.find(
            (e) => e.nombre == form.tipoSucesoArt?.trim(),
          )?.id || "ACCID",
        nroSiniestroArt: form.nroSiniestroArt || "",
        diagnosticoOMS:
          accidentFormState.data.diagnoticoOMSConverted || form.diagnosticoOMS,
        tipoDocumentoTrabajador:
          form.tipoDocumentoTrabajador || tipoDocumento || "",
        numeroDocumentoTrabajador:
          form.numeroDocumentoTrabajador || numeroDocumento || "",
        apellidoTrabajador: form.apellidoTrabajador || "",
        nombreTrabajador: form.nombreTrabajador || "",
        sexoTrabajador: form.sexoTrabajador || "",
        cuilCuitTrabajador:
          form.cuilCuitTrabajador ||
          (form.numeroDocumentoTrabajador && form.sexoTrabajador
            ? getCuilCuit(form.numeroDocumentoTrabajador, form.sexoTrabajador)
            : ""),
        calleTrabajador: form.calleTrabajador || "",
        numeroTrabajador: form.numeroTrabajador || "",
        pisoTrabajador: form.pisoTrabajador || "",
        departamentoTrabajador: form.departamentoTrabajador || "",
        localidadTrabajador: form.localidadTrabajador || "",
        provinciaTrabajador: form.provinciaTrabajador || "",
        codigoPostalTrabajador: form.codigoPostalTrabajador || "",
        areaTelefonosTrabajador: form.areaTelefonosTrabajador || "",
        telefonosTrabajador: form.telefonosTrabajador || "",
        areaTelefonoCelularTrabajador: form.areaTelefonoCelularTrabajador || "",
        telefonoCelularTrabajador: form.telefonoCelularTrabajador || "",
        cuitEmpleador: empleadorInicial?.cuit ?? null,
        razonSocialEmpleador: empleadorInicial?.razonSocial ?? "",
        calleEmpleador: empleadorInicial?.calle ?? "",
        numeroEmpleador: empleadorInicial?.numero ?? "",
        pisoEmpleador: empleadorInicial?.piso ?? "",
        departamentoEmpleador: empleadorInicial?.departamento ?? "",
        localidadEmpleador: empleadorInicial?.localidad ?? "",
        provinciaEmpleador: empleadorInicial?.provincia ?? "",
        codigoPostalEmpleador: empleadorInicial?.codigoPostal ?? "",
        telefonosEmpleador: empleadorInicial?.telefonos ?? "",
      });
    }
  }, [accidentFormState.isFetching, accidentModel, employers.isFetching]);

  const handleSubmit = (form) => {
    const model = {
      ...form,
      fechaHoraAccidente: convertDateTimeToNet(form.fechaHoraAccidente),
      fechaNacimientoTrabajador: convertDateTimeToNet(
        form.fechaNacimientoTrabajador,
      ),
    };
    setAccidentForm(null);
    setAccidentModel(null);
    dispatch(postAccidentForm(model, artId))
      .then((res) => handleSubmitForm(res.value.data.ret))
      .catch(() => handleClose());
  };

  const handleClose = () => {
    setAccidentForm(null);
    setAccidentModel(null);
    handleCloseForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      disableBackdropClick={true}>
      <AddFormHeader formName="siniestro" handleClose={handleClose} />
      <DialogContent className={classes.container}>
        {isLoading ? (
          <div className={classes.circularLoadingContainer}>
            <CircularLoading />
          </div>
        ) : (
          <AccidentForm
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            model={accidentModel}
            form={accidentForm}
            artId={artId}
            fromFacade={facadeAccident ? true : false}
            employers={employersOptions}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AccidentFormContainer;
