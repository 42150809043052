const HtmlToReactParser = require("html-to-react").Parser;
const createDOMPurify = require("dompurify");
const htmlToReactParser = new HtmlToReactParser();
const DOMPurify = createDOMPurify(window);

export const isEdgeChromium = () => {
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") !== -1;
  return isEdgeChromium;
};

export const parseHTMLToReact = (HTMLString) => {
  return typeof HTMLString === "string" || HTMLString instanceof String
    ? htmlToReactParser.parse(
      DOMPurify.sanitize(HTMLString.replace(/>\s+</g, "><")),
    )
    : "";
};

/**
 * Retorna la cantidad de bytes equivalente en KB o MB.
 *
 * @param {Number} bytes - La cantidad de bytes
 *
 * @example
 * getFileSizeAsString(1.024) => "1KB"
 * getFileSizeAsString(1.000.000) => "1MB"
 *
 * @returns {string} El tamaño en KB o MB
 */
export const getFileSizeAsString = (bytes) => {
  if (bytes === 0) {
    return "";
  }

  const enKilobytes = Math.ceil(bytes / 1024);
  if (enKilobytes < 1024) {
    return enKilobytes + "KB";
  }

  const enMegabytes = Math.ceil(bytes / 1024 / 1024);
  return enMegabytes + "MB";
};

/**
 * Transforma un campo de tipo bool en "Sí/No".
 * Si está vacío/null, retorna string vacia "".
 * Si está redactado, retorna "---".
 *
 * @param {string} campo
 *
 * @returns {string}
 */
export const getBoolParaExportacion = (campo) => {
  if (campo === null || campo === "") {
    return "";
  }

  const informacionSensible = campo === "---";
  if (informacionSensible) {
    return "---";
  }

  return campo ? "Sí" : "No";
};

/**
 * Reemplaza los valores de los campos sensibles por "---".
 *
 * @param {Object} registro - Fila de la tabla que queremos redactar.
 * @param {string[]} listaArtsConCamposSensibles - La lista de arts con sus
 * campos sensibles.
 * @param {string} propiedadRelacion - El nombre de la propiedad para
 * hacer la relación entre la ART correcta y el registro.
 */
export function redactFromRegistroConfidentialFields(
  registro,
  listaArtsConCamposSensibles,
  propiedadRelacion,
) {
  const artAsociadaRegistro = listaArtsConCamposSensibles.find(
    (art) => art.idArt === registro[propiedadRelacion],
  );

  if (!artAsociadaRegistro) return registro;

  return redactConfidentialFields(registro, artAsociadaRegistro.campos);
}

/**
 * Crea una copia del objeto y sobreescribe los valores
 * de las propiedades con "---" si encuentra que
 * la propiedad del objeto está en la lista de campos.
 *
 * @param {Object} objeto - El objeto a modificar
 * @param {string[]} campos - Lista de campos sensibles
 *
 * @returns {Object}
 */
function redactConfidentialFields(objeto, campos) {
  if (campos.length < 1) return objeto;

  // si solamente hacemos copiaRedactada = objeto (creyendo que estamos copiando)
  // va a resultar que después de la exportación, se borran los datos en la tabla.
  // Esto sucede ya que hacer copia = objeto, no copia el objeto y sus propiedades,
  // sino que le estamos indicando a JavaScript que "copia" apunta al
  // "objeto" en memoria.
  // Es decir, sin querer vamos a modificar también el original al modificar la copia.
  let copiaRedactada = JSON.parse(JSON.stringify(objeto));

  for (const propiedad in objeto) {
    const match = campos.find(
      // esto es necesario porque .NET utiliza los campos con NombreCampo
      // y aca en el front estan como nombreCampo. Si comparamos directamente
      // nunca van a ser iguales
      (c) => c.toLowerCase() === propiedad.toLowerCase(),
    );
    if (match) {
      copiaRedactada[propiedad] = "---";
    }
  }

  return copiaRedactada;
}

export const getCuilCuit = (numeroDocumento, genero) => {
  const HOMBRE = ["HOMBRE", "M", "MALE"],
    MUJER = ["MUJER", "F", "FEMALE"],
    SOCIEDAD = ["SOCIEDAD", "S", "SOCIETY"];
  let AB, C;

  if (typeof numeroDocumento !== "string") {
    numeroDocumento = numeroDocumento.toString();
  }

  if (numeroDocumento.length !== 8 || isNaN(numeroDocumento)) {
    if (numeroDocumento.length === 7 && !isNaN(numeroDocumento)) {
      numeroDocumento = "0".concat(numeroDocumento);
    } else {
      return "";
    }
  }

  genero = genero.toUpperCase();

  // Defino el valor del prefijo.
  if (HOMBRE.indexOf(genero) >= 0) {
    AB = "20";
  } else if (MUJER.indexOf(genero) >= 0) {
    AB = "27";
  } else {
    AB = "30";
  }

  /*
   * Los numeros (excepto los dos primeros) que le tengo que
   * multiplicar a la cadena formada por el prefijo y por el
   * numero de document_number los tengo almacenados en un arreglo.
   */
  const multiplicadores = [3, 2, 7, 6, 5, 4, 3, 2];

  // Realizo las dos primeras multiplicaciones por separado.
  let calculo = parseInt(AB.charAt(0)) * 5 + parseInt(AB.charAt(1)) * 4;

  /*
   * Recorro el arreglo y el numero de document_number para
   * realizar las multiplicaciones.
   */
  for (let i = 0; i < 8; i++) {
    calculo += parseInt(numeroDocumento.charAt(i)) * multiplicadores[i];
  }

  // Calculo el resto.
  let resto = parseInt(calculo) % 11;

  /*
   * Llevo a cabo la evaluacion de las tres condiciones para
   * determinar el valor de C y conocer el valor definitivo de
   * AB.
   */
  if (SOCIEDAD.indexOf(genero) < 0 && resto === 1) {
    if (HOMBRE.indexOf(genero) >= 0) {
      C = "9";
    } else {
      C = "4";
    }
    AB = "23";
  } else if (resto === 0) {
    C = "0";
  } else {
    C = 11 - resto;
  }
  const cuil_cuit = `${AB}-${numeroDocumento}-${C}`;

  return cuil_cuit;
};

/*
 * Devuelve un string en title case. Ejemplo: Ciudad autonoma de buenos aires => Ciudad Autonoma De Buenos Aires
 */

export function stringToTitleCase(s) {
  const words = s.toLowerCase().split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(' ');
}

/*
 *Devuelve un string reemplazando los caracteres con acentos si es posible. 
 */
export function removeAccentMarks(string) {
  return typeof string.normalize !== 'undefined' ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : string;
}