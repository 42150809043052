import {
  CANCEL_ADHERENCE,
  CHANGE_SELECT_PROVIDER,
  CONFIRM_ADHERENCE,
  CREATE_PROVIDER,
  DELETE_PROVIDER,
  DISABLE_ART_PRESTADOR,
  GET_LIST_PROVIDERS,
  GET_USER_PROVIDERS,
  REQUEST_ADHESION_BY_PROVIDER,
  UPDATE_PROVIDER,
} from "../actions/providers";

const INITIAL_STATE = {
  listProviders: {
    isFetching: false,
    didInvalidate: false,
    list: [],
    namesProviders: {},
  },
  isFetching: false,
  didInvalidate: false,
  list: [],
  selectedProvider: false,
  disableArtPrestador: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
};

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_USER_PROVIDERS}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      };
    case `${GET_USER_PROVIDERS}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case `${GET_USER_PROVIDERS}_FULFILLED`: {
      let { selectedProvider, data } = action.payload;
      let defaultProvider = null;

      if (selectedProvider)
        defaultProvider = data.filter(
          (provider) => provider.id === selectedProvider,
        );

      const incomingProvider = action.payload.data[0]
        ? action.payload.data[0].id
        : null;

      selectedProvider = defaultProvider
        ? defaultProvider[0].id
        : incomingProvider;

      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        list: action.payload.data,
        selectedProvider,
      };
    }

    case CHANGE_SELECT_PROVIDER:
      return {
        ...state,
        selectedProvider: action.payload.provider,
      };

    case `${GET_LIST_PROVIDERS}_REJECTED`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_LIST_PROVIDERS}_PENDING`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_LIST_PROVIDERS}_FULFILLED`: {
      const dataHelp = {};
      action.payload.data.providers.forEach((item) => {
        dataHelp[item.nombre] = item.nombre;
      });

      return {
        ...state,
        listProviders: {
          isFetching: false,
          didInvalidate: false,
          list: action.payload.data.providers,
          namesProviders: dataHelp,
        },
      };
    }
    case `${CREATE_PROVIDER}_PENDING`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${CREATE_PROVIDER}_REJECTED`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${CREATE_PROVIDER}_FULFILLED`: {
      const listProviders = [...state.listProviders.list];
      listProviders.push({
        ...action.payload.provider,
        id: action.payload.data.id,
      });

      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: false,
          list: listProviders,
        },
      };
    }
    case `${UPDATE_PROVIDER}_PENDING`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${UPDATE_PROVIDER}_REJECTED`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${UPDATE_PROVIDER}_FULFILLED`: {
      const listProviders = state.listProviders.list.map((p) => {
        if (p.id === action.payload.provider.id) {
          return action.payload.provider;
        } else {
          return p;
        }
      });

      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: false,
          list: listProviders,
        },
      };
    }
    case `${DELETE_PROVIDER}_PENDING`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${DELETE_PROVIDER}_REJECTED`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${DELETE_PROVIDER}_FULFILLED`: {
      const listProviders = [...state.listProviders.list];

      var removeIndex = listProviders
        .map((p) => {
          return p.id;
        })
        .indexOf(action.payload.id);

      listProviders.splice(removeIndex, 1);

      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: false,
          list: listProviders,
        },
      };
    }
    case `${REQUEST_ADHESION_BY_PROVIDER}_PENDING`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${REQUEST_ADHESION_BY_PROVIDER}_REJECTED`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${REQUEST_ADHESION_BY_PROVIDER}_FULFILLED`: {
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: false,
        },
      };
    }
    case `${CANCEL_ADHERENCE}_PENDING`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${CANCEL_ADHERENCE}_REJECTED`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${CANCEL_ADHERENCE}_FULFILLED`: {
      const listProviders = state.listProviders.list;

      const provider = listProviders.find(
        (p) => p.id === action.payload.providerId,
      );

      const wriList = [...provider.arts];

      const removeIndex = wriList
        .map((art) => art.id)
        .indexOf(action.payload.adhesion.id);

      wriList.splice(removeIndex, 1);
      provider.arts = wriList;

      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: false,
          list: listProviders,
        },
      };
    }
    case `${CONFIRM_ADHERENCE}_PENDING`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${CONFIRM_ADHERENCE}_REJECTED`:
      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${CONFIRM_ADHERENCE}_FULFILLED`: {
      const listProviders = state.listProviders.list;

      const provider = listProviders.find(
        (p) => p.id === action.payload.providerId,
      );

      const wriList = [...provider.arts];

      wriList.forEach((art) => {
        if (art.id === action.payload.adhesion.id) {
          art.adherida = 0;
        }
      });

      provider.arts = wriList;

      return {
        ...state,
        listProviders: {
          ...state.listProviders,
          isFetching: false,
          didInvalidate: false,
          list: listProviders,
        },
      };
    }
    case `${DISABLE_ART_PRESTADOR}_PENDING`:
      return {
        ...state,
        disableArtPrestador: {
          ...state.disableArtPrestador,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${DISABLE_ART_PRESTADOR}_REJECTED`:
      return {
        ...state,
        disableArtPrestador: {
          ...state.disableArtPrestador,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${DISABLE_ART_PRESTADOR}_FULFILLED`: {
      return {
        ...state,
        disableArtPrestador: {
          ...state.disableArtPrestador,
          isFetching: false,
          didInvalidate: false,
        },
      };
    }
    default:
      return state;
  }
};

export default providers;
