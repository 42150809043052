import {
  GET_LIST_USERS,
  USER_REGISTER,
  USER_DELETE,
  ADD_PROVIDER_IN_USER,
  DELETE_PROVIDER_IN_USER,
  GET_USER_ROLES,
} from "../actions/users";

const INITIAL_STATE = {
  listUsers: {
    isFetching: false,
    didInvalidate: false,
    list: [],
  },
  roles: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
};

const users = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_LIST_USERS}_REJECTED`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: false,
          didInvalidate: true,
          token: false,
        },
      };
    case `${GET_LIST_USERS}_PENDING`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_LIST_USERS}_FULFILLED`: {
      return {
        ...state,
        listUsers: {
          isFetching: false,
          didInvalidate: false,
          list: action.payload.data,
        },
      };
    }
    case `${USER_REGISTER}_REJECTED`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${USER_REGISTER}_PENDING`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${USER_REGISTER}_FULFILLED`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: false,
          didInvalidate: false,
        },
      };
    case `${USER_DELETE}_REJECTED`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${USER_DELETE}_PENDING`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${USER_DELETE}_FULFILLED`:
      const listUsers = [...state.listUsers.list];

      var removeIndex = listUsers
        .map((p) => {
          return p.email;
        })
        .indexOf(action.payload.user.Email);

      listUsers.splice(removeIndex, 1);
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: false,
          didInvalidate: false,
          list: listUsers,
        },
      };
    case `${ADD_PROVIDER_IN_USER}_REJECTED`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${ADD_PROVIDER_IN_USER}_PENDING`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${ADD_PROVIDER_IN_USER}_FULFILLED`: {
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: false,
          didInvalidate: false,
        },
      };
    }
    case `${DELETE_PROVIDER_IN_USER}_REJECTED`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${DELETE_PROVIDER_IN_USER}_PENDING`:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${DELETE_PROVIDER_IN_USER}_FULFILLED`: {
      const listUsers = state.listUsers.list;

      const user = listUsers.find((u) => u.id === action.payload.userId);

      const providerList = [...user.prestadores];

      const removeIndex = providerList
        .map((provider) => provider.id)
        .indexOf(action.payload.providerId);

      providerList.splice(removeIndex, 1);
      user.prestadores = providerList;

      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          isFetching: false,
          didInvalidate: false,
          list: listUsers,
        },
      };
    }
    case `${GET_USER_ROLES}_REJECTED`:
      return {
        ...state,
        roles: {
          ...state.roles,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_USER_ROLES}_PENDING`:
      return {
        ...state,
        roles: {
          ...state.roles,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_USER_ROLES}_FULFILLED`: {
      return {
        ...state,
        roles: {
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default users;
