import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExitToAppOutlined, LockOutlined, Person } from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { signoutUser } from "../../../actions/login";
import ChangePasswordDialog from "../setPassword/changePasswordDialog";

const useStyles = makeStyles(() => ({
  icon: {
    marginLeft: 5,
    color: "#858a93",
  },
  profileIconContainer: {
    display: "flex",
  },
  menu: {
    marginTop: 40,
    marginLeft: 0,
  },
  userName: {
    fontSize: "small",
  },
  listItemIcon: {
    marginRight: -20,
  },
}));

const IconUser = () => {
  const dispatch = useDispatch();

  const [openMenu, setOpenMenu] = React.useState(null);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);

  const handleClose = () => {
    setOpenMenu(null);
    setOpenChangePassword(false);
  };

  const handleSignout = () => {
    dispatch(signoutUser());
  };

  const handleClickChangePassword = () => {
    setOpenMenu(null);
    setOpenChangePassword(true);
  };

  const handleClickMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const userName = useSelector((state) => state.auth.userName);
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.profileIconContainer}>
        <Tooltip title="Perfil" arrow>
          <IconButton
            size="small"
            onClick={handleClickMenu}
            className={classes.icon}>
            <Person />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={openMenu}
        keepMounted
        open={Boolean(openMenu)}
        onClose={handleClose}
        className={classes.menu}>
        <MenuItem disabled className={classes.userName}>
          {userName}
        </MenuItem>
        <MenuItem onClick={handleClickChangePassword}>
          <ListItemIcon size="small" className={classes.listItemIcon}>
            <LockOutlined />
          </ListItemIcon>
          <ListItemText primary="Cambiar contraseña" />
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={handleSignout}>
          <ListItemIcon size="small" className={classes.listItemIcon}>
            <ExitToAppOutlined />
          </ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </MenuItem>
      </Menu>
      <ChangePasswordDialog open={openChangePassword} onClose={handleClose} />
    </React.Fragment>
  );
};

export default IconUser;
