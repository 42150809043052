import { Paper, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAccidentDashboard } from "../../../../actions/accidentDashboard";
import { clearForm } from "../../../../actions/forms";
import { setFormsGeneralProperties } from "../../../../actions/formsState";
import FormContainer from "../../forms/formContainer";
import AddDocumentation from "./addDocumentation/addDocumentation";
import AppointmentsCalendar from "./appointmentsCalendar/appointmentsCalendar";
import AttentionsInAnotherProvider from "./attentionsInAnotherProvider/attentionsInAnotherProvider";
import AccidentDocuments from "./documents/accidentDocuments";
import AccidentHeader from "./header/accidentHeader";
import BackArrowHeader from "./header/backArrowHeader";
import AccidentForms from "./prePrintedForms/accidentPrePrintedForms";

const height = 300;
const borderRadius = 5;
const margin = 10;

const useStyles = makeStyles((theme) => ({
  columnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerDiv: {
    flex: 1,
    margin: margin,
    minWidth: 780,
    borderRadius: borderRadius,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  addDocumentationDiv: {
    flex: 1,
    height: height,
    minWidth: 300,
    margin: margin,
    borderRadius: borderRadius,
    backgroundColor: "#f5f5f5",
  },
  accidentDocumentsDiv: {
    flex: 1.5,
    height: height,
    minWidth: 500,
    margin: margin,
    borderRadius: borderRadius,
    backgroundColor: "#f5f5f5",
  },
  formAndAppointmentsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: margin,
    height: height,
    minWidth: 300,
  },
  formsDiv: {
    flex: 1,
    marginBottom: margin,
    borderRadius: borderRadius,
    backgroundColor: "#f5f5f5",
  },
  appointmentsInProviderDiv: {
    flex: 1,
    marginTop: margin,
    borderRadius: borderRadius,
    backgroundColor: "#f5f5f5",
  },
  calendarDiv: {
    flex: 1.5,
    height: height,
    minWidth: 500,
    margin: margin,
  },
}));

const AccidentDashboard = ({ setDisplayAccidents }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const onBackClick = () => {
    setDisplayAccidents("block");
  };

  useEffect(() => {
    dispatch(setFormsGeneralProperties({
      idSiniestro: accident.idSiniestro,
      idArt: accident.mlCloudCoreArtId,
      artName: accident.art,
    }))
  }, [accident.idSiniestro, accident.mlCloudCoreArtId, accident.art])

  useEffect(() => {
    return () => {
      dispatch(clearAccidentDashboard());
      dispatch(clearForm());
    };
  }, []);

  return (
    <div>
      <FormContainer />
      <div className={classes.columnContainer}>
        <div className={classes.rowContainer}>
          <BackArrowHeader onClick={onBackClick} />
          <Paper elevation={2} className={classes.headerDiv}>
            <AccidentHeader />
          </Paper>
        </div>
        <div className={classes.rowContainer}>
          <Paper elevation={2} className={classes.addDocumentationDiv}>
            <AddDocumentation />
          </Paper>
          <Paper elevation={2} className={classes.accidentDocumentsDiv}>
            <AccidentDocuments />
          </Paper>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.formAndAppointmentsContainer}>
            <Paper elevation={2} className={classes.formsDiv}>
              <AccidentForms />
            </Paper>
            <Paper elevation={2} className={classes.appointmentsInProviderDiv}>
              <AttentionsInAnotherProvider />
            </Paper>
          </div>
          <div className={classes.calendarDiv}>
            <AppointmentsCalendar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccidentDashboard;
