import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAccident } from "../../../../actions/accidentDashboard";
import { getPendingAuthorizations } from "../../../../actions/authorizations";
import PendingAuthorizationsTable from "../../../commons/table/pendingAuthorizationsTable";
import AccidentDashboardContainer from "../../accident/accidentDashboard/accidentDashboardContainer";
import FormContainer from "../../forms/formContainer";

const PendingAuthorizationsContainer = () => {
  const dispatch = useDispatch();

  const pendingAuthorizations = useSelector(
    (state) => state.pendingAuthorizations,
  );
  const namesArts = useSelector((state) => state.art.shortNameArt);

  const [time, setTime] = useState(2);
  const [displayAccidents, setDisplayAccidents] = useState("block");
  useEffect(() => {
    dispatch(getPendingAuthorizations(time));
  }, []);

  const handleAccidentDetail = (accident) => {
    dispatch(
      setSelectedAccident({
        ...accident,
        mlCloudCoreArtId: accident.artMLCloud,
        idSiniestro: accident.siniestroId,
      }),
    );
    setDisplayAccidents("none");
  };

  const handleChange = (event) => {
    setTime(event.target.value);
    dispatch(getPendingAuthorizations(event.target.value));
  };

  return (
    <div>
      {displayAccidents === "none" && (
        <AccidentDashboardContainer setDisplayAccidents={setDisplayAccidents} />
      )}
      <FormContainer />
      <PendingAuthorizationsTable
        namesArts={namesArts}
        data={pendingAuthorizations.list}
        loading={pendingAuthorizations.isFetching}
        error={pendingAuthorizations.didInvalidate}
        fetchData={() => dispatch(getPendingAuthorizations(time))}
        title="Pendientes"
        handleAccidentDetail={handleAccidentDetail}
        displayAccidents={displayAccidents}
        handleChangeTime={handleChange}
        time={time}
      />
    </div>
  );
};

export default PendingAuthorizationsContainer;
