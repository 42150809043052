import MaterialTable from "material-table";
import React, { useState } from "react";
import { localization, useTableStyles } from "./config/tableConfig";
import TitleContainer from "./utilsTable/titleWithFetchData";

const crudArtTable = ({
  tableIcons,
  arts,
  loading,
  fetchArts,
  handleOpenModalEditar,
  handleOpenModalEliminar,
}) => {
  const classes = useTableStyles()();
  const [columnObject] = useState([
    {
      title: "Razón social",
      field: "nombre",
      render: (rowData) => rowData.nombre,
    },
    {
      title: "Nombre corto",
      field: "nombreCorto",
      render: (rowData) => rowData.nombreCorto,
    },
  ]);

  return (
    <div className={classes.tablePadding}>
      <MaterialTable
        icons={tableIcons}
        columns={columnObject}
        data={arts}
        isLoading={loading}
        locale="es"
        title={<TitleContainer title="ARTs" fetchData={fetchArts} />}
        options={{
          actionsColumnIndex: -1,
          rowStyle: localization.rowStyle,
          headerStyle: localization.headerStyle,
          pageSize: 10,
          search: false,
          filtering: true,
          exportButton: false,
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Editar ART",
            onClick: (event, rowData) => handleOpenModalEditar(rowData),
          },
          {
            icon: "delete",
            tooltip: "Eliminar ART",
            onClick: (event, rowData) => handleOpenModalEliminar(rowData),
          },
        ]}
        localization={{
          ...localization,
          body: {
            emptyDataSourceMessage: "No hay ARTs que mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
      />
    </div>
  );
};
export default crudArtTable;
