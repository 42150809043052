import { makeStyles } from "@material-ui/core/styles";

export const useTableStyles = (displayAccidents) =>
  makeStyles((theme) => ({
    tablePadding: {
      "& > *:first-child": {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    container: {
      display: displayAccidents,
    },
    fptContainer: {
      display: "flex",
      height: "100vh",
      padding: 0,
    },
  }));

export const localization = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsSelect: "filas",
    firstTooltip: "Primera página",
    previousTooltip: "Página anterior",
    nextTooltip: "Página siguiente",
    lastTooltip: "Última página",
  },
  toolbar: {
    searchPlaceholder: "Buscar...",
    exportTitle: "Exportar",
    exportName: "Exportar a CSV",
    nRowsSelected: `{0} fila(s) seleccionada(s)`,
  },
  rowStyle: {
    fontFamily: "Roboto, sans-serif !important",
  },
  headerStyle: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem",
    lineHeight: "1.3125rem",
    fontWeight: 500,
    fontFamily: "Roboto, sans-serif !important",
  },
};
