import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { Print } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import CircularLoading from "../../../../commons/loadingData/circularLoading";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: 100,
    overflow: "auto",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 5,
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    borderRadius: 5,
  },
  button: {
    cursor: "pointer",
  },
  emptyState: {
    alignSelf: "center",
    marginTop: 20,
  },
}));

const AccidentFormsCards = ({ data, loading, generatePrePrintedHTML }) => {
  const classes = useStyles();

  const esPrestador = useSelector((state) => state.auth.roles?.PRESTADOR);

  return (
    <div className={classes.container}>
      {loading ? (
        <CircularLoading />
      ) : data.length ? (
        data.map((form) => {
          return (
            <Paper className={classes.card} key={form.idPlantillaPreImpresion}>
              <Typography variant="body2">{form.nombre}</Typography>
              {esPrestador && (
                <Tooltip
                  title="Emitir formulario"
                  className={classes.button}
                  onClick={() => generatePrePrintedHTML(form)}>
                  <Print />
                </Tooltip>
              )}
            </Paper>
          );
        })
      ) : (
        <Typography className={classes.emptyState} variant="body2">
          No hay formularios disponibles
        </Typography>
      )}
    </div>
  );
};

export default AccidentFormsCards;
