import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';


// Para mas informacion de la api de turnstile https://developers.cloudflare.com/turnstile/get-started/client-side-rendering/

// Consideraciones a tener en cuenta: Siempre reiniciar el componente despues de llamar a la api si falla. 
// Ya que se tiene que adquirir un nuevo token, por que el anterior deja de ser valido automaticamente. Hay ejemplo de esto en login

const Captcha = forwardRef(({ onVerify, onError, onReset, theme }, ref) => {
    const turnstileRef = useRef(null);
    const widgetId = useRef(null);

    useEffect(() => {
        // Si el script de turnstile existe (siempre deberia existir)
        if (window.turnstile) {

            // Llamado a render del script turnstile que crea el componente
            try {
                const id = window.turnstile.render(turnstileRef.current, {

                    // Configuracion del captcha (hay mas parametros disponibles al final de la documentacion de cloudflare mencionada arriba)
                    sitekey: CONFIG.captchaSiteKey,
                    callback: onVerify,
                    "error-callback": (error) =>
                        onError?.(error),
                    "timeout-callback": () => {
                        // El captcha se reinicia por su cuenta cuando hay timeout, por lo que deberiamos llamar a reset
                        onReset()
                    },
                    theme: theme || 'auto',
                    size: "flexible",
                    language: "es-es"
                });
                widgetId.current = id;
                onReset();
            }
            catch (e) {
                console.error("Failed to render captcha", e);
            }



        } else {
            console.error("Turntile script no ha sido cargado");
        }

        // Removemos el widget en caso de cambio de configuracion o limpieza al desmontar
        return () => {
            if (widgetId.current) {
                window.turnstile.remove(widgetId.current);
            }
        }

    }, [onVerify, theme, onError]);

    const handleReset = () => {
        onReset();
        if (window.turnstile && widgetId.current) {
            window.turnstile?.reset(widgetId.current);
        } else {
            console.warn("No existe captcha para reiniciar")
        }
    }

    useImperativeHandle(ref, () => {
        // Devolvemos funciones que puede usar el padre para que sea mas facil actualizar el componente 
        return {
            reset() {
                handleReset();
            }
        }
    }, [])

    return (
        <>
            <div ref={turnstileRef}> </div>
        </>

    );
});

export default Captcha;