import { ListItem, ListItemIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import DrawerItemTooltip from "./drawerItemTooltip";

const useStyles = makeStyles((theme) => ({
  nested: {
    marginTop: "8px",
    backgroundColor: (props) =>
      props.selected
        ? theme.palette.primary.main
        : theme.palette.primary.contrastText,
    borderRadius: "7px",
    "&:hover": {
      backgroundColor: (props) =>
        props.selected ? theme.palette.primary.main : "rgba(0, 0, 0, 0.04)",
    },
  },
  text: {
    color: (props) =>
      props.selected
        ? theme.palette.primary.contrastText
        : theme.palette.primary.backgroundListItem,
    fontFamily: "Roboto, sans-serif",
    margin: 0,
  },
  icon: {
    color: (props) =>
      props.selected
        ? theme.palette.primary.contrastText
        : theme.palette.primary.backgroundListItem,
  },
}));

const ListItemComponent = ({ path, selected, title, icon: Icon, id, open }) => {
  const classes = useStyles({ selected });

  return (
    <DrawerItemTooltip tooltipText={title} drawerOpen={open} >
      <ListItem
        button
        className={classes.nested}
        component={Link}
        to={path}
        id={id}>
        <ListItemIcon className={classes.icon}>{Icon}</ListItemIcon>
        <p className={classes.text}>{title}</p>
      </ListItem>
    </DrawerItemTooltip>

  );
};

export default memo(ListItemComponent);
