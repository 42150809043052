import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import LogoSectigo from "../../../assets/images/logo_sectigo.png";
import TermsConditionsDialog from "../../commons/termsConditions/termsConditions";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 15px 0 5px",
  },
  link: {
    textDecoration: "none",
    fontFamily: "Roboto, sans-serif",
    fontSize: "small",
    color: theme.palette.primary.backgroundListItem,
    fontWeight: 500,
    "&:hover": {
      color: theme.palette.secondary.dark,
      textDecoration: "underline",
    },
    "&:active": {
      color: theme.palette.secondary.main,
      textDecoration: "none",
    },
  },
  gridContainer: {
    justifyContent: "space-between",
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 0 5px 0",
  },
}));

export default function LoginFooter({
  terms,
  passwordRecovery,
  loginAccount,
  register,
}) {
  const classes = useStyles();

  const [openTerms, setOpenTerms] = React.useState(false);

  const handleClick = () => {
    setOpenTerms(true);
  };

  const handleClose = () => {
    setOpenTerms(false);
  };

  return (
    <div className={classes.footer}>
      <div>
        <a
          href="https://sectigo.com/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={LogoSectigo} alt="" />
        </a>
      </div>
      {register && (
        <div>
          <a
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=FaLFT-NXyESMjjLMW8rLBqQDWnRMtfVPnmmt4X0_35dUOFdXRkpFR1MzNlA3WVhSRjNFSlZZNThTTS4u"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}>
            <p>Regístrese aquí</p>
          </a>
        </div>
      )}
      {terms && (
        <div>
          <Link to="#" className={classes.link} onClick={handleClick}>
            <p>Términos y condiciones</p>
          </Link>
        </div>
      )}
      {passwordRecovery && (
        <div>
          <Link to="/account/passwordRecovery" className={classes.link}>
            <p>¿Olvidó su contraseña?</p>
          </Link>
        </div>
      )}
      {loginAccount && (
        <div>
          <Link to="/account/login" className={classes.link}>
            <p>¿Ya tiene una cuenta?</p>
          </Link>
        </div>
      )}
      <TermsConditionsDialog
        open={openTerms}
        onClose={handleClose}></TermsConditionsDialog>
    </div>
  );
}
