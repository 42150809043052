import { ResponsiveBar } from "@nivo/bar";
import React from "react";

const MyResponsiveBarMonths = ({ data, keys, type }) => {
  data.forEach((item) => {
    if (item.quarter === 1) item["date"] = `Ene ${item.year}`;
    else if (item.quarter === 2) item["date"] = `Feb ${item.year}`;
    else if (item.quarter === 3) item["date"] = `Mar ${item.year}`;
    else if (item.quarter === 4) item["date"] = `Abr ${item.year}`;
    else if (item.quarter === 5) item["date"] = `May ${item.year}`;
    else if (item.quarter === 6) item["date"] = `Jun ${item.year}`;
    else if (item.quarter === 7) item["date"] = `Jul ${item.year}`;
    else if (item.quarter === 8) item["date"] = `Ago ${item.year}`;
    else if (item.quarter === 9) item["date"] = `Sep ${item.year}`;
    else if (item.quarter === 10) item["date"] = `Oct ${item.year}`;
    else if (item.quarter === 11) item["date"] = `Nov ${item.year}`;
    else if (item.quarter === 12) item["date"] = `Dic ${item.year}`;
  });
  // let newArrayOrder = [];
  // let test = [];
  // const getDataMonth = (dataMonth, name) => {
  //   const helper = (month, year, art, count) => {
  //     switch (month) {
  //       case 1: return { month: 1, date: `Ene ${year}`, [art]: count, year };
  //       case 2: return { month: 2, date: `Feb ${year}`, [art]: count, year };
  //       case 3: return { month: 3, date: `Mar ${year}`, [art]: count, year };
  //       case 4: return { month: 4, date: `Abr ${year}`, [art]: count, year };
  //       case 5: return { month: 5, date: `May ${year}`, [art]: count, year };
  //       case 6: return { month: 6, date: `Jun ${year}`, [art]: count, year };
  //       case 7: return { month: 7, date: `Jul ${year}`, [art]: count, year };
  //       case 8: return { month: 8, date: `Ago ${year}`, [art]: count, year };
  //       case 9: return { month: 9, date: `Sep ${year}`, [art]: count, year };
  //       case 10: return { month: 10, date: `Oct ${year}`, [art]: count, year };
  //       case 11: return { month: 11, date: `Nov ${year}`, [art]: count, year };
  //       case 12: return { month: 12, date: `Dic ${year}`, [art]: count, year };
  //     };
  //   };
  //   const monthActually = Number(moment().format('M'));
  //   const lastYear = Number(moment().format('YYYY')) - 1;
  //   const dataMonthHelper = dataMonth.filter(dat => !(dat.month === monthActually && dat.year === lastYear));
  //   const addLasted = dataMonth.filter(dat => (dat.month === monthActually && dat.year === lastYear));
  //   dataMonthHelper.map(item => helper(item.month, item.year, name, item.count));
  //   if (addLasted) dataMonthHelper.push(...addLasted);
  //   return dataMonthHelper.map(item => helper(item.month, item.year, name, item.count));
  // };
  // if (data && data.length) {
  //   const linealTest = data.map(item => getDataMonth(item.monthCount, item.art));
  //   const todo = [];
  //   const monthActually = Number(moment().format('M'));
  //   const lastYear = Number(moment().format('YYYY')) - 1;
  //   linealTest.forEach(test => test.forEach((otro) => {
  //     if (otro.month === monthActually && otro.year === lastYear) {
  //       if (!todo[12]) return todo[12] = { ...otro };
  //       return todo[12] = { ...todo[12], ...otro };
  //     }
  //     if (!todo[otro.month - 1]) return todo[otro.month - 1] = { ...otro };
  //     todo[otro.month - 1] = { ...todo[otro.month - 1], ...otro };
  //   }));
  //   const end = todo.slice(monthActually).reverse();
  //   const start = todo.slice(0, monthActually).reverse();
  //   test = [...start, ...end];
  //   if (test.length === 13) {
  //     newArrayOrder = [
  //       ...test.slice(0, monthActually),
  //       ...test.slice(monthActually + 1),
  //       ...test.slice(monthActually, monthActually + 1)
  //     ];
  //   }
  // }
  return (
    <React.Fragment>
      <ResponsiveBar
        // data={(newArrayOrder.length && newArrayOrder.reverse()) || (test.length && test.reverse())}
        data={data}
        keys={keys}
        indexBy={type}
        padding={0.3}
        margin={{
          top: 20,
          right: 90,
          bottom: 60,
          left: 60,
        }}
        groupMode="stacked"
        colors={{ scheme: "set3" }}
        borderRadius={0}
        borderColor={{ from: "color", modifiers: [["darker", "1.2"]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -90,
          legendPosition: "middle",
          legendOffset: 36,
        }}
        axisLeft={{
          tickSize: 3,
          tickPadding: 11,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -47,
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 110,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 10,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        enableLabel={false}
        labelSkipWidth={5}
        labelSkipHeight={8}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate
        motionStiffness={90}
        motionDamping={8}
      />
    </React.Fragment>
  );
};
export default MyResponsiveBarMonths;
