import {
  GET_PENDING_APPOINTMENTS,
  GET_APPOINTMENTS_FILTER,
  CLEAN_APPOINTMENTS_FILTER,
  REMOVE_APPOINTMENT_FROM_LIST,
  POST_APPOINTMENT,
  APPOINTMENT_SESSION,
  POST_SESSION,
  CLEAR_IDTURNOS,
  UPDATE_APPOINTMENT,
} from "../actions/appointments";

const INITIAL_STATE = {
  pendingAppointments: {
    isFetching: false,
    didInvalidate: false,
    list: [],
  },
  appointmentsFilter: {
    isFetching: false,
    didInvalidate: false,
    list: [],
  },
  postAppointment: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  appointmentSession: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  postSession: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
};

const appointments = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_PENDING_APPOINTMENTS}_REJECTED`:
      return {
        ...state,
        pendingAppointments: {
          ...state.pendingAppointments,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_PENDING_APPOINTMENTS}_PENDING`:
      return {
        ...state,
        pendingAppointments: {
          ...state.pendingAppointments,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_PENDING_APPOINTMENTS}_FULFILLED`:
      return {
        ...state,
        pendingAppointments: {
          ...state.pendingAppointments,
          isFetching: false,
          didInvalidate: false,
          list:
            action.payload.data && action.payload.data.appointments
              ? action.payload.data.appointments
              : action.payload.data,
        },
      };

    case `${GET_APPOINTMENTS_FILTER}_REJECTED`:
      return {
        ...state,
        appointmentsFilter: {
          ...state.appointmentsFilter,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_APPOINTMENTS_FILTER}_PENDING`:
      return {
        ...state,
        appointmentsFilter: {
          ...state.appointmentsFilter,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_APPOINTMENTS_FILTER}_FULFILLED`:
      return {
        ...state,
        appointmentsFilter: {
          ...state.appointmentsFilter,
          isFetching: false,
          didInvalidate: false,
          list: action.payload.data.appointments,
        },
      };

    case `${CLEAN_APPOINTMENTS_FILTER}`:
      return {
        ...state,
        appointmentsFilter: {
          ...state.appointmentsFilter,
          isFetching: false,
          didInvalidate: false,
          list: [],
        },
      };

    case `${REMOVE_APPOINTMENT_FROM_LIST}`:
      const appointmentIdToRemove = action.payload.data.appointmentIdToRemove;
      const newPendingAppointmentsList = state.pendingAppointments.list.filter(
        (appointment) => appointment.id !== appointmentIdToRemove,
      );
      const newAppointmentsFilterList = state.appointmentsFilter.list.filter(
        (appointment) => appointment.id !== appointmentIdToRemove,
      );

      return {
        ...state,
        pendingAppointments: {
          ...state.pendingAppointments,
          list: newPendingAppointmentsList,
        },
        appointmentsFilter: {
          ...state.appointmentsFilter,
          list: newAppointmentsFilterList,
        },
      };
    case `${POST_APPOINTMENT}_REJECTED`:
      return {
        ...state,
        postAppointment: {
          ...state.postAppointment,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${POST_APPOINTMENT}_PENDING`:
      return {
        ...state,
        postAppointment: {
          ...state.postAppointment,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${POST_APPOINTMENT}_FULFILLED`: {
      return {
        ...state,
        postAppointment: {
          ...state.postAppointment,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${APPOINTMENT_SESSION}_REJECTED`:
      return {
        ...state,
        appointmentSession: {
          ...state.appointmentSession,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${APPOINTMENT_SESSION}_PENDING`:
      return {
        ...state,
        appointmentSession: {
          ...state.appointmentSession,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${APPOINTMENT_SESSION}_FULFILLED`: {
      return {
        ...state,
        appointmentSession: {
          ...state.appointmentSession,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${POST_SESSION}_REJECTED`:
      return {
        ...state,
        postSession: {
          ...state.postSession,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${POST_SESSION}_PENDING`:
      return {
        ...state,
        postSession: {
          ...state.postSession,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${POST_SESSION}_FULFILLED`: {
      return {
        ...state,
        postSession: {
          ...state.postSession,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case CLEAR_IDTURNOS: {
      return {
        ...state,
        postSession: {
          data: [],
        },
        postAppointment: {
          data: [],
        },
      };
    }
    default:
      return state;
  }
};

export default appointments;
