import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: 250,
    width: "calc(100vw / 4)",
    height: "calc(100vh - 270px)",
    border: "1px solid #f1f1f1",
    overflow: "auto",
    backgroundColor: "#e6e6e64f",
    "-webkit-box-shadow": "0px 0px 1px 1px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "0px 0px 1px 1px rgba(0,0,0,0.1)",
    "box-shadow": "0px 0px 1px 1px rgba(0,0,0,0.1)",
  },
  textStatus: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "small",
    marginTop: -10,
    marginLeft: 10,
  },
  success: {
    color: "ForestGreen",
  },
  pending: {
    color: "Highlight",
  },
  text: {
    fontWeight: "bold",
    color: "#303030",
    fontFamily: "Roboto, sans-serif !important",
  },
  listItemIconContainer: {
    display: "flex",
    justifyContent: "row",
    alignItems: "center",
  },
}));

export const customListLeft = (items, checked, leftChecked, handleToggle) => {
  const classes = useStyles();

  items.sort((a, b) => a.nombre.localeCompare(b.nombre));

  return (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.length === 0
          ? null
          : items.map((value) => {
              const labelId = `transfer-list-item-${value.id}-label`;
              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}>
                  <div className={classes.listItemIconContainer}>
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        disabled={leftChecked.length !== 1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <p className={classes.text}>{value.nombre}</p>
                  </div>
                </ListItem>
              );
            })}
      </List>
    </Paper>
  );
};

export const customListRight = (items) => {
  const classes = useStyles();
  items.sort((a, b) => a.nombre.localeCompare(b.nombre));

  return (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.length === 0
          ? null
          : items.map((value) => {
              return (
                <ListItem key={value.id} role="listitem" button>
                  {value.adherida === 0 ? (
                    <div>
                      <b>
                        <p className={classes.text}>{value.nombre}</p>
                      </b>
                      <p className={`${classes.textStatus} ${classes.success}`}>
                        Adherida
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className={classes.text}>{value.nombre}</p>
                      <p className={`${classes.textStatus} ${classes.pending}`}>
                        Pendiente
                      </p>
                    </div>
                  )}
                </ListItem>
              );
            })}
        <ListItem />
      </List>
    </Paper>
  );
};
