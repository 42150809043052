import { FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { getIn, useField, useFormikContext } from "formik";
import React, { useEffect } from "react";

const FormikRadio = ({ caseInsensitive = false, children, ...props }) => {
  const [field, meta] = useField(props);
  const { values, setFieldValue } = useFormikContext();

  const fieldValue = getIn(values, props.name);

  useEffect(() => {
    if (props.callBack) {
      props.callBack(fieldValue);
    }
  }, [fieldValue]);

  useEffect(() => {
    if (caseInsensitive) {
      const value = children?.find(
        (child) =>
          child?.props?.value?.toLowerCase() === props.value?.toLowerCase(),
      )?.props.value;

      if (value) setFieldValue(props.name, value);
    }
  }, []);

  return (
    <FormControl
      component="fieldset"
      error={meta.touched && meta.error ? true : false}
      disabled={props.disabled}>
      <FormLabel component="legend">{props.label}</FormLabel>
      <RadioGroup
        name={props.name}
        value={props.value}
        row={props.row}
        {...field}>
        {children}
      </RadioGroup>
      <FormHelperText>
        {meta.touched && meta.error ? meta.error : null}
      </FormHelperText>
    </FormControl>
  );
};

export default FormikRadio;
