import { Button, CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  changePassword,
  setPassword,
  verifyPassword,
} from "../../../actions/password";
import snackbarUtils from "../../../utils/snackbarUtils";
import TextField from "../textField/textField";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  button: {
    display: "flex",
    color: "white",
    margin: theme.spacing(2, 0, 2),
  },
  progress: {
    width: "20px !important",
    height: "20px !important",
    margin: 1,
  },
  icon: {
    color: "#303030",
  },
  newPasswordInput: {
    paddingBottom: 10,
  },
}));

const ComposedTextField = function ({ type, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // en caso de que el usuario se haya olvidado su contraseña,
  // le llegará un mail donde lo redigiremos a una URL asi:
  // /resetPassword?token=xxxxx
  // con useSearchParams, podemos acceder a la variable "token"
  const [params] = useSearchParams();

  const loadingSetPassword = useSelector(
    (state) => state.password.setPassword.isFetching,
  );
  const loadingVerifyPassword = useSelector(
    (state) => state.password.verifyPassword.isFetching,
  );
  const loadingChangePassword = useSelector(
    (state) => state.password.changePassword.isFetching,
  );

  const [password, setearPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [validatePassword, setValidatePassword] = useState(false);
  const [validateConfirmPassword, setValidateConfirmPassword] = useState(false);

  const handleChangePassword = (value) => {
    setearPassword(value);
    setValidateConfirmPassword(false);
    setConfirmPassword("");
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const dataUser = {
      Token: params.get("token"),
      Password: password,
    };

    const dataPassword = {
      CurrentPassword: currentPassword,
      Password: password,
    };

    if (type === "ActivateAccount") {
      dispatch(verifyPassword(dataUser))
        .then((data) => {
          if (data.action.payload && data.action.payload.status === 200) {
            snackbarUtils.success("¡Su cuenta se ha activado con éxito!");
            setTimeout(() => {
              navigate("/account/login");
            }, 3000);
          }
        })
        .catch((error) => {});
    } else if (type === "ResetPassword") {
      dispatch(setPassword(dataUser))
        .then((data) => {
          if (data.action.payload && data.action.payload.status === 200) {
            snackbarUtils.success("¡Su contraseña se ha modificado con éxito!");
            setTimeout(() => {
              navigate("/account/login");
            }, 3000);
          }
        })
        .catch((error) => {});
    } else if (type === "ChangePassword") {
      dispatch(changePassword(dataPassword))
        .then((data) => {
          if (data.action.payload && data.action.payload.status === 200) {
            snackbarUtils.success(`${data.action.payload.data}`);
            handleChangePassword("");
            setCurrentPassword("");
            setTimeout(() => {
              handleClose();
            }, 2000);
          }
        })
        .catch((error) => {});
    }
  };

  const newPasswordInput = () => {
    if (type === "ChangePassword") {
      return (
        <Grid
          container
          direction={"row"}
          spacing={3}
          alignItems="flex-end"
          className={classes.newPasswordInput}>
          <Grid item xs={1}>
            <LockOutlinedIcon className={classes.icon} />
          </Grid>
          <Grid item xs={11}>
            <TextField
              setFieldValue={setCurrentPassword}
              validateInput={setValidatePassword}
              fieldValue={currentPassword}
              placeholder="Contraseña actual"
              rules={["require"]}
            />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <form className={classes.form}>
      {newPasswordInput()}
      <Grid container direction={"row"} spacing={3} alignItems="flex-end">
        <Grid item xs={1}>
          <LockOutlinedIcon className={classes.icon} />
        </Grid>
        <Grid item xs={11}>
          <TextField
            setFieldValue={handleChangePassword}
            validateInput={setValidatePassword}
            fieldValue={password}
            placeholder="Nueva contraseña"
            rules={["require", "max", "min", "alphanumeric"]}
          />
        </Grid>
        <Grid item xs={1}>
          <LockOutlinedIcon className={classes.icon} />
        </Grid>
        <Grid item xs={11}>
          <TextField
            setFieldValue={setConfirmPassword}
            fieldValue={confirmPassword}
            fullWidth
            placeholder="Confirmar contraseña"
            rules={["require", "match"]}
            valueToCompare={password}
            validateInput={setValidateConfirmPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={
              loadingVerifyPassword ||
              loadingSetPassword ||
              loadingChangePassword ||
              validatePassword === false ||
              validateConfirmPassword === false
            }
            type="submit"
            onClick={handleSubmitForm}>
            {loadingVerifyPassword ||
            loadingSetPassword ||
            loadingChangePassword ? (
              <CircularProgress
                className={classes.progress}
                color="secondary"
              />
            ) : type === "ChangePassword" ? (
              "Cambiar contraseña"
            ) : (
              "Confirmar"
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ComposedTextField;
