import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import ChangePass from "../../commons/setPassword/setPassword";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontFamily: "Roboto, sans-serif",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "default",
  },
  dialogContent: {
    color: "#484848",
    margin: 0,
    padding: 0,
    overflow: "hidden",
  },
  progress: {
    width: "20px !important",
    height: "20px !important",
    margin: 1,
  },
  footer: {
    fontSize: "small",
  },
  description: {
    marginBottom: 10,
  },
  container: {
    marginTop: -20,
    marginRight: 5,
    marginBottom: 0,
  },
  fields: {
    marginTop: 10,
  },
  title: {
    margin: -2,
  },
}));

export default function ChangePasswordDialog({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      disableBackdropClick={true}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h2 className={classes.title}>Cambiar contraseña</h2>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContent
          className={classes.dialogContent}
          id="alert-dialog-description"
          align="justify">
          <div className={classes.container}>
            <Typography className={classes.description}>
              A continuación, ingrese su <b>contraseña actual</b> y luego,
              repita su <b>nueva contraseña</b> dos veces.
            </Typography>
            <div className={classes.fields}>
              <ChangePass type="ChangePassword" handleClose={onClose} />
            </div>
            <Typography className={classes.footer}>
              Las nuevas credenciales tendrán efecto en el siguiente inicio de
              sesión.
            </Typography>
          </div>
        </DialogContent>
      </DialogContent>
    </Dialog>
  );
}
