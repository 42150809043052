import { GET_DOCUMENT_TYPES } from "../actions/documentType";

const INITIAL_STATE = {
  documentType: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
};

const documentType = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_DOCUMENT_TYPES}_REJECTED`:
      return {
        ...state,
        documentType: {
          ...state.documentType,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_DOCUMENT_TYPES}_PENDING`:
      return {
        ...state,
        documentType: {
          ...state.documentType,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_DOCUMENT_TYPES}_FULFILLED`: {
      return {
        ...state,
        documentType: {
          ...state.documentType,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default documentType;
