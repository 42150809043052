import axios from "axios";

export const GET_ACCIDENTS_IN_TREATMENT = "GET_ACCIDENTS_IN_TREATMENT";
export const GET_ACCIDENTS_PER_MONTH = "GET_ACCIDENTS_PER_MONTH";
export const GET_PENDING_AUTHORIZATIONS_DASHBOARD = "GET_PENDING_AUTHORIZATIONS_DASHBOARD";
export const GET_CITATION_FREQUENCIES_AVERAGE = "GET_CITATION_FREQUENCIES_AVERAGE";
export const GET_AVERAGE_TREATMENT_DAYS = "GET_AVERAGE_TREATMENT_DAYS";
export const GET_AVG_AUTH_ANSWER_DAYS_PER_QUARTER = "GET_AVG_AUTH_ANSWER_DAYS_PER_QUARTER";
export const GET_PREVALENCE_RATE = "GET_PREVALENCE_RATE";
export const GET_AVERAGE_ATTENTIONS_NUMBER = "GET_AVERAGE_ATTENTIONS_NUMBER";

export const getAccidentsPerMonth = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_ACCIDENTS_PER_MONTH,
      payload: axios
        .get(`dashboard/${selectedProvider}/AccidentsPerMonthPerWri`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getAccidentsInTreatment = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_ACCIDENTS_IN_TREATMENT,
      payload: axios
        .get(`dashboard/${selectedProvider}/AccidentsInTreatmentCount`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getCitationFrequenciesAverage = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_CITATION_FREQUENCIES_AVERAGE,
      payload: axios
        .get(`dashboard/${selectedProvider}/AvgCitationFrequency`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getPendingAuthorizations = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_PENDING_AUTHORIZATIONS_DASHBOARD,
      payload: axios
        .get(`dashboard/${selectedProvider}/PendingAuthroizations`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getAverageTreatmentDays = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_AVERAGE_TREATMENT_DAYS,
      payload: axios
        .get(`dashboard/${selectedProvider}/AverageTreatmentDays`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getAvgAuthAnswerDaysPerQuarter = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_AVG_AUTH_ANSWER_DAYS_PER_QUARTER,
      payload: axios
        .get(`dashboard/${selectedProvider}/AvgAuthAnswerDaysPerQuarter`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getPrevalenceRate = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_PREVALENCE_RATE,
      payload: axios
        .get(`dashboard/${selectedProvider}/PrevalenceRate`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getAverageAttentionsNumber = () => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_AVERAGE_ATTENTIONS_NUMBER,
      payload: axios
        .get(`dashboard/${selectedProvider}/AverageAttentionsNumber`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};
