import {
  Button,
  makeStyles,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextField from "../../../commons/formik/formikTextField";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 20,
  },
  paper: {
    display: "flex",
    padding: 20,
  },
  input: {
    marginRight: 20,
    width: 250,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const validation = Yup.object({
  art: Yup.object().required("Campo obligatorio"),
  tipoDocumento: Yup.string().required("Campo obligatorio"),
  numeroDocumento: Yup.string().required("Campo obligatorio"),
});

const newAccidentModel = {
  art: null,
  tipoDocumento: "DNI",
  numeroDocumento: "",
};

const NewAccident = ({ handleSubmit, arts, documentTypes }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6" className={classes.title}>
        Agregar siniestro
      </Typography>
      <Paper className={classes.paper}>
        <Formik
          initialValues={newAccidentModel}
          validationSchema={validation}
          onSubmit={(values) => {
            handleSubmit(values);
          }}>
          {({ values }) => (
            <Form>
              <FormikSelect
                name="art"
                value={values.art}
                label="Art"
                className={classes.input}>
                {arts.map((art) => (
                  <MenuItem value={art} key={art.id}>
                    {art.nombre}
                  </MenuItem>
                ))}
              </FormikSelect>
              <FormikSelect
                name="tipoDocumento"
                value={values.tipoDocumento}
                label="Tipo Documento"
                className={classes.input}>
                {documentTypes.map((documentType) => (
                  <MenuItem value={documentType.tipo} key={documentType.id}>
                    {documentType.tipo}
                  </MenuItem>
                ))}
              </FormikSelect>
              <FormikTextField
                id="numeroDocumento"
                name="numeroDocumento"
                label="Documento Trabajador"
                type="text"
                className={classes.input}
              />
              <Tooltip title="Ingresar Siniestro">
                <Button
                  variant="contained"
                  className={classes.button}
                  type="submit"
                  color="primary">
                  <AddCircle />
                </Button>
              </Tooltip>
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default NewAccident;
