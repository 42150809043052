import React from "react";
import SpeechRecognition from "react-speech-recognition";
import MicIcon from "@material-ui/icons/Mic";
import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import VoiceVisualization from "./voiceVisualization";
import { isEdgeChromium } from "../../../utils/util";

const useStyles = (disabled) =>
  makeStyles((theme) => ({
    container: {
      height: 25,
    },
    mic: {
      position: "relative",
      marginTop: 3,
      cursor: disabled ? "default" : "pointer",
      marginLeft: -30,
      zIndex: "1",
      color: disabled ? "grey" : "black",
    },
  }));

const SpeachToText = ({ listening, setIsSelected, transcript, disabled }) => {
  const classes = useStyles(disabled)();

  if (
    !SpeechRecognition.browserSupportsSpeechRecognition() ||
    isEdgeChromium()
  ) {
    return null;
  }

  const start = () => {
    if (!disabled) {
      setIsSelected(true);
      SpeechRecognition.startListening({
        language: "es-AR",
      });
    }
  };

  return (
    <div>
      <div className={classes.container}>
        <Tooltip title="Dictado por voz" className={classes.mic}>
          <MicIcon onClick={start} />
        </Tooltip>
      </div>
      <VoiceVisualization listening={listening} transcript={transcript} />
    </div>
  );
};
export default SpeachToText;
