import axios from "axios";

export const GET_DOCUMENT_TYPES = "GET_DOCUMENT_TYPES";

export const getDocumentTypes = () => (dispatch) => {
  dispatch({
    type: GET_DOCUMENT_TYPES,
    payload: axios
      .get(`documentType`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
