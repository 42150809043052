import { Tooltip, Typography, lighten, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
    padding: 5,
    backgroundColor: lighten(theme.palette.primary.main, 0.7),
    borderRadius: 10,
    border: `2px solid ${lighten(theme.palette.primary.main, 0.2)}`,
    color: theme.palette.primary.gradientLogin2,
  },
  icon: {
    cursor: "pointer",
    marginLeft: 10,
    transition: "transform 0.2s ease-in-out",
  },
  vibrando: {
    animation: "$vibrar 0.5s ease-in-out",
  },
  "@keyframes vibrar": {
    "0%": {
      transform: "rotate(-15deg)",
    },
    "50%": {
      transform: "rotate(15deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
  labelText: {
    paddingLeft: 10,
  },
}));

const FeatureLabel = ({ labelText, icon, onClick, tooltipText }) => {
  const classes = useStyles();

  const [vibrar, setVibrar] = useState(false);
  const timeoutID = useRef();

  useEffect(() => {
    handleHover();
    return () => {
      clearTimeout(timeoutID.current);
    };
  }, []);

  const handleHover = () => {
    setVibrar(true);

    timeoutID.current = setTimeout(() => {
      setVibrar(false);
    }, 500);
  };

  return (
    <div className={classes.labelContainer}>
      <Typography className={classes.labelText}>{labelText}</Typography>
      <Tooltip title={tooltipText} arrow={true} onMouseEnter={handleHover}>
        {icon &&
          React.cloneElement(icon, {
            fontSize: "large",
            className: `${classes.icon} ${vibrar ? classes.vibrando : ""}`,
            onClick: onClick,
          })}
      </Tooltip>
    </div>
  );
};

export default FeatureLabel;
