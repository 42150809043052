import React, { useState } from "react";
import ArtInProviderTable from "../../commons/table/artInProviderTable";
import EditArtAdhesionDialog from "../../commons/dialog/editArtAdhesionDialog";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { useSelector, useDispatch } from "react-redux";
import { getArtsNotAdheredByProviderId } from "../../../actions/art";
import {
  requestAdhesionByProviderId,
  cancelAdherence,
  confirmAdherence,
} from "../../../actions/providers";
import ConfirmDeleteDialog from "../../commons/dialog/confirmDeleteDialog";
import ConfirmDialog from "../../commons/dialog/confirmDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: "10px 30px",
    },
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const initialAdhesion = {
  artId: "",
  idPrestadorArt: "",
};

const ArtInProvider = ({ provider, refresh }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const artsNotAdhered = useSelector((state) => state.art.listArtsNotAdhered);

  const [openEdit, setOpenEdit] = useState(false);
  const [adhesion, setAdhesion] = useState(initialAdhesion);
  const [addingNewAdhesion, setAddingNewAdhesion] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenEdit = (adhesion) => {
    if (adhesion !== null) {
      setAdhesion({ ...adhesion, artId: adhesion.id });
      setAddingNewAdhesion(false);
    } else {
      dispatch(getArtsNotAdheredByProviderId(provider.id));
    }
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setAdhesion(initialAdhesion);
    setOpenEdit(false);
    setAddingNewAdhesion(true);
  };

  const handleDelete = () => {
    dispatch(cancelAdherence(provider.id, adhesion));
    handleCloseDelete();
  };

  const handleOpenDelete = (adhesion) => {
    setAdhesion(adhesion);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setAdhesion(initialAdhesion);
    setOpenDelete(false);
  };

  const handleSubmit = (adhesion, isNew) => {
    if (isNew) {
      adhesion.artAdherida = 2;
      dispatch(requestAdhesionByProviderId(provider.id, adhesion)).then(() =>
        refresh(),
      );
    } else {
      dispatch(requestAdhesionByProviderId(provider.id, adhesion)).then(() =>
        refresh(),
      );
    }
    handleCloseEdit();
  };

  const handleOpenConfirm = (adhesion) => {
    setAdhesion(adhesion);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setAdhesion(initialAdhesion);
    setOpenConfirm(false);
  };

  const handleConfirm = () => {
    dispatch(confirmAdherence(provider.id, adhesion));
    handleCloseConfirm();
  };

  return (
    <div>
      <ArtInProviderTable
        data={provider.arts}
        handleOpenEdit={handleOpenEdit}
        handleDelete={handleOpenDelete}
        handleOpenConfirm={handleOpenConfirm}
      />
      <div className={classes.root}>
        <Fab
          onClick={() => handleOpenEdit(null)}
          color="primary"
          aria-label="add">
          <AddIcon />
        </Fab>
      </div>
      <EditArtAdhesionDialog
        adhesion={adhesion}
        artsNotAdhered={artsNotAdhered.list}
        open={openEdit}
        handleClose={handleCloseEdit}
        addingNewAdhesion={addingNewAdhesion}
        handleSubmit={handleSubmit}
        loading={artsNotAdhered.isFetching}
      />

      <ConfirmDeleteDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
        title="Eliminar Adhesión"
        text={"¿Está seguro que desea eliminar la adhesión?"}
      />
      <ConfirmDialog
        open={openConfirm}
        handleClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
        title="Confirmar Adhesión"
        text="¿Está seguro que desea confirmar la adhesión?"
      />
    </div>
  );
};

export default ArtInProvider;
