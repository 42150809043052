import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import React from "react";

const useStyles = (color) =>
  makeStyles((theme) => ({
    container: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      backgroundColor:
        color === "secondary"
          ? theme.palette.secondary.dark
          : theme.palette.primary.main,
      borderRadius: "5px 5px 0px 0px",
      padding: 5,
    },
    title: {
      fontWeight: "bold",
      color: "white",
    },
    button: {
      position: "absolute",
      right: 10,
      top: 3,
      color: "white",
      background: "transparent",
      border: "none",
      display: "flex",
      alignItems: "center",
    },
    icon: {
      cursor: "pointer",
      marginLeft: 5,
    },
  }));

const CardTitle = ({ title, color, icons = [] }) => {
  const classes = useStyles(color)();

  return (
    <div className={classes.container}>
      <Typography variant="body2" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.button}>
        {icons.map(({ Icon, tooltip, onClick, disabled }, index) => (
          <Tooltip
            key={index}
            title={disabled ? `${tooltip} (Deshabilitado)` : tooltip}>
            <span>
              <Icon
                className={classes.icon}
                onClick={!disabled ? onClick : undefined}
              />
            </span>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default CardTitle;
