import DateFnsUtils from "@date-io/date-fns";
import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { AccessTime, Delete, DriveEta } from "@material-ui/icons";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: 270,
    height: 260,
    backgroundColor: "#f5f5f5",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.dark,
    padding: 2,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  title: {
    color: "white",
    fontWeight: "bold",
  },
  appointmentContainer: {
    paddingTop: 10,
    overflow: "auto",
  },
  card: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "20%",
  },
  button: {
    cursor: "pointer",
    margin: 5,
  },
  emptyState: {
    display: "flex",
    justifyContent: "center",
    marginTop: 60,
    marginBottom: 60,
  },
  timePicker: {
    width: 120,
  },
  carIcon: {
    color: "grey",
  },
  carIconT: {
    color: "green",
  },
}));

const AppointmentSessionCards = ({
  data,
  onDelete,
  setTraslado,
  handleChangeDate,
  handleSetDisableSave,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="body2" className={classes.title}>
          TURNOS
        </Typography>
      </div>
      <div className={classes.appointmentContainer}>
        {data.length === 0 ? (
          <div className={classes.emptyState}>
            <Typography variant="body2">No hay turnos para agregar</Typography>
          </div>
        ) : (
          data.map((appointment, index) => (
            <Paper className={classes.card} key={appointment.fechaHora}>
              <div className={classes.contentContainer}>
                <Typography variant="body2">
                  {moment(appointment.fechaHora).format("YYYY/MM/DD")}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    value={moment(appointment.fechaHora).format("lll")}
                    name="hora"
                    label="Hora Turno"
                    variant="inline"
                    autoOk={true}
                    ampm={false}
                    keyboardIcon={<AccessTime />}
                    onChange={(value) => {
                      if (value != null && value != "Invalid Date") {
                        handleChangeDate(
                          index,
                          moment(appointment.fechaHora)
                            .hour(moment(value).hour())
                            .minute(moment(value).minute())
                            .toDate(),
                        );
                        handleSetDisableSave(false);
                      } else {
                        handleSetDisableSave(true);
                      }
                    }}
                    className={classes.timePicker}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className={classes.actionsContainer}>
                <Tooltip title="traslado" className={classes.button}>
                  <DriveEta
                    className={
                      appointment.traslado ? classes.carIconT : classes.carIcon
                    }
                    onClick={() => setTraslado(appointment)}
                  />
                </Tooltip>
                <Tooltip
                  title="Eliminar"
                  className={classes.button}
                  onClick={() => onDelete(appointment)}>
                  <Delete />
                </Tooltip>
              </div>
            </Paper>
          ))
        )}
      </div>
    </Paper>
  );
};

export default AppointmentSessionCards;
