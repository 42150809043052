import {
  GET_ACCIDENTS_IN_TREATMENT,
  GET_ACCIDENTS_PER_MONTH,
  GET_PENDING_AUTHORIZATIONS_DASHBOARD,
  GET_CITATION_FREQUENCIES_AVERAGE,
  GET_AVERAGE_TREATMENT_DAYS,
  GET_AVG_AUTH_ANSWER_DAYS_PER_QUARTER,
  GET_PREVALENCE_RATE,
  GET_AVERAGE_ATTENTIONS_NUMBER,
} from "../actions/dashboard";
import { didInvalidate } from "../utils/errorHandler";

const INITIAL_STATE = {
  accidentsInTreatment: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  accidentsPerMonth: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  pendingAuthorizations: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  citationFrequenciesAverage: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  averageTreatmentDays: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  avgAuthAnswerDaysPerQuarter: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  prevalenceRate: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  averageAttentionsNumber: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
};

const dashboard = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_ACCIDENTS_IN_TREATMENT}_REJECTED`:
      return {
        ...state,
        accidentsInTreatment: {
          ...state.accidentsInTreatment,
          isFetching: false,
          didInvalidate: true,
        },
      };

    case `${GET_ACCIDENTS_IN_TREATMENT}_PENDING`:
      return {
        ...state,
        accidentsInTreatment: {
          ...state.accidentsInTreatment,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ACCIDENTS_IN_TREATMENT}_FULFILLED`: {
      const dataHelp = [];

      action.payload.data.forEach((item) => {
        if (!item.errors || item.errors.length === 0)
          dataHelp.push({
            ...item,
            value: item.accidentsCount,
            id: item.art,
            label: item.art,
          });
      });

      return {
        ...state,
        accidentsInTreatment: {
          isFetching: false,
          didInvalidate: didInvalidate(action),
          data: dataHelp,
        },
      };
    }

    case `${GET_ACCIDENTS_PER_MONTH}_REJECTED`:
      return {
        ...state,
        accidentsPerMonth: {
          ...state.accidentsPerMonth,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ACCIDENTS_PER_MONTH}_PENDING`:
      return {
        ...state,
        accidentsPerMonth: {
          ...state.accidentsPerMonth,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ACCIDENTS_PER_MONTH}_FULFILLED`: {
      const dataHelp = [];
      const names = [];

      action.payload.data.forEach((item) => {
        if (item.art) {
          names.push(item.art);
        }
      });

      if (names.length) {
        action.payload.data.forEach((item) => {
          if (item.monthCount) {
            item.monthCount.forEach((citation, index) => {
              names.forEach((name) => {
                if (item.art === name) {
                  if (!dataHelp[index]) dataHelp[index] = { quarter: citation.month, year: citation.year };
                  dataHelp[index][name] = citation.count;
                }
              });
            });
          }
        });
      }
      return {
        ...state,
        accidentsPerMonth: {
          isFetching: false,
          didInvalidate: didInvalidate(action),
          data: dataHelp,
        },
      };
    }

    case `${GET_PENDING_AUTHORIZATIONS_DASHBOARD}_REJECTED`:
      return {
        ...state,
        pendingAuthorizations: {
          ...state.pendingAuthorizations,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_PENDING_AUTHORIZATIONS_DASHBOARD}_PENDING`:
      return {
        ...state,
        pendingAuthorizations: {
          ...state.pendingAuthorizations,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_PENDING_AUTHORIZATIONS_DASHBOARD}_FULFILLED`: {
      const dataHelp = [];

      if (action.payload.data.pendingAuthorizationsCount) {
        action.payload.data.pendingAuthorizationsCount.forEach((item) => {
          dataHelp.push({
            ...item,
            value: item.count,
            id: item.wriName,
            label: item.wriName,
          });
        });
      }

      return {
        ...state,
        pendingAuthorizations: {
          isFetching: false,
          didInvalidate: didInvalidate(action),
          data: dataHelp,
        },
      };
    }

    case `${GET_CITATION_FREQUENCIES_AVERAGE}_REJECTED`:
      return {
        ...state,
        citationFrequenciesAverage: {
          ...state.citationFrequenciesAverage,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_CITATION_FREQUENCIES_AVERAGE}_PENDING`:
      return {
        ...state,
        citationFrequenciesAverage: {
          ...state.citationFrequenciesAverage,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_CITATION_FREQUENCIES_AVERAGE}_FULFILLED`: {
      const dataHelp = [];
      const names = [];

      action.payload.data.forEach((item) => {
        if (item.art) {
          names.push(item.art);
        }
      });

      if (names.length) {
        action.payload.data.forEach((item) => {
          if (item.citationFrequenciesAverages) {
            item.citationFrequenciesAverages.forEach((citation, index) => {
              names.forEach((name) => {
                if (item.art === name) {
                  if (!dataHelp[index])
                    dataHelp[index] = { quarter: citation.quarter, date: `${citation.quarter} ${citation.year}` };
                  dataHelp[index][name] = citation.citationFrequencyAverage;
                }
              });
            });
          }
        });
      }

      return {
        ...state,
        citationFrequenciesAverage: {
          isFetching: false,
          didInvalidate: didInvalidate(action),
          data: dataHelp,
        },
      };
    }

    case `${GET_AVERAGE_TREATMENT_DAYS}_REJECTED`:
      return {
        ...state,
        averageTreatmentDays: {
          ...state.averageTreatmentDays,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_AVERAGE_TREATMENT_DAYS}_PENDING`:
      return {
        ...state,
        averageTreatmentDays: {
          ...state.averageTreatmentDays,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_AVERAGE_TREATMENT_DAYS}_FULFILLED`: {
      const dataHelp = [];
      const names = [];

      action.payload.data.forEach((item) => {
        if (item.art) {
          names.push(item.art);
        }
      });

      if (names.length) {
        action.payload.data.forEach((item) => {
          if (item.averageTreatmentDays) {
            item.averageTreatmentDays.forEach((citation, index) => {
              names.forEach((name) => {
                if (item.art === name) {
                  if (!dataHelp[index])
                    dataHelp[index] = { quarter: citation.quarter, date: `${citation.quarter} ${citation.year}` };
                  dataHelp[index][name] = citation.averageTreatmentDays;
                }
              });
            });
          }
        });
      }

      return {
        ...state,
        averageTreatmentDays: {
          isFetching: false,
          didInvalidate: didInvalidate(action),
          data: dataHelp,
        },
      };
    }

    case `${GET_AVG_AUTH_ANSWER_DAYS_PER_QUARTER}_REJECTED`:
      return {
        ...state,
        avgAuthAnswerDaysPerQuarter: {
          ...state.avgAuthAnswerDaysPerQuarter,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_AVG_AUTH_ANSWER_DAYS_PER_QUARTER}_PENDING`:
      return {
        ...state,
        avgAuthAnswerDaysPerQuarter: {
          ...state.avgAuthAnswerDaysPerQuarter,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_AVG_AUTH_ANSWER_DAYS_PER_QUARTER}_FULFILLED`: {
      const dataHelp = [];
      const names = [];

      action.payload.data.forEach((item) => {
        if (item.art) {
          names.push(item.art);
        }
      });

      if (names.length) {
        action.payload.data.forEach((item) => {
          if (item.averageResponseTimes) {
            item.averageResponseTimes.forEach((citation, index) => {
              names.forEach((name) => {
                if (item.art === name) {
                  if (!dataHelp[index])
                    dataHelp[index] = { quarter: citation.period, date: `${citation.period} ${citation.year}` };
                  dataHelp[index][name] = citation.averageResponseTime;
                }
              });
            });
          }
        });
      }

      return {
        ...state,
        avgAuthAnswerDaysPerQuarter: {
          isFetching: false,
          didInvalidate: didInvalidate(action),
          data: dataHelp,
        },
      };
    }

    case `${GET_PREVALENCE_RATE}_REJECTED`: {
      return {
        ...state,
        prevalenceRate: {
          ...state.prevalenceRate,
          data: [],
          isFetching: false,
          didInvalidate: true,
        },
      };
    }

    case `${GET_PREVALENCE_RATE}_PENDING`:
      return {
        ...state,
        prevalenceRate: {
          ...state.prevalenceRate,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_PREVALENCE_RATE}_FULFILLED`: {
      const _didInvalidate = didInvalidate(action);
      const prevalenceRate = [
        {
          art: "Ingreso",
          data: [],
        },
        {
          art: "Egreso",
          data: [],
        },
        {
          art: "Indice Prev",
          data: [],
        },
      ];

      action.payload.data.forEach((item) => {
        if (!_didInvalidate) {
          prevalenceRate[0].data.push({
            quarter: item.quarter,
            x: `${item.quarter} ${item.year}`,
            y: isNaN(item.iamiCount) === true ? 0 : item.iamiCount,
          });
          prevalenceRate[1].data.push({
            quarter: item.quarter,
            x: `${item.quarter} ${item.year}`,
            y: isNaN(item.amCount) === true ? 0 : item.amCount,
          });
          prevalenceRate[2].data.push({
            quarter: item.quarter,
            x: `${item.quarter} ${item.year}`,
            y: isNaN(item.prevalenceRate) === true ? 0 : item.prevalenceRate,
          });
        }
      });

      return {
        ...state,
        prevalenceRate: {
          isFetching: false,
          didInvalidate: _didInvalidate,
          data: prevalenceRate,
        },
      };
    }

    case `${GET_AVERAGE_ATTENTIONS_NUMBER}_REJECTED`:
      return {
        ...state,
        averageAttentionsNumber: {
          ...state.averageAttentionsNumber,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_AVERAGE_ATTENTIONS_NUMBER}_PENDING`:
      return {
        ...state,
        averageAttentionsNumber: {
          ...state.averageAttentionsNumber,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_AVERAGE_ATTENTIONS_NUMBER}_FULFILLED`: {
      const dataHelp = [];

      action.payload.data.forEach((item) => {
        if (!item.errors || item.errors.length === 0) dataHelp.push(item);
      });

      return {
        ...state,
        averageAttentionsNumber: {
          isFetching: false,
          didInvalidate: didInvalidate(action),
          data: dataHelp.map((item) => ({
            ...item,
            data: item.averageNumberOfAttentions.map((attentions) => ({
              x: `${attentions.quarter} ${attentions.year}`,
              y: attentions.averageAttentionsNumber,
              quarter: attentions.quarter,
            })),
            id: item.art,
          })),
        },
      };
    }

    default:
      return state;
  }
};

export default dashboard;
