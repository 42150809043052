import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        marginLeft: 20,
        marginRight: 40,
    },
    container: {
        display: "flex",
        alignItems: "center",
        padding: 10,
        backgroundColor: theme.palette.primary.main,
    },
    text: {
        color: "white",
        fontWeight: "bold",
        textTransform: "upperCase",
    },
    closeIcon: {
        color: "white",
        cursor: "pointer",
        width: 25,
        height: 25,
    },
}));

const NewFormHeader = ({ formName, handleClose }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography className={classes.text}>INGRESANDO: {formName}</Typography>
            </div>
            <Tooltip title="Cerrar">
                <Close onClick={handleClose} className={classes.closeIcon} />
            </Tooltip>
        </div>
    );
};

export default NewFormHeader;
