import {
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  Zoom,
} from "@material-ui/core";
import {
  BorderColorRounded,
  CloudDoneOutlined,
  MailOutline,
  NoteAddOutlined,
  Visibility,
} from "@material-ui/icons";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectivityErrors } from "../../../../../actions/connectivityResponse";
import { openNewForm } from "../../../../../actions/formsState";
import CircularLoading from "../../../../commons/loadingData/circularLoading";
import NewsIconComponent from "./icons/NewsIconComponent";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: 265,
    overflow: "auto",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 5,
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
  },
  cardBackground: {
    backgroundColor: "#f4f5df",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    cursor: "pointer",
    marginLeft: 15,
  },
  disableButton: {
    color: "lightgrey",
    marginLeft: 15,
  },
  emptyState: {
    alignSelf: "center",
    marginTop: 30,
  },
  buttonAsociarFormulario: {
    background: "transparent",
    border: "none",
    padding: 0,
    marginTop: 2,
  },
  icon: {
    cursor: "pointer",
  },
  formInfoContainer: {
    width: 250,
  },
  formDate: {
    color: "grey",
  },
  sentMethodIcon: {
    color: "grey",
  },
  recivedByArt: {
    marginLeft: 5,
    color: "grey",
  },
}));

const AccidentDocumentsCards = ({
  data,
  loading,
  handleOpenDetail,
  handleOpenSign,
  setOpenErroresConectividadDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const esPrestador = useSelector((state) => state.auth.roles?.PRESTADOR);
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );

  const handleOpenErrorDetail = (document) => {
    dispatch(
      getConnectivityErrors(document.nroTransaccion, accident.mlCloudCoreArtId),
    ).then((resp) => {
      setOpenErroresConectividadDialog(document);
    });
  };
  return (
    <div className={classes.container}>
      {loading ? (
        <CircularLoading />
      ) : data.length ? (
        data.map((document) => {
          return (
            <Paper
              className={`${classes.card} ${document.aceptaIdr && !document.asociado
                ? classes.cardBackground
                : ""
                }`}
              key={document.nroFormulario}>
              <div className={classes.formInfoContainer}>
                <Typography variant="body2" className={classes.formDate}>
                  {moment(document.fechaHoraFormulario).format("DD/MM/YYYY")}
                </Typography>
                <Typography variant="body2" color={document.usuarioCreacion == document.usuarioModificacion && document.usuarioCreacion == "ProcesoPoint" ? "error" : ""}>
                  {document.nombreFormulario}
                </Typography>
              </div>
              <div>
                <div className={classes.rowContainer}>
                  {
                    {
                      Servicio: (
                        <Tooltip
                          title="Enviado por servicio"
                          TransitionComponent={Zoom}
                          arrow>
                          <CloudDoneOutlined
                            className={classes.sentMethodIcon}
                          />
                        </Tooltip>
                      ),
                      Correo: (
                        <Tooltip
                          title="Enviado por mail"
                          TransitionComponent={Zoom}
                          arrow>
                          <MailOutline className={classes.sentMethodIcon} />
                        </Tooltip>
                      ),
                    }[document.metodoEnvio]
                  }
                  <Typography variant="body2" className={classes.recivedByArt}>
                    {document.fechaRecibidoPorLaArt
                      ? moment(document.fechaRecibidoPorLaArt).format(
                        "DD/MM/YYYY",
                      )
                      : null}
                  </Typography>
                </div>
                <Typography variant="body2">{document.estado}</Typography>
              </div>
              <NewsIconComponent
                document={document}
                openErrorDetail={handleOpenErrorDetail}
              />
              <div className={classes.actionsContainer}>
                {esPrestador && (
                  <>
                    {document.aceptaIdr && (
                      <Tooltip
                        title={
                          document.editable
                            ? "Asociar formulario"
                            : "Asociar formulario (Deshabilitado)"
                        }
                        arrow="top">
                        <button
                          className={classes.buttonAsociarFormulario}
                          disabled={!document.editable}>
                          <NoteAddOutlined
                            className={classes.icon}
                            onClick={() => {
                              dispatch(
                                openNewForm('idr', documentation.data.find((f) => f.formulario === "Idr")?.nombre, { nroFormularioFPA: document.nroFormulario }),
                              );
                            }}
                          />
                        </button>
                      </Tooltip>
                    )}
                    {document.aceptaFirmaTrabajador && (
                      <Tooltip
                        title={
                          document.editable
                            ? "Firma del trabajador"
                            : "Firma del trabajador (Deshabilitado)"
                        }
                        arrow="top">
                        <button
                          className={classes.buttonAsociarFormulario}
                          disabled={!document.editable}>
                          <BorderColorRounded
                            className={classes.icon}
                            onClick={() =>
                              accident.editable
                                ? handleOpenSign(document)
                                : null
                            }
                          />
                        </button>
                      </Tooltip>
                    )}
                  </>
                )}
                <Tooltip
                  title={
                    document.permitirVisualizacion
                      ? "Ver documento"
                      : "Sin permiso para visualizar"
                  }
                  className={
                    document.permitirVisualizacion
                      ? classes.button
                      : classes.disableButton
                  }
                  onClick={() =>
                    document.permitirVisualizacion
                      ? handleOpenDetail(document)
                      : null
                  }>
                  <Visibility />
                </Tooltip>
              </div>
            </Paper>
          );
        })
      ) : (
        <Typography className={classes.emptyState} variant="body2">
          No hay documentos disponibles
        </Typography>
      )}
    </div>
  );
};

export default AccidentDocumentsCards;
