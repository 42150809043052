import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Calendar from "react-calendar";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginRight: 20,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.dark,
    padding: 2,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  title: {
    color: "white",
    fontWeight: "bold",
  },
  calendar: {
    width: 400,
    boxShadow: "1px 1px 7px rgba(219,219,219,1)",
    borderRadius: "0px 0px 5px 5px",
  },
  tileCalendar: {
    height: 30,
    padding: 0,
  },
}));

const AppointmentSessionCalendar = ({ onClick, appointment }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="body2" className={classes.title}>
          AGREGAR TURNO
        </Typography>
      </div>
      <Calendar
        className={classes.calendar}
        tileClassName={classes.tileCalendar}
        view="month"
        onClickDay={onClick}
        minDate={appointment.fechaHora}
      />
    </div>
  );
};

export default AppointmentSessionCalendar;
