import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    margin: 10,
  },
  deleteButton: {
    backgroundColor: "red",
    color: "white",
  },
}));

const ConfirmDeleteDialog = ({
  open,
  handleClose,
  handleConfirm,
  title,
  text,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>{text}</p>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleConfirm}
          className={classes.deleteButton}
          variant="contained">
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
