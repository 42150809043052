import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getArtPrestadores } from "../../../../actions/arts";
import { disableArtPrestador } from "../../../../actions/providers";
import snackbarUtils from "../../../../utils/snackbarUtils";
import ConfirmDeleteDialog from "../../../commons/dialog/confirmDeleteDialog";
import PrestadorTable from "./prestadorTable";

const PrestadorContainer = () => {
  const dispatch = useDispatch();

  const artPrestadores = useSelector((state) => state.arts.artPrestadores);
  const disableArtPrestadorState = useSelector(
    (state) => state.providers.disableArtPrestador,
  );

  const [prestador, setPrestador] = useState({});
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    dispatch(getArtPrestadores());
  }, []);

  const handleDelete = () => {
    dispatch(disableArtPrestador(prestador.id, prestador.arts[0].id)).then(
      () => {
        dispatch(getArtPrestadores());
        snackbarUtils.success("Prestador deshabilitado correctamente");
      },
    );
    handleCloseDelete();
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setPrestador({});
  };

  const handleOpenDelete = (p) => {
    setPrestador(p);
    setOpenDelete(true);
  };

  return (
    <div>
      <PrestadorTable
        data={artPrestadores.data}
        loading={
          artPrestadores.isFetching || disableArtPrestadorState.isFetching
        }
        fetchData={() => dispatch(getArtPrestadores())}
        handleDelete={handleOpenDelete}
      />
      <ConfirmDeleteDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
        title="Deshabilitar Prestador"
        text={`¿Está seguro que desea deshabilitar el prestador ${prestador.nombre}?`}
      />
    </div>
  );
};

export default PrestadorContainer;
