import { makeStyles, Paper, Tooltip } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    borderRadius: 5,
    borderStyle: "solid",
    borderColor: theme.palette.secondary.dark,
    cursor: "pointer",
    marginLeft: 10,
  },
  backArrow: {
    width: 40,
    height: 40,
  },
}));

const BackArrowHeader = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Tooltip title="Atrás" onClick={onClick} className={classes.container}>
      <Paper elevation={2}>
        <ArrowBack className={classes.backArrow} />
      </Paper>
    </Tooltip>
  );
};

export default BackArrowHeader;
