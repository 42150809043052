import { makeStyles } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useState } from "react";
import ArtInProvider from "../../ui/providers/artInProvider";
import { localization, useTableStyles } from "./config/tableConfig";
import TitleContainer from "./utilsTable/titleWithFetchData";

const useStyles = makeStyles((theme) => ({
  artInProviderContainer: {
    backgroundColor: "#fafafa",
    padding: 10,
  },
}));

const ProvidersTable = ({
  data,
  loading,
  fetchData,
  handleOpenEdit,
  handleDelete,
}) => {
  const classes = useStyles();
  const tableClasses = useTableStyles()();
  const [columnObject] = useState([
    {
      title: "Razón social",
      field: "nombre",
      render: (rowData) => rowData.nombre,
    },
  ]);

  return (
    <div className={tableClasses.tablePadding}>
      <MaterialTable
        columns={columnObject}
        data={data}
        isLoading={loading}
        locale="es"
        title={<TitleContainer title="Prestadores" fetchData={fetchData} />}
        options={{
          actionsColumnIndex: -1,
          rowStyle: localization.rowStyle,
          headerStyle: localization.headerStyle,
          pageSize: 10,
          search: false,
          filtering: true,
          exportButton: false,
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Editar Prestador",
            onClick: (event, rowData) => handleOpenEdit(rowData),
          },
          {
            icon: "delete",
            tooltip: "Eliminar Prestador",
            onClick: (event, rowData) => handleDelete(rowData),
          },
        ]}
        localization={{
          ...localization,
          body: {
            emptyDataSourceMessage: "No hay Prestadores que mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
        detailPanel={[
          {
            tooltip: "Más Información",
            render: (rowData) => (
              <div className={classes.artInProviderContainer}>
                <ArtInProvider provider={rowData} refresh={fetchData} />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
export default ProvidersTable;
