import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttentionInAnotherProviderHTML,
  getAttentionsInAnotherProvider,
} from "../../../../../actions/accidentDashboard";
import { parseHTMLToReact } from "../../../../../utils/util";
import PrintAndDownloadDialog from "../../../../commons/dialog/PrintAndDownloadDialog";
import CardTitle from "../cardTitle";
import AttentionsInAnotherProviderCards from "./attentionsInAnotherProviderCards";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));

const AttentionsInAnotherProvider = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const attentions = useSelector(
    (state) => state.accidentDashboard.attentionsInAnotherProvider,
  );
  const accident = useSelector((state) => state.accidentDashboard.accident);
  const attentionHTML = useSelector(
    (state) => state.accidentDashboard.attentionInAnotherProviderHTML,
  );

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(
      getAttentionsInAnotherProvider(
        accident.idSiniestro,
        accident.mlCloudCoreArtId,
      ),
    );
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const generateAttentionInAnotherProviderHTML = () => {
    setOpenModal(true);
    dispatch(
      getAttentionInAnotherProviderHTML({
        accidentId: accident.idSiniestro,
        WRIId: accident.mlCloudCoreArtId,
      }),
    );
  };

  return (
    <div className={classes.container}>
      <CardTitle title="ATENCIONES EN OTRO PRESTADOR" color="primary" />
      <AttentionsInAnotherProviderCards
        data={attentions.data}
        loading={attentions.isFetching}
        generateAttentionInAnotherProviderHTML={
          generateAttentionInAnotherProviderHTML
        }
      />
      <PrintAndDownloadDialog
        maxWidth="lg"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        childComponent={
          <React.Fragment>
            {parseHTMLToReact(attentionHTML.data)}
          </React.Fragment>
        }
        loading={attentionHTML.isFetching || !openModal}
        title="ATENCIÓN EN OTRO PRESTADOR"
        showDownloadButton={false}
        reactToPrintProps={{ suppressErrors: true }}
      />
    </div>
  );
};

export default AttentionsInAnotherProvider;
