import { TextField, withStyles } from "@material-ui/core";
import React from "react";

const DarkerDisabledTextFieldComponent = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.35)",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.35)",
    },
  },
})(TextField);

const DarkerDisabledTextField = (props) => {
  return <DarkerDisabledTextFieldComponent {...props} disabled={true} />;
};

export default DarkerDisabledTextField;
