import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmails } from "../../../actions/emails";
import EmailsTable from "../../commons/table/emailsTable";

const EmailContainer = () => {
  const dispatch = useDispatch();

  const emails = useSelector((state) => state.emails);
  const namesArts = useSelector((state) => state.art.shortNameArt);

  const [dateFrom, setDateFrom] = useState(
    moment(new Date()).add(-1, "days").format("YYYY-MM-DDT00:00:00"),
  );
  const [dateTo, setDateTo] = useState(
    moment(new Date()).format("YYYY-MM-DDT00:00:00"),
  );

  useEffect(() => {
    dispatch(getEmails(dateFrom, dateTo));
  }, []);

  return (
    <EmailsTable
      namesArts={namesArts}
      data={emails.list}
      loading={emails.isFetching}
      error={emails.didInvalidate}
      fetchData={(from, to) => dispatch(getEmails(from, to))}
      title="Correos enviados"
      dateFrom={dateFrom}
      dateTo={dateTo}
      setDateFrom={setDateFrom}
      setDateTo={setDateTo}
    />
  );
};

export default EmailContainer;
