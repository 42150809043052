import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { Clear, Edit, GetApp, Visibility } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  downloadAttachment,
  getAttachmentBase64,
} from "../../../../actions/forms";
import {
  downloadAttachmentForm,
  downloadFile,
} from "../../../../utils/filesUtils";
import { getFileSizeAsString } from "../../../../utils/util";
import FileView from "./fileView";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflow: "auto",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 5,
  },
  card: {
    display: "flex",
    marginTop: 10,
    marginBottom: 5,
  },
  filesContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingLeft: 15,
    marginLeft: 8,
    borderRadius: 10,
  },
  button: {
    cursor: "pointer",
    marginRight: 15,
  },
  disabledButton: {
    marginRight: 15,
    color: "grey",
  },
  visibilityIcon: {
    color: theme.palette.primary.main, // Cambia el color a tu gusto
    fontSize: 24, // Cambia el tamaño del icono si es necesario
  },
}));

const UploadedFiles = ({
  files,
  handleEdit,
  handleDeleteFile,
  configuration,
  selectedDocument,
  disabled,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openFileView, setOpenFileView] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const isPdf = (file) => {
    return file && file.mimeType.includes("application/pdf");
  };

  const isImage = (file) => {
    return file && file.mimeType.includes("image/");
  };

  const isImageOrPdf = (file) => {
    return isImage(file) || isPdf(file);
  };

  const handleOpenFileView = (file) => {
    if (isImageOrPdf(file)) {
      setSelectedFile(file);
      setOpenFileView(true);
      if (file.base64 === null) {
        dispatch(
          getAttachmentBase64(selectedDocument.nroFormulario, file.nombre),
        );
      }
    }
  };

  const handleCloseFileView = () => {
    setSelectedFile(null);
    setOpenFileView(false);
  };

  const handleDownload = (file) => {
    if (selectedDocument) {
      dispatch(
        downloadAttachment(selectedDocument.nroFormulario, file.nombre),
      ).then((response) => {
        downloadFile(response.value.data, file.nombre);
      });
    } else {
      downloadAttachmentForm(file);
    }
  };

  const getTipoAdjuntoNombre = (file) => {
    const tipoAdjunto = configuration.typifyAttachmentFile.find(
      (t) => t.tipoAdjunto === file.tipo,
    );
    return tipoAdjunto ? tipoAdjunto.nombre : "";
  };

  return (
    <div className={classes.container}>
      {files.map((file) => (
        // cuando se está editando el formulario, tenemos disponible
        // file.filename. Cuando estamos cargando el formulario desde
        // el back, tenemos disponible file.nombre
        <div className={classes.card} key={file.filename ?? file.nombre}>
          <Paper elevation={2} className={classes.filesContainer}>
            <Typography variant="body2">
              {file.filename ?? file.nombre}
              {" — " + getFileSizeAsString(file.bytes)}
            </Typography>
            {configuration.typifyAttachmentFile && (
              <Typography variant="body2">
                <b>Tipo:</b> {getTipoAdjuntoNombre(file)}
              </Typography>
            )}
            {configuration.showAttachmentObservation && (
              <Typography variant="body2">
                <b>Observaciones:</b> {file.observaciones}
              </Typography>
            )}
          </Paper>
          <Paper elevation={2} className={classes.actionsContainer}>
            <Tooltip
              title={
                isImageOrPdf(file) ? "Ver" : "No es posible ver el archivo"
              }
              className={
                isImageOrPdf(file) ? classes.button : classes.disabledButton
              }
              onClick={() => handleOpenFileView(file)}
              arrow>
              <Visibility
                className={
                  isImageOrPdf(file)
                    ? classes.visibilityIcon
                    : classes.disabledButton
                }
              />
            </Tooltip>
            {!file.base64 && (
              <Tooltip
                title="Descargar"
                className={classes.button}
                onClick={() => handleDownload(file)}>
                <GetApp />
              </Tooltip>
            )}
            {configuration.typifyAttachmentFile && (
              <Tooltip
                title="Editar"
                className={disabled ? classes.disabledButton : classes.button}
                onClick={() => {
                  if (!disabled) {
                    handleEdit(file);
                  }
                }}>
                <Edit />
              </Tooltip>
            )}
            <Tooltip
              title="Eliminar"
              className={disabled ? classes.disabledButton : classes.button}
              onClick={() => {
                if (!disabled) {
                  handleDeleteFile(file);
                }
              }}>
              <Clear />
            </Tooltip>
          </Paper>
        </div>
      ))}
      {isImageOrPdf(selectedFile) && (
        <FileView
          file={selectedFile}
          open={openFileView}
          handleClose={handleCloseFileView}
          isImage={isImage(selectedFile)}
        />
      )}
    </div>
  );
};

export default UploadedFiles;
