import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { openNewForm } from "../../../../../actions/formsState";
import CircularLoading from "../../../../commons/loadingData/circularLoading";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: 265,
    overflow: "auto",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 5,
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    borderRadius: 5,
  },
  button: {
    background: "transparent",
    border: "none",
    padding: 0,
    marginTop: 2,
  },
  icon: {
    cursor: "pointer",
  },
  noDataMessage: {
    textAlign: "center",
    marginTop: 20,
  },
}));

const AddDocumentationCards = ({ data, loading }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const handleOpenAddForm = (form) => {
    dispatch(openNewForm(form.formulario, form.nombre));
  }
  return (
    <div className={classes.container}>
      {loading ? (
        <CircularLoading />
      ) : data.length === 0 ? (
        <Typography variant="body2" className={classes.noDataMessage}>
          No hay documentos disponibles.
        </Typography>
      ) : (
        data.map((form) => (
          <Paper className={classes.card} key={form.formulario}>
            <Typography variant="body2">{form.nombre}</Typography>
            <Tooltip title="Agregar documento" arrow="top">
              <button className={classes.button}>
                <AddCircleOutline
                  className={classes.icon}
                  onClick={() => handleOpenAddForm(form)}
                />
              </button>
            </Tooltip>
          </Paper>
        ))
      )}
    </div>
  );
};

export default AddDocumentationCards;
