import React from "react";
import moment from "moment";
import { ResponsiveLine } from "@nivo/line";

const MyResponsiveLine = ({ data }) => {
  let newArrayOrder = [];

  if (data.length) {
    const helper = (dataArt) => {
      const quarterActual = moment().quarter();
      const positions = [{}, {}, {}, {}];
      dataArt.forEach((item) => {
        if (quarterActual === 1) {
          if (item.quarter === 2) positions[0] = item;
          if (item.quarter === 3) positions[1] = item;
          if (item.quarter === 4) positions[2] = item;
          if (item.quarter === 1) positions[3] = item;
        } else if (quarterActual === 2) {
          if (item.quarter === 3) positions[0] = item;
          if (item.quarter === 4) positions[1] = item;
          if (item.quarter === 1) positions[2] = item;
          if (item.quarter === 2) positions[3] = item;
        } else if (quarterActual === 3) {
          if (item.quarter === 4) positions[0] = item;
          if (item.quarter === 1) positions[1] = item;
          if (item.quarter === 2) positions[2] = item;
          if (item.quarter === 3) positions[3] = item;
        } else if (quarterActual === 4) {
          if (item.quarter === 1) positions[0] = item;
          if (item.quarter === 2) positions[1] = item;
          if (item.quarter === 3) positions[2] = item;
          if (item.quarter === 4) positions[3] = item;
        }
      });
      return positions;
    };
    newArrayOrder = data.map((item) => ({
      id: item.art || item.id,
      data: helper(item.data),
    }));
  }

  return (
    <React.Fragment>
      <ResponsiveLine
        data={newArrayOrder}
        margin={{
          top: 20,
          right: 100,
          bottom: 50,
          left: 60,
        }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        curve="cardinal"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 0,
          tickPadding: 12,
          tickRotation: 0,
          legend: "",
          legendOffset: 43,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 4,
          tickPadding: 20,
          tickRotation: 0,
          legend: "",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        enableGridX={false}
        colors={{ scheme: "nivo" }}
        pointSize={4}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor", modifiers: [] }}
        pointLabel="y"
        pointLabelYOffset={-14}
        areaOpacity={0}
        enableCrosshair={false}
        useMesh
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 10,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </React.Fragment>
  );
};

export default MyResponsiveLine;
