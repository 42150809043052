import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { getIn, useField, useFormikContext } from "formik";
import React, { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginBottom: -3,
  },
}));

const FormikSelect = ({ caseInsensitive = false, children, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const { values, setFieldValue } = useFormikContext();
  const fieldValue = getIn(values, props.name);

  useEffect(() => {
    if (props.callBack) {
      props.callBack(fieldValue);
    }
  }, [fieldValue]);

  useEffect(() => {
    if (caseInsensitive) {
      const value = children?.find(
        (child) =>
          child?.props?.value?.toLowerCase() === props.value?.toLowerCase(),
      )?.props.value;

      if (value) setFieldValue(props.name, value);
    }
  }, []);

  return (
    <FormControl
      error={meta.touched && meta.error ? true : false}
      className={
        props.className
          ? `${classes.formControl} ${props.className}`
          : classes.formControl
      }
      disabled={props.disabled}
      variant={props.variant || "standard"}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        name={props.name}
        value={props.value}
        variant={props.variant}
        label={props.label}
        {...field}>
        {children}
      </Select>
      <FormHelperText>
        {meta.touched && meta.error ? meta.error : null}
      </FormHelperText>
    </FormControl>
  );
};

export default FormikSelect;
