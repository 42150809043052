import { Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import FormikCheckbox from "../../../commons/formik/formikCheckbox";
import FormikDatePicker from "../../../commons/formik/formikDatePicker";
import FormikTextField from "../../../commons/formik/formikTextField";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    padding: 20,
    marginBottom: 20,
  },
  input: {
    display: "inline-flex",
    marginTop: 10,
    marginRight: 20,
    width: 250,
  },
  checkbox: {
    marginTop: 20,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const initialValues = {
  siniestro: "",
  dni: "",
  fechaDesde: null,
  fechaHasta: null,
  otrosPrestadores: false,
};

const validationSchema = yup.object().shape({
  fechaDesde: yup
    .date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaDesde",
      message: "La fecha desde debe ser menor o igual a la fecha hasta",
      test: function (value) {
        if (this.parent.fechaHasta && value <= this.parent.fechaHasta) {
          return true;
        }
        return false;
      },
    }),

  fechaHasta: yup
    .date()
    .nullable()
    .typeError("Fecha inválida")
    .test({
      name: "fechaHasta",
      message: "La fecha hasta debe ser mayor o igual a la fecha desde",
      test: function (value) {
        if (this.parent.fechaDesde && value >= this.parent.fechaDesde) {
          return true;
        }
        return false;
      },
    }),
});

const SearchAuthorizationForm = ({ handleSubmit, loading }) => {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.paper}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <>
              <Form>
                <FormikTextField
                  name="siniestro"
                  variant="standard"
                  label="Siniestro"
                  className={classes.input}
                />
                <FormikTextField
                  name="dni"
                  variant="standard"
                  label="DNI"
                  className={classes.input}
                />
                <FormikDatePicker
                  name="fechaDesde"
                  variant="standard"
                  label="Fecha Desde"
                  className={classes.input}
                />
                <FormikDatePicker
                  name="fechaHasta"
                  variant="standard"
                  label="Fecha Hasta"
                  className={classes.input}
                />
                <FormikCheckbox
                  id="otrosPrestadores"
                  name="otrosPrestadores"
                  label="¿Incluir otros prestadores?"
                  className={classes.checkbox}
                />
              </Form>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  type="submit"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}>
                  Buscar
                </Button>
              </div>
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default SearchAuthorizationForm;
