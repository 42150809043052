import { CircularProgress, MenuItem, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getArts } from "../../../actions/arts";
import { getProviders } from "../../../actions/providers";
import FormikTextField from "../../commons/formik/formikTextField";
import FormikActions from "../formik/formikActions";
import FormikAutocomplete from "../formik/formikAutocomplete";
import FormikSelect from "../formik/formikSelect";

const useStyles = makeStyles({
  loading: {
    marginLeft: 290,
    marginTop: 100,
    marginBottom: 100,
  },
  input: {
    width: "100%",
  },
});

const validation = (roles) =>
  Yup.object({
    nombre: Yup.string().required("Campo obligatorio"),
    apellido: Yup.string().required("Campo obligatorio"),
    email: Yup.string().required("Campo obligatorio").email("Email inválido"),
    idRol: Yup.string().required("Campo obligatorio"),
    idArt: Yup.string()
      .nullable()
      .test({
        name: "art",
        message: "Campo obligatorio",
        test: function (value) {
          let rol = roles.find((r) => r.id == this.parent.idRol);
          if (rol && rol.tipo.includes("Art")) {
            return value;
          } else return true;
        },
      }),
    idPrestador: Yup.string()
      .nullable()
      .test({
        name: "art",
        message: "Campo obligatorio",
        test: function (value) {
          let rol = roles.find((r) => r.id == this.parent.idRol);
          if (rol && rol.tipo.includes("Prestador")) {
            return value;
          } else return true;
        },
      }),
  });

const EditUserDialog = ({
  user,
  open,
  handleClose,
  addingNewUser,
  handleSubmit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const roles = useSelector((state) => state.users.roles);
  const arts = useSelector((state) => state.arts.list);
  const providers = useSelector((state) => state.providers.listProviders.list);
  const token = useSelector((state) => state.auth.token);

  const isArtRol = (idRol) => {
    let rol = roles.data.find((r) => r.id === idRol);
    if (rol && rol.tipo.includes("Art")) {
      return true;
    } else return false;
  };

  const isPrestadorRol = (idRol) => {
    let rol = roles.data.find((r) => r.id === idRol);
    if (rol && rol.tipo.includes("Prestador")) {
      return true;
    } else return false;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      {roles.isFetching ? (
        <CircularProgress className={classes.loading} size={40} />
      ) : (
        <>
          <DialogTitle>
            {addingNewUser ? "Agregar Usuario" : "Editar Usuario"}
          </DialogTitle>
          <Formik
            initialValues={user}
            validationSchema={() => validation(roles.data)}
            onSubmit={(values) => {
              if (isArtRol(values.idRol)) {
                values.idPrestador = "";
              } else if (isPrestadorRol(values.idRol)) {
                values.idArt = "";
              }
              handleSubmit(values, addingNewUser);
            }}>
            {({ values }) => (
              <Form>
                <DialogContent>
                  <FormikTextField
                    margin="dense"
                    name="nombre"
                    label="Nombre"
                    type="text"
                    fullWidth
                  />
                  <FormikTextField
                    margin="dense"
                    name="apellido"
                    label="Apellido"
                    type="text"
                    fullWidth
                  />
                  <FormikTextField
                    margin="dense"
                    name="email"
                    label="Email"
                    type="text"
                    fullWidth
                  />
                  <FormikSelect
                    name="idRol"
                    label="Rol"
                    className={classes.input}>
                    {roles.data.map((r) => {
                      return (
                        <MenuItem key={r.id} value={r.id}>
                          {r.tipo}
                        </MenuItem>
                      );
                    })}
                  </FormikSelect>
                  {isArtRol(values.idRol) && (
                    <FormikAutocomplete
                      name="idArt"
                      label="Art"
                      options={arts}
                      getOptions={() => dispatch(getArts())}
                      className={classes.input}
                    />
                  )}
                  {isPrestadorRol(values.idRol) && (
                    <FormikAutocomplete
                      name="idPrestador"
                      label="Prestador"
                      options={providers}
                      getOptions={() => dispatch(getProviders(token))}
                      className={classes.input}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <FormikActions handleCancel={handleClose} />
                </DialogActions>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Dialog>
  );
};

export default EditUserDialog;
