import axios from "axios";
export const POST_IMPORT_IET = "POST_IMPORT_IET";
export const DOWNLOAD_IET_FILE = "DOWNLOAD_IET_FILE";

export const downloadIetFile = () => (dispatch) => {
  return dispatch({
    type: DOWNLOAD_IET_FILE,
    payload: axios
      .get(`importForm/informacionArchivoIet`, {
        responseType: "blob",
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const postImportIet = (art, adjuntos) => (dispatch) => {
  return dispatch({
    type: POST_IMPORT_IET,
    payload: axios
      .post(`importForm/Iet?wriId=${art}`, adjuntos)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
