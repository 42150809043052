import { GET_FILES, UPDATE_FILE } from "../actions/files";

const INITIAL_STATE = {
  availableFiles: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  updatedFile: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
};

const files = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_FILES}_REJECTED`:
      return {
        ...state,
        availableFiles: {
          ...state.availableFiles,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_FILES}_PENDING`:
      return {
        ...state,
        availableFiles: {
          ...state.availableFiles,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_FILES}_FULFILLED`:
      return {
        ...state,
        availableFiles: {
          ...state.availableFiles,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${UPDATE_FILE}_REJECTED`:
      return {
        ...state,
        updatedFile: {
          ...state.updatedFile,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${UPDATE_FILE}_PENDING`:
      return {
        ...state,
        updatedFile: {
          ...state.updatedFile,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${UPDATE_FILE}_FULFILLED`:
      return {
        ...state,
        updatedFile: {
          ...state.updatedFile,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export default files;
