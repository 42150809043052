import ConfirmDialog from "./confirmDialog";

const ConfirmCancelChangesDialog = ({ open, handleClose, handleConfirm }) => {
    return (
        <div>
            <ConfirmDialog
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                title="CERRAR VENTANA"
                text="¿Está seguro que desea cancelar esta operación? En caso de aceptar, se perderá la información ingresada"
            />
        </div>
    )
}

export default ConfirmCancelChangesDialog;