import {
  CLEAN_ACCIDENTS_FILTER,
  CLEAN_ACCIDENT_FORM,
  GET_ACCIDENTS,
  GET_ACCIDENTS_ART,
  GET_ACCIDENTS_FILTER,
  GET_ACCIDENT_FACADE,
  GET_ACCIDENT_FACADE_OPEN,
  GET_ACCIDENT_FORM,
  GET_REASONS_CLOUSURE,
  POST_ACCIDENT_FORM,
  POST_CLOSE_ACCIDENT,
  RE_OPEN_ACCIDENT,
} from "../actions/accidents";

const INITIAL_STATE = {
  isFetching: false,
  isFetchingFilter: false,
  didInvalidate: false,
  didInvalidateFilter: false,
  list: [],
  listFilter: [],
  art: {
    isFetching: false,
    didInvalidate: false,
    data: [],
  },
  form: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  postForm: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  facadeAndOpen: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  facade: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  reOpen: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  reasonsClousure: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
  closeAccident: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
};

const accidents = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_ACCIDENTS}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
      };
    case `${GET_ACCIDENTS}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case `${GET_ACCIDENTS}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        list: action.payload.data.accidents,
      };
    case `${GET_ACCIDENTS_ART}_REJECTED`:
      return {
        ...state,
        art: {
          ...state.art,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ACCIDENTS_ART}_PENDING`:
      return {
        ...state,
        art: {
          ...state.art,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ACCIDENTS_ART}_FULFILLED`:
      return {
        ...state,
        art: {
          ...state.art,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data.accidents,
        },
      };
    case `${GET_ACCIDENTS_FILTER}_REJECTED`:
      return {
        ...state,
        isFetchingFilter: false,
        didInvalidateFilter: true,
      };
    case `${GET_ACCIDENTS_FILTER}_PENDING`:
      return {
        ...state,
        isFetchingFilter: true,
        didInvalidateFilter: false,
      };
    case `${GET_ACCIDENTS_FILTER}_FULFILLED`:
      return {
        ...state,
        isFetchingFilter: false,
        didInvalidateFilter: false,
        listFilter: action.payload.data.accidents,
      };
    case `${CLEAN_ACCIDENTS_FILTER}`:
      return {
        ...state,
        isFetchingFilter: false,
        didInvalidateFilter: false,
      };
    case `${GET_ACCIDENT_FORM}_REJECTED`:
      return {
        ...state,
        form: {
          ...state.form,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ACCIDENT_FORM}_PENDING`:
      return {
        ...state,
        form: {
          ...state.form,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ACCIDENT_FORM}_FULFILLED`:
      return {
        ...state,
        form: {
          ...state.form,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${POST_ACCIDENT_FORM}_REJECTED`:
      return {
        ...state,
        postForm: {
          ...state.postForm,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${POST_ACCIDENT_FORM}_PENDING`:
      return {
        ...state,
        postForm: {
          ...state.postForm,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${POST_ACCIDENT_FORM}_FULFILLED`:
      return {
        ...state,
        postForm: {
          ...state.postForm,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case CLEAN_ACCIDENT_FORM:
      return {
        ...state,
        form: INITIAL_STATE.form,
      };
    case `${GET_ACCIDENT_FACADE_OPEN}_REJECTED`:
      return {
        ...state,
        facadeAndOpen: {
          ...state.facadeAndOpen,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ACCIDENT_FACADE_OPEN}_PENDING`:
      return {
        ...state,
        facadeAndOpen: {
          ...state.facadeAndOpen,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ACCIDENT_FACADE_OPEN}_FULFILLED`:
      return {
        ...state,
        facadeAndOpen: {
          ...state.facadeAndOpen,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${GET_ACCIDENT_FACADE}_REJECTED`:
      return {
        ...state,
        facade: {
          ...state.facade,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_ACCIDENT_FACADE}_PENDING`:
      return {
        ...state,
        facade: {
          ...state.facade,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_ACCIDENT_FACADE}_FULFILLED`:
      return {
        ...state,
        facade: {
          ...state.facade,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${RE_OPEN_ACCIDENT}_REJECTED`:
      return {
        ...state,
        reOpen: {
          ...state.reOpen,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${RE_OPEN_ACCIDENT}_PENDING`:
      return {
        ...state,
        reOpen: {
          ...state.reOpen,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${RE_OPEN_ACCIDENT}_FULFILLED`:
      return {
        ...state,
        reOpen: {
          ...state.reOpen,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${GET_REASONS_CLOUSURE}_REJECTED`:
      return {
        ...state,
        reasonsClousure: {
          ...state.reasonsClousure,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${GET_REASONS_CLOUSURE}_PENDING`:
      return {
        ...state,
        reasonsClousure: {
          ...state.reasonsClousure,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${GET_REASONS_CLOUSURE}_FULFILLED`:
      return {
        ...state,
        reasonsClousure: {
          ...state.reasonsClousure,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    case `${POST_CLOSE_ACCIDENT}_REJECTED`:
      return {
        ...state,
        closeAccident: {
          ...state.closeAccident,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${POST_CLOSE_ACCIDENT}_PENDING`:
      return {
        ...state,
        closeAccident: {
          ...state.closeAccident,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${POST_CLOSE_ACCIDENT}_FULFILLED`:
      return {
        ...state,
        closeAccident: {
          ...state.closeAccident,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };

    default:
      return state;
  }
};

export default accidents;
