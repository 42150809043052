import { Tooltip } from "@material-ui/core";

const DrawerItemTooltip = ({ children, drawerOpen, tooltipText }) => {
    return (
        <Tooltip
            title={tooltipText}
            placement="bottom"
            arrow
            disableHoverListener={drawerOpen}
            disableFocusListener={drawerOpen}
            disableTouchListener
        >
            {children}
        </Tooltip>
    )
}

export default DrawerItemTooltip; 