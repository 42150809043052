import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailableDocumentsToAdd
} from "../../../../../actions/accidentDashboard";
import CardTitle from "../cardTitle";
import AddDocumentationCards from "./addDocumentationCards";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));

const AddDocumentation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accident = useSelector((state) => state.accidentDashboard.accident);
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );

  useEffect(() => {
    dispatch(
      getAvailableDocumentsToAdd(
        accident.idSiniestro,
        accident.mlCloudCoreArtId,
      ),
    );
  }, []);




  return (
    <>
      <div className={classes.container}>
        <CardTitle title="AGREGAR DOCUMENTACIÓN" color="primary" />
        <AddDocumentationCards
          data={documentation.data}
          loading={documentation.isFetching}
        />
      </div>
    </>
  );
};

export default AddDocumentation;
