import {
  Button,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircle, Edit } from "@material-ui/icons";
import moment from "moment";
import React from "react";

const useStyles = makeStyles({
  card: {
    display: "flex",
    margin: 10,
    padding: 15,
  },
  cardLeftContainer: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
  },
  cardRightContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardIcon: {
    width: 30,
    height: 30,
    cursor: "pointer",
  },
});

const FacadeAccidentCards = ({ accidents, handleAdd, handleEdit }) => {
  const classes = useStyles();
  return accidents.map((accident) => (
    <Paper
      elevation={2}
      className={classes.card}
      key={accident.nroSiniestroArt}>
      <div className={classes.cardLeftContainer}>
        <Typography>
          <b>Nº Siniestro:</b> {accident.nroSiniestroArt}
        </Typography>
        <Typography>
          <b>Fecha hora accidente:</b>{" "}
          {moment(accident.fechaHoraAccidente).format("DD/MM/YYYY HH:mm")}
        </Typography>
        <Typography>
          <b>Diagnóstico:</b> {accident.diagnostico}
        </Typography>
        <Typography>
          <b>Empleador:</b> {accident.razonSocialEmpleador} (
          {accident.cuitEmpleador})
        </Typography>
        <Typography>
          <b>Referente:</b> {accident.referenteApellidoyNombre}
        </Typography>
        <Typography>
          <b>Referente Email:</b> {accident.referenteEmail}
        </Typography>
      </div>
      <div className={classes.cardRightContainer}>
        {handleAdd ? (
          <Tooltip title="Ingresar Siniestro">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => handleAdd(accident, null)}>
              <AddCircle />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Actualizar Siniestro">
            <Edit
              className={classes.cardIcon}
              onClick={() => handleEdit(accident, null)}
            />
          </Tooltip>
        )}
      </div>
    </Paper>
  ));
};

export default FacadeAccidentCards;
