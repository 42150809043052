import { MenuItem } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormDiagnosis, getFormEmployers } from "../../../../actions/forms";
import { getCuilCuit } from "../../../../utils/util";
import GooglePlacesAutocomplete from "../../../commons/formComponents/GooglePlacesAutocomplete";
import DarkerDisabledTextField from "../../../commons/formComponents/darkerDisabledTextField";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikDatePicker from "../../../commons/formik/formikDatePicker";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextField from "../../../commons/formik/formikTextField";
import FormSectionDivider from "../formSectionDivider";
import { useStyles } from "../formStyle";
import { siniestroValidation, validateRequiredFields } from "../formValidation";

const AccidentForm = ({
  handleClose,
  handleSubmit,
  model,
  form,
  artId,
  fromFacade,
  employers,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const diagnosis = useSelector((state) => state.forms.diagnosis);

  const setDireccionValue = (setFieldValue, field, value) => {
    setFieldValue(field, value ? value : "");
  };

  const setDireccion = (setFieldValue, value, isTrabajador) => {
    const aux = isTrabajador ? "Trabajador" : "Empleador";

    setDireccionValue(setFieldValue, `calle${aux}`, value.calle);
    setDireccionValue(setFieldValue, `numero${aux}`, value.numero);
    setDireccionValue(setFieldValue, `localidad${aux}`, value.localidad);
    setDireccionValue(setFieldValue, `provincia${aux}`, value.provincia);
    setDireccionValue(setFieldValue, `codigoPostal${aux}`, value.codigoPostal);
  };

  const diagnosisIsFound = diagnosis.data.some(
    (d) => d.id === form.diagnosticoOMS,
  );

  if (!diagnosisIsFound) {
    form.diagnosticoOMS = "";
  }

  return (
    <Formik
      initialValues={form}
      validationSchema={() => siniestroValidation(fromFacade)}
      validate={(values) => validateRequiredFields(values, model)}
      onSubmit={(values) => {
        handleSubmit(values);
      }}>
      {({ setFieldValue, values }) => (
        <Form className={classes.container}>
          <div className={classes.fieldContainer}>
            <FormSectionDivider title="Siniestro" />
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikAutocomplete
                  name="tipoSucesoArt"
                  label={`Tipo ${model.tipoSucesoArt.required ? "*" : ""}`}
                  variant="outlined"
                  options={model.tipoSucesoArt.options}
                  className={classes.input}
                  disabled={fromFacade}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikDateTimePicker
                  name="fechaHoraAccidente"
                  label={`Fecha Hora Accidente ${
                    model.tipoSucesoArt.required ? "*" : ""
                  }`}
                  inputVariant="outlined"
                  disableFuture
                  className={classes.input}
                  autoComplete="off"
                  disabled={fromFacade}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="nroSiniestroArt"
                  label={`Nro Siniestro ART ${
                    model.nroSiniestroArt.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={fromFacade}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikDatePicker
                  name="fechaReagravacion"
                  label={`Fecha Reagravación ${
                    model.fechaReagravacion.required ? "*" : ""
                  }`}
                  inputVariant="outlined"
                  className={classes.input}
                  autoComplete="off"
                  disabled={fromFacade}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="referenteApellidoyNombre"
                label={`Referente ART ${
                  model.referenteApellidoyNombre.required ? "*" : ""
                }`}
                variant="outlined"
                options={model.referenteApellidoyNombre.options}
                callBack={(value) => {
                  if (value) {
                    setFieldValue("idReferenteExterno", value.id);
                    setFieldValue("referenteEmail", value.email);
                  } else {
                    setFieldValue("idReferenteExterno", null);
                    setFieldValue("referenteEmail", null);
                  }
                }}
                returnProp="nombre"
                className={classes.input}
                disabled={
                  fromFacade && form.referenteApellidoyNombre ? true : false
                }
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="diagnosticoOMS"
                label={`Diagnóstico OMS ${
                  model.diagnosticoOMS.required ? "*" : ""
                }`}
                variant="outlined"
                options={diagnosis.data}
                getOptions={() => dispatch(getFormDiagnosis(artId))}
                className={classes.input}
                renderOption={(option) => `${option.id} - ${option.nombre}`}
                filterOptions={(option) => option.id + option.nombre}
                disabled={fromFacade && diagnosisIsFound}
              />
            </div>
            {fromFacade && !diagnosisIsFound && (
              <div className={classes.rowContainer}>
                <DarkerDisabledTextField
                  label="Diagnóstico informado por la ART"
                  type="text"
                  variant="outlined"
                  value={form.diagnostico}
                  className={classes.input}
                />
              </div>
            )}

            <FormSectionDivider title="Trabajador" />
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikSelect
                  name="tipoDocumentoTrabajador"
                  value={form.tipoDocumentoTrabajador}
                  label={`Tipo Documento ${
                    model.tipoDocumentoTrabajador.required ? "*" : ""
                  }`}
                  variant="outlined"
                  className={classes.input}
                  disabled={true}>
                  {model.tipoDocumentoTrabajador.options.map((t) => (
                    <MenuItem value={t} key={t}>
                      {t}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="numeroDocumentoTrabajador"
                  label={`Número Documento ${
                    model.numeroDocumentoTrabajador.required ? "*" : ""
                  }`}
                  type="number"
                  variant="outlined"
                  className={classes.input}
                  disabled={true}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="apellidoTrabajador"
                  label={`Apellido ${
                    model.apellidoTrabajador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={fromFacade}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="nombreTrabajador"
                  label={`Nombre ${model.nombreTrabajador.required ? "*" : ""}`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                  disabled={fromFacade}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikDatePicker
                  name="fechaNacimientoTrabajador"
                  label={`Fecha Nacimiento ${
                    model.fechaNacimientoTrabajador.required ? "*" : ""
                  }`}
                  inputVariant="outlined"
                  disableFuture
                  className={classes.input}
                  autoComplete="off"
                  disabled={fromFacade}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikSelect
                  name="sexoTrabajador"
                  value={form.sexoTrabajador}
                  label={`Sexo ${model.sexoTrabajador.required ? "*" : ""}`}
                  variant="outlined"
                  className={classes.input}
                  disabled={fromFacade && form.sexoTrabajador}
                  callBack={(value) => {
                    if (
                      value &&
                      values.numeroDocumentoTrabajador &&
                      // Si el CUIL/CUIT es traido por el validador, debemos usar
                      // ese valor. De lo contrario, el boton de sexo va a sobreescribir
                      // el valor de CUIL/CUIT durante el callback.
                      !fromFacade
                    ) {
                      setFieldValue(
                        "cuilCuitTrabajador",
                        getCuilCuit(values.numeroDocumentoTrabajador, value),
                      );
                    }
                  }}>
                  {model.sexoTrabajador.options.map((s) => (
                    <MenuItem value={s.id} key={s.id}>
                      {s.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </div>
            </div>
            <div className={classes.rowContainer}>
              <FormikTextField
                name="cuilCuitTrabajador"
                label={`Cuil ${model.cuilCuitTrabajador.required ? "*" : ""}`}
                type="text"
                variant="outlined"
                className={classes.input}
                disabled={fromFacade}
              />
            </div>
            <div className={classes.rowContainer}>
              <GooglePlacesAutocomplete
                handleSubmit={(value) =>
                  setDireccion(setFieldValue, value, true)
                }
                label="Buscar una dirección para autocompletar los campos"
                variant="outlined"
                type="search"
                autoComplete="off"
                fullWidth
              />
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="calleTrabajador"
                  label={`Calle ${model.calleTrabajador.required ? "*" : ""}`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="numeroTrabajador"
                  label={`Número ${model.numeroTrabajador.required ? "*" : ""}`}
                  type="number"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="pisoTrabajador"
                  label={`Piso ${model.pisoTrabajador.required ? "*" : ""}`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="departamentoTrabajador"
                  label={`Departamento ${
                    model.departamentoTrabajador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="localidadTrabajador"
                  label={`Localidad ${
                    model.localidadTrabajador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="provinciaTrabajador"
                  label={`Provincia ${
                    model.provinciaTrabajador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <FormikTextField
                name="codigoPostalTrabajador"
                label={`Código postal ${
                  model.codigoPostalTrabajador.required ? "*" : ""
                }`}
                type="number"
                variant="outlined"
                className={classes.input}
              />
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="areaTelefonosTrabajador"
                  label={`Área Teléfono ${
                    model.areaTelefonosTrabajador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="telefonosTrabajador"
                  label={`Teléfono ${
                    model.telefonosTrabajador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="areaTelefonoCelularTrabajador"
                  label={`Área Celular${
                    model.areaTelefonoCelularTrabajador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="telefonoCelularTrabajador"
                  label={`Celular ${
                    model.telefonoCelularTrabajador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
            </div>
            <FormSectionDivider title="Empleador" />
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="cuitEmpleador"
                optionId="cuit"
                label={`Cuit ${model.cuitEmpleador.required ? "*" : ""}`}
                variant="outlined"
                options={employers}
                getOptions={() => dispatch(getFormEmployers(artId))}
                callBack={(value) => {
                  setFieldValue(
                    "razonSocialEmpleador",
                    value ? value.razonSocial || "" : "",
                  );
                  setFieldValue(
                    "calleEmpleador",
                    value ? value.calle || "" : "",
                  );
                  setFieldValue(
                    "numeroEmpleador",
                    value ? value.numero || "" : "",
                  );
                  setFieldValue("pisoEmpleador", value ? value.piso || "" : "");
                  setFieldValue(
                    "departamentoEmpleador",
                    value ? value.departamento || "" : "",
                  );
                  setFieldValue(
                    "localidadEmpleador",
                    value ? value.localidad || "" : "",
                  );
                  setFieldValue(
                    "provinciaEmpleador",
                    value ? value.provincia || "" : "",
                  );
                  setFieldValue(
                    "codigoPostalEmpleador",
                    value ? value.codigoPostal || "" : "",
                  );
                  setFieldValue(
                    "telefonosEmpleador",
                    value ? value.telefonos || "" : "",
                  );
                }}
                className={classes.input}
                freeSolo={true}
                returnProp="cuit"
                labelOption={(option) => option.cuit}
                helperText="Nuevo Empleador"
                renderOption={(option) =>
                  `${option.cuit} - ${option.razonSocial}`
                }
                filterOptions={(option) => option.cuit + option.razonSocial}
                disabled={fromFacade}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikTextField
                name="razonSocialEmpleador"
                label={`Razón Social ${
                  model.razonSocialEmpleador.required ? "*" : ""
                }`}
                type="text"
                variant="outlined"
                className={classes.input}
                disabled={fromFacade}
              />
            </div>
            <div className={classes.rowContainer}>
              <GooglePlacesAutocomplete
                handleSubmit={(value) =>
                  setDireccion(setFieldValue, value, false)
                }
                label="Buscar una dirección para autocompletar los campos"
                variant="outlined"
                type="search"
                autoComplete="off"
                fullWidth
              />
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="calleEmpleador"
                  label={`Calle ${model.calleEmpleador.required ? "*" : ""}`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="numeroEmpleador"
                  label={`Número ${model.numeroEmpleador.required ? "*" : ""}`}
                  type="number"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="pisoEmpleador"
                  label={`Piso ${model.pisoEmpleador.required ? "*" : ""}`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="departamentoEmpleador"
                  label={`Departamento ${
                    model.departamentoEmpleador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="localidadEmpleador"
                  label={`Localidad ${
                    model.localidadEmpleador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="provinciaEmpleador"
                  label={`Provincia ${
                    model.provinciaEmpleador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikTextField
                  name="codigoPostalEmpleador"
                  label={`Código postal ${
                    model.codigoPostalEmpleador.required ? "*" : ""
                  }`}
                  type="number"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <FormikTextField
                  name="telefonosEmpleador"
                  label={`Teléfono ${
                    model.telefonosEmpleador.required ? "*" : ""
                  }`}
                  type="text"
                  variant="outlined"
                  className={classes.input}
                />
              </div>
            </div>
          </div>
          <div className={classes.actionsContainer}>
            <FormikActions handleCancel={handleClose} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AccidentForm;
