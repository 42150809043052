import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAccident } from "../../../../actions/accidentDashboard";
import {
  attendedAppointment,
  cancelledAppointment,
  getPendingAppointments,
  removeAndRefreshPendingAppointments,
  unAttendedAppointment,
} from "../../../../actions/appointments";
import snackbarUtils from "../../../../utils/snackbarUtils";
import AppointmentsTable from "../../../commons/table/appointmentsTable";
import AccidentDashboardContainer from "../../accident/accidentDashboard/accidentDashboardContainer";

const PendingAppointmentContainer = () => {
  const dispatch = useDispatch();

  const pendingAppointments = useSelector(
    (state) => state.appointments.pendingAppointments,
  );
  const namesArts = useSelector((state) => state.art.shortNameArt);

  const [displayAccidents, setDisplayAccidents] = useState("block");
  const [time, setTime] = React.useState(2);

  const cancelledAction = (data) => {
    dispatch(cancelledAppointment(data.id, data.mlCloudCoreArtId)).then(() => {
      dispatch(removeAndRefreshPendingAppointments(data.id));
      snackbarUtils.success("Turno cancelado correctamente");
    });
  };

  const attendedAction = (data) => {
    dispatch(attendedAppointment(data.id, data.mlCloudCoreArtId)).then(() => {
      dispatch(removeAndRefreshPendingAppointments(data.id));
      snackbarUtils.success("Turno marcado como concurrido correctamente");
    });
  };

  const unAttendedAction = (data) => {
    dispatch(unAttendedAppointment(data.id, data.mlCloudCoreArtId)).then(() => {
      dispatch(removeAndRefreshPendingAppointments(data.id));
      snackbarUtils.success("Turno marcado como no concurrido correctamente");
    });
  };

  useEffect(() => {
    dispatch(getPendingAppointments(time));
  }, []);

  const handleAccidentDetail = (accident) => {
    dispatch(
      setSelectedAccident({
        ...accident,
        siniestro: accident.nroSiniestro,
        documento: accident.nroDocumento,
      }),
    );
    setDisplayAccidents("none");
  };

  const handleChange = (event) => {
    setTime(event.target.value);
    dispatch(getPendingAppointments(event.target.value));
  };

  return (
    <div>
      {displayAccidents === "none" && (
        <AccidentDashboardContainer setDisplayAccidents={setDisplayAccidents} />
      )}
      <AppointmentsTable
        namesArts={namesArts}
        data={pendingAppointments.list}
        loading={pendingAppointments.isFetching}
        error={pendingAppointments.didInvalidate}
        fetchData={() => dispatch(getPendingAppointments(time))}
        title="Asistencias pendientes de informar"
        cancelledAction={cancelledAction}
        handleChangeTime={handleChange}
        time={time}
        attendedAction={attendedAction}
        unAttendedAction={unAttendedAction}
        handleAccidentDetail={handleAccidentDetail}
        displayAccidents={displayAccidents}
      />
    </div>
  );
};

export default PendingAppointmentContainer;
