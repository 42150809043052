import { Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    marginTop: 20,
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    padding: 1,
    marginBottom: 20,
    marginLeft: -8,
    marginRight: -8,
  },
}));

const FormSectionDivider = ({ title }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Divider className={classes.divider} />
    </div>
  );
};

export default FormSectionDivider;
