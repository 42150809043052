import axios from "axios";

export const GET_LIST_USERS = "GET_LIST_USERS";
export const USER_REGISTER = "USER_REGISTER";
export const USER_DELETE = "USER_DELETE";
export const ADD_PROVIDER_IN_USER = "ADD_PROVIDER_IN_USER";
export const DELETE_PROVIDER_IN_USER = "DELETE_PROVIDER_IN_USER";
export const GET_USER_ROLES = "GET_USER_ROLES";

export const getListUsers = () => (dispatch) => {
  dispatch({
    type: GET_LIST_USERS,
    payload: axios
      .get("account")
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => Promise.reject(error)),
  });
};

export const userRegister = (user) => (dispatch) => {
  return dispatch({
    type: USER_REGISTER,
    payload: axios
      .post("account/register", user)
      .then((response) => ({
        data: response.data,
      }))
      .catch((error) => Promise.reject(error)),
  });
};

export const userDelete = (user) => (dispatch) => {
  return dispatch({
    type: USER_DELETE,
    payload: axios
      .delete("account", {
        headers: {
          "Content-Type": "application/json",
        },
        data: user,
      })
      .then((response) => ({
        data: response.data,
        user,
      }))
      .catch((error) => Promise.reject(error)),
  });
};

export const addProviderInUser = (providerId, userId) => (dispatch) => {
  return dispatch({
    type: ADD_PROVIDER_IN_USER,
    payload: axios
      .put(`provider/${providerId}/users/add/${userId}`)
      .then((response) => response)
      .catch((error) => Promise.reject(error)),
  });
};

export const deleteProviderInUser = (providerId, userId) => (dispatch) => {
  return dispatch({
    type: DELETE_PROVIDER_IN_USER,
    payload: axios
      .put(`provider/${providerId}/users/delete/${userId}`)
      .then((response) => ({
        data: response.data,
        providerId,
        userId,
      }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getUserRoles = () => (dispatch) => {
  dispatch({
    type: GET_USER_ROLES,
    payload: axios
      .get("account/roles")
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => Promise.reject(error)),
  });
};
