import { Typography, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikTextField from "../../../commons/formik/formikTextField";
import CircularLoading from "../../../commons/loadingData/circularLoading";

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    marginBottom: 10,
  },
  input: {
    flex: 1,
    marginRight: 20,
  },
  loading: {
    marginTop: 40,
    marginBottom: 80,
  },
}));

const validation = () =>
  Yup.object({
    profesionalApellidoNombre: Yup.string()
      .required("Campo obligatorio")
      .nullable(),
    matricula: Yup.string().required("Campo obligatorio"),
    especialidad: Yup.string().required("Campo obligatorio").nullable(),
  });

const AddProfessional = ({
  open,
  handleSubmit,
  especialidadesProfesional,
  professionalModel,
  handleCancel,
  edit,
  errorProfessionalsDuplicated,
}) => {
  const classes = useStyles();

  const professionals = useSelector((state) => state.forms.professionals);

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      <DialogTitle>
        {!edit ? "Agregar Profesional" : "Editar Profesional"}
      </DialogTitle>
      {professionals.isFetching || especialidadesProfesional.length === 0 ? (
        <DialogContent className={classes.loading}>
          <CircularLoading />
        </DialogContent>
      ) : (
        <Formik
          initialValues={professionalModel}
          validationSchema={() => validation()}
          onSubmit={(values) => handleSubmit(values)}>
          {() => (
            <Form>
              <DialogContent>
                <div className={classes.rowContainer}>
                  <FormikTextField
                    name="profesionalApellidoNombre"
                    label="Profesional *"
                    type="text"
                    className={classes.input}
                    disabled={edit}
                  />
                  <FormikTextField
                    name="matricula"
                    label="Matrícula *"
                    type="text"
                    className={classes.input}
                  />
                </div>
                <div className={classes.rowContainer}>
                  <FormikAutocomplete
                    name="especialidad"
                    label="Especialidad *"
                    options={especialidadesProfesional}
                    className={classes.input}
                  />
                </div>
                {errorProfessionalsDuplicated && (
                  <Typography color="error">
                    Ya existe un profesional con este nombre.
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <FormikActions handleCancel={handleCancel} />
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default AddProfessional;
