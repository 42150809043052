import axios from "axios";

export const GET_EMAILS = "GET_EMAILS";
export const GET_EMAIL_CONTENT = "GET_EMAIL_CONTENT";

export const getEmails = (dateFrom, dateTo) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    dispatch({
      type: GET_EMAILS,
      payload: axios
        .get(
          `email?prestadorId=${selectedProvider}&from=${dateFrom}&To=${dateTo}`
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};

export const getEmailContent = (artId, emailId) => (dispatch) => {
  dispatch({
    type: GET_EMAIL_CONTENT,
    payload: axios
      .get(`email/content?artId=${artId}&emailId=${emailId}`)
      .then((response) => ({ data: response.data, emailId }))
      .catch((error) => Promise.reject(error)),
  });
};
