import DateFnsUtils from "@date-io/date-fns";
import { Tooltip, Zoom, makeStyles } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { es } from "date-fns/esm/locale";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import React from "react";
import { localization, useTableStyles } from "./config/tableConfig";
import DetailsTableEmails from "./utilsTable/detailsTableEmails";
import TitleContainer from "./utilsTable/titleWithFetchData";

const useStyles = makeStyles({
  datePicker: {
    marginLeft: 20,
    marginBottom: 20,
  },
  detailsTableEmailsContainer: {
    backgroundColor: "#fafafa",
  },
});

const EmailTableComponent = ({
  data,
  namesArts,
  loading,
  error,
  fetchData,
  title,
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
}) => {
  const classes = useStyles();
  const tableClasses = useTableStyles()();
  const columns = [
    {
      title: "Art",
      field: "art",
      lookup: namesArts,
      filtering: !!namesArts,
      render: (rowData) => rowData.art.toUpperCase(),
    },
    {
      title: "Fecha",
      field: "fechaHoraEnvio",
      customFilterAndSearch: (term, rowData) => {
        if (moment(rowData.fechaHoraEnvio).format("DD/MM/YYYY").includes(term))
          return true;
        return false;
      },
      render: (rowData) => moment(rowData.fechaHoraEnvio).format("DD/MM/YYYY"),
    },
    {
      title: "Siniestro",
      field: "siniestro",
      render: (rowData) => {
        if (rowData.siniestro === 0) return " ";
        return rowData.siniestro;
      },
    },
    { title: "Documento", field: "numeroDocumento" },
    {
      title: "Destinatario",
      field: "destinatario",
      cellStyle: {
        paddingRight: "10px",
      },
    },
    {
      title: "Asunto",
      field: "asunto",
      customFilterAndSearch: (term, rowData) =>
        rowData.asunto.toLowerCase().includes(term.toLowerCase()),
      render: (rowData) => {
        if (rowData.asunto.length > 50) {
          return (
            <Tooltip title={rowData.asunto} TransitionComponent={Zoom} arrow>
              <p>
                {rowData.asunto.substring(0, 50)}
                <b>
                  <i>...[ver más]</i>
                </b>
              </p>
            </Tooltip>
          );
        } else {
          return <p>{rowData.detalle}</p>;
        }
      },
    },
    {
      title: "Tiene Adjunto",
      field: "parsedHasAdjunto",
      lookup: { Si: "Si", No: "No" },
    },
  ];

  return (
    <div className={tableClasses.tablePadding}>
      <MaterialTable
        columns={columns}
        data={data}
        isLoading={loading}
        title={
          <TitleContainer
            title={title}
            fetchData={() => {
              fetchData(dateFrom, dateTo);
            }}
          />
        }
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="dateFrom"
                  label="Desde"
                  value={dateFrom}
                  maxDate={dateTo}
                  onChange={(e) => {
                    const from = moment(e).format("YYYY-MM-DDT00:00:00");
                    setDateFrom(from);
                    fetchData(from, dateTo);
                  }}
                  className={classes.datePicker}
                />
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="dateTo"
                  label="Hasta"
                  value={dateTo}
                  maxDate={Date()}
                  minDate={dateFrom}
                  onChange={(e) => {
                    const to = moment(e).format("YYYY-MM-DDT00:00:00");
                    setDateTo(to);
                    fetchData(dateFrom, to);
                  }}
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>
            </div>
          ),
        }}
        options={{
          actionsColumnIndex: -1,
          headerStyle: localization.headerStyle,
          rowStyle: localization.rowStyle,
          pageSize: 10,
          search: false,
          filtering: true,
        }}
        localization={{
          ...localization,
          body: {
            emptyDataSourceMessage: "No hay correos que mostrar",
            filterRow: {
              filterTooltip: "Filtrar",
            },
          },
        }}
        detailPanel={[
          {
            tooltip: "Contenido",
            render: (rowData) => (
              <div className={classes.detailsTableEmailsContainer}>
                <DetailsTableEmails rowData={rowData} />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default EmailTableComponent;
