import { Card, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getArt, getArtsNotAdhered } from "../../../actions/art";
import TransferList from "../../commons/transferList/transferList";

const useStyles = makeStyles({
  lodash: {
    justifyContent: "center",
    marginTop: "230px",
    textAlign: "center",
  },
  card: {
    minHeight: "88%",
  },
});

const TransferListContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const artsAdhered = useSelector((state) => state.art.listAssignedArts.list);
  const artsNotAdhered = useSelector(
    (state) => state.art.listArtsNotAdhered.list,
  );
  const loading = useSelector((state) => state.art.isFetching);
  const loadingArtsNotAdhered = useSelector(
    (state) => state.art.listArtsNotAdhered.isFetching,
  );
  const userName = useSelector((state) => state.auth.userName);

  useEffect(() => {
    if (!artsAdhered && !loading) dispatch(getArt());
    if (!artsNotAdhered && !loadingArtsNotAdhered)
      dispatch(getArtsNotAdhered());
  }, [artsAdhered, artsNotAdhered]);

  if (loading === true || loadingArtsNotAdhered) {
    return (
      <Card className={classes.card}>
        <div className={classes.lodash}>
          <CircularProgress size={40} />
        </div>
      </Card>
    );
  }

  return (
    <Card className={classes.card}>
      <TransferList
        artsNotAdhered={artsNotAdhered}
        artsAdhered={artsAdhered}
        userName={userName}
      />
    </Card>
  );
};

export default TransferListContainer;
