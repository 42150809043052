import {
  addDays,
  differenceInDays,
  format,
  isAfter,
  isWithinInterval,
  parseISO,
  subDays,
} from "date-fns";
import es from "date-fns/locale/es";
import moment from "moment";

/**
 * Recibe dos fechas y devuelve la fecha con menor
 * valor.
 *
 * @param {string} fechaUno - Formato YYYY-MM-DDTHH:mm:ss
 * @param {string} fechaDos - Formato YYYY-MM-DDTHH:mm:ss
 *
 * @example
 * Recibimos a) {2024-02-15T12:20:27.902Z} y b) {2024-03-15T12:20:27.902Z}
 * y devolvemos b), ya que es la fecha posterior.
 *
 * @returns {string} La fecha con menor valor
 */
export const getFechaMasReciente = (fechaUno, fechaDos) => {
  return isAfter(parseISO(fechaUno), parseISO(fechaDos)) ? fechaDos : fechaUno;
};

/**
 * @param {Date} datetime - La fecha que queremos transformar
 *
 * @example
 * Recibimos {2024-02-15T12:20:27.902Z} y devolvemos "2024-02-15T12:20"
 *
 * @returns {string} - La fecha como string en formato YYYY-MM-DDTHH:mm
 */
export function convertDateTimeToNet(datetime) {
  return datetime ? moment(datetime).format("YYYY-MM-DDTHH:mm") : null;
}

/**
 * Valida que la fecha esté dentro de un intervalo dado por el formulario.
 *
 * @param {string} dateToValidate - Formato ISO8601
 * @param {Object} form
 * @param {Object} appointmentsPostForm
 * @param {Object} model
 *
 * @returns {true|false}
 */
export const validateDateRange = (
  dateToValidate,
  form,
  appointmentsPostForm,
  model,
) => {
  const formDate =
    form.fechaPrimeraCuracion ||
    form.fechaAlta ||
    form.fechaAtencion ||
    appointmentsPostForm.data.fechaAlta ||
    appointmentsPostForm.data.fechaPrimeraCuracion ||
    appointmentsPostForm.data.fechaAtencion;

  const appointmentDate = new Date(dateToValidate);
  const cantidadDiasPorParametro =
    model.data.configuration.appointmentRangeParameter;

  const mayorFechaPosible = addDays(formDate, cantidadDiasPorParametro);
  const menorFechaPosible = subDays(formDate, cantidadDiasPorParametro);

  return isWithinInterval(appointmentDate, {
    start: menorFechaPosible,
    end: mayorFechaPosible,
  });
};

/**
 * Calcula la diferencia en días entre la fecha de atención
 * y la fecha de primera curación. Si la diferencia en días
 * es negativa, devuelve 0 ya que no hubo dias de baja.
 *
 * @param {Date} fechaAtencion
 * @param {Date} fechaPrimeraCuracion
 *
 * @returns {number} - La cantidad **positiva** de dias de diferencia, o 0
 */
export const getCantidadDiasBajaMedica = (
  fechaAtencion,
  fechaPrimeraCuracion,
) => {
  if (typeof fechaAtencion === "string") {
    fechaAtencion = parseISO(fechaAtencion);
  }

  if (typeof fechaPrimeraCuracion === "string") {
    fechaPrimeraCuracion = parseISO(fechaPrimeraCuracion);
  }

  const diferenciaEnDias = differenceInDays(
    fechaAtencion,
    fechaPrimeraCuracion,
  );
  // no queremos retornar valores negativos
  return diferenciaEnDias > 0 ? diferenciaEnDias : 0;
};

/**
 * Función encargada de realizar la lógica de formato para
 * la exportación de fechas. A veces vienen vacias, como null
 * o redactadas. En vez de realizar esto dentro de cada función
 * de exportación, se creo esta función.
 *
 * @param {Date|string} fecha - Fecha en cualquier formato
 * @param {string} formato - Identificador de date-fns ("P", "dd/MM/yyyy").
 * Para ver la especificacion completa: https://date-fns.org/v3.3.1/docs/format
 *
 * @example
 * Si usamos getFormatoFechaExportacion("2024-08-03T10:00:00", "DD/MM/YY"),
 * recibimos {08/03/2024}.
 *
 * @returns {Date|string}
 */
export function getFormatoFechaExportacion(fecha, formato) {
  if (fecha === null || fecha === "") {
    return "";
  }

  const informacionSensible = fecha === "---";
  if (informacionSensible) {
    return "---";
  }

  return format(parseISO(fecha), formato, { locale: es });
}
