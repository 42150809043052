import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProviders } from "../../../actions/providers";
import {
  addProviderInUser,
  deleteProviderInUser,
} from "../../../actions/users";
import ConfirmDeleteDialog from "../../commons/dialog/confirmDeleteDialog";
import EditProviderInUserDialog from "../../commons/dialog/editProviderInUserDialog";
import ProviderInUserTable from "../../commons/table/providerInUserTable";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: "10px 30px",
    },
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const initialProvider = {
  id: "",
};

const ProviderInUser = ({ user, refresh }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const providers = useSelector((state) => state.providers.listProviders);
  const token = useSelector((state) => state.auth.token);
  const esSoporte = useSelector(
    (state) => state.auth.roles?.tipo === "Soporte",
  );

  const [openEdit, setOpenEdit] = useState(false);
  const [provider, setProvider] = useState(initialProvider);
  const [addingNewProvider, setAddingNewProvider] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenEdit = (providerSelected) => {
    if (providerSelected !== null) {
      setProvider(providerSelected);
      setAddingNewProvider(false);
    } else {
      dispatch(getProviders(token));
    }
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setProvider(initialProvider);
    setOpenEdit(false);
    setAddingNewProvider(true);
  };

  const handleDelete = () => {
    dispatch(deleteProviderInUser(provider.id, user.id)).then(() =>
      enqueueSnackbar(<p>Se ha eliminado el prestador correctamente!</p>, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      }),
    );
    handleCloseDelete();
  };

  const handleOpenDelete = (providerSelected) => {
    setProvider(providerSelected);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setProvider(initialProvider);
    setOpenDelete(false);
  };

  const handleSubmit = (provider, isNew) => {
    dispatch(addProviderInUser(provider.id, user.id))
      .then(() => refresh())
      .then(() =>
        enqueueSnackbar(
          <p>
            {isNew
              ? "Se ha asociado el prestador correctamente!"
              : "Se ha modificado el prestador correctamente!"}
          </p>,
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
        ),
      );
    handleCloseEdit();
  };

  return (
    <div>
      <ProviderInUserTable
        data={user.prestadores}
        handleOpenEdit={handleOpenEdit}
        handleDelete={handleOpenDelete}
      />

      {!esSoporte && (
        <div className={classes.root}>
          <Fab
            onClick={() => handleOpenEdit(null)}
            color="primary"
            aria-label="add">
            <AddIcon />
          </Fab>
        </div>
      )}

      <EditProviderInUserDialog
        provider={provider}
        providersInUser={user.prestadores}
        providers={providers.list}
        open={openEdit}
        handleClose={handleCloseEdit}
        addingNewProvider={addingNewProvider}
        handleSubmit={handleSubmit}
        loading={providers.isFetching}
      />
      <ConfirmDeleteDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
        title="Eliminar prestador"
        text={`¿Está seguro que desea eliminar al prestador ${provider.nombre}?`}
      />
    </div>
  );
};

export default ProviderInUser;
