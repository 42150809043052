import { DOWNLOAD_IET_FILE, POST_IMPORT_IET } from "../actions/importForms";

const INITIAL_STATE = {
  postImportIet: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
};

const importForm = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${POST_IMPORT_IET}_REJECTED`:
      return {
        ...state,
        postImportIet: {
          ...state.postImportIet,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${POST_IMPORT_IET}_PENDING`:
      return {
        ...state,
        postImportIet: {
          ...state.postImportIet,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${POST_IMPORT_IET}_FULFILLED`: {
      return {
        ...state,
        postImportIet: {
          ...state.postImportIet,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    case `${DOWNLOAD_IET_FILE}_REJECTED`:
      return {
        ...state,
        postImportIet: {
          ...state.postImportIet,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${DOWNLOAD_IET_FILE}_PENDING`:
      return {
        ...state,
        postImportIet: {
          ...state.postImportIet,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${DOWNLOAD_IET_FILE}_FULFILLED`: {
      return {
        ...state,
        postImportIet: {
          ...state.postImportIet,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default importForm;
