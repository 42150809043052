import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalidades } from "../../../../actions/forms";
import { removeAccentMarks, stringToTitleCase } from '../../../../utils/util';
import GooglePlacesAutocomplete from "../../../commons/formComponents/GooglePlacesAutocomplete";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikTextField from "../../../commons/formik/formikTextField";
import FormSectionDivider from "../formSectionDivider";
import { useStyles } from "../formStyle";


const DireccionFpt = ({ isDesde, values, fields, disabled, setFieldValue, idArt, direccionesHabituales, setDireccionesHabitualesOtro, initialValues }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const listaLocalidadesRedux = useSelector((state) => state.forms.localidades);

    const donde = isDesde ? "Desde" : "Hasta";
    const callesCapitalFederal = fields[`calle${donde}`].options;

    // Cambia si habilitar o no codigo postal. Deberia solo habilitarse si no se sabe el codigo postal.
    const nuevoEstadoCodigoPostal = (calle, numero, localidad) => {
        if (localidad && localidad == "Capital Federal" && calle && numero) {
            const direccionEncontrada = callesCapitalFederal.find((c) => (c.nombre == calle && c.numeroDesde <= numero && numero <= c.numeroHasta));
            if (direccionEncontrada) {
                return false;
            } else {
                return true;
            }
        } else {
            return false
        }

    }
    const [estadoCodigoPostal, setEstadoCodigoPostal] = useState(nuevoEstadoCodigoPostal(initialValues[`calle${donde}`], initialValues[`numero${donde}`], initialValues[`localidad${donde}`]));


    const esCapitalFederal = values[`provincia${donde}`] == "Capital Federal";

    const opcionesCalles = (callesSeleccionadas) => {
        const calles = {};

        // Evitamos repetir calles en las opciones
        callesCapitalFederal.forEach((calle) => {
            if (!calles[calle.nombre]) {
                calles[calle.nombre] = { id: calle.nombre, nombre: calle.nombre }
            }
        })
        const callesActuales = callesSeleccionadas.map((calle) => ({ id: calle, nombre: calle })).filter((c) => c.id != "");
        const ret = [
            ...Object.keys(calles).map((calle) => (calles[calle])),
            ...callesActuales
        ]
        return ret;
    }
    // Siguiente calle guarda el valor de la calle hasta que se obtengan las nuevas calles (ya que asignar valor antes de opcion deja vacio el componente)
    const [siguienteCalle, setSiguienteCalle] = useState(null);
    const [opcionesCallesCapitalFederal, setOpcionesCallesCapitalFederal] = useState(
        opcionesCalles([
            values[`calle${donde}`],
            values[`entreCalle${donde}`],
            values[`entreCalle${donde}1`]
        ])
    );

    const opcionesLocalidad = () => {
        const opcionesLocalidad = listaLocalidadesRedux[donde.toLowerCase()].data.map((lugar) => {
            return {
                id: lugar.localidad,
                nombre: lugar.localidad,
            }
        })
        return opcionesLocalidad;
    }
    // Siguiente localidad guarda el valor de la localidad hasta que se obtengan nuevas localidades (ya que se deben buscar antes las opciones) Necesario para direcciones habituales/googlemaps
    const [siguienteLocalidad, setSiguienteLocalidad] = useState(null);
    const [localidades, setLocalidades] = useState(opcionesLocalidad());

    useEffect(() => {
        setOpcionesCallesCapitalFederal(opcionesCalles(
            [
                siguienteCalle ? siguienteCalle : values[`calle${donde}`],
                values[`entreCalle${donde}`],
                values[`entreCalle${donde}1`]
            ]
        ));
    }, [callesCapitalFederal, siguienteCalle])

    // Caso direcciones habituales y googlePlacesAutocomplete. Esperar opciones antes de cambiar valor 
    useEffect(() => {
        if (siguienteCalle !== null) {
            setFieldValue(`calle${donde}`, siguienteCalle);
            // Actualizar estado codigo postal
            setSiguienteLocalidad(null);
        }
    }, [opcionesCallesCapitalFederal])

    useEffect(() => {
        setLocalidades(opcionesLocalidad());
    }, [listaLocalidadesRedux[donde.toLowerCase()].data])

    // Caso direcciones habituales y googlePlacesAutocomplete. Esperar opciones antes de cambiar valor
    useEffect(() => {
        if (siguienteLocalidad !== null) {
            if (localidades.find((l) => l.id == siguienteLocalidad)) {
                setFieldValue(`localidad${donde}`, siguienteLocalidad);
            } else {
                setFieldValue(`localidad${donde}`, initialValues[`localidad${donde}`]);
            }
            setSiguienteLocalidad(null);
        }
    }, [localidades])

    // Actualizar codigo postal
    useEffect(() => {
        const localidad = values[`localidad${donde}`];
        const calle = values[`calle${donde}`];
        const numero = values[`numero${donde}`];

        setEstadoCodigoPostal(nuevoEstadoCodigoPostal(calle, numero, localidad));
        if (localidad) {
            if (localidad != "Capital Federal") {
                const localidadProvincia = listaLocalidadesRedux[donde.toLowerCase()].data.find((p) => p.localidad == localidad);
                if (localidadProvincia) {
                    setFieldValue(`codigoPostal${donde}`, localidadProvincia.codigoPostal, false);
                } else {
                    setFieldValue(`codigoPostal${donde}`, initialValues[`codigoPostal${donde}`], false)
                }
            } else {
                const direccionEncontrada = callesCapitalFederal.find((c) => (c.nombre == calle && c.numeroDesde <= numero && numero <= c.numeroHasta));
                if (direccionEncontrada) {
                    setFieldValue(`codigoPostal${donde}`, direccionEncontrada.codigoPostal);
                } else {
                    setFieldValue(`codigoPostal${donde}`, initialValues[`codigoPostal${donde}`])
                }

            }
        }

    }, [values[`localidad${donde}`], values[`calle${donde}`], values[`numero${donde}`], listaLocalidadesRedux.isFetching])

    const setDireccionValue = (field, value) => {
        setFieldValue(field, value ? value : "");
    };




    const setDireccion = (value) => {
        if (Object.keys(value).length != 0) {
            const localidad = removeAccentMarks(stringToTitleCase(value.localidad || ""));
            const provincia = removeAccentMarks(stringToTitleCase(value.provincia || ""));
            // Caso direccion guardada previamente localidad = Capital Federal y caso busqueda google
            if (localidad == "Capital Federal" || provincia == "Ciudad Autonoma De Buenos Aires") {
                const capital = "Capital Federal"
                setDireccionValue(`provincia${donde}`, capital);
                setDireccionValue(`localidad${donde}`, capital);
                // Si tengo calles no las busco
                setSiguienteCalle(value.calle);
                setDireccionValue(`numero${donde}`, value.numero);

            }
            else {
                // Caso otra provincia
                if (provincia != `provincia${donde}`) {
                    // Caso provincia esta en la lista
                    if (fields[`provincia${donde}`].options.find((p) => provincia == p.id)) {
                        setDireccionValue(`provincia${donde}`, provincia);
                        setSiguienteLocalidad(localidad);
                        dispatch(getLocalidades(idArt, provincia, isDesde))
                    } else {
                        setFieldValue(`provincia${donde}`, initialValues[`provincia${donde}`]);
                        setFieldValue(`localidad${donde}`, initialValues[`localidad${donde}`]);
                    }


                } else {
                    setDireccionValue(`provincia${donde}`, provincia);
                    setDireccionValue(`localidad${donde}`, localidad);

                }
                setDireccionValue(`calle${donde}`, value.calle);
                setDireccionValue(`numero${donde}`, value.numero);
            }

            setDireccionValue(`codigoPostal${donde}`, value.codigoPostal);
            setDireccionValue(
                setFieldValue,
                `tipoDireccion${donde}`,
                value.tipoDireccion,
            );
        } else {
            setFieldValue(`provincia${donde}`, "");
            onProvinceChange("");
        }

    };




    const onLocalidadChange = (value) => {
        // Reiniciar los campos dependientes de localidad
        setFieldValue(`calle${donde}`, "");
        setFieldValue(`numero${donde}`, "");
        setFieldValue(`piso${donde}`, "");
        setFieldValue(`departamento${donde}`, "");
        setFieldValue(`entreCalle${donde}`, "");
        setFieldValue(`entreCalle${donde}1`, "");

        setEstadoCodigoPostal(false);
    };

    const onProvinceChange = (value) => {
        onLocalidadChange(null);
        if (value?.id == "Capital Federal") {
            setFieldValue(`localidad${donde}`, "Capital Federal");
        }
        else {
            setLocalidades([]);
            setFieldValue(`localidad${donde}`, "");
        }
    };


    return (
        <>
            <FormSectionDivider title={`Dirección ${donde}`} />
            <div className={classes.rowContainer}>
                <FormikAutocomplete
                    name={`direccionHabitual${donde}`}
                    label="Direcciones habituales"
                    variant="outlined"
                    type="search"
                    autoComplete="off"
                    options={direccionesHabituales}
                    callBack={(value) => {
                        if (value) {
                            setDireccion(value);

                            setDireccionesHabitualesOtro((prevState) =>
                                fields.direccionesHabituales.options.filter(
                                    (d) => d.calle !== value.calle || d.tipoDireccion === 4,
                                ),
                            );
                        } else {
                            setDireccion({});
                            setDireccionesHabitualesOtro([
                                ...fields.direccionesHabituales.options,
                            ]);
                        }
                    }}
                    className={classes.input}
                    returnProp="calle"
                    renderOption={(option) => {
                        if (option.tipoDireccion === 4) {
                            return "Otras";
                        } else {
                            return `${option.calle ? option.calle : ""} ${option.numero ? option.numero : ""
                                } - ${option.localidad ? option.localidad : ""}, ${option.provincia ? option.provincia : ""
                                }`;
                        }
                    }}
                    filterOptions={(option) =>
                        option.calle +
                        option.numero +
                        option.localidad +
                        option.provincia
                    }
                    labelOption={(option) => {
                        if (option.tipoDireccion === 4) {
                            return "Otras";
                        } else {
                            return `${option.calle ? option.calle : ""} ${option.numero ? option.numero : ""
                                } - ${option.localidad ? option.localidad : ""}, ${option.provincia ? option.provincia : ""
                                }`;
                        }
                    }}
                    disabled={disabled}
                />
            </div>
            <div className={classes.rowContainer}>
                <GooglePlacesAutocomplete
                    handleSubmit={(value) =>
                        setDireccion(value)
                    }
                    label="Buscar una dirección para autocompletar los campos"
                    variant="outlined"
                    type="search"
                    autoComplete="off"
                    fullWidth
                    disabled={disabled}
                />
            </div>


            <div className={classes.rowContainer}>
                <div className={classes.leftColumnContainer}>
                    <FormikAutocomplete
                        name={`provincia${donde}`}
                        label={`Provincia ${fields[`provincia${donde}`].required ? "*" : ""
                            }`}
                        type="text"
                        variant="outlined"
                        options={fields[`provincia${donde}`].options}
                        className={classes.input}
                        disabled={disabled}
                        callBack={(value) => {
                            onProvinceChange(value, values);
                        }}
                    />

                </div>
                <div className={classes.rightColumnContainer}>
                    {
                        values[`provincia${donde}`] != "Capital Federal" ?
                            <FormikAutocomplete
                                name={`localidad${donde}`}
                                label={`Localidad ${fields[`localidad${donde}`].required ? "*" : ""
                                    }`}
                                type="text"
                                variant="outlined"
                                className={classes.input}
                                options={localidades}
                                disabled={disabled || !values[`provincia${donde}`]}
                                callBack={(value) => {
                                    onLocalidadChange(value, values);
                                }}
                                getOptions={() => {
                                    dispatch(getLocalidades(idArt, values[`provincia${donde}`], isDesde));
                                }}
                            />
                            :
                            <FormikTextField
                                name={`localidad${donde}`}
                                label={`Localidad ${fields[`localidad${donde}`].required ? "*" : ""
                                    }`}
                                type="text"
                                variant="outlined"
                                className={classes.input}
                                disabled={true}
                                callBack={(value) => {
                                    onLocalidadChange(value, values);
                                }}
                            />
                    }
                </div>
            </div>

            <div className={classes.rowContainer}>
                <div className={classes.leftColumnContainer}>

                    {
                        esCapitalFederal ?
                            <FormikAutocomplete
                                name={`calle${donde}`}
                                label={`Calle ${fields[`calle${donde}`].required ? "*" : ""}`}
                                type="text"
                                variant="outlined"
                                className={classes.input}
                                disabled={disabled || !values[`localidad${donde}`]}
                                options={opcionesCallesCapitalFederal}
                                freeSolo
                            />
                            :
                            <FormikTextField
                                name={`calle${donde}`}
                                label={`Calle ${fields[`calle${donde}`].required ? "*" : ""}`}
                                type="text"
                                variant="outlined"
                                className={classes.input}
                                disabled={disabled || !values[`localidad${donde}`]}
                            />

                    }

                </div>
                <div className={classes.rightColumnContainer}>
                    <FormikTextField
                        name={`numero${donde}`}
                        label={`Número ${fields[`numero${donde}`].required ? "*" : ""}`}
                        type="number"
                        variant="outlined"
                        className={classes.input}
                        disabled={disabled || !values[`localidad${donde}`]}
                    />
                </div>
            </div>
            <div className={classes.rowContainer}>
                <div className={classes.leftColumnContainer}>
                    <FormikTextField
                        name={`piso${donde}`}
                        label={`Piso ${fields[`piso${donde}`].required ? "*" : ""}`}
                        type="text"
                        variant="outlined"
                        className={classes.input}
                        disabled={disabled || !values[`localidad${donde}`]}
                    />
                </div>
                <div className={classes.rightColumnContainer}>
                    <FormikTextField
                        name={`departamento${donde}`}
                        label={`Departamento ${fields[`departamento${donde}`].required ? "*" : ""
                            }`}
                        type="text"
                        variant="outlined"
                        className={classes.input}
                        disabled={disabled || !values[`localidad${donde}`]}
                    />
                </div>
            </div>
            <div className={classes.rowContainer}>
                <div className={classes.leftColumnContainer}>
                    {
                        esCapitalFederal ?
                            <FormikAutocomplete
                                name={`entreCalle${donde}`}
                                label={`Entre calle ${fields[`entreCalle${donde}`].required ? "*" : ""
                                    }`}
                                type="text"
                                variant="outlined"
                                className={classes.input}
                                disabled={disabled || !values[`localidad${donde}`]}
                                options={opcionesCallesCapitalFederal}
                                freeSolo
                            />
                            :
                            <FormikTextField
                                name={`entreCalle${donde}`}
                                label={`Entre calle ${fields[`entreCalle${donde}`].required ? "*" : ""
                                    }`}
                                type="text"
                                variant="outlined"
                                className={classes.input}
                                disabled={disabled || !values[`localidad${donde}`]}
                            />

                    }
                </div>
                <div className={classes.rightColumnContainer}>

                    {
                        esCapitalFederal ?
                            <FormikAutocomplete
                                name={`entreCalle${donde}1`}
                                label={`Y calle ${fields[`entreCalle${donde}1`].required ? "*" : ""
                                    }`}
                                type="text"
                                variant="outlined"
                                className={classes.input}
                                disabled={disabled || !values[`localidad${donde}`]}
                                options={opcionesCallesCapitalFederal}
                                freeSolo
                            />
                            :
                            <FormikTextField
                                name={`entreCalle${donde}1`}
                                label={`Y calle ${fields[[`entreCalle${donde}1`]].required ? "*" : ""
                                    }`}
                                type="text"
                                variant="outlined"
                                className={classes.input}
                                disabled={disabled || !values[`localidad${donde}`]}
                            />

                    }
                </div>
            </div>

            <div className={classes.rowContainer}>
                <div className={classes.leftColumnContainer}>
                    <FormikTextField
                        name={`codigoPostal${donde}`}
                        label="Código postal *"
                        type="number"
                        variant="outlined"
                        className={classes.input}
                        disabled={disabled || !estadoCodigoPostal}
                    />
                </div>
                <div className={classes.rightColumnContainer}>
                    <FormikTextField
                        name={`telefonos${donde}`}
                        label={`Teléfono ${fields[`telefonos${donde}`].required ? "*" : ""
                            }`}
                        type="text"
                        variant="outlined"
                        className={classes.input}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    )
}


export default DireccionFpt;