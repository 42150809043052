import axios from "axios";

export const GET_ARTS = "GET_ARTS";
export const POST_ART = "POST_ART";
export const PUT_ART = "PUT_ART";
export const DELETE_ART = "DELETE_ART";
export const CLEAR_SHOW_ERROR_MESSAGE = "CLEAR_SHOW_ERROR_MESSAGE";
export const GET_ART_PRESTADORES = "GET_ART_PRESTADORES";

export const getArts = () => (dispatch) => {
  dispatch({
    type: GET_ARTS,
    payload: axios
      .get(`workriskinsurance`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => Promise.reject(error)),
  });
};

export const postArt = (art) => (dispatch) => {
  dispatch({
    type: POST_ART,
    payload: axios
      .post(`workriskinsurance`, art)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => Promise.reject(error)),
  });
};

export const putArt = (art) => (dispatch) => {
  dispatch({
    type: PUT_ART,
    payload: axios
      .put(`workriskinsurance`, art)
      .then(() => art)
      .catch((error) => Promise.reject(error)),
  });
};

export const deleteArt = (id) => (dispatch) => {
  dispatch({
    type: DELETE_ART,
    payload: axios
      .delete(`workriskinsurance/${id}`)
      .then((response) => {
        return { data: { ...response.data, id } };
      })
      .catch((error) => Promise.reject(error)),
  }).then((response) => {
    if (response === `${DELETE_ART}_FULFILLED`) dispatch(getArts());
  });
};

export const getArtPrestadores = () => (dispatch) => {
  dispatch({
    type: GET_ART_PRESTADORES,
    payload: axios
      .get("workriskinsurance/providers")
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => Promise.reject(error)),
  });
};
