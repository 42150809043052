import { Button, Paper, Typography, makeStyles } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { format } from "date-fns";
import React from "react";
import { downloadFile } from "../../../../../utils/filesUtils";
import FeatureLabel from "../../../../commons/labels/FeatureLabel";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
  paper: {
    margin: "40px auto",
    padding: 40,
  },
  typographyContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 15,
  },
  exitButton: { color: "red", border: "1px solid red", marginLeft: 10 },
}));

const ImportIetError = ({ handleExit, handleReset, file }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div>
        <div className={classes.typographyContainer}>
          <Typography variant="h6">Errores de Importación</Typography>
        </div>
        {file ? (
          <FeatureLabel
            labelText="REGISTROS IET A CORREGIR"
            icon={<GetApp />}
            onClick={() =>
              downloadFile(
                file,
                `Registros Iet con Errores ${format(
                  new Date(),
                  "dd-MM-yyyy",
                )}.csv`,
              )
            }
            tooltipText="Descargar archivo"
          />
        ) : null}
        <div className={classes.buttonContainer}>
          <Button variant="contained" color="primary" onClick={handleReset}>
            Reintentar
          </Button>
          <Button
            className={classes.exitButton}
            variant="outlined"
            onClick={handleExit}>
            Salir
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default ImportIetError;
