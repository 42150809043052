import { TextField } from "@material-ui/core";
import React from "react";
import { useField } from "formik";

const FormikTextField = (props) => {
  const [field, meta] = useField(props);

  return (
    <TextField
      {...props}
      {...field}
      error={meta.touched && meta.error ? true : false}
      helperText={
        props.helperText
          ? props.helperText
          : meta.touched && meta.error
            ? meta.error
            : null
      }
    />
  );
};

export default FormikTextField;