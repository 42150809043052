import axios from "axios";

export const GET_CONFIDENTIAL_FIELDS = "GET_CONFIDENTIAL_FIELDS";

export const getConfidentialFields = (form) => (dispatch, getState) => {
  const { selectedProvider } = getState().providers;

  if (selectedProvider) {
    return dispatch({
      type: GET_CONFIDENTIAL_FIELDS,
      payload: axios
        .get(`confidentialField/${selectedProvider}?form=${form}`)
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  }
};
