import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 10,
  },
  marginRight: {
    marginRight: 10,
  },
}));

const FormikActions = ({
  handleCancel,
  submitText,
  cancelText,
  thirdButtonText,
  disabled,
  hasThirdButton,
  handleThirdButton,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        onClick={handleCancel}
        color="primary"
        type="button"
        className={classes.marginRight}>
        {cancelText ? cancelText : "Cancelar"}
      </Button>
      <Button
        className={classes.marginRight}
        color="primary"
        type="submit"
        variant="contained"
        disabled={disabled}>
        {submitText ? submitText : "Aceptar"}
      </Button>
      {hasThirdButton && (
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={disabled}
          onClick={handleThirdButton}>
          {thirdButtonText}
        </Button>
      )}
    </div>
  );
};

export default FormikActions;
