import { makeStyles } from "@material-ui/core";
import React from "react";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";

const useStyles = makeStyles({
  internmentInput: {
    width: "45%",
    marginLeft: 5,
  },
  internment: {
    display: "flex",
  },
});

const InternmentContainer = ({ fields, ietModel, representa }) => {
  const classes = useStyles();
  const evolucionInternacion = representa === "Evolución Internación";
  const disabledFechaProbableDeAlta =
    representa === "Externación" ||
    (evolucionInternacion && ietModel.fechaProbableDeAlta);

  return (
    <div className={classes.internment}>
      <FormikDateTimePicker
        name="fechaProbableDeAlta"
        inputVariant="outlined"
        value={ietModel.fechaProbableDeAlta}
        label={`Fecha Probable de Alta ${
          fields.fechaProbableDeAlta.required ? "*" : ""
        }`}
        disabled={disabledFechaProbableDeAlta}
        className={classes.internmentInput}
        autoComplete="off"
      />
      {evolucionInternacion && (
        <FormikDateTimePicker
          name="fechaProrrogaAlta"
          inputVariant="outlined"
          label={`Fecha Prórroga Alta ${
            fields.fechaProrrogaAlta.required ? "*" : ""
          }`}
          className={classes.internmentInput}
          autoComplete="off"
        />
      )}
    </div>
  );
};

export default InternmentContainer;
