import React, { useEffect, useState } from "react";
import { useSpeechRecognition } from "react-speech-recognition";
import { makeStyles, TextField } from "@material-ui/core";
import SpeechToText from "./speechToText";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const TextFieldSpeech = (props) => {
  const classes = useStyles();
  const { finalTranscript, transcript, listening } = useSpeechRecognition();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (finalTranscript && isSelected) {
      props.onChange(finalTranscript + " ", true, props.id);
      setIsSelected(false);
    }
  }, [finalTranscript]);

  return (
    <div
      className={
        props.className
          ? `${classes.container} ${props.className}`
          : classes.container
      }
    >
      <TextField
        {...props}
        onChange={(event) => {
          props.onChange(event.target.value, false, props.id);
        }}
      />
      <SpeechToText
        listening={listening && isSelected}
        setIsSelected={setIsSelected}
        transcript={transcript}
        disabled={props.disabled}
      />
    </div>
  );
};
export default TextFieldSpeech;
