import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TutorialPanel from "../../commons/tutorialPanel/tutorialPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontSize: "large",
    fontFamily: "Roboto, sans-serif",
    letterSpacing: "0.0075em",
    color: "#484848",
    marginLeft: 5,
  },
  title: {
    fontWeight: 500,
    marginBottom: 0,
    marginTop: 0,
  },
  subtitle: {
    marginTop: 3,
  },
  sectionTitle: {
    fontWeight: 500,
    marginBottom: 10,
    marginTop: 40,
  },
}));

export default function TutorialsContainer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Guías de Usuario</Typography>
      <Typography className={classes.subtitle} variant="body2">
        En esta sección encontrarás guías sobre diferentes funcionalidades del
        sistema.
      </Typography>
      <div className={classes.sectionTitle}>
        <span role="img" aria-label="usuario">
          🙍‍♂️
        </span>{" "}
        Usuario
      </div>
      <TutorialPanel
        videoId="0FqE89N5iss"
        title="Inicio y Cierre de Sesión"
        subtitle="Cómo iniciar o cerrar sesión en el sistema."
        id="InicioCierreSesion"
      />
      <TutorialPanel
        videoId="-W_y9o2Qyys"
        title="Recuperar Contraseña"
        subtitle="Si tiene problemas con su inicio de sesión y no recuerda su
        contraseña, tenga presente recordar esta guía."
        id="pnlRecuperarContraseña"
      />
      <div className={classes.sectionTitle}>
        <span role="img" aria-label="estadisticas-actualidad">
          📈
        </span>{" "}
        Estadísticas de Actualidad
      </div>
      <TutorialPanel
        videoId="1vx5VbF8elo"
        title="Módulo de Inicio"
        subtitle="Muestra estadísticas del momento sobre la gestión de ARTs."
        id="pnlModuloInicio"
      />
      <div className={classes.sectionTitle}>
        <span role="img" aria-label="gestions-arts">
          🏢
        </span>{" "}
        Gestión de ARTs
      </div>
      <TutorialPanel
        videoId="B2DIedNLsbU"
        title='Módulo "Mis ARTs"'
        subtitle="Gestione las ARTs disponibles y asignadas."
        id="pnlModuloARTs"
      />
      <div className={classes.sectionTitle}>
        <span role="img" aria-label="siniestros">
          🤕
        </span>{" "}
        Siniestros
      </div>
      <TutorialPanel
        videoId="-dQLWFgfygg"
        title="Agregar Nuevo Siniestro"
        subtitle="Agregue siniestros para diferentes ARTs de forma rápida y
        sencilla."
        id="pnlAgregarSiniestro"
      />
      <TutorialPanel
        videoId="fKiHO7LxE80"
        title='Siniestros "En Curso"'
        subtitle="Observe y gestione los siniestros que se encuentra en curso."
        id="pnlSiniestrosEnCurso"
      />
      <TutorialPanel
        videoId="MqbcMQlISMU"
        title="Buscar Siniestros"
        subtitle="Realice búsquedas de siniestros para diferentes ARTs."
        id="pnlBuscarSiniestros"
      />
      <div className={classes.sectionTitle}>
        <span role="img" aria-label="formularios">
          📝
        </span>{" "}
        Formularios
      </div>
      <TutorialPanel
        videoId="xQiqK40HTxg"
        title="Informe de Atención Médica Inicial"
        subtitle="Carga de Formulario de Informe de Atención Médica Inicial (IAMI)"
        id="pnlIAMI"
      />
      <TutorialPanel
        videoId="JLRVfhgDkPA"
        title="Evolución y Tratamiento"
        subtitle="Carga de Formulario de Evolución y Tratamiento (IET)"
        id="pnlIET"
      />
      <TutorialPanel
        videoId="8j6Ox6n8N7o"
        title="Pedido de Autorización"
        subtitle="Carga de Formulario de Pedido de Autorización (FPA)"
        id="pnlFPA"
      />
      <TutorialPanel
        videoId="NH-qiVFP85k"
        title="Pedido de Traslado"
        subtitle="Carga de Formulario de Pedido de Traslado (FPT)"
        id="pnlFPT"
      />
      <TutorialPanel
        videoId="2AyF5VTnBpc"
        title="Alta Médica"
        subtitle="Carga de Formulario Alta Médica (AM)"
        id="pnlAM"
      />
      <TutorialPanel
        videoId="kYjAjxnOZx0"
        title="Carga de Turno"
        subtitle="Distintas formas para cargar un turno"
        id="pnlCargaTurno"
      />
      <TutorialPanel
        videoId="IVylGbW8y2U"
        title="Adjuntar un archivo"
        subtitle="Adjuntar un archivo dentro de un formulario"
        id="pnlAdjuntarArchivo"
      />
      <p className={classes.sectionTitle}>
        <span role="img" aria-label="autorizaciones">
          ✒️
        </span>{" "}
        Autorizaciones
      </p>
      <TutorialPanel
        videoId="ycO3DLgNpqo"
        title="Autorizaciones Pendientes"
        subtitle="Gestione y verifique los estados de las autorizaciones ya
        generadas."
        id="pnlAutorizacionesPendientes"
      />
      <TutorialPanel
        videoId="MkHd6CJgT8U"
        title="Autorizaciones Respondidas"
        subtitle="Gestione y verifique los estados de las autorizaciones que se encuentren
        respondidas."
        id="pnlAutorizacionesRespondidas"
      />
      <TutorialPanel
        videoId="gLrZS7WSbPo"
        title="Buscar Autorizaciones"
        subtitle="Realice búsquedas de autorizaciones ingresadas por el prestador
        para cada uno de los siniestros."
        id="pnlBuscarAutorizaciones"
      />
      <p className={classes.sectionTitle}>
        <span role="img" aria-label="turnos">
          🗓️
        </span>{" "}
        Turnos
      </p>
      <TutorialPanel
        videoId="26MtaDVpYdY"
        title="Turnos: Asistencias Pendientes"
        subtitle="Vea los turnos atrasados y que no fueron asistidos por el
        prestador."
        id="pnlTurnosAsistenciasPendientes"
      />
      <TutorialPanel
        videoId="1pc1vK_ryKs"
        title="Buscar Turnos"
        subtitle="Visualice todos los turnos de cualquier ART."
        id="pnlBuscarTurnos"
      />
      <p className={classes.sectionTitle}>
        <span role="img" aria-label="estadisticas">
          📊
        </span>{" "}
        Estadísticas
      </p>
      <TutorialPanel
        videoId="ygTt93gYn8M"
        title="Siniestros por Mes"
        subtitle="Vea la cantidad de ingresos de siniestros por ART en el período
        comprendido."
        id="pnlEstadisticasSiniestrosPorMes"
      />
      <TutorialPanel
        videoId="G0kW6W0vsTY"
        title="Promedio de Frecuencia de Citación"
        subtitle="Vea el promedio de días de citaciones que tiene el prestador en el
        tratamiento de siniestros."
        id="pnlEstadisticasSiniestrosPromedioFrecuenciaCitación"
      />
      <TutorialPanel
        videoId="3ZVElMphQJ4"
        title="Días de Tratamiento por Trismestre, por ART"
        subtitle="Vea la cantidad de días promedio que tiene el prestador en el
        tratamiento de los siniestros para cada ART."
        id="pnlEstadisticasDiasTratamientoTrimestreART"
      />
      <TutorialPanel
        videoId="0s2bkQNLRj8"
        title="Días promedio de Respuestas FPA"
        subtitle="Vea el promedio de días que transcurren entre la realizaición de
        un FPA hacia una ART y su respuesta."
        id="pnlEstadisticasDíasPromedioRespuestasFPA"
      />
      <TutorialPanel
        videoId="ReHlL0K7txg"
        title="Índice de Prevalencia"
        subtitle="Vea la cantidad de ingresos de siniestros sobre las altas
        realizadas en un igual período."
        id="pnlEstadisticasIndicePrevalencia"
      />
      <TutorialPanel
        videoId="FFEmsk37qrw"
        title="Cantidad promedio de Siniestros por ART"
        subtitle="Vea el promedio de atenciones efectuadas por el prestador para el
        tratamiento de los siniestros en cada ART."
        id="pnlEstadisticasCantidadPromedioSiniestrosART"
      />
      <p className={classes.sectionTitle}>
        <span role="img" aria-label="correos">
          📧
        </span>{" "}
        Correos
      </p>
      <TutorialPanel
        videoId="Onr-RAzg_5I"
        title='Módulo "Correos"'
        subtitle="Vea las novedades enviadas via E-mail."
        id="pnlCorreosEnviados"
      />
      <div className={classes.sectionTitle}>
        <span role="img" aria-label="ayuda">
          ❔
        </span>{" "}
        Ayuda
      </div>
      <TutorialPanel
        videoId="eEJ9XqEBIwU"
        title="Soporte para Prestadores"
        subtitle="Carga de consultas"
        id="pnlSoportePrestadores"
      />
    </div>
  );
}
