import { Button, MenuItem, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React from "react";
import FormikAutocomplete from "../formik/formikAutocomplete";
import FormikDatePicker from "../formik/formikDatePicker";
import FormikSelect from "../formik/formikSelect";
import FormikTextField from "../formik/formikTextField";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    padding: 20,
    marginBottom: 20,
  },
  input: {
    display: "inline-flex",
    marginTop: 10,
    marginRight: 20,
    width: 250,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const SearchComponent = ({
  handleSubmit,
  invalid,
  art,
  initialValues,
  estadosOptions,
  validationSchema,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.paper}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <>
              <Form>
                <FormikSelect
                  name="art"
                  label="Art"
                  variant="standard"
                  value={initialValues.art}
                  className={classes.input}>
                  {art.map((art) => {
                    return (
                      <MenuItem value={art.id} key={art.id}>
                        {art.nombre}
                      </MenuItem>
                    );
                  })}
                </FormikSelect>

                <FormikTextField
                  name="siniestro"
                  variant="standard"
                  label="Siniestro"
                  className={classes.input}
                />
                <FormikTextField
                  name="dni"
                  variant="standard"
                  label="DNI"
                  className={classes.input}
                />
                <FormikAutocomplete
                  name="estado"
                  label="Estado"
                  variant="standard"
                  className={classes.input}
                  options={estadosOptions}
                />
                <FormikDatePicker
                  name="fechaDesde"
                  variant="standard"
                  label="Fecha Desde"
                  className={classes.input}
                />
                <FormikDatePicker
                  name="fechaHasta"
                  variant="standard"
                  label="Fecha Hasta"
                  className={classes.input}
                />
              </Form>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  type="submit"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={invalid}>
                  Buscar
                </Button>
              </div>
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default SearchComponent;
