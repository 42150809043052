import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ViewListIcon from "@material-ui/icons/ViewList";
import React from "react";
import { useDispatch } from "react-redux";
import { requestAdhesion } from "../../../actions/art";
import ModalTransferList from "../simpleModal/modalTransferList";
import Snackbar from "../snack/snack";
import { customListLeft, customListRight } from "./customList";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px 0px 27px 0px",
    minWidth: "100%",
    minHeight: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  button: {
    margin: theme.spacing(0.5, 0),
    border: "1px solid black",
  },
  containerLodash: {
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  alert: {
    color: "red",
    fontFamily: "Roboto, sans-serif",
    maxWidth: "150px",
  },
  text: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "medium",
    margin: 0,
    padding: "0px 0px 0px 12px",
    color: "#606060",
    fontWeight: "bold",
    flex: 1,
    backgroundColor: "#f1f1f1",
    marginBottom: 8,
    cursor: "default",
    borderRadius: 2,
    "-webkit-box-shadow": "0px 0px 1px 1px rgba(0,0,0,0.1)",
    "-moz-box-shadow": "0px 0px 1px 1px rgba(0,0,0,0.1)",
    "box-shadow": "0px 0px 1px 1px rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
  },
  adhered: {
    color: "#ffffff",
    backgroundColor: theme.palette.secondary.dark,
  },
  available: {
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontSize: "large",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
    color: "#484848",
    marginLeft: "25px",
    marginBottom: 7,
  },
  icon: {
    marginRight: 10,
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList({
  artsNotAdhered,
  artsAdhered,
  userName,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [checked, setChecked] = React.useState([]);
  const [open, setOpen] = React.useState({ open: false, art: [] });
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackInfo, setSnackInfo] = React.useState({
    variant: "error",
    message: "",
  });

  const leftChecked = intersection(checked, artsNotAdhered);

  const handleOpen = (art) => {
    setOpen({ open: true, art });
  };

  const handleClose = () => {
    setOpen({ open: false, art: [] });
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    handleOpen(checked[0].Name);
  };

  const handleConfirmed = () => {
    dispatch(requestAdhesion(leftChecked[0]))
      .then(() => {
        setChecked(not(checked, leftChecked));
        setOpen({ open: false, art: [] });
        setSnackInfo({
          variant: "success",
          message:
            "Proceso de adhesión iniciado correctamente. Nos esteremos contactando con usted a la brevedad.",
        });
        setOpenSnack(true);
      })
      .catch(() => {
        handleClose();
        setSnackInfo({
          variant: "error",
          message: "El proceso de adhesión no se inicio correctamente.",
        });
        setOpenSnack(true);
      });
  };

  return (
    <div>
      <p className={classes.title}>Mis ARTs</p>
      <Grid container spacing={2} alignItems="center" className={classes.root}>
        <Grid item>
          <div className={`${classes.text} ${classes.available}`}>
            <Icon className={classes.icon}>
              <ViewListIcon />
            </Icon>
            <p>DISPONIBLES</p>
          </div>
          {customListLeft(artsNotAdhered, checked, leftChecked, handleToggle)}
        </Grid>

        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => {
                handleCheckedRight();
              }}
              disabled={leftChecked.length !== 1}
              aria-label="move selected right">
              &gt;
            </Button>
            {leftChecked.length > 1 && (
              <p className={classes.alert}>
                No se permite seleccionar mas de una ART.
              </p>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <div className={`${classes.text} ${classes.adhered}`}>
            <Icon className={classes.icon}>
              <PlaylistAddCheckIcon />
            </Icon>
            <p>ASIGNADAS</p>
          </div>
          {customListRight(artsAdhered)}
        </Grid>
      </Grid>
      <Snackbar
        open={openSnack}
        handleClose={() => setOpenSnack(false)}
        variant={snackInfo.variant}
        message={snackInfo.message}
      />
      <div>
        {open.open && (
          <ModalTransferList
            open={open.open}
            art={open.art}
            handleClose={handleClose}
            handleConfirmed={handleConfirmed}
          />
        )}
      </div>
    </div>
  );
}
