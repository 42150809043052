import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

const EditArtDialog = ({
  art,
  openEditar,
  handleCloseEditar,
  addingNewArt,
  editArt,
  handleConfirmEditar,
  isLoadingEditArt,
  formErrors,
}) => {
  return (
    <Dialog
      open={openEditar || isLoadingEditArt}
      onClose={handleCloseEditar}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      <DialogTitle>{addingNewArt ? "Agregar ART" : "Editar ART"}</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" fullWidth>
          {art.id && (
            <TextField
              autoFocus
              value={art.id || ""}
              onChange={editArt}
              margin="dense"
              id="id"
              label="Id"
              type="text"
              fullWidth
              disabled
            />
          )}
          <TextField
            value={art.nombre || ""}
            onChange={editArt}
            margin="dense"
            id="nombre"
            label="Razón social"
            type="text"
            fullWidth
            required
            error={!!formErrors.nombre}
            helperText={formErrors.nombre}
            disabled={isLoadingEditArt}
          />
          <TextField
            value={art.nombreCorto || ""}
            onChange={editArt}
            margin="dense"
            id="nombreCorto"
            label="Nombre corto"
            type="text"
            fullWidth
            required
            error={!!formErrors.nombreCorto}
            helperText={formErrors.nombreCorto}
            disabled={isLoadingEditArt}
          />
          <TextField
            value={art.integrationUrl || ""}
            onChange={editArt}
            margin="dense"
            id="integrationUrl"
            label="URL Integration"
            type="text"
            fullWidth
            required
            error={!!formErrors.integrationUrl}
            helperText={formErrors.integrationUrl}
            disabled={isLoadingEditArt}
          />
          <TextField
            value={art.nombreCliente || ""}
            onChange={editArt}
            margin="dense"
            id="nombreCliente"
            label="Nombre Cliente"
            type="text"
            fullWidth
            required
            error={!!formErrors.nombreCliente}
            helperText={formErrors.nombreCliente}
            disabled={isLoadingEditArt}
          />
          <TextField
            value={art.integrationApiKey || ""}
            onChange={editArt}
            margin="dense"
            id="integrationApiKey"
            label="Integration Api Key"
            type="text"
            fullWidth
            required
            error={!!formErrors.integrationApiKey}
            helperText={formErrors.integrationApiKey}
            disabled={isLoadingEditArt}
          />
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={
                <Switch
                  checked={art.enviarFormularios}
                  onChange={editArt}
                  id="enviarFormularios"
                  disabled={isLoadingEditArt}
                />
              }
              label="Envía formularios por mail"
              labelPlacement="start"
              id="enviarFormularios"
            />
          </FormGroup>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={
                <Switch
                  checked={art.enviarTurnos}
                  onChange={editArt}
                  id="enviarTurnos"
                  disabled={isLoadingEditArt}
                />
              }
              label="Envía turnos por mail"
              labelPlacement="start"
              id="enviarTurnos"
            />
          </FormGroup>
          <FormControl
            error={!!formErrors.metodoCargaSiniestros}
            disabled={isLoadingEditArt}
            required>
            <InputLabel id="metodoCargaSiniestros">
              Método de carga de siniestros
            </InputLabel>
            <Select
              id="metodoCargaSiniestros"
              labelId="metodoCargaSiniestros"
              value={art.metodoCargaSiniestros}
              onChange={(event) => editArt(event, "metodoCargaSiniestros")}>
              <MenuItem value="F">Fachada</MenuItem>
              <MenuItem value="M">Manual</MenuItem>
            </Select>
            <FormHelperText>{formErrors.metodoCargaSiniestros}</FormHelperText>
          </FormControl>
          <TextField
            value={art.codigoSrt || ""}
            onChange={editArt}
            margin="dense"
            id="codigoSrt"
            label="Código ante la S.R.T."
            type="text"
            fullWidth
            required
            error={!!formErrors.codigoSrt}
            helperText={formErrors.codigoSrt}
            disabled={isLoadingEditArt}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseEditar}
          color="primary"
          disabled={isLoadingEditArt}>
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmEditar}
          color="primary"
          disabled={isLoadingEditArt}
          variant="contained">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditArtDialog;
