import { makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginBottom: 15,
  },
  card: {
    display: "flex",
    marginTop: 10,
    marginBottom: 5,
  },
  infoContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    marginLeft: 8,
    paddingLeft: 15,
  },
  button: {
    cursor: "pointer",
    marginRight: 15,
  },
  disabledButton: {
    marginRight: 15,
    color: "grey",
  },
}));

const EspecialidadesCard = ({ especialidades, disabled, handleDelete }) => {
  const classes = useStyles();

  const especialidadesState = useSelector(
    (state) => state.forms.specialtiesDiagnosis,
  );

  const getEspecialidad = (id) => {
    const e = especialidadesState.data.find((p) => p.id === id);
    return e ? e.especialidad : undefined;
  };

  const getDiagnostico = (id) => {
    const e = especialidadesState.data.find((p) => p.id === id);
    return e ? e.diagnostico : undefined;
  };

  return (
    <div className={classes.container}>
      {especialidades.map((especialidad) => (
        <div
          className={classes.card}
          key={especialidad.idEspecialidadDiagnosticoOms}>
          <Paper elevation={2} className={classes.infoContainer}>
            <div>
              <Typography>
                <b>Especialidad: </b>
                {getEspecialidad(especialidad.idEspecialidadDiagnosticoOms)}
              </Typography>
              <Typography>
                <b>Diagnóstico: </b>
                {getDiagnostico(especialidad.idEspecialidadDiagnosticoOms)}
              </Typography>
              <div className={classes.rowContainer}>
                <Typography>
                  <b>Mismo centro: </b> {especialidad.turno ? "Si" : "No"}
                </Typography>
                {especialidad.turno && (
                  <Typography>
                    <b>Fecha de turno: </b>
                    {moment(especialidad.turno).format("DD/MM/YYYY")}
                  </Typography>
                )}
              </div>
            </div>
          </Paper>
          <Paper elevation={2} className={classes.actionContainer}>
            <Tooltip
              title="Eliminar"
              className={disabled ? classes.disabledButton : classes.button}
              onClick={() => {
                if (!disabled) {
                  handleDelete(especialidad);
                }
              }}>
              <Clear />
            </Tooltip>
          </Paper>
        </div>
      ))}
    </div>
  );
};

export default EspecialidadesCard;
