import { Button, makeStyles, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    textAlign: "center",
    padding: 10,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: "white",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  actionsContainer: {
    margin: 10,
  },
  text: {
    color: "white",
    fontWeight: "bold",
    textTransform: "upperCase",
  },
  loading: {
    margin: 100,
    marginLeft: 200,
    marginRight: 200,
  },
}));

const FpaAutorizationDialog = ({
  open,
  title,
  handleSubmit,
  codigoAutorizacion,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} disableBackdropClick={true}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.text}>{title}</Typography>
      </DialogTitle>
      <div>
        {codigoAutorizacion ? (
          <DialogContent className={classes.contentContainer}>
            <Typography>
              El Pedido de Autorización realizado ha sido APROBADO con el
              Código:
            </Typography>
            <Typography>
              <strong>{codigoAutorizacion}</strong>
            </Typography>
          </DialogContent>
        ) : (
          <DialogContent className={classes.contentContainer}>
            <Typography>
              El Pedido de Autorización realizado se encuentra en gestión
            </Typography>
          </DialogContent>
        )}
        <DialogActions className={classes.actionsContainer}>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={() => {
              handleSubmit();
            }}>
            Aceptar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
export default FpaAutorizationDialog;
