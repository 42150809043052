import { RESET_PASSWORD, VERIFY_PASSWORD, SET_PASSWORD, CHANGE_PASSWORD } from "../actions/password";

const INITIAL_STATE = {
  resetPassword: {
    isFetching: false,
    didInvalidate: false,
    list: [],
  },
  verifyPassword: {
    isFetching: false,
    didInvalidate: false,
    list: [],
  },
  setPassword: {
    isFetching: false,
    didInvalidate: false,
    list: [],
  },
  changePassword: {
    isFetching: false,
    didInvalidate: false,
    list: [],
  },
};

const password = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${RESET_PASSWORD}_REJECTED`:
      return {
        ...state,
        resetPassword: {
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${RESET_PASSWORD}_PENDING`:
      return {
        ...state,
        resetPassword: {
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${RESET_PASSWORD}_FULFILLED`:
      return {
        ...state,
        resetPassword: {
          isFetching: false,
          didInvalidate: false,
          list: action.payload,
        },
      };

    case `${VERIFY_PASSWORD}_REJECTED`: {
      return {
        ...state,
        verifyPassword: {
          isFetching: false,
          didInvalidate: true,
        },
      };
    }
    case `${VERIFY_PASSWORD}_PENDING`:
      return {
        ...state,
        verifyPassword: {
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${VERIFY_PASSWORD}_FULFILLED`:
      return {
        ...state,
        verifyPassword: {
          isFetching: false,
          didInvalidate: false,
          list: action.payload,
        },
      };

    case `${SET_PASSWORD}_REJECTED`:
      return {
        ...state,
        setPassword: {
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${SET_PASSWORD}_PENDING`:
      return {
        ...state,
        setPassword: {
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${SET_PASSWORD}_FULFILLED`:
      return {
        ...state,
        setPassword: {
          isFetching: false,
          didInvalidate: false,
          list: action.payload,
        },
      };
    case `${CHANGE_PASSWORD}_REJECTED`:
      return {
        ...state,
        changePassword: {
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${CHANGE_PASSWORD}_PENDING`:
      return {
        ...state,
        changePassword: {
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${CHANGE_PASSWORD}_FULFILLED`:
      return {
        ...state,
        changePassword: {
          isFetching: false,
          didInvalidate: false,
          list: action.payload,
        },
      };
    default:
      return state;
  }
};

export default password;
