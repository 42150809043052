import { Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import FormikTextField from "../../../commons/formik/formikTextField";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    padding: 20,
    marginBottom: 20,
  },
  input: {
    display: "inline-flex",
    marginRight: 20,
    width: 250,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const validationSchema = yup.object().shape(
  {
    accident: yup
      .string()
      .matches(/^[\d\/-]*$/, "Solo números, y los caracteres / -")
      .when("workerId", {
        is: (workerId) =>
          workerId === undefined || workerId === null || workerId === "",
        then: () =>
          yup
            .string()
            .matches(/^[\d\/-]*$/, "Solo números, y los caracteres / -")
            .required("Llene por lo menos un campo"),
      })
      .typeError("Ingrese sólo números"),
    workerId: yup
      .string()
      .matches(/^[0-9]*$/, "Ingrese sólo números")
      .max(8, "El documento debe contener 8 dígitos")
      .when("accident", {
        is: (accident) =>
          accident === undefined || accident === null || accident === "",
        then: () =>
          yup
            .string()
            .matches(/^[0-9]*$/, "Ingrese sólo números")
            .max(8, "El documento debe contener 8 dígitos")
            .typeError("Ingrese sólo números")
            .required("Llene por lo menos un campo"),
      })
      .typeError("Ingrese sólo números"),
  },
  ["accident", "workerId"],
);

const initialValues = {
  workerId: "",
  accident: "",
};

const SearchSiniestrosArt = ({ isSearching, handleSearch }) => {
  const classes = useStyles();

  const handleSubmitSearch = (values) => {
    let query = "";

    if (values.accident) query = `${query}accident=${values.accident}`;
    // como los parametros son opcionales, no se puede meter siempre el simbolo "&" porque, de venir
    // el parametro de accidente vacio, la request tira un 400, ya que seria: "url.../accidentsArt/&WorkerId".
    if (values.workerId && values.accident)
      query = `${query}&WorkerId=${values.workerId}`;
    if (values.workerId && !values.accident)
      query = `${query}WorkerId=${values.workerId}`;

    handleSearch(query);
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitSearch}>
          {({ handleSubmit }) => (
            <>
              <Form>
                <FormikTextField
                  name="accident"
                  label="Siniestro"
                  variant="standard"
                  className={classes.input}
                />

                <FormikTextField
                  name="workerId"
                  label="Documento Trabajador"
                  variant="standard"
                  className={classes.input}
                />
              </Form>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  type="submit"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSearching}>
                  Buscar
                </Button>
              </div>
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default SearchSiniestrosArt;
