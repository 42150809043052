import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postSession } from "../../../../../../actions/appointments";
import {
  getFormProfessionalSpecialties
} from "../../../../../../actions/forms";
import { openNewForm } from "../../../../../../actions/formsState";
import { convertDateTimeToNet } from "../../../../../../utils/datetimeUtils";
import snackbarUtils from "../../../../../../utils/snackbarUtils";
import CircularLoading from "../../../../../commons/loadingData/circularLoading";
import AppointmentSessionCalendar from "./appointmentSessionCalendar";
import AppointmentSessionCards from "./appointmentSessionCards";
import AppointmentSessionHeader from "./appointmentSessionHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#f5f5f5",
    flexDirection: "column",
  },
  appointmentsContainer: {
    display: "flex",
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: "white",
    fontWeight: "bold",
    marginLeft: 10,
  },
  closeIcon: {
    color: "white",
    cursor: "pointer",
    width: 25,
    height: 25,
  },
  calendar: {
    width: 1000,
    marginRight: 10,
    boxShadow: "1px 1px 7px rgba(219,219,219,1)",
    borderRadius: "5px 5px 5px 5px",
  },
  tileCalendar: {
    height: 30,
    padding: 0,
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 2,
    backgroundColor: "white",
    paddingRight: 10,
    boxShadow: "0px 0 5px lightGrey",
  },
  loading: {
    marginTop: 60,
    marginBottom: 60,
  },
}));

const AppointmentSession = ({
  open,
  appointment,
  handleClose,
  handleOpenConfirmDialog,
  artId,
  siniestro,
  nroFormulario,
  callBack,
  accident,
  hasFpt,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const session = useSelector((state) => state.appointments.postSession);
  const professionalSpecialties = useSelector(
    (state) => state.forms.professionalSpecialties,
  );

  const [appointments, setAppointments] = useState([]);
  const [disableGuardarSesiones, setDisableGuardarSesiones] = useState(false);

  useEffect(() => {
    setAppointments([{ ...appointment }]);
  }, [appointment]);

  useEffect(() => {
    if (open && artId) {
      dispatch(getFormProfessionalSpecialties(artId));
    }
  }, [artId]);

  const handleAddAppointment = (date) => {
    if (date > appointment.fechaHora) {
      const newAppointment = { ...appointment, fechaHora: date };

      newAppointment.fechaHora.setHours(appointment.fechaHora.getHours());
      newAppointment.fechaHora.setMinutes(appointment.fechaHora.getMinutes());

      if (
        !appointments.find(
          (a) =>
            moment(a.fechaHora).dayOfYear() ===
            moment(newAppointment.fechaHora).dayOfYear(),
        )
      ) {
        setAppointments((prevState) => [...prevState, newAppointment]);
      } else {
        snackbarUtils.error("Ya tiene un turno agregado para esta fecha");
      }
    } else {
      snackbarUtils.error("No es posible agregar un turno para esta fecha");
    }
  };

  const handleDeleteAppointment = (appointment) => {
    setAppointments(
      appointments.filter(
        (a) => a.fechaHora.getTime() !== appointment.fechaHora.getTime(),
      ),
    );
  };

  const setTrasladoAppointment = (appointment) => {
    const appointmentAux = appointments;
    appointmentAux.forEach((e) => {
      if (e.fechaHora.getTime() === appointment.fechaHora.getTime()) {
        e.traslado = !e.traslado;
      }
    });
    setAppointments([...appointmentAux]);
  };

  const setAppointmentHs = (index, time) => {
    if (time != "Invalid Date" && time != null) {
      const appointmentAux = appointments;

      if (appointmentAux[index]) {
        appointmentAux[index].fechaHora = moment(time)
          .date(moment(appointmentAux[index].fechaHora).date())
          .month(moment(appointmentAux[index].fechaHora).month())
          .toDate();
      }

      setAppointments([...appointmentAux]);
    }
  };

  const handleSubmitSession = (shouldOpenFpt) => {
    const model = [];
    const especialidad = professionalSpecialties.data.find(
      (e) => e.id == appointments[0].especialidad,
    );
    appointments.forEach((a) => {
      model.push({
        ...a,
        fechaHora: convertDateTimeToNet(a.fechaHora),
        especialidadNombre: especialidad.nombre,
      });
    });
    dispatch(postSession(model, artId, siniestro, nroFormulario)).then((res) => {
      if (callBack) {
        callBack();
      }
      handleClose();
      snackbarUtils.success("Sesión de turnos creada correctamente");
      if (shouldOpenFpt) {
        dispatch(openNewForm("Fpt", "Formulario de Pedido de Traslado", {
          professionalName: model[0].profesionalApellidoNombre, // Todos tienen el mismo profesional y especialidad
          specialityName: model[0].especialidadNombre,
          appointmentDates: model.filter((e) => e.traslado == true).map((e) => e.fechaHora),
          appointmentsIds: res.value?.data?.turnosArray
        }));
      }
    });
  };

  const handleSetDisableSave = (bool) => {
    setDisableGuardarSesiones(bool);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOpenConfirmDialog}
        maxWidth="md"
        disableBackdropClick={true}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>SESIÓN DE TURNOS</Typography>
          <Tooltip title="Cerrar">
            <Close
              onClick={handleOpenConfirmDialog}
              className={classes.closeIcon}
            />
          </Tooltip>
        </div>
        {session.isFetching ? (
          <div className={classes.loading}>
            <CircularLoading />
          </div>
        ) : (
          <div>
            <DialogContent className={classes.container}>
              <AppointmentSessionHeader
                appointment={appointment}
                accident={accident}
              />
              <div className={classes.appointmentsContainer}>
                <AppointmentSessionCalendar
                  onClick={handleAddAppointment}
                  appointment={appointment}
                />
                <AppointmentSessionCards
                  data={appointments}
                  onDelete={handleDeleteAppointment}
                  setTraslado={setTrasladoAppointment}
                  handleChangeDate={setAppointmentHs}
                  handleSetDisableSave={handleSetDisableSave}
                />
              </div>
            </DialogContent>
            <div className={classes.actionsContainer}>
              <DialogActions>
                <Button onClick={handleOpenConfirmDialog} color="primary">
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    handleSubmitSession(
                      appointments.find((a) => a.traslado == true) && hasFpt,
                    );
                  }}
                  color="primary"
                  variant="contained"
                  disabled={!appointments.length || disableGuardarSesiones}>
                  Guardar Sesión
                </Button>
              </DialogActions>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default AppointmentSession;
