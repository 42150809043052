import {
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  close,
  getAccidents,
  getReasonsClousure,
} from "../../../../actions/accidents";
import snackbarUtils from "../../../../utils/snackbarUtils";
import ConfirmDialog from "../../../commons/dialog/confirmDialog";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextField from "../../../commons/formik/formikTextField";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 20px",
  },
  title: {
    padding: "16px 16px 0px",
  },
  contentContainer: {
    minWidth: "500px",
    minHeight: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "500px",
  },
  formControl: {
    margin: " 10px 0",
  },
  buttonContainer: {
    padding: "30px 0px 0px 10px",
    display: "flex",
    justifyContent: "end",
  },
  button: {
    margin: "0px 10px",
  },
  closeAccidentButton: {
    backgroundColor: "red",
    color: "white",
  },
}));

const validationSchema = Yup.object({
  idMotivoCierre: Yup.number()
    .integer("Identificador inválido")
    .required("Campo obligatorio")
    .min(0, "Identificador inválido"),
  observaciones: Yup.string().max(200, "Puede ingresar hasta 200 caracteres"),
});

const CloseAccident = ({ accident, open, handleClose, invalid }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reasonsClousure = useSelector(
    (state) => state.accidents.reasonsClousure,
  );

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [values, setValues] = useState("");

  const closeConfirm = () => {
    setOpenConfirmDialog(false);
  };

  const openConfirm = () => {
    setOpenConfirmDialog(true);
  };

  const submitConfirm = () => {
    if (values) {
      dispatch(close(accident, values)).then(() => {
        dispatch(getAccidents());
        snackbarUtils.success("Siniestro cerrado exitosamente");
      });
      handleClose();
      closeConfirm();
    }
  };

  const initialValues = {
    idMotivoCierre: "",
    observaciones: "",
  };

  useEffect(() => {
    accident && dispatch(getReasonsClousure(accident));
  }, [accident]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
      className={classes.container}>
      <DialogTitle className={classes.title}>Cerrar Siniestro</DialogTitle>
      <Card className={classes.contentContainer}>
        {reasonsClousure.isFetching ? (
          <CircularProgress size={40} />
        ) : (
          <CardContent>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                if (values) {
                  setValues(values);
                  openConfirm();
                }
              }}>
              <Form className={classes.form}>
                <FormikSelect
                  name="idMotivoCierre"
                  label="Motivo del cierre"
                  variant="standard"
                  value={initialValues.idMotivoCierre}
                  className={classes.formControl}>
                  {reasonsClousure.data.length &&
                    reasonsClousure.data.map((rc) => {
                      return (
                        <MenuItem value={rc.id} key={rc.id}>
                          {rc.descripcion}
                        </MenuItem>
                      );
                    })}
                </FormikSelect>

                <FormikTextField
                  name="observaciones"
                  multiline
                  rows={1}
                  rowsMax={5}
                  variant="standard"
                  label="Observaciones"
                  className={classes.formControl}
                />
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={handleClose}
                    color="primary"
                    className={classes.button}>
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={invalid}
                    className={`${classes.closeAccidentButton} ${classes.button}`}>
                    Cerrar Siniestro
                  </Button>
                </div>
              </Form>
            </Formik>
          </CardContent>
        )}
      </Card>
      <ConfirmDialog
        open={openConfirmDialog}
        title={"¿Está seguro que desea cerrar el Siniestro?"}
        handleClose={closeConfirm}
        handleConfirm={submitConfirm}
      />
    </Dialog>
  );
};

export default CloseAccident;
