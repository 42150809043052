import { APPOINTMENT_SESSION_BASE64 } from "../actions/reports";

const INITIAL_STATE = {
  appointmentSession: {
    isFetching: false,
    didInvalidate: false,
    data: {},
  },
};

const reports = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${APPOINTMENT_SESSION_BASE64}_REJECTED`:
      return {
        ...state,
        appointmentSession: {
          ...state.appointmentSession,
          isFetching: false,
          didInvalidate: true,
        },
      };
    case `${APPOINTMENT_SESSION_BASE64}_PENDING`:
      return {
        ...state,
        appointmentSession: {
          ...state.appointmentSession,
          isFetching: true,
          didInvalidate: false,
        },
      };
    case `${APPOINTMENT_SESSION_BASE64}_FULFILLED`: {
      return {
        ...state,
        appointmentSession: {
          ...state.appointmentSession,
          isFetching: false,
          didInvalidate: false,
          data: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default reports;
