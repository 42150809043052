import axios from "axios";
import { refreshToken, signoutUser } from "../actions/login";

let isRefreshing = false;
let refreshPromise = null;

let failedError401Request = []

// Funcion para continuar las demas requests:
const processRequestQueue = (error, newToken = null) => {
  failedError401Request.forEach(prom => {
    if (error) {
      // Caso error ejecuta el catch (devuelve error)
      prom.reject(error);
    } else {
      // Caso logrado llama al then (reintenta request)
      prom.resolve(newToken);
    }
  });

  failedError401Request = [];
};


const configureAxiosInterceptor = (store) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const state = store.getState();
      const isAuthenticated = !!state.auth.token;

      if (error.response && error.response.status === 401 && isAuthenticated) {
        // Caso primer 401
        if (!isRefreshing) {
          isRefreshing = true;
          // Refresh token request
          refreshPromise = axios
            .get("/account/refreshToken")
            .then((response) => {
              // Actualizacion token
              store.dispatch(refreshToken(response.data));
              const token = response.data.token;
              // Reintentar todas las otras request con error 401
              processRequestQueue(null, token);
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${response.data.token}`;

              return axios(originalRequest);
            })
            .catch((refreshError) => {
              // Rechazar otras request con error 401 (Es necesario para controlar las request que fallaron anteriormente)

              processRequestQueue(refreshError);
              store.dispatch(signoutUser());
              return Promise.reject(refreshError);
            })
            .finally(() => {
              isRefreshing = false;
            });

          return refreshPromise.catch((e) => Promise.reject(e));
        }

        // Caso otro 401 mientras se esta haciendo refresh token 

        // Esta promise es finalizada una vez termine la request para obtener refresh Token
        return new Promise((resolve, reject) => {
          failedError401Request.push({ resolve, reject })
        })
          .then((token) => {
            // Reintentar request
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${token}`;
            return axios(originalRequest)
          })
          .catch((error) => {
            // Rechazar normalmente
            return Promise.reject(error);
          })
      }
      return Promise.reject(error);
    },
  );
};

const createRefreshTokenMiddleware = (store) => {

  // Configurar interceptor
  configureAxiosInterceptor(store);

  return (next) => (action) => {
    if (action.type.includes("persist")) return next(action);

    return next(action);


  };
};

export default createRefreshTokenMiddleware;
