import { MenuItem } from "@material-ui/core";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormDiagnosis,
  getFormNaturalezasLesion,
  getFormZonasAfectadas,
} from "../../../../actions/forms";
import {
  getCantidadDiasBajaMedica,
  getFechaMasReciente,
} from "../../../../utils/datetimeUtils";
import DarkerDisabledTextField from "../../../commons/formComponents/darkerDisabledTextField";
import FormikActions from "../../../commons/formik/formikActions";
import FormikAutocomplete from "../../../commons/formik/formikAutocomplete";
import FormikDateTimePicker from "../../../commons/formik/formikDateTimePicker";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextFieldSpeech from "../../../commons/formik/formikTextFieldSpeech";
import AppointmentControl from "../appointmentControl/appointmentControl";
import DropZone from "../dropZone/dropZone";
import FormSectionDivider from "../formSectionDivider";
import { useStyles } from "../formStyle";
import { ietValidation, validateRequiredFields } from "../formValidation";
import InternmentContainer from "../internment/InternmentContainer";
import ProfessionalControl from "../professionalControl/professionalControl";

const Iet = ({
  handleClose,
  files,
  setFiles,
  handleSubmit,
  model,
  formValues,
  selectedDocument,
  openAppointment,
  handleAppointmentSubmit,
  handleCloseAppointment,
  handleOpenAppointment,
  appointmentModel,
  isView,
  disabled,
  idArt,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const naturalezasLesion = useSelector(
    (state) => state.forms.naturalezasLesion,
  );
  const zonasAfectadas = useSelector((state) => state.forms.zonasAfectadas);
  const [professionalError, setProfessionalError] = useState(false);
  const [attachmentError, setAttachmentError] = useState(false);

  const fields = model.fields;

  const fechaAtencionMinDate =
    fields.fechaAtencion.validations && fields.fechaAtencion.validations.minDate
      ? fields.fechaPrimeraCuracion.options
        ? getFechaMasReciente(
          fields.fechaAtencion.validations.minDate,
          fields.fechaPrimeraCuracion.options,
        )
        : fields.fechaAtencion.validations.minDate
      : fields.fechaPrimeraCuracion.options
        ? fields.fechaPrimeraCuracion.options
        : undefined;

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      validationSchema={() => ietValidation(fechaAtencionMinDate)}
      validate={(values) => validateRequiredFields(values, fields)}
      onSubmit={(values) => {
        if (model.configuration.requiresAttachment && files.length === 0) {
          setAttachmentError(true);
        } else if (!professionalError) {
          handleSubmit(values);
        }
      }}>
      {({ values, setFieldValue }) => (
        <Form className={classes.container}>
          <div className={classes.fieldContainer}>
            <FormSectionDivider title="Evolución" />
            {model.configuration.showTypeFormCombo && (
              <div className={classes.rowContainer}>
                <FormikSelect
                  name="nombreTipoFormulario"
                  label={`Tipo de Formulario Especifico ${fields.nombreTipoFormulario.required ? "*" : ""
                    }`}
                  value={formValues.nombreTipoFormulario}
                  variant="outlined"
                  className={classes.input}
                  callBack={(e) => {
                    const selectedFormulario =
                      fields.tiposFormulario.options.find(
                        (f) => f.nombre === e,
                      );
                    setFieldValue(
                      "representa",
                      selectedFormulario?.representa?.trim(),
                    );
                  }}
                  disabled={disabled}>
                  {fields.tiposFormulario.options.map((f) => (
                    <MenuItem key={f.nombre} value={f.nombre}>
                      {f.formulario} - {f.nombre}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </div>
            )}
            <div className={classes.rowContainer}>
              <div className={classes.leftColumnContainer}>
                <FormikDateTimePicker
                  name="fechaAtencion"
                  label={`Fecha Hora de Atención ${fields.fechaAtencion.required ? "*" : ""
                    }`}
                  inputVariant="outlined"
                  disableFuture
                  className={classes.input}
                  disabled={disabled}
                  minDate={fechaAtencionMinDate}
                  autoComplete="off"
                />
              </div>
              <div className={classes.rightColumnContainer}>
                <AppointmentControl
                  addAppointmentControl={
                    model.configuration.addAppointmentControl
                  }
                  appointmentModel={appointmentModel}
                  name="fechaHoraNuevoControl"
                  handleAppointmentSubmit={handleAppointmentSubmit}
                  openAppointment={openAppointment}
                  handleOpenAppointment={handleOpenAppointment}
                  handleCloseAppointment={handleCloseAppointment}
                  especialidadesProfesional={fields.especialidadProfesional}
                  required={fields.fechaHoraNuevoControl.required}
                  disabled={disabled}
                  idArt={idArt}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="nroFormularioFPA"
                label={`Pedido de Autorización para asociar ${fields.nroFormularioFPA.required ? "*" : ""
                  }`}
                variant="outlined"
                options={fields.nroFormularioFPA.options}
                className={classes.input}
                renderOption={(option) =>
                  `${moment(option.fecha).format("DD/MM/YYYY HH:mm")} ${option.estado ? "-" : ""
                  } ${option.estado || ""} ${option.detalle ? "-" : ""}  ${option.detalle || ""
                  }`
                }
                labelOption={(option) =>
                  `${moment(option.fecha).format("DD/MM/YYYY HH:mm")} ${option.estado ? "-" : ""
                  } ${option.estado || ""}`
                }
                filterOptions={(option) =>
                  moment(option.fecha).format("DD/MM/YYYY HH:mm") +
                  option.estado +
                  option.detalle
                }
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="observaciones"
                name="observaciones"
                label={`Evolución Tratamiento o Prácticas Realizadas ${fields.observaciones.required ? "*" : ""
                  }`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>

            {model.configuration.showDaysOff && (
              <div className={classes.rowContainer}>
                <DarkerDisabledTextField
                  label="Días de Baja"
                  type="text"
                  variant="outlined"
                  value={getCantidadDiasBajaMedica(
                    values.fechaAtencion,
                    fields.fechaPrimeraCuracion.options,
                  )}
                  className={classes.input}
                />
              </div>
            )}
            {formValues.tratamientoAnterior ? (
              <div className={classes.rowContainer}>
                <DarkerDisabledTextField
                  label="Tratamiento Anterior"
                  type="text"
                  variant="outlined"
                  value={formValues.tratamientoAnterior}
                  multiline
                  className={classes.input}
                />
              </div>
            ) : (
              !isView && fields.tratamientoAnterior.options && (
                <div className={classes.rowContainer}>
                  <DarkerDisabledTextField
                    label="Tratamiento Anterior"
                    type="text"
                    variant="outlined"
                    value={fields.tratamientoAnterior.options}
                    multiline
                    className={classes.input}
                  />
                </div>
              )
            )}
            <FormSectionDivider title="Diagnóstico" />
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="diagnosticoOMS"
                label={`Diagnóstico OMS ${fields.diagnosticoOMS.required ? "*" : ""
                  }`}
                variant="outlined"
                options={diagnosis.data}
                getOptions={() => dispatch(getFormDiagnosis(idArt))}
                className={classes.input}
                renderOption={(option) => `${option.id} - ${option.nombre}`}
                filterOptions={(option) => option.id + option.nombre}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikTextFieldSpeech
                id="diagnostico"
                name="diagnostico"
                label={`Diagnóstico Actual ${fields.diagnostico.required ? "*" : ""
                  }`}
                type="text"
                variant="outlined"
                multiline
                rows={3}
                rowsMax={10}
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="naturalezaLesion"
                label={`Naturaleza de la Lesión ${fields.naturalezaLesion.required ? "*" : ""
                  }`}
                variant="outlined"
                options={naturalezasLesion.data}
                getOptions={() =>
                  dispatch(getFormNaturalezasLesion(idArt))
                }
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <div className={classes.rowContainer}>
              <FormikAutocomplete
                name="zonaAfectada"
                label={`Zona Afectada ${fields.zonaAfectada.required ? "*" : ""
                  }`}
                variant="outlined"
                options={zonasAfectadas.data}
                getOptions={() =>
                  dispatch(getFormZonasAfectadas(idArt))
                }
                className={classes.input}
                disabled={disabled}
              />
            </div>
            <FormSectionDivider title="Profesional" />
            <ProfessionalControl
              disabled={disabled}
              fields={fields}
              values={values}
              setFieldValue={setFieldValue}
              profesionalPreCarga={formValues.profesionalApellidoNombre}
              error={professionalError}
              setError={setProfessionalError}
              especialidadesProfesional={fields.especialidadProfesional}
              habilitarEspecialidadInforme={true}
              isView={isView}
              idArt={idArt}
            />

            {model.fields.internacion?.options?.find(
              (intern) => intern == values.representa,
            ) && (
                <>
                  <FormSectionDivider title="Internación" />
                  <InternmentContainer
                    fields={fields}
                    ietModel={formValues}
                    representa={values.representa}
                  />
                </>
              )}

            {model.configuration.showAttachments && (
              <div>
                <FormSectionDivider title="Adjuntos" />
                <div className={classes.rowContainer}>
                  <DropZone
                    files={files}
                    setFiles={setFiles}
                    configuration={model.configuration}
                    error={attachmentError}
                    setError={setAttachmentError}
                    disabled={disabled}
                    selectedDocument={selectedDocument}
                    accept={model.configuration.availableExtensions}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classes.actionsContainer}>
            <FormikActions handleCancel={handleClose} disabled={disabled} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Iet;
