export const downloadPdf = (base64String, nombreArchivo) => {
  var decodedData = atob(base64String);
  var byteArray = new Uint8Array(decodedData.length);
  for (var i = 0; i < decodedData.length; i++) {
    byteArray[i] = decodedData.charCodeAt(i);
  }
  var blob = new Blob([byteArray], { type: "application/pdf" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = nombreArchivo;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadImage = (base64Data, name) => {
  const fullBase64 = `data:image/png;base64,${base64Data}`;
  const url = fullBase64;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const downloadAttachmentForm = (file) => {
  if (file.mimeType == "application/pdf") {
    downloadPdf(file.value, file.filename);
  } else if (file.mimeType.split("/")[0] === "image") {
    downloadImage(file.value, file.filename);
  } else if (file.mimeType.includes("excel") || file.mimeType.includes("csv")) {
    downloadBase64AsCsv(file.value, file.filename);
  } else {
    downloadFile(file.value, file.filename);
  }
};

export const downloadFile = (file, name) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const downloadCsv = (data, filename) => {
  const encoding = "data:text/csv;charset=utf-8,%EF%BB%BF";
  const link = document.createElement("a");
  link.setAttribute("href", encoding + escape(data));
  link.setAttribute("download", `${filename}.csv`);

  document.body.appendChild(link); // Required for FF

  link.click();
};

export const downloadBase64AsCsv = (base64string, filename) => {
  // Es necesario, para los archivos CSV, transformar el base64
  // en un array de bytes. Si no hicieramos este paso intermedio
  // y llamaramos a downloadFile(file.value, file.filename), el CSV
  // producido consistiria de una string en base64 y nada mas.
  // Esto es debido a que Blob() espera una string en UNICODE,
  // pero base64 es ASCII.
  const fileDataAsUnicodeArray = new Uint8Array(
    Array.from(atob(base64string), (char) => char.charCodeAt(0)),
  );

  downloadFile(fileDataAsUnicodeArray, filename);
};
