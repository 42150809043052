import { GET_EMAILS } from "../actions/emails";
import { GET_EMAIL_CONTENT } from "../actions/emails";

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  list: [],
  isFetchingContent: false,
  didInvalidateContent: false,
};

const emails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_EMAILS}_REJECTED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        token: false,
      };
    case `${GET_EMAILS}_PENDING`:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case `${GET_EMAILS}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        list: action.payload.data.emails.map((email) => {
          return {
            ...email,
            parsedHasAdjunto: email.hasAdjunto ? "Si" : "No",
          };
        }),
      };
    case `${GET_EMAIL_CONTENT}_REJECTED`:
      return {
        ...state,
        isFetchingContent: false,
        didInvalidateContent: true,
        token: false,
      };
    case `${GET_EMAIL_CONTENT}_PENDING`:
      return {
        ...state,
        isFetchingContent: true,
        didInvalidateContent: false,
      };
    case `${GET_EMAIL_CONTENT}_FULFILLED`:
      const emails = state.list;
      emails.find((e) => e.idEmailEnviado === action.payload.emailId)["content"] = action.payload.data.emailContent;

      return {
        ...state,
        isFetchingContent: false,
        didInvalidateContent: false,
        list: emails,
      };
    default:
      return state;
  }
};

export default emails;
