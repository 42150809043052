import { Card, CardContent, makeStyles } from "@material-ui/core";
import moment from "moment";
import React from "react";

const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    padding: "16px",
    marginLeft: "32px",
    marginRight: "16px",
  },
  details: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    padding: "16px",
    borderBottom: "solid 1px #d1d1d1",
  },
  textTitle: {
    fontWeight: "bold",
  },
  textValue: {
    fontWeight: 300,
  },
});

const DetailsTableAccidents = ({ rowData }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Card>
        <CardContent>
          <div className={classes.details}>
            <div className={classes.textTitle}>Administrador</div>
            <div className={classes.textValue}>
              {rowData.administrador || "---"}
            </div>
          </div>

          <div className={classes.details}>
            <div className={classes.textTitle}>Empleador</div>
            <div className={classes.textValue}>
              {rowData.empleador} ({rowData.cuitEmpleador || "---"})
            </div>
          </div>

          <div className={classes.details}>
            <div className={classes.textTitle}>Alta</div>
            <div className={classes.textValue}>
              {rowData.fechaAlta
                ? moment(rowData.fechaAlta).format("DD/MM/YYYY")
                : "---"}
            </div>
          </div>

          <div className={classes.details}>
            <div className={classes.textTitle}>Primera Curación</div>
            <div className={classes.textValue}>
              {rowData.fechaPrimeraCuracion
                ? moment(rowData.fechaPrimeraCuracion).format("DD/MM/YYYY")
                : "---"}
            </div>
          </div>

          <div className={classes.details}>
            <div className={classes.textTitle}>Estado</div>
            <div className={classes.textValue}>{rowData.estado || "---"}</div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DetailsTableAccidents;
