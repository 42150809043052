import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import YouTube from "react-youtube";

const IconLeftExpansionPanelSummary = withStyles({
  expandIcon: {
    order: -1,
    marginLeft: -20,
    marginRight: 5,
  },
})(AccordionSummary);

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontFamily: "Roboto, sans-serif",
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  panelDetailContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function TutorialPanel({ videoId, title, subtitle, id }) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const newId = `pnl${id}`;
  const opts = {
    width: "640",
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion expanded={expanded === newId} onChange={handleChange(newId)}>
      <IconLeftExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.headerContainer}>
          <Typography className={classes.heading}>{title}</Typography>
          <Typography className={classes.secondaryHeading}>
            {subtitle}
          </Typography>
        </div>
      </IconLeftExpansionPanelSummary>
      <AccordionDetails className={classes.panelDetailContainer}>
        <YouTube videoId={videoId} opts={opts} />
      </AccordionDetails>
    </Accordion>
  );
}
