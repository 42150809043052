import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAccident } from "../../../../actions/accidentDashboard";
import {
  cleanAccidentForm,
  getAccidentFacadeAndOpen,
  getAccidentForm,
} from "../../../../actions/accidents";
import { getDocumentTypes } from "../../../../actions/documentType";
import { getFormDiagnosis, getFormEmployers } from "../../../../actions/forms";
import snackbarUtils from "../../../../utils/snackbarUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import AccidentFormContainer from "../../forms/accident/accidentFormContainer";
import AccidentDashboardContainer from "../accidentDashboard/accidentDashboardContainer";
import AccidentsFacadeAndOpen from "./facadeAndOpenAccidents/accidentsFacadeAndOpen";
import NewAccident from "./newAccident";

const useStyles = makeStyles({
  loading: {
    marginTop: 100,
  },
});

const NewAccidentContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const art = useSelector((state) => state.art);
  const documentType = useSelector((state) => state.documentType.documentType);
  const facadeAndOpenState = useSelector(
    (state) => state.accidents.facadeAndOpen,
  );

  const [openForm, setOpenForm] = useState(false);
  const [openAccidentOpen, setOpenAccidentOpen] = useState(false);
  const [openAccidents, setOpenAccidents] = useState([]);
  const [facadeAccidents, setFacadeAccidents] = useState([]);
  const [facadeAccident, setFacadeAccident] = useState(null);
  const [artId, setArtId] = useState(null);
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [numeroDocumento, setNumeroDocumento] = useState("");
  const [displayNewAccident, setDisplayNewAccident] = useState("block");

  useEffect(() => {
    if (documentType.data.length === 0 && !documentType.isFetching) {
      dispatch(getDocumentTypes());
    }
  }, []);

  const handleSubmitSearch = (values) => {
    setArtId(values.art.id);
    switch (values.art.metodoCargaSiniestros) {
      case "M":
        dispatch(
          getAccidentForm(
            values.art.id,
            values.tipoDocumento,
            values.numeroDocumento,
            null,
            null,
          ),
        );
        dispatch(getFormEmployers(values.art.id));
        setTipoDocumento(values.tipoDocumento);
        setNumeroDocumento(values.numeroDocumento);
        setOpenForm(true);
        break;
      case "F":
        dispatch(
          getAccidentFacadeAndOpen(
            values.art.id,
            values.tipoDocumento,
            values.numeroDocumento,
          ),
        ).then((res) => {
          handleOpenAccidentOpen(
            res.value.data.openAccidents,
            res.value.data.facadeAccidents,
            values.art.id,
          );
        });
        break;
      default:
        snackbarUtils.error(
          "No se pueden cargar siniestros para la ART seleccionada.",
        );
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFacadeAccident(null);
    setTipoDocumento("");
    setNumeroDocumento("");
    dispatch(cleanAccidentForm());
  };

  const handleSubmitForm = (ret) => {
    handleCloseForm();
    if (Array.isArray(ret)) {
      handleOpenAccidentOpen(ret, [], null);
    } else {
      snackbarUtils.success("Siniestro ingresado correctamente");
      handleView(ret);
    }
  };

  const handleCloseAccidentOpen = () => {
    setOpenAccidentOpen(false);
    setOpenAccidents([]);
    setFacadeAccidents([]);
  };

  const handleOpenAccidentOpen = (openAccidents, facadeAccidents, artId) => {
    if (openAccidents.length === 0 && facadeAccidents.length === 1) {
      handleAddFacade(facadeAccidents[0], artId);
    } else {
      setOpenAccidents(openAccidents);
      setFacadeAccidents(facadeAccidents);
      setOpenAccidentOpen(true);
    }
  };

  const handleView = (accident) => {
    dispatch(
      setSelectedAccident({
        mlCloudCoreArtId: artId,
        editable: true,
        ...accident,
      }),
    );
    handleCloseAccidentOpen();
    setDisplayNewAccident("none");
  };

  const handleAddFacade = (accident, artIdParam) => {
    const artIdAux = artIdParam || artId;
    setFacadeAccident(accident);
    dispatch(
      getAccidentForm(artIdAux, null, null, null, accident.diagnosticoOMS),
    );
    dispatch(getFormEmployers(artIdAux));
    dispatch(getFormDiagnosis(artIdAux));
    setOpenForm(true);
    handleCloseAccidentOpen();
  };

  return (
    <div>
      {displayNewAccident === "block" ? (
        art.isFetching ||
        documentType.isFetching ||
        facadeAndOpenState.isFetching ? (
          <div className={classes.loading}>
            <CircularLoading />
          </div>
        ) : (
          <NewAccident
            handleSubmit={handleSubmitSearch}
            arts={art.artsAdhered}
            documentTypes={documentType.data}
          />
        )
      ) : (
        <AccidentDashboardContainer
          setDisplayAccidents={setDisplayNewAccident}
        />
      )}
      <AccidentFormContainer
        open={openForm}
        artId={artId}
        handleCloseForm={handleCloseForm}
        handleSubmitForm={handleSubmitForm}
        facadeAccident={facadeAccident}
        tipoDocumento={tipoDocumento}
        numeroDocumento={numeroDocumento}
      />
      <AccidentsFacadeAndOpen
        open={openAccidentOpen}
        handleClose={handleCloseAccidentOpen}
        openAccidents={openAccidents}
        facadeAccidents={facadeAccidents}
        handleView={handleView}
        handleAdd={handleAddFacade}
      />
    </div>
  );
};

export default NewAccidentContainer;
